const module = 'COINS_AND_CHAINS';

export const GET_CHAINS_PENDING = `${module}/GET_CHAINS_PENDING`;
export const GET_CHAINS_SUCCESS = `${module}/GET_CHAINS_SUCCESS`;
export const GET_CHAINS_ERROR = `${module}/GET_CHAINS_ERROR`;

export const GET_COINS_PENDING = `${module}/GET_COINS_PENDING`;
export const GET_COINS_SUCCESS = `${module}/GET_COINS_SUCCESS`;
export const GET_COINS_ERROR = `${module}/GET_COINS_ERROR`;

export const GET_PRESALE_COINS_PENDING = `${module}/GET_PRESALE_COINS_PENDING`;
export const GET_PRESALE_COINS_SUCCESS = `${module}/GET_PRESALE_COINS_SUCCESS`;
export const GET_PRESALE_COINS_ERROR = `${module}/GET_PRESALE_COINS_ERROR`;

export const GET_COIN_PENDING = `${module}/GET_COIN_PENDING`;
export const GET_COIN_SUCCESS = `${module}/GET_COIN_SUCCESS`;
export const GET_COIN_ERROR = `${module}/GET_COIN_ERROR`;

export const ADD_COIN_PENDING = `${module}/ADD_COIN_PENDING`;
export const ADD_COIN_SUCCESS = `${module}/ADD_COIN_SUCCESS`;
export const ADD_COIN_ERROR = `${module}/ADD_COIN_ERROR`;

export const EDIT_COIN_PENDING = `${module}/EDIT_COIN_PENDING`;
export const EDIT_COIN_SUCCESS = `${module}/EDIT_COIN_SUCCESS`;
export const EDIT_COIN_ERROR = `${module}/EDIT_COIN_ERROR`;

export const CHANGE_BLOCK_DEPOSIT_PENDING = `${module}/CHANGE_BLOCK_DEPOSIT_PENDING`;
export const CHANGE_BLOCK_DEPOSIT_SUCCESS = `${module}/CHANGE_BLOCK_DEPOSIT_SUCCESS`;
export const CHANGE_BLOCK_DEPOSIT_ERROR = `${module}/CHANGE_BLOCK_DEPOSIT_ERROR`;

export const CHANGE_BLOCK_WITHDRAW_PENDING = `${module}/CHANGE_BLOCK_WITHDRAW_PENDING`;
export const CHANGE_BLOCK_WITHDRAW_SUCCESS = `${module}/CHANGE_BLOCK_WITHDRAW_SUCCESS`;
export const CHANGE_BLOCK_WITHDRAW_ERROR = `${module}/CHANGE_BLOCK_WITHDRAW_ERROR`;

export const GET_CHAIN_PENDING = `${module}/GET_CHAIN_PENDING`;
export const GET_CHAIN_SUCCESS = `${module}/GET_CHAIN_SUCCESS`;
export const GET_CHAIN_ERROR = `${module}/GET_CHAIN_ERROR`;

export const ADD_CHAIN_PENDING = `${module}/ADD_CHAIN_PENDING`;
export const ADD_CHAIN_SUCCESS = `${module}/ADD_CHAIN_SUCCESS`;
export const ADD_CHAIN_ERROR = `${module}/ADD_CHAIN_ERROR`;

export const EDIT_CHAIN_PENDING = `${module}/EDIT_CHAIN_PENDING`;
export const EDIT_CHAIN_SUCCESS = `${module}/EDIT_CHAIN_SUCCESS`;
export const EDIT_CHAIN_ERROR = `${module}/EDIT_CHAIN_ERROR`;

export const DELETE_COIN_PENDING = `${module}/DELETE_COIN_PENDING`;
export const DELETE_COIN_SUCCESS = `${module}/DELETE_COIN_SUCCESS`;
export const DELETE_COIN_ERROR = `${module}/DELETE_COIN_ERROR`;

export const DELETE_CHAIN_PENDING = `${module}/DELETE_CHAIN_PENDING`;
export const DELETE_CHAIN_SUCCESS = `${module}/DELETE_CHAIN_SUCCESS`;
export const DELETE_CHAIN_ERROR = `${module}/DELETE_CHAIN_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;
export const SET_COIN_FIELD = `${module}/SET_COIN_FIELD`;
export const SET_CHAIN_FIELD = `${module}/SET_CHAIN_FIELD`;
export const RESET_COIN_STATE = `${module}/RESET_COIN_STATE`;