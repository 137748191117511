import React from "react";
import { Container, } from "reactstrap";
import EmptyHeader from '../../../components/Headers/EmptyHeader'
import DarkTable from "../../../components/Table"

class Entries extends React.Component {

  componentDidMount() {
    this.props.getDistributionEntries(this.props.match.params.distribution_id || '');
  }

  onPagingClick = (ev, page) => {
    this.props.getDistributionEntries(this.props.match.params.distribution_id || '', { page: page });
  }

  render() {
    return (
      <>
        <EmptyHeader />
        <Container className="mt--6" fluid>
          <DarkTable
            tableTitle="Distribution Entries"
            columns={{
              created_at: "Date",
              ref_id: "Distribution ID",
              credit: "Amount",
              user_id: "User ID"
            }}
            actionsColumn={false}
            rows={this.props.entries}
            meta={this.props.meta}
            onPagingClick={this.onPagingClick}
          />
        </Container>
      </>
    );
  }
}

export default Entries;
