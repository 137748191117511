import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Users from '../components/index';
import State from '../../../redux/Users';
import LoginState from '../../../redux/Login';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getAllUsers: State.actions.getAllUsers,
            getTotalUsersLineLevels: State.actions.getTotalUsersLineLevels,
            block: State.actions.blockUser,
            cancelUserOrders: State.actions.cancelUserOrders,
            setField: State.actions.setField,
        },
        dispatch
    );

const mapStateToProps = state => ({
    token: LoginState.selectors.token(state),
    allUsers: State.selectors.allUsers(state),
    totalUsersLineLevels: State.selectors.totalUsersLineLevels(state),
    meta: State.selectors.meta(state),
    users_query: State.selectors.users_query(state),
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Users);

