import * as c from './constants';
import history from '../../history';
import * as storage from '../../utils/storage';

const initialState = {
  currentUser: {},
  email: "",
  response: "",
  status: {pending: false, success: false, error: false},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_PROFILE_PENDING:
    case c.SET_PASSWORD_PENDING:
        return {
          ...state,
          status: {pending: true, error: false, success: false},
        };
    case c.GET_PROFILE_ERROR:
    case c.SET_PASSWORD_ERROR:
        return {
          ...state,
          status: {pending: false, error: action.payload, success: false},
        };
    case c.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case c.SET_PROFILE_SUCCESS:{
      return {
        ...state,
        status: {pending: false, error: false, success: true},
      };
    }
    case c.SET_PASSWORD_SUCCESS: {
      setTimeout(function () {      
        storage.set('admin_auth_token', '');
        history.push('/auth/login');
      }, 5000);
      return {
        ...state,
        status: {pending: false, error: false, success: true},
      };
    }
    case c.SET_CURRENT_USER_FIELD: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case c.SET_FIELD: { 
      return {
        ...state ,
        [action.payload.field]: action.payload.value,
      }
    }
    default: {
      return {
        ...state
      };
    }
  }
};
