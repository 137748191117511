const module = 'LOGIN';

export const SET_FIELD = `${module}/SET_FIELD`;
export const LOGOUT = `${module}/LOGOUT`;

export const FORGOT_PASS_SUCCESS = `${module}/FORGOT_PASS_SUCCESS`;
export const FORGOT_PASS_PENDING = `${module}/FORGOT_PASS_PENDING`;
export const FORGOT_PASS_ERROR = `${module}/FORGOT_PASS_ERROR`;

export const RESET_PASS_SUCCESS = `${module}/RESET_PASS_SUCCESS`;
export const RESET_PASS_PENDING = `${module}/RESET_PASS_PENDING`;
export const RESET_PASS_ERROR = `${module}/RESET_PASS_ERROR`;

export const LOGIN_SUCCESS = `${module}/LOGIN_SUCCESS`;
export const LOGIN_PENDING = `${module}/LOGIN_PENDING`;
export const LOGIN_ERROR = `${module}/LOGIN_ERROR`;

export const IS2FA_ENABLED_SUCCESS = `${module}/IS2FA_ENABLED_SUCCESS`;
export const IS2FA_ENABLED_PENDING = `${module}/IS2FA_ENABLED_PENDING`;
export const IS2FA_ENABLED_ERROR = `${module}/IS2FA_ENABLED_ERROR`;

export const ISRECAPTCHAENABLED_PENDING = `${module}/ISRECAPTCHAENABLED_PENDING`;
export const ISRECAPTCHAENABLED_ERROR = `${module}/ISRECAPTCHAENABLED_ERROR`;
export const ISRECAPTCHAENABLED_SUCCESS = `${module}/ISRECAPTCHAENABLED_SUCCESS`;

export const USER_PERMISSIONS_PENDING = `${module}/USER_PERMISSIONS_PENDING`;
export const USER_PERMISSIONS_ERROR = `${module}/USER_PERMISSIONS_ERROR`;
export const USER_PERMISSIONS_SUCCESS = `${module}/USER_PERMISSIONS_SUCCESS`;

export const GEETEST_REGISTER_PENDING = `${module}/GEETEST_REGISTER_PENDING`;
export const GEETEST_REGISTER_ERROR = `${module}/GEETEST_REGISTER_ERROR`;
export const GEETEST_REGISTER_SUCCESS = `${module}/GEETEST_REGISTER_SUCCESS`;

export const GEETEST_VALIDATE = `${module}/GEETEST_VALIDATE`;
export const GEETEST_RESET = `${module}/GEETEST_RESET`;
export const GEETEST_EMPTY = `${module}/GEETEST_EMPTY`;

export const REMOVE_TOKEN = `${module}/REMOVE_TOKEN`;