import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import CoinsAndChains from '../components/index';
import CoinsAndChainsState from '../../../redux/CoinsAndChains';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getChains: CoinsAndChainsState.actions.getChains,
            getCoins: CoinsAndChainsState.actions.getCoins,
            getCoin: CoinsAndChainsState.actions.getCoin,
            addCoin: CoinsAndChainsState.actions.addCoin,
            updateCoin: CoinsAndChainsState.actions.editCoin,
            setField: CoinsAndChainsState.actions.setField,
            setCoinField: CoinsAndChainsState.actions.setCoinField,
            resetForm: CoinsAndChainsState.actions.resetForm,
            changeBlockDeposit: CoinsAndChainsState.actions.changeBlockDeposit,
            changeBlockWithdraw: CoinsAndChainsState.actions.changeBlockWithdraw
        },
        dispatch
    );

const mapStateToProps = state => ({ 
    chains: CoinsAndChainsState.selectors.chains(state),
    coins: CoinsAndChainsState.selectors.coins(state),
    coin: CoinsAndChainsState.selectors.coin(state),
    status: CoinsAndChainsState.selectors.status(state),
    
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(CoinsAndChains);

