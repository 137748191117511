import React from 'react';
import {Container, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import { hasPermission } from '../../utils';

export default class TabsHeader extends React.Component {
  getTabsAndLinks = () => {
    return [
      {name: 'Withdrawals', link: '/manage-wallets', permission: 'withdraw.view.all'},
      {name: 'Manual Withdrawals', link: '/manage-wallets/manual-withdrawals', permission: 'transaction.view.all'},
      {name: 'Deposits', link: '/manage-wallets/deposits', permission: 'transaction.view.all'},
      {name: 'Manual Deposits', link: '/manage-wallets/manual-deposits', permission: 'transaction.view.all'},
      // {name: 'Operations', link: '/manage-wallets/operatiosn', permission: 'transaction.view.all'},
      {name: 'Statistics', link: '/manage-wallets/stats', permission: 'transaction.view.all'},
    ].filter(item => hasPermission(item.permission));
  };

  render () {
    const {match} = this.props;
    const tabs = this.getTabsAndLinks ();

    return (
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <Nav tabs>
            {tabs.map (item => (
              <NavItem key={item.link}>
                <NavLink
                  className={classnames('nav-item', { active: match.path === item.link })}
                  href={item.link}
                >
                  {item.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Container>
      </div>
    );
  }
}
