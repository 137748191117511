import React, { useState, useMemo } from 'react';
import { Button, Card, CardTitle } from 'reactstrap';

import { CardInfo } from './CardInfo';
import { getBasicField, getRestFields } from '../../utils/shuftiPro';

const ShuftiPro = ({ data }) => {
  const showEmptyField = false;
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);

  const listCompany = useMemo(() => {
    if (!data) return [];

    const list = Object.keys(data).filter((key) => isNaN(Number(data[key])));
    setSelectedCompanyName(list[0]);
    return list;
  }, [data]);

  const basicInfoFields = useMemo(() => {
    if (!data || !selectedCompanyName) return null;

    return getBasicField(data[selectedCompanyName]);
  }, [data, selectedCompanyName]);

  const restFields = useMemo(() => {
    if (!data || !selectedCompanyName) return null;

    return getRestFields(data[selectedCompanyName]);
  }, [data, selectedCompanyName]);

  if (!data) {
    return <h4>No data</h4>;
  }

  return (
    <div className="shufti-pro">
      <Card className="card-info">
        <div className="d-flex flex-column flex-direction m-3">
          <CardTitle className="text-capitalize card-header p-3 mb-3">
            <h5 className="m-0">
              Compan{listCompany.length > 1 ? 'ies' : 'y'}
            </h5>
          </CardTitle>
          <div className="d-flex flex-wrap gap-1">
            {listCompany.map((key, index) => (
              <Button
                key={index}
                onClick={() => setSelectedCompanyName(key)}
                color={selectedCompanyName === key ? 'primary' : 'secondary'}
                className="mb-3"
              >
                {data[key]['company_name']}
              </Button>
            ))}
          </div>
        </div>
      </Card>

      <CardInfo
        cardHeader="basic_info"
        data={basicInfoFields}
        showEmptyField={showEmptyField}
      />

      <CardInfo data={restFields} showEmptyField={showEmptyField} />
    </div>
  );
};

export default ShuftiPro;
