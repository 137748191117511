import React from "react";
import { Col, Form, Row } from "reactstrap";
import { renderABasicInput, renderHtmlEditor } from "../Inputs";

export default class MaintenanceForm extends React.Component {
  render() {
    const { object } = this.props;

    return (
      <>
        {object ? (
          <Form>
            <Row>
              <Col md="12">
                {renderABasicInput(
                  this.props,
                  "Title",
                  "title",
                  "text",
                  false,
                  0,
                  { md: "2" },
                  { md: "10" }
                )}
              </Col>
            </Row>

            <Row>
              <Col md="12">
                {renderHtmlEditor(
                  this.props,
                  "Message",
                  "message",
                  false,
                  { md: "2" },
                  { md: "10" }
                )}
              </Col>
            </Row>
          </Form>
        ) : null}
      </>
    );
  }
}
