import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Roles from '../components/index';
import RolesState from '../../../redux/Roles';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getRoles: RolesState.actions.getRoles,
            removeRole: RolesState.actions.removeRole,
            resetForm: RolesState.actions.resetForm,
        },
        dispatch
    );

const mapStateToProps = state => ({
    status: RolesState.selectors.status (state),
    roles: RolesState.selectors.roles (state),
    rolesMeta: RolesState.selectors.rolesMeta (state)
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Roles);

