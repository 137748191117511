import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  NavLink,
  Row,
  Col
} from "reactstrap";
import Can from "../../components/Permissions";

class CardsHeader extends React.Component {
  render() {
    const { data, classNames = 'pb-6' } = this.props;
    return (
      <>
        { data ?
        <div className={`header bg-info ${classNames}`}>
          <Container fluid>
            <div className="header-body">
              <Row>
              <Can run="transaction.view.all">
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <NavLink href={"/orders-from-market"}>
                        <Row>
                          <div className="col"> 
                            <CardTitle
                              // tag="h5"
                              className="title text-muted mb-0"
                            >
                              Number of orders
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              { data.orders_count }
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                              <i className="ni ni-active-40" />
                            </div>
                          </Col>
                        </Row>
                      </NavLink>
                    </CardBody>
                  </Card>
                </Col>
                </Can>
                <Can run="user.view">
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <NavLink href="/manage-users">
                        <Row>
                          <div className="col">
                            <CardTitle
                              // tag="h5"
                              className="title text-muted mb-0"
                            >
                              Number of users
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                            { data.users_total }
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                              <i className="ni ni-chart-pie-35" />
                            </div>
                          </Col>
                        </Row>
                      </NavLink>
                    </CardBody>
                  </Card>
                </Col>
                </Can>
                <Can run="withdraw.view.all">
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <NavLink href="#withdraw">
                        <Row>
                          <div className="col">
                            <CardTitle
                              // tag="h5"
                              className="title text-muted mb-0"
                            >
                            Withdrawal email pending confirm
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{ data.withdrawals_count }</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                              <i className="ni ni-money-coins" />
                            </div>
                          </Col>
                        </Row>
                      </NavLink>
                    </CardBody>
                  </Card>
                </Col>
                </Can>
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <NavLink  href="/bots">
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="title text-muted mb-0"
                            >
                            Number of active bots
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{ data.activeBotsCount || 0 }</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                              <i className="ni ni-money-coins" />
                            </div>
                          </Col>
                        </Row>
                      </NavLink>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        : null }
      </>
    );
  }
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default CardsHeader;
