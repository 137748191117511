import * as ac from './actionCreators';
import api from '../../api';
import { isEmpty } from 'lodash';

export function getManualDistributions(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getManualDistributions.pending());
    try {
      const token = getState().Login.token;
      const data = await api.distributions.getManualDistributions(payload, token);
      dispatch(ac.getManualDistributions.success(data));
    } catch (err) {
      dispatch(ac.getManualDistributions.error(err));
    }
  };
}

export function getManualDistribution(distribution_id, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getManualDistribution.pending());
    try {
      const token = getState().Login.token;
      const data = await api.distributions.getManualDistribution(distribution_id, payload, token);
      dispatch(ac.getManualDistribution.success(data));
    } catch (err) {
      dispatch(ac.getManualDistribution.error(err));
    }
  };
}

export function getDistributionTotalPrdxForAllLines(distributionId) {
  return async (dispatch, getState) => {
    dispatch(ac.getDistributionTotalPrdxForAllLines.pending());
    try {
      const token = getState().Login.token;
      const data = await api.distributions.getDistributionTotalPrdxForAllLines(distributionId, token);
      dispatch(ac.getDistributionTotalPrdxForAllLines.success(data));
    } catch (err) {
      dispatch(ac.getDistributionTotalPrdxForAllLines.error(err));
    }
  };
}

export function completeManualDistribution(distribution_id, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.completeManualDistribution.pending());
    try {
      const token = getState().Login.token;
      const data =  await api.distributions.completeManualDistribution(distribution_id, payload, token);
      dispatch(ac.completeManualDistribution.success(data));
    } catch (err) {
      dispatch(ac.completeManualDistribution.error(err));
    }
  };
}

export function getManualDistributionFunds(distribution_id, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getManualDistributionFunds.pending());
    try {
      const token = getState().Login.token;
      const data = await api.distributions.getManualDistributionFunds(distribution_id, payload, token);
      dispatch(ac.getManualDistributionFunds.success(data));
    } catch (err) {
      dispatch(ac.getManualDistributionFunds.error(err));
    }
  };
}

export function updateManualDistributionFunds(distribution_id, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.updateManualDistributionFunds.pending());
    try {
      const token = getState().Login.token;
      const data = await api.distributions.updateManualDistributionFunds(distribution_id, payload, token);
      dispatch(ac.updateManualDistributionFunds.success(data));
    } catch (err) {
      dispatch(ac.updateManualDistributionFunds.error(err));
    }
  };
}

export function getManualDistributionBalances(distribution_id, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getManualDistributionBalances.pending());
    try {
      let payloadData = {};

      if(!isEmpty(payload)){
        payloadData = {
          page: payload.page,
          level: payload.balancesLevel,
          query:  payload.balancesQuery
        };
      }

      const token = getState().Login.token;
      const data = await api.distributions.getManualDistributionBalances(distribution_id, payloadData, token);
      dispatch(ac.getManualDistributionBalances.success(data));
    } catch (err) {
      dispatch(ac.getManualDistributionBalances.error(err));
    }
  };
}

export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}
