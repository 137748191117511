import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../utils/redux';

export const getAll = asyncActionCreator(c.GET_USERS_PENDING, c.GET_USERS_SUCCESS, c.GET_USERS_ERROR);
export const getTotalUsersLineLevels = asyncActionCreator(c.GET_TOTAL_USERS_LINE_LEVELS_PENDING, c.GET_TOTAL_USERS_LINE_LEVELS_SUCCESS, c.GET_TOTAL_USERS_LINE_LEVELS_ERROR);
export const block = asyncActionCreator(c.BLOCK_USER_PENDING, c.BLOCK_USER_SUCCESS, c.BLOCK_USER_ERROR);
export const getInfo = asyncActionCreator(c.GET_INFO_PENDING, c.GET_INFO_SUCCESS, c.GET_INFO_ERROR);
export const getUser = asyncActionCreator(c.GET_USER_PENDING, c.GET_USER_SUCCESS, c.GET_USER_ERROR);
// KYB
export const getKybStatus = asyncActionCreator(c.GET_KYB_STATUS_PENDING, c.GET_KYB_STATUS_SUCCESS, c.GET_KYB_STATUS_ERROR);
export const updateKYBStepTwo = asyncActionCreator(c.UPDATE_KYB_STEP_TWO_PENDING, c.UPDATE_KYB_STEP_TWO_SUCCESS, c.UPDATE_KYB_STEP_TWO_ERROR);
export const getListOfBusinessMembers = asyncActionCreator(c.GET_BUSINESS_MEMBERS_LIST_PENDING, c.GET_BUSINESS_MEMBERS_LIST_SUCCESS, c.GET_BUSINESS_MEMBERS_LIST_ERROR);
export const downloadKYBDocument = asyncActionCreator(c.DOWNLOAD_KYB_DOCUMENT_PENDING, c.DOWNLOAD_KYB_DOCUMENT_SUCCESS, c.DOWNLOAD_KYB_DOCUMENT_ERROR);
export const getUserFees = asyncActionCreator(c.GET_USER_FEES_PENDING, c.GET_USER_FEES_SUCCESS, c.GET_USER_FEES_ERROR);
export const disableTradePass = asyncActionCreator(c.DISABLE_TRADE_PASS_PENDING, c.DISABLE_TRADE_PASS_SUCCESS, c.DISABLE_TRADE_PASS_ERROR);
export const disableAntiphishingKey = asyncActionCreator(c.DISABLE_ANTIPHISHING_PENDING, c.DISABLE_ANTIPHISHING_SUCCESS, c.DISABLE_ANTIPHISHING_ERROR);
export const disableGoogleAuth = asyncActionCreator(c.DISABLE_GOOGLE_AUTH_PENDING, c.DISABLE_GOOGLE_AUTH_SUCCESS, c.DISABLE_GOOGLE_AUTH_ERROR);
export const disableSMSAuth = asyncActionCreator(c.DISABLE_SMS_AUTH_PENDING, c.DISABLE_SMS_AUTH_SUCCESS, c.DISABLE_SMS_AUTH_ERROR);
export const changeBlockDepositCrypto = asyncActionCreator(c.CHANGE_BLOCK_DEPOSIT_CRYPTO_PENDING, c.CHANGE_BLOCK_DEPOSIT_CRYPTO_SUCCESS, c.CHANGE_BLOCK_DEPOSIT_CRYPTO_ERROR);
export const changeBlockDepositFiat = asyncActionCreator(c.CHANGE_BLOCK_DEPOSIT_FIAT_PENDING, c.CHANGE_BLOCK_DEPOSIT_FIAT_SUCCESS, c.CHANGE_BLOCK_DEPOSIT_FIAT_ERROR);
export const changeBlockWithdrawCrypto = asyncActionCreator(c.CHANGE_BLOCK_WITHDRAW_CRYPTO_PENDING, c.CHANGE_BLOCK_WITHDRAW_CRYPTO_SUCCESS, c.CHANGE_BLOCK_WITHDRAW_CRYPTO_ERROR);
export const changeBlockWithdrawFiat = asyncActionCreator(c.CHANGE_BLOCK_WITHDRAW_FIAT_PENDING, c.CHANGE_BLOCK_WITHDRAW_FIAT_SUCCESS, c.CHANGE_BLOCK_WITHDRAW_FIAT_ERROR);
export const enableDetectIP = asyncActionCreator(c.ON_DETECT_IP_PENDING, c.ON_DETECT_IP_SUCCESS, c.ON_DETECT_IP_ERROR);
export const disableDetectIP = asyncActionCreator(c.OFF_DETECT_IP_PENDING, c.OFF_DETECT_IP_SUCCESS, c.OFF_DETECT_IP_ERROR);

export const cancelUserOrders = asyncActionCreator(c.CANCEL_USER_ORDERS_PENDING, c.CANCEL_USER_ORDERS_SUCCESS, c.CANCEL_USER_ORDERS_ERROR);

export const updateUser = asyncActionCreator(c.UPDATE_USER_PENDING, c.UPDATE_USER_SUCCESS, c.UPDATE_USER_ERROR);
export const updateUserPassword = asyncActionCreator(c.UPDATE_PASSWORD_PENDING, c.UPDATE_PASSWORD_SUCCESS, c.UPDATE_PASSWORD_ERROR);
export const updateWithdrawSettings = asyncActionCreator(c.UPDATE_WITHDRAW_SETTINGS_PENDING, c.UPDATE_WITHDRAW_SETTINGS_SUCCESS, c.UPDATE_WITHDRAW_SETTINGS_ERROR);
export const getWithdrawSettings = asyncActionCreator(c.GET_WITHDRAW_SETTINGS_PENDING, c.GET_WITHDRAW_SETTINGS_SUCCESS, c.GET_WITHDRAW_SETTINGS_ERROR);
export const updateSettings = asyncActionCreator(c.UPDATE_SETTINGS_PENDING, c.UPDATE_SETTINGS_SUCCESS, c.UPDATE_SETTINGS_ERROR);
export const updateFees = asyncActionCreator(c.UPDATE_FEES_PENDING, c.UPDATE_FEES_SUCCESS, c.UPDATE_FEES_ERROR);
export const getUserLogs = asyncActionCreator(c.USER_LOGS_PENDING, c.USER_LOGS_SUCCESS, c.USER_LOGS_ERROR);
export const getUserOrders = asyncActionCreator(c.USER_ORDERS_PENDING, c.USER_ORDERS_SUCCESS, c.USER_ORDERS_ERROR);
export const getUserTrades = asyncActionCreator(c.USER_TRADES_PENDING, c.USER_TRADES_SUCCESS, c.USER_TRADES_ERROR);
export const downloadUserTrades = asyncActionCreator(c.DOWNLOAD_USER_TRADES_PENDING, c.DOWNLOAD_USER_TRADES_SUCCESS, c.DOWNLOAD_USER_TRADES_ERROR);
export const getUserWallet = asyncActionCreator(c.USER_WALLET_PENDING, c.USER_WALLET_SUCCESS, c.USER_WALLET_ERROR);
export const getDepositAddresses = asyncActionCreator(c.USER_WALLET_ADDRS_PENDING, c.USER_WALLET_ADDRS_SUCCESS, c.USER_WALLET_ADDRS_ERROR);
export const getUserDistributions = asyncActionCreator(c.USER_DISTRIBUTIONS_PENDING, c.USER_DISTRIBUTIONS_SUCCESS, c.USER_DISTRIBUTIONS_ERROR);
export const getUserWithdrawals = asyncActionCreator(c.USER_WITHDRAWALS_PENDING, c.USER_WITHDRAWALS_SUCCESS, c.USER_WITHDRAWALS_ERROR);
export const getUserDeposits = asyncActionCreator(c.USER_DEPOSITS_PENDING, c.USER_DEPOSITS_SUCCESS, c.USER_DEPOSITS_ERROR);
export const getUserBonusContracts = asyncActionCreator(c.USER_BONUS_CONTRACTS_PENDING, c.USER_BONUS_CONTRACTS_SUCCESS, c.USER_BONUS_CONTRACTS_ERROR);
export const getUserBots = asyncActionCreator(c.USER_BOTS_PENDING, c.USER_BOTS_SUCCESS, c.USER_BOTS_ERROR);
export const cancelWithdraw = asyncActionCreator(c.USER_CANCEL_WITHDRAW_PENDING, c.USER_CANCEL_WITHDRAW_SUCCESS, c.USER_CANCEL_WITHDRAW_ERROR);

export const setWithdrawSettingsField = actionCreator(c.SET_WITHDRAW_SETTINGS_FIELD);
export const setUserField = actionCreator(c.SET_CURRENT_USER_FIELD);
export const setField = actionCreator(c.SET_FIELD);