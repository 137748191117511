import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Entries from '../components/Entries';
import State from '../../../redux/Distributions';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getDistributionEntries: State.actions.getDistributionEntries,
    },
    dispatch
  );

const mapStateToProps = state => ({
  entries: State.selectors.entries(state),
  meta: State.selectors.meta(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Entries);

