import * as ac from './actionCreators';
import api from '../../api';
import { validateEmail, validateNotEmpty, validatePassword, validateConfirmPassword } from '../../utils/validator';
import { InvalidFields } from '../../utils/errors';
import {logoutOnUnauthorizedError} from '../../utils/auth';



export function getProfileDetails() {
  return async (dispatch, getState) => {
    dispatch(ac.getProfileDetails.pending());
    try {
      const token = getState().Login.token;
      const data = await api.profile.getProfileDetails(token);
      dispatch(ac.getProfileDetails.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getProfileDetails.error(err));
    }
  };
}

export function updatePassword(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.updatePassword.pending());
    try {
      let field_errors = {};
      if (!validatePassword(payload.password)) field_errors.password = 'The password must contain at least one lowercase (a-z) letter, one uppercase (A-Z) letter, one digit (0-9) and one special character.';
      if (!validatePassword(payload.current_password)) field_errors.current_password = 'Field is required.';
      if (!validateConfirmPassword(payload.password, payload.confirm_password))
        field_errors.confirm_password = 'Invalid password confirmation';
      
      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });
      
      const payloadData = {
        current: payload.current_password,
        new: payload.confirm_password
      }

      const token = getState().Login.token;
      const data = await api.profile.updatePassword(payloadData, token);
      dispatch(ac.updatePassword.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.updatePassword.error(err));
    }
  };
}


export function updateProfileDetails(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.updateProfileDetails.pending());
    try {
      let field_errors = {};
      if (!validateEmail(payload.email)) field_errors.email = 'Please enter a valid email address';
      if (validateNotEmpty(payload.password) && !validatePassword(payload.password)) field_errors.password = 'The password must contain at least one lowercase (a-z) letter, one uppercase (A-Z) letter, one digit (0-9) and one special character.';
      if (validateNotEmpty(payload.password) && !validateConfirmPassword(payload.password, payload.confirmPassword))
        field_errors.confirmPassword = 'Invalid password confirmation';
      
      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });
      
      const token = getState().Login.token;
      const data = await api.profile.updateProfileDetails(payload, token);
      dispatch(ac.updateProfileDetails.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.updateProfileDetails.error(err));
    }
  };
}

export function setCurrentUserField(field, value) {
  return dispatch => dispatch(ac.setUserField({field, value}));
}

export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}