import React from 'react';
import UserTabs from 'components/Tabs/user.jsx';

import { Card, Container, CardHeader, CardBody, Input } from 'reactstrap';
import DarkTable from '../../../components/Table';

export default class UsersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false,
      accountFilter: 'main',
    };
  }

  componentDidMount = () => {
    const user_id = this.props.match.params.user_id;
    this.props.getUserWallet({ user_id: user_id });
    this.props.getDepositAddresses(user_id);
    this.props.getCoins();
  };

  searchWallet = (account, e) => {
    this.props.setField('wallet_query_' + account, e.target.value);
  };

  setAccountFilter = (account) => {
    this.setState((state) => ({
      ...state,
      accountFilter: account,
    }));
  };

  getAccountFilterArray = () => {
    const { wallet } = this.props;

    const defaultOptions = [{ label: 'all', value: '' }];

    if (wallet) {
      defaultOptions.push(
        ...Object.keys(wallet).map((account) => ({
          label: account,
          value: account,
        })),
      );
    }

    return defaultOptions.sort((a, b) =>
      new Intl.Collator().compare(b.label, a.label),
    );
  };

  renderViewWalletSection = (account) => {
    const { wallet } = this.props;
    const wallet_query = this.props['wallet_query_' + account] || '';

    const filteredWallet =
      wallet &&
      wallet[account] &&
      wallet[account]['data'].filter((item) =>
        item.symbol.toLowerCase().includes(wallet_query.toLowerCase()),
      );

    return (
      <DarkTable
        light={true}
        inlineSearch={(e) => this.searchWallet(account, e)}
        tableTitle="View Wallet"
        columns={{
          symbol: 'Coin',
          available: 'Available balance',
          locked: 'Locked balance',
          in_orders: 'In Orders',
          total_balance: 'Total balance',
        }}
        rows={filteredWallet}
      />
    );
  };

  renderDepositAddresses = () => {
    const addresses = this.props.coins.map((coin) => {
      let address = '';
      let foundAddress = this.props.addresses.find(
        (item) => item.chain_symbol === coin.chain_symbol,
      );

      if (foundAddress && foundAddress.address) {
        address = foundAddress.address;
      }

      return {
        coin_symbol: coin.symbol,
        address,
      };
    });

    return (
      <DarkTable
        light
        columns={{
          coin_symbol: 'Coin Symbol',
          address: 'Address',
        }}
        rows={addresses}
      />
    );
  };

  render() {
    const { match, wallet } = this.props;
    const { accountFilter } = this.state;

    return (
      <>
        <UserTabs match={match} />
        <Container className="mt--6" fluid>
          <Card className="user_content">
            <Card>
              <CardHeader>Deposit Addresses</CardHeader>
              <CardBody>{this.renderDepositAddresses()}</CardBody>
            </Card>

            {wallet && (
              <>
                <div className="row mt-5 mb-4 px-4">
                  <div className="col-2">
                    <label htmlFor="account">Select Account:</label>
                    <Input
                      id="account"
                      type="select"
                      value={accountFilter}
                      onChange={(e) => this.setAccountFilter(e.target.value)}
                    >
                      {this.getAccountFilterArray().map((option) => (
                        <option
                          key={option.value || option.label}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>

                {Object.keys(wallet).map((account) => {
                  if (accountFilter && accountFilter !== account) {
                    return null;
                  }

                  return (
                    <Card>
                      <CardHeader>
                        <span className="mr-3">[{account}] User Balances</span>
                        {Number(wallet[account].contract_id) > 0 && (
                          <>
                            <span className="mr-3">
                              [{wallet[account].contract_id}] Contract ID
                            </span>
                            {Number(wallet[account].bot_id) > 0 ? (
                              <span>[{wallet[account].bot_id}] Bot ID</span>
                            ) : (
                              <span>[manual] Bot ID</span>
                            )}
                          </>
                        )}
                      </CardHeader>
                      <CardBody>
                        {this.renderViewWalletSection(account)}
                      </CardBody>
                    </Card>
                  );
                })}
              </>
            )}
          </Card>
        </Container>
      </>
    );
  }
}
