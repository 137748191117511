import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Referrals from '../components/index';
import ReferralsState from '../../../redux/Referrals';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getReferrals: ReferralsState.actions.getReferrals,
            setField: ReferralsState.actions.setField,
        },
        dispatch
    );

const mapStateToProps = state => ({
    status: ReferralsState.selectors.status (state),
    referrals: ReferralsState.selectors.referrals (state),
    referrals_meta: ReferralsState.selectors.referrals_meta (state),
    referral_query: ReferralsState.selectors.referral_query (state),
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Referrals);

