export const status = state => state.Login.status;
export const password = state => state.Login.password;
export const password_confirm = state => state.Login.password_confirm;

export const response = state =>  state.Login.response;
export const email = state => state.Login.email;
export const remember = state => state.Login.remember;
export const recaptchaEnabled = state => state.Login.recaptchaEnabled;
export const google_auth_key = state => state.Login.google_auth_key;
export const recaptcha = state => state.Login.recaptcha;
export const geetest = state => state.Login.geetest;
export const twoFAModal = state => state.Login.twoFAModal;
export const token = state => state.Login.token;
export const otp_code = state => state.Login.otp_code;
export const currentUser = state => state.Login.currentUser;