import { api } from '../config';
import { get, postJson } from '../utils/superagent';
import { assetValidToken } from '../utils/auth';

export const getDistributionEvents = async (data, token) => {
  try {
    let events = await assetValidToken(token).then(() => get(`${api}/admin/distribution`, data, token));
    events.Meta.pages = Math.ceil(events.Meta.count / events.Meta.limit);
    return events;
  } catch (error) {
    throw error;
  }
}

export const getDistributionOrders = async (distribution_id, data, token) => {
  try {
    let orders = await assetValidToken(token).then(() => get(`${api}/admin/distribution/${distribution_id}`, data, token));
    orders.Meta.pages = Math.ceil(orders.Meta.count / orders.Meta.limit);
    return orders
  } catch (error) {
    throw error;
  }
}

export const getDistributionEntries = async (distribution_id, data, token) => {
  try {
    let entries = await assetValidToken(token).then(() => get(`${api}/admin/distribution/${distribution_id}/entries`, data, token));
    entries.Meta.pages = Math.ceil(entries.Meta.count / entries.Meta.limit);
    return entries
  } catch (error) {
    throw error;
  }
}

export const getManualDistributions = async (data, token) => {
  try {
    let events = await assetValidToken(token).then(() => get(`${api}/admin/manual-distribution`, data, token));
    events.Meta.pages = Math.ceil(events.Meta.count / events.Meta.limit);
    return events;
  } catch (error) {
    throw error;
  }
}

export const getManualDistribution = async (distribution_id, data, token) => {
  try {
    return await assetValidToken(token).then(() => get(`${api}/admin/manual-distribution/${distribution_id}`, data, token));
  } catch (error) {
    throw error;
  }
}

export const getDistributionTotalPrdxForAllLines = async (distributionId, token) => {
  try {
    return await assetValidToken(token).then(() => get(`${api}/admin/total-distributed-prdx-line/${distributionId}`, {}, token));
  } catch (error) {
    throw error;
  }
}

export const getManualDistributionFunds = async (distribution_id, data, token) => {
  try {
    await assetValidToken(token);
    let funds = await get(`${api}/admin/manual-distribution/${distribution_id}/funds`, data, token);
    funds.Meta.pages = Math.ceil(funds.Meta.count / funds.Meta.limit);
    return funds
  } catch (error) {
    throw error;
  }
}

export const completeManualDistribution = async (distribution_id, data, token) => {
  try {
    return await assetValidToken(token).then(() => postJson(`${api}/admin/manual-distribution/${distribution_id}/complete`, data, token));
  } catch (error) {
    throw error;
  }
}

export const updateManualDistributionFunds = async (distribution_id, data, token) => {
  try {
    return await assetValidToken(token).then(() => postJson(`${api}/admin/manual-distribution/${distribution_id}/funds`, data, token));
  } catch (error) {
    throw error;
  }
}

export const getManualDistributionBalances = async (distribution_id, data, token) => {
  try {
    let balances = await assetValidToken(token).then(() => get(`${api}/admin/manual-distribution/${distribution_id}/balances`, data, token));
    balances.Meta.pages = Math.ceil(balances.Meta.count / balances.Meta.limit);
    return balances
  } catch (error) {
    throw error;
  }
}
