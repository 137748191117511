import * as ac from './actionCreators';
import api from '../../api';
import {socialNetworks,prdxLines, upperCaseFirstLetter, getDateStringWithZeroOffset, getLocalizedWithZeroOffsetDate} from '../../utils';
import { logoutOnUnauthorizedError } from '../../utils/auth';
import { InvalidFields,ServerValidationError } from "../../utils/errors";
import moment from 'moment';
import { isEmpty } from 'lodash';

export function getProject(projectId) {
  return async (dispatch, getState) => {
    dispatch(ac.getProject.pending());
    try {
      const token = getState().Login.token;
      const data = await api.launchpads.getProject(projectId, token);
      dispatch(ac.getProject.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getProject.error(err));
    }
  };
}

export function getProjects(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getProjects.pending());
    try {
      const payloadData = {
        limit: payload.limit,
        page: payload.page,
      };

      const token = getState().Login.token;
      const data = await api.launchpads.getProjects(payloadData, token);
      dispatch(ac.getProjects.success(data));
    } catch (err) {
      dispatch(ac.getProjects.error(err));
    }
  };
}

export function addProject(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.addProject.pending());
    try {
      let field_errors = validateProject(payload, false, 'add');
     
      if (!isEmpty(field_errors))
        throw new InvalidFields("Invalid form fields", { field_errors });

      const token = getState().Login.token;
      let payloadData = {...payload};

      const lineLevels = {};
      prdxLines.forEach((item) => {
        lineLevels[item] = payloadData[`${item}_line_level`];
        delete payloadData[`${item}_line_level`];
      });

      const socialMediaLinks = {};
      socialNetworks.forEach((item) => {
        socialMediaLinks[item] = payloadData[`${item}_url`];
        delete payloadData[`${item}_url`];
      });
      payloadData = {...payloadData, line_levels: JSON.stringify(lineLevels), social_media_links: JSON.stringify(socialMediaLinks)};
      
      payloadData.start_date = getDateStringWithZeroOffset(moment(payloadData.start_date).format());
      payloadData.end_date = getDateStringWithZeroOffset(moment(payloadData.end_date).format());

      const formData = new FormData();
      for (const prop in payloadData) {
        formData.append(prop, payloadData[prop]);
      }

      await api.launchpads.addProject(formData, token);
      dispatch(ac.addProject.success());
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.addProject.error(err));
    }
  };
}

export function updateProject(payload, isStarted) {
  return async (dispatch, getState) => {
    dispatch(ac.updateProject.pending());
    try {
      let field_errors = validateProject(payload, isStarted, 'update');

      if (!isEmpty(field_errors))
        throw new InvalidFields("Invalid form fields", { field_errors });
      const token = getState().Login.token;
      let payloadData = {...payload};

      //if logo in base64
      if(typeof payloadData.logo === 'string'){
        const imageType = payloadData.logo.substring("data:image/".length, payloadData.logo.indexOf(";base64"));
        const logoFile = dataUrlToFile(payloadData.logo, `projectLogo.${imageType}`);
        payloadData.logo = logoFile;
      }

      const lineLevels = {};
      prdxLines.forEach((item) => {
        lineLevels[item] = payloadData[`${item}_line_level`];
        delete payloadData[`${item}_line_level`];
      });

      const socialMediaLinks = {};
      socialNetworks.forEach((item) => {
        socialMediaLinks[item] = payloadData[`${item}_url`];
        delete payloadData[`${item}_url`];
      });
      payloadData = {...payloadData, line_levels: JSON.stringify(lineLevels), social_media_links: JSON.stringify(socialMediaLinks)};

      payloadData.start_date = getDateStringWithZeroOffset(moment(payloadData.start_date).format());
      payloadData.end_date = getDateStringWithZeroOffset(moment(payloadData.end_date).format());

      const formData = new FormData();
      for (const prop in payloadData) {
        formData.append(prop, payloadData[prop]);
      }

      await api.launchpads.updateProject(payloadData.id, formData, token);
      dispatch(ac.updateProject.success());
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.updateProject.error(err));
    }
  };
}

  function dataUrlToFile(dataUrl, filename){
  
    var arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

function validateProject(payload, isStarted , mode){
  let field_errors = {};

  if(!payload.logo) {
    field_errors.logo =
      "The logo is required";
  }else if(typeof payload.logo !== 'string' && !payload.logo.type.includes('image/')){
    field_errors.logo =
      "The logo must be image";
  }

  if(!isStarted && !payload.coin_symbol) 
    field_errors.coin_symbol =
    "Token symbol is required";

  if(!payload.title) 
    field_errors.title =
    "Token full name is required";

  if(!payload.status) {
    field_errors.status =
    "Status is required";
  }else if(mode === 'add' && payload.status === 'deleted'){
    field_errors.status =
    "Cant be deleted";
  }

  if(!isStarted && !payload.contributions_cap) 
    field_errors.contributions_cap =
      "Total token available is required";
  
  if(!isStarted && !payload.presale_price) 
    field_errors.presale_price =
      "Presale price is required";
  
  if(!payload.blockchain) 
    field_errors.blockchain =
      "Blockchain is required";

  if(!isStarted && !payload.start_date) 
    field_errors.start_date =
      "Start date time is required";
    
  if(!isStarted && !payload.end_date) 
    field_errors.end_date =
      "End date time is required";

  if(!isStarted && payload.start_date && payload.end_date){

    if(payload.start_date >= payload.end_date){
      field_errors.start_date =
        "Start date must be less than end date";
      
      field_errors.end_date =
        "End date must be more than start date";
    }
    else if(payload.timezone && getLocalizedWithZeroOffsetDate(payload.start_date, payload.timezone) <= Date.now()){

      field_errors.start_date = 
        "Start date must be more than current date";
    }
  }

  if(!isStarted && !payload.timezone) 
    field_errors.timezone =
      "Timezone is required";

  if(!isStarted){
    prdxLines.forEach((item) => {
      if(!payload[`${item}_line_level`]) 
        field_errors[`${item}_line_level`] =
          `${upperCaseFirstLetter(item)} line level is required`;
    });
  }

  if(!payload.short_info) 
    field_errors.short_info =
      "Short project card info is required";

  if(!payload.details) 
    field_errors.details =
      "Pool details is required";

  if(!payload.token_details) 
    field_errors.token_details =
      "Token details is required";

  if(!payload.project_info) 
    field_errors.project_info =
      "Project details is required";

  return field_errors;
}



export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}

export function setProjectField(field, value) {
  return dispatch => dispatch(ac.setProjectField({field, value}));
}

export function setFilterField(field, value) {
  return dispatch => dispatch(ac.setFilterField({field, value}));
}

export function resetForm() {
  return dispatch => dispatch(ac.resetForm());
}