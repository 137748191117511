import { isEmpty } from "lodash";
import React, {Fragment, useState} from "react";
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Table,
  Col,
  Button,
  Row,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";

import Pagination from "../Pagination";
import PaginationSummary from "../Pagination/summary";

export default ({
  className,
  light,
  columns,
  rows,
  RowComponent,
  inlineActions,
  tableTitle,
  actionsColumn,
  detailsColumn,
  actions,
  meta,
  onPagingClick,
  inlineSearch,
  inlineSearchDefaultValue,
  inlineSearchPlaceholder,
  inlineFilter,
  inlineFilterData,
  inlineFilterSelected,
  inlineFilterCoin,
  inlineFilterCoinData,
  inlineFilterCoinSelected,
  formFilter,
  renderFormFilter,
  checkboxesColumn,
  checkedRows,
  updateCheckedRows,
  inlineFilterAccount,
  inlineFilterAccountData,
  inlineFilterAccountSelected,
  headerLeftColumnSize = 6,
  headerTitle,
  onRowSelectChange,
  startDateFilter,
  startDateFilterPlaceholder,
  startDateFilterSelected,
  endDateFilter,
  endDateFilterPlaceholder,
  endDateFilterSelected,
  orderSettings,
  filterActions = () => true,
  ...rest
}) => {
  const [openedDetailsRowId, setOpenedDetailsRowId] = useState(null);

  const toggleDetailsRow = (rowId) => {
    if (openedDetailsRowId) setOpenedDetailsRowId(null);
    else setOpenedDetailsRowId(rowId);
  };

  const getDetailsRowColSpan = () => {
    let colSpan = 0;

    if (!isEmpty(columns)) colSpan += Object.keys(columns).length;
    if (checkboxesColumn) ++colSpan;
    if (actionsColumn && !isEmpty(actions)) ++colSpan;
    if (detailsColumn) ++colSpan;

    return colSpan;
  };

  let headerRightColumnSize = 12 - headerLeftColumnSize;

  return (
    <Row>
      <div className="col">
        <Card className={!light ? "bg-default shadow" : "shadow"}>
          <CardHeader
            className={light ? "border-0" : "bg-transparent border-0"}
          >
            <Row>
              <Col xs={headerLeftColumnSize}>
                <Row>
                  <Col xs="12">
                    <h3 className={light ? "mb-0" : "text-white mb-0"}>
                      {tableTitle}
                    </h3>
                  </Col>
                </Row>

                {headerTitle && (
                  <Row>
                    <Col xs="12">
                      <div className="table-header-title">{headerTitle}</div>
                    </Col>
                  </Row>
                )}
              </Col>
              {!isEmpty(inlineActions) && (
                <Col className="text-right" xs={headerRightColumnSize}>
                  {inlineActions.map((item, index) => (
                    <Button
                        key={item.text + index}
                        className="btn-neutral btn-round btn-icon"
                        color="default"
                        href=""
                        id="tooltip969372949"
                        onClick={(e) => item.func(e)}
                        size="sm"
                    >
                      <span className="btn-inner--text">{item.text}</span>
                    </Button>
                  ))}
                </Col>
              )}

              {(inlineFilter ||
                inlineSearch ||
                inlineFilterAccount ||
                inlineFilterCoin ||
                startDateFilter ||
                endDateFilter) && (
                <Col
                  className={`text-right ${
                    (inlineFilter ||
                      inlineSearch ||
                      inlineFilterAccount ||
                      inlineFilterCoin ||
                      startDateFilter ||
                      endDateFilter) &&
                    !isEmpty(inlineActions)
                      ? "element-in-new-line"
                      : ""
                  }`}
                  xs={
                    (inlineFilter ||
                      inlineSearch ||
                      inlineFilterAccount ||
                      inlineFilterCoin ||
                      startDateFilter ||
                      endDateFilter) &&
                    !isEmpty(inlineActions)
                      ? 12
                      : headerRightColumnSize
                  }
                >
                  {!formFilter && inlineSearch && (
                    <div className="dataTables_filter search_holder">
                      <Input
                        type="text"
                        defaultValue={inlineSearchDefaultValue || ''}
                        placeholder={inlineSearchPlaceholder || "Search"}
                        onChange={(e) => inlineSearch(e)}
                      />
                    </div>
                  )}
                  {inlineFilter && (
                    <div className="dataTables_filter search_holder">
                      <Input
                        type="select"
                        value={inlineFilterSelected || ""}
                        onChange={(e) => inlineFilter(e.target.value)}
                      >
                        {inlineFilterData.map((option) => (
                          <option
                            key={option.value || option.label}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </Input>
                    </div>
                  )}
                  {inlineFilterAccount && (
                    <div className="dataTables_filter search_holder">
                      <Input
                        type="select"
                        value={inlineFilterAccountSelected || "main"}
                        onChange={(e) => inlineFilterAccount(e.target.value)}
                      >
                        {inlineFilterAccountData.map((option) => (
                          <option
                            key={option.value || option.label}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </Input>
                    </div>
                  )}
                  {inlineFilterCoin && (
                    <div className="dataTables_filter search_holder">
                      <Input
                        type="select"
                        value={inlineFilterCoinSelected || "main"}
                        onChange={(e) => inlineFilterCoin(e.target.value)}
                      >
                        {inlineFilterCoinData.map((option) => (
                          <option
                            key={option.value || option.label}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </Input>
                    </div>
                  )}
                  {endDateFilter && (
                    <div className="dataTables_filter search_holder">
                      <DatePicker
                        selected={endDateFilterSelected}
                        onChange={(date) => endDateFilter(date)}
                        selectsEnd
                        startDate={startDateFilterSelected}
                        endDate={endDateFilterSelected}
                        placeholderText={
                          startDateFilterPlaceholder || "To Date"
                        }
                      />
                    </div>
                  )}
                  {startDateFilter && (
                    <div className="dataTables_filter search_holder">
                      <DatePicker
                        selected={startDateFilterSelected}
                        onChange={(date) => startDateFilter(date)}
                        selectsStart
                        startDate={startDateFilterSelected}
                        endDate={endDateFilterSelected}
                        placeholderText={
                          endDateFilterPlaceholder || "From Date"
                        }
                      />
                    </div>
                  )}
                </Col>
              )}
              <Col>{formFilter && renderFormFilter()}</Col>
            </Row>
          </CardHeader>

          <Table
            className={`align-items-center table-flush ${
              !light ? "table-dark " : ""
            }${className ? className : ""}`}
            responsive
          >
            <thead className={!light ? "thead-dark" : "thead-light"}>
              <tr role="row">
                {checkboxesColumn && <th scope="col" />}
                {columns &&
                  Object.keys(columns).map((key) => {
                    if(!isEmpty(orderSettings) && orderSettings.orderFields.includes(key)){
                      return (
                        <th key={key} className='ordered-td' onClick={() => orderSettings.orderFunction(key)}>
                          <span>{columns[key]}</span> {orderSettings.order && orderSettings.orderBy === key && (<i className={`ni ni-${orderSettings.order === 'desc' ? 'bold-down' : 'bold-up'}`} />)}
                        </th>
                      );
                    }

                    return (
                      <th key={key}>{columns[key]} </th>
                    );
                  }
                  )}
                {detailsColumn && <th key="details">Details</th>}
                {actionsColumn && !isEmpty(actions) && <th scope="col" />}
              </tr>
            </thead>
            <tbody className="list">
              {rows &&
                rows.map((row, index) => {
                  return RowComponent ? (
                    <RowComponent key={index} index={index} data={row} />
                  ) : (
                    <Fragment key={index}>
                      <tr role="row" key={index}>
                        {checkboxesColumn && (
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                !!checkedRows.find((r) => r.id === row.id)
                              }
                              onChange={(e) => {
                                const updatedCheckedRows = checkedRows.filter(
                                  (r) => r.id !== row.id
                                );

                                if (!checkedRows.find((r) => r.id === row.id)) {
                                  updatedCheckedRows.push(row);
                                }

                                updateCheckedRows(updatedCheckedRows);
                              }}
                            />
                          </td>
                        )}
                        {Object.keys(columns).map((key) => {
                          // if (key === 'email') return <td>{row[key].split('@')[0]}</td>

                          if (rest[key + "CustomRowComponent"]) {
                            const CustomRowComponent =
                              rest[key + "CustomRowComponent"];

                            return <CustomRowComponent key={key} data={row} />;
                          }

                          if (row[key + "_select"]) {
                            // status_select_data
                            return (
                              <td key={key}>
                                <Input
                                  type="select"
                                  value={row[key + "_select"]}
                                  onChange={(e) =>
                                    onRowSelectChange(e.target.value)
                                  }
                                >
                                  {row[key + "_select_data"].map((option) => (
                                    <option
                                      key={option.value || option.label}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </Input>
                              </td>
                            );
                          }

                          return row[key + "_link"] ? (
                            <td key={key}>
                              <a
                                className="text-light"
                                href={row[key + "_link"]}
                              >
                                {row[key]}
                              </a>
                            </td>
                          ) : (
                            <td key={key}>{row[key]}</td>
                          );
                        })}
                        {actionsColumn && !isEmpty(actions) && (
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                {actions.filter((item) => filterActions(item, row)).map((action) => (
                                  <DropdownItem
                                    key={action.name}
                                    href=""
                                    disabled={
                                      action.disabled &&
                                      action.disabled["values"].indexOf(
                                        row[action.disabled["by"]]
                                      ) !== -1
                                    }
                                    onClick={(e) => action.onClick(e, row)}
                                  >
                                    {action.changeNameIfNecessary &&
                                    action.changeNameIfNecessary(row)
                                      ? action.changeNameIfNecessary(row)
                                      : action.name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        )}
                        {detailsColumn && (
                          <td
                            className="cursor-pointer"
                            onClick={() => toggleDetailsRow(row.id)}
                          >
                            Details{" "}
                            {openedDetailsRowId &&
                            openedDetailsRowId === row.id ? (
                              <i class="fas fa-caret-down"></i>
                            ) : (
                              <i class="fas fa-caret-up"></i>
                            )}
                          </td>
                        )}
                      </tr>
                      {openedDetailsRowId && openedDetailsRowId === row.id && (
                        <tr>
                          <td colSpan={getDetailsRowColSpan()}>
                            {row.details}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
            </tbody>
          </Table>
          <div className="pagingHolder">
            {meta && (
              <PaginationSummary
                className={`${!light ? 'dark-table' : ''} dataTables_info`}
                page={meta.page}
                count={meta.count}
                // limit={Math.min(10, meta.count)}
                limit={meta.limit}
              />
            )}
            {meta && (
              <Pagination
                pages={meta.pages}
                current={meta.page}
                trim="2"
                onClick={onPagingClick}
              />
            )}
          </div>
        </Card>
      </div>
    </Row>
  );
};
