import { api } from "../config";
import { get } from "../utils/superagent";
import { assetValidToken } from "../utils/auth";

export const getPrdxCirculation = (token) =>
    assetValidToken(token).then(() =>
        get(`${api}/admin/prdx-circulation`, {}, token)
    );

export const getTotalPrdxForAllLines = async (token) => 
    assetValidToken(token).then(() => get(`${api}/admin/total-prdx-line`, {}, token));
