import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Dashboard from '../components/index';
import DashboardState from '../../../redux/Dashboard';
import MarketsState from '../../../redux/Markets';
import UsersState from '../../../redux/Users';
// import CoinsAndChains from '../../../redux/CoinsAndChains';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getWithdrawals: DashboardState.actions.getWithdrawals,
            getTradesPerDay: DashboardState.actions.getTradesPerDay,
            getFeesPerDay: DashboardState.actions.getFeesPerDay,
            getGeneralStatistics: DashboardState.actions.getGeneralStatistics,
            getBotsStatistics: DashboardState.actions.getBotsStatistics,
            getUsersPerLevel: DashboardState.actions.getUsersPerLevel,
            getCoinStatistics: DashboardState.actions.getCoinStatistics,
            getMarkets: MarketsState.actions.getMarkets,
            getUsersInfo: UsersState.actions.getInfo,
            // getCoins: CoinsAndChains.actions.getCoins
        },
        dispatch
    );

const mapStateToProps = state => ({
    data: DashboardState.selectors.data(state),
    chartValues: DashboardState.selectors.chartValues(state),
    chartFeesBotValues: DashboardState.selectors.chartFeesBotValues(state),
    chartFeesManualValues: DashboardState.selectors.chartFeesManualValues(state),
    meta: DashboardState.selectors.meta(state),
    pending_withdrawals: DashboardState.selectors.pending_withdrawals(state),
    coins: DashboardState.selectors.coins(state),
    // simpleCoins: CoinsAndChains.selectors.coins(state),
    total_profit: DashboardState.selectors.total_profit(state),
    markets: MarketsState.selectors.markets(state),
    usersCards: UsersState.selectors.cards(state),
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Dashboard);

