import * as c from './constants';
import {asyncActionCreator, actionCreator} from '../../utils/redux';

export const getProjects = asyncActionCreator(
    c.GET_PROJECTS_PENDING,
    c.GET_PROJECTS_SUCCESS,
    c.GET_PROJECTS_ERROR
  );
export const getProject = asyncActionCreator(c.GET_PROJECT_PENDING, c.GET_PROJECT_SUCCESS, c.GET_PROJECT_ERROR);
export const addProject = asyncActionCreator(c.ADD_PROJECT_PENDING, c.ADD_PROJECT_SUCCESS, c.ADD_PROJECT_ERROR);
export const updateProject = asyncActionCreator(c.UPDATE_PROJECT_PENDING, c.UPDATE_PROJECT_SUCCESS, c.UPDATE_PROJECT_ERROR);
export const setField = actionCreator(c.SET_FIELD);
export const setProjectField = actionCreator(c.SET_PROJECT_FIELD);
export const resetForm = actionCreator(c.RESET_PROJECT_STATE);
export const setFilterField = actionCreator(c.SET_FILTER_FIELD);