import React from "react";
import history from '../../../history';
import moment from "moment";

import {
  Container,
  Row,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";


import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import DarkTable from "../../../components/Table";
import { hasPermission } from '../../../utils';

class TrendBotsStatistics extends React.Component {

  state = {
    fromStatisticsDate: null,
    toStatisticsDate: null,
    botId: '',
    pair: 'btcusdt'
  }

  onFromStatisticsDatePickerChange = (date) => {
    const {toStatisticsDate} = this.state;
    this.setState({fromStatisticsDate: date});

    const statisticsDateData = {from_date: moment(date).unix()};
    if(toStatisticsDate) statisticsDateData.to_date = moment(toStatisticsDate).unix();
    this.props.getTrendBotsStatistics(this.getTrendBotsStatisticsParams([{name: 'statisticsDateData', value:  statisticsDateData}]));
  }

  onToStatisticsDatePickerChange = (date) => {
    const {fromStatisticsDate} = this.state;
    this.setState({toStatisticsDate: date});

    const statisticsDateData = {to_date: moment(date).unix()};
    if(fromStatisticsDate) statisticsDateData.from_date = moment(fromStatisticsDate).unix();
    this.props.getTrendBotsStatistics(this.getTrendBotsStatisticsParams([{name: 'statisticsDateData', value:  statisticsDateData}]));
  }
  
  componentDidMount() {
    if (!hasPermission('admin.view')) {
      history.push('/error');
    } else {
      const {bot_id, pair} = this.props.match.params;
      this.setState({botId: bot_id});

      if(pair){
        this.setState({pair});
        this.props.getTrendBotsStatistics(this.getTrendBotsStatisticsParams([{name: 'botId', value: bot_id},{name: 'pair', value: pair}]));
      }
      else{
        this.props.getTrendBotsStatistics(this.getTrendBotsStatisticsParams([{name: 'botId', value: bot_id}]));
      }

      this.props.getMarkets({limit: 1000});
      this.props.getTotalActiveBots();
    }
  }

  inlineSearch = async (e) => {
    this.setState({botId: e.target.value});
    this.props.getTrendBotsStatistics(this.getTrendBotsStatisticsParams([{name: 'botId', value:  e.target.value}]));
  }

  onPagingClick = (ev, page) => { 
    this.props.getTrendBotsStatistics(this.getTrendBotsStatisticsParams([{name: 'page', value:  page}]));
  }

  onDownloadClick = (format) => { 
    this.props.downloadTrendBotsStatistics({...this.getTrendBotsStatisticsParams(), format});
  }


    getPairOptions = () => {
        return this.props.markets.map((item) => {
          return {label: item.id, value: item.id}
        });
    };

  changePair = (value) => {
    this.setState({pair: value});
    this.props.getTrendBotsStatistics(this.getTrendBotsStatisticsParams([{name: 'pair', value}]));
  }

  getTrendBotsStatisticsParams = (paramsToSet) => {
    const {fromStatisticsDate, toStatisticsDate} = this.state;
    const statisticsDateData = {
      from_date: fromStatisticsDate ? moment(fromStatisticsDate).unix() : null,
      to_date: toStatisticsDate ? moment(toStatisticsDate).unix() : null
    };

    const params = {page: 1, pair: this.state.pair, statisticsDateData, botId: this.state.botId};

    if(!isEmpty(paramsToSet)){
      paramsToSet.forEach((item) =>  params[item.name] = item.value);
    }

    return params;
  }


  renderStatisticsSection = () => {
    const tableTitle = <div>
      <div className='d-flex align-items-center'>
        <div className='mr-2'><Link to='/bots'>Bots</Link></div>{`/`}<div className='ml-2'><Link to='/bots/grid-bots-statistics'>Bots Statistics</Link></div>
        <Nav className="justify-content-start ml-3" pills>
          <NavItem className="mr-2 mr-md-0">
            <NavLink
              className={'py-2 px-3'}
              href={'/bots/grid-bots-statistics'}
            >
              <span className="d-none d-md-block">Grid</span>
              </NavLink>
          </NavItem>
          <NavItem className="mr-2 mr-md-0">
            <NavLink
              className={'py-2 px-3 active'}
            >
              <span className="d-none d-md-block">Trend</span>
            </NavLink>
          </NavItem>
          </Nav>
      </div>
      <div style={{'font-size': '16px'}}>Total Active Bots: {this.props.totalActiveBots}</div>
    </div>;

    let inlineActions = [];

    if(hasPermission("admin.view")){
      inlineActions.push({
        text: "PDF",
        func: () => this.onDownloadClick('pdf')
      });

      inlineActions.push({
        text: "CSV",
        func: () => this.onDownloadClick('csv')
      });
    }

    return <DarkTable
      light
      headerLeftColumnSize={5}
      inlineActions={inlineActions}
      inlineSearch={this.inlineSearch}
      inlineSearchDefaultValue={this.state.botId}
      inlineSearchPlaceholder={'Search by bot id'}
      tableTitle={tableTitle}
      inlineFilter={this.changePair}
      inlineFilterData={this.getPairOptions()}
      inlineFilterSelected={this.state.pair}
      endDateFilter={this.onToStatisticsDatePickerChange}
      endDateFilterSelected={this.state.toStatisticsDate}
      startDateFilter={this.onFromStatisticsDatePickerChange}
      startDateFilterSelected={this.state.fromStatisticsDate}
      columns={{
        bot_id: "id",
        email: "user",
        pair: "pair",
        contract_id: "contract id",
        expired_at: "expired at",
        profit: "profit",
        profit_percent: "profit %",
      }}
      rows={this.props.trendBotsStatistics}
      meta={this.props.trendBotsStatisticsMeta}
      onPagingClick={this.onPagingClick}
    />
  };

  render() { 

    return (
      <>
      <SimpleHeader name="Trend Bots Statistics" parentName="Bots" />
      <Container className="mt--6" fluid>
        <Row>&nbsp;</Row>
        {this.renderStatisticsSection()}
      </Container>
      </>
    );
  }
}

export default TrendBotsStatistics;