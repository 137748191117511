/*eslint-disable*/
import React from "react";

import {Container} from "reactstrap";

class Calendar extends React.Component {
  render() {
    return (
        <Container fluid>
          <footer className="footer pt-0">
          </footer>
        </Container>
    );
  }
}

export default Calendar;
