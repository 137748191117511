const module = 'ROLES';

export const GET_ROLES_PENDING = `${module}/GET_ROLES_PENDING`;
export const GET_ROLES_SUCCESS = `${module}/GET_ROLES_SUCCESS`;
export const GET_ROLES_ERROR = `${module}/GET_ROLES_ERROR`;

export const GET_ROLE_PENDING = `${module}/GET_ROLE_PENDING`;
export const GET_ROLE_SUCCESS = `${module}/GET_ROLE_SUCCESS`;
export const GET_ROLE_ERROR = `${module}/GET_ROLE_ERROR`;

export const ADD_ROLE_PENDING = `${module}/ADD_ROLE_PENDING`;
export const ADD_ROLE_SUCCESS = `${module}/ADD_ROLE_SUCCESS`;
export const ADD_ROLE_ERROR = `${module}/ADD_ROLE_ERROR`;

export const SET_ROLE_PENDING = `${module}/SET_ROLE_PENDING`;
export const SET_ROLE_SUCCESS = `${module}/SET_ROLE_SUCCESS`;
export const SET_ROLE_ERROR = `${module}/SET_ROLE_ERROR`;

export const DELETE_ROLE_PENDING = `${module}/DELETE_ROLE_PENDING`;
export const DELETE_ROLE_SUCCESS = `${module}/DELETE_ROLE_SUCCESS`;
export const DELETE_ROLE_ERROR = `${module}/DELETE_ROLE_ERROR`;

export const GET_PERMISSIONS_PENDING = `${module}/GET_PERMISSIONS_PENDING`;
export const GET_PERMISSIONS_SUCCESS = `${module}/GET_PERMISSIONS_SUCCESS`;
export const GET_PERMISSIONS_ERROR = `${module}/GET_PERMISSIONS_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;
export const SET_ROLE_FIELD = `${module}/SET_ROLE_FIELD`;
export const RESET_ROLES_STATE = `${module}/RESET_ROLES_STATE`;

