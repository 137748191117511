import {api} from '../config';
import {get, post, del} from '../utils/superagent';
import {assetValidToken} from '../utils/auth';

export const getEvents = async(data, token) => {
  const events = await assetValidToken(token).then(() => get(`${api}/burn-events`, data, token));
  events.meta.pages = Math.ceil(events.meta.count / events.meta.limit);
  return events;
};
export const addEvent = (data, token) => assetValidToken(token).then(() => post(`${api}/admin/burn-events`, {
  ...data,
  source: 'admin'
}, token));
export const removeEvent = (id, token) => assetValidToken(token).then(() => del(`${api}/admin/burn-events/${id}`, {source: 'admin'}, token));