import React from "react";
// reactstrap components
import SuccessModal from "../../../components/Modal/success.jsx";
import ProfileForm from "components/UserForm/profile.jsx";
import PasswordForm from "components/UserForm/password.jsx";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import {Button, Card, CardBody, CardHeader, Container} from "reactstrap";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false,
    };
  }

  componentDidMount() { 
    this.props.getProfileDetails();
  }

  toggleModal = () => {
    this.setState({
        blockModal: !this.state.blockModal,
    });
  };

  updateUser = async ( event ) => {
    event.preventDefault();
    await this.props.updateUser(this.props.currentUser);
    if (this.props.status.success) {
      this.toggleModal();
    }
  };

  updatePassword = async ( event ) => {
    event.preventDefault();
    await this.props.updatePassword(this.props.currentUser);
    if (this.props.status.success) {
      this.toggleModal();
    }
  };

  renderConfirmationModal = () => {
    return (
        <SuccessModal
            isOpen={this.state.blockModal}
            toggleFunction={() => this.toggleModal()}
            closeFunction={() => this.toggleModal()}
            body="Saved"
        />
    )
  };

  render() {
    const title = "Edit profile"; 
    const {currentUser, setCurrentUserField, status} = this.props;
    return (
      <>
          <SimpleHeader name="Edit profile" parentName="Manage Profile" />
          <Container className="mt--6" fluid>
              <Card>
                  <CardHeader>
                      <h3 className="mb-0">{title}</h3>
                  </CardHeader>
                  <CardBody>
                      <ProfileForm object={currentUser} status={status} onChange={setCurrentUserField} />
                      <Button color="success" type="button" className="float-right"
                              onClick={(e) => this.updateUser(e)}>
                          {title}
                      </Button>
                  </CardBody>
                  <CardBody>
                      <PasswordForm object={currentUser} status={status} onChange={setCurrentUserField} />
                      <Button color="success" type="button" className="float-right"
                              onClick={(e) => this.updatePassword(e)}>
                          Change password
                      </Button>
                  </CardBody>
              </Card>
              {this.renderConfirmationModal()}
          </Container>
      </>
    );
  }
}

export default Profile;
