const module = 'ANNOUNCEMENTS';

export const GET_ANNOUNCEMENT_PENDING = `${module}/GET_ANNOUNCEMENT_PENDING`;
export const GET_ANNOUNCEMENT_SUCCESS = `${module}/GET_ANNOUNCEMENT_SUCCESS`;
export const GET_ANNOUNCEMENT_ERROR = `${module}/GET_ANNOUNCEMENT_ERROR`;

export const GET_ANNOUNCEMENTS_PENDING = `${module}/GET_ANNOUNCEMENTS_PENDING`;
export const GET_ANNOUNCEMENTS_SUCCESS = `${module}/GET_ANNOUNCEMENTS_SUCCESS`;
export const GET_ANNOUNCEMENTS_ERROR = `${module}/GET_ANNOUNCEMENTS_ERROR`;

export const GET_ANNOUNCEMENTS_SETTINGS_PENDING = `${module}/GET_ANNOUNCEMENTS_SETTINGS_PENDING`;
export const GET_ANNOUNCEMENTS_SETTINGS_SUCCESS = `${module}/GET_ANNOUNCEMENTS_SETTINGS_SUCCESS`;
export const GET_ANNOUNCEMENTS_SETTINGS_ERROR = `${module}/GET_ANNOUNCEMENTS_SETTINGS_ERROR`;

export const ADD_ANNOUNCEMENT_PENDING = `${module}/ADD_ANNOUNCEMENT_PENDING`;
export const ADD_ANNOUNCEMENT_SUCCESS = `${module}/ADD_ANNOUNCEMENT_SUCCESS`;
export const ADD_ANNOUNCEMENT_ERROR = `${module}/ADD_ANNOUNCEMENT_ERROR`;

export const EDIT_ANNOUNCEMENT_PENDING = `${module}/EDIT_ANNOUNCEMENT_PENDING`;
export const EDIT_ANNOUNCEMENT_SUCCESS = `${module}/EDIT_ANNOUNCEMENT_SUCCESS`;
export const EDIT_ANNOUNCEMENT_ERROR = `${module}/EDIT_ANNOUNCEMENT_ERROR`;

export const ADD_TOPIC_PENDING = `${module}/ADD_TOPIC_PENDING`;
export const ADD_TOPIC_SUCCESS = `${module}/ADD_TOPIC_SUCCESS`;
export const ADD_TOPIC_ERROR = `${module}/ADD_TOPIC_ERROR`;

export const EDIT_TOPIC_PENDING = `${module}/EDIT_TOPIC_PENDING`;
export const EDIT_TOPIC_SUCCESS = `${module}/EDIT_TOPIC_SUCCESS`;
export const EDIT_TOPIC_ERROR = `${module}/EDIT_TOPIC_ERROR`;

export const DELETE_TOPIC_PENDING = `${module}/DELETE_TOPIC_PENDING`;
export const DELETE_TOPIC_SUCCESS = `${module}/DELETE_TOPIC_SUCCESS`;
export const DELETE_TOPIC_ERROR = `${module}/DELETE_TOPIC_ERROR`;

export const CHANGE_ANNOUNCEMENT_STATUS_PENDING = `${module}/CHANGE_ANNOUNCEMENT_STATUS_PENDING`;
export const CHANGE_ANNOUNCEMENT_STATUS_SUCCESS = `${module}/CHANGE_ANNOUNCEMENT_STATUS_SUCCESS`;
export const CHANGE_ANNOUNCEMENT_STATUS_ERROR = `${module}/CHANGE_ANNOUNCEMENT_STATUS_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;
export const SET_ANNOUNCEMENT_FIELD = `${module}/SET_ANNOUNCEMENT_FIELD`;
export const SET_ANNOUNCEMENT = `${module}/SET_ANNOUNCEMENT`;
export const RESET_ANNOUNCEMENT_STATE = `${module}/RESET_ANNOUNCEMENT_STATE`;

export const SET_TOPIC_FIELD = `${module}/SET_TOPIC_FIELD`;
