import * as c from './constants';
import {asyncActionCreator, actionCreator} from '../../utils/redux';

export const getMarkets = asyncActionCreator(c.GET_MARKETS_PENDING, c.GET_MARKETS_SUCCESS, c.GET_MARKETS_ERROR);
export const getMarket = asyncActionCreator(c.GET_MARKET_PENDING, c.GET_MARKET_SUCCESS, c.GET_MARKET_ERROR);
export const addMarket = asyncActionCreator(c.ADD_MARKET_PENDING, c.ADD_MARKET_SUCCESS, c.ADD_MARKET_ERROR);
export const editMarket = asyncActionCreator(c.EDIT_MARKET_PENDING, c.EDIT_MARKET_SUCCESS, c.EDIT_MARKET_ERROR);
export const disableMarket = asyncActionCreator(c.DELETE_MARKETS_PENDING, c.DELETE_MARKETS_SUCCESS, c.DELETE_MARKETS_ERROR);
export const getOrdersFromMarket = asyncActionCreator(c.GET_ORDERS_PENDING, c.GET_ORDERS_SUCCESS, c.GET_ORDERS_ERROR);
export const getVolumeDistributedPercent = asyncActionCreator(c.GET_VOLUME_DISTRIBUTED_PERCENT_PENDING, c.GET_VOLUME_DISTRIBUTED_PERCENT_SUCCESS, c.GET_VOLUME_DISTRIBUTED_PERCENT_ERROR);
export const updateMarketSettings = asyncActionCreator(c.UPDATE_MARKET_SETTINGS_PENDING, c.UPDATE_MARKET_SETTINGS_SUCCESS, c.UPDATE_MARKET_SETTINGS_ERROR);

export const cancelMarketOrders = asyncActionCreator(c.CANCEL_ORDERS_PENDING, c.CANCEL_ORDERS_SUCCESS, c.CANCEL_ORDERS_ERROR);
export const cancelOrder = asyncActionCreator(c.CANCEL_ORDER_PENDING, c.CANCEL_ORDER_SUCCESS, c.CANCEL_ORDER_ERROR);

export const setField = actionCreator(c.SET_FIELD);
export const setMarketField = actionCreator(c.SET_MARKET_FIELD);
export const setSettingsField = actionCreator(c.SET_SETTINGS_FIELD);
export const resetForm = actionCreator(c.RESET_MARKET_STATE);
export const setFilterField = actionCreator(c.SET_FILTER_FIELD);