import React from "react";
import history from "../../../history";

import { Container, Row, Input } from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import DarkTable from "../../../components/Table";
import NotificationAlert from "react-notification-alert";
import { hasPermission } from "../../../utils";

const statusList = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "disabled",
    label: "Disabled",
  },
];

export default class MaintenancesView extends React.Component {
  componentDidMount() {
    if (!hasPermission("admin.view")) {
      history.push("/error");
    } else {
      this.props.getMaintenances();
    }
  }

  componentWillUnmount() {
  }

  getMaintenancesActionsArray = () => {
    return [
      {
        name: "Edit",
        onClick: this.editMaintenance,
        permission: "admin.view", 
      },
    ].filter((item) => hasPermission(item.permission));
  };

  addMaintenance = () => {
    history.push("/maintenance");
  };

  editMaintenance = (e, maintenance) => {
    history.push(`/maintenance/${maintenance.id}`);
  };

  renderMaintenancesSection = () => {
    let inlineActions = [];

    if(hasPermission("admin.view")){
      inlineActions.push({
        text: "+ Add maintenance",
        func: this.addMaintenance
      });
    }

    return (
      <DarkTable
        tableTitle="Maintenances"
        inlineActions={inlineActions}
        actionsColumn={true}
        actions={this.getMaintenancesActionsArray()}
        // inlineSearch={this.searchMarkets}
        columns={{
          id: "Id",
          title: "Title",
          status: "Status",
        }}
        // onRowSelectChange={(e) => {
        //   console.log(e);
        // }}
        statusCustomRowComponent={({ data }) => {
          const handleChangeStatus = (e) => {
            // TODO: need update
            if (hasPermission("admin.view")) {
              this.props.changeMaintenanceStatus(
                e.target.value,
                data.id,
                this.refs.notificationAlert
              );
            }
          };

          return (
            <td key={data.id}>
              <Input
                type="select"
                className="custom-row-select"
                value={data.status}
                onChange={handleChangeStatus}
              >
                <option value="" disabled hidden>
                  Select status
                </option>
                {statusList.map((option) => (
                  <option
                    key={option.value || option.label}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Input>
            </td>
          );
        }}
        rows={this.props.maintenances}
      />
    );
  };

  render() {
    return (
      <>
        <SimpleHeader name="Manage Maintenances" parentName="Maintenances" />
        <Container className="mt--6" fluid>
          <Row>&nbsp;</Row>

          {this.renderMaintenancesSection()}

          <div className="rna-wrapper" ref="notifH">
            <NotificationAlert ref="notificationAlert" />
          </div>
        </Container>
      </>
    );
  }
}
