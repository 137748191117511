import React, { useMemo } from 'react';
import { Badge } from 'reactstrap';

import {KYB_GENERAL_STATUS} from "../../constants/kyb";

export const KYBStatusDot = ({ status }) => {
  const color = useMemo(() => {
    switch (status) {
      case KYB_GENERAL_STATUS.NONE:
        return 'light';
      case KYB_GENERAL_STATUS.FAIL:
        return 'danger';
      case KYB_GENERAL_STATUS.PENDING:
        return 'warning';
      case KYB_GENERAL_STATUS.SUCCESS:
        return 'success';

      default:
        return 'light';
    }
  }, [status]);

  const icon = useMemo(() => {
    switch (status) {
      case KYB_GENERAL_STATUS.NONE:
        return <i className="fas fa-ban" />;
      case KYB_GENERAL_STATUS.FAIL:
        return <i className="fas fa-times" />;
      case KYB_GENERAL_STATUS.PENDING:
        return <i className="fas fa-spinner" />;
      case KYB_GENERAL_STATUS.SUCCESS:
        return <i className="fas fa-check" />;
      default:
        return '';
    }
  }, [status]);

  return (
    <Badge
      className="d-flex justify-content-center align-items-center badge"
      color={color}
    >
      {icon}
    </Badge>
  );
};
