import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Markets from '../components/index';
import MarketsState from '../../../redux/Markets';
import CoinsState from '../../../redux/CoinsAndChains';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getCoins: CoinsState.actions.getCoins,
            getMarket: MarketsState.actions.getMarket,
            getMarkets: MarketsState.actions.getMarkets,
            addMarket: MarketsState.actions.addMarket,
            editMarket: MarketsState.actions.editMarket,
            setMarketField: MarketsState.actions.setMarketField,
            resetForm: MarketsState.actions.resetForm,
        },
        dispatch
    );

const mapStateToProps = state => ({
    status: MarketsState.selectors.status (state),
    coins: CoinsState.selectors.coins (state),
    market: MarketsState.selectors.market (state),
    markets: MarketsState.selectors.markets (state),
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Markets);

