import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Maintenances from "../components/index";
import MaintenancesState from "../../../redux/Maintenances";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getMaintenances: MaintenancesState.actions.getMaintenances,
      setField: MaintenancesState.actions.setField,
      resetForm: MaintenancesState.actions.resetForm,
      changeMaintenanceStatus:
      MaintenancesState.actions.changeMaintenanceStatus,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  maintenances: MaintenancesState.selectors.maintenances(state),
});

export default connect(mapStateToProps, mapActionsToProps)(Maintenances);
