const module = 'SETTINGS';

export const GET_SETTINGS_INITIAL_DATA_PENDING = `${module}/GET_SETTINGS_INITIAL_DATA_PENDING`;
export const GET_SETTINGS_INITIAL_DATA_SUCCESS = `${module}/GET_SETTINGS_INITIAL_DATA_SUCCESS`;
export const GET_SETTINGS_INITIAL_DATA_ERROR = `${module}/GET_SETTINGS_INITIAL_DATA_ERROR`;

export const UPDATE_SETTINGS_PENDING = `${module}/UPDATE_SETTINGS_PENDING`;
export const UPDATE_SETTINGS_SUCCESS = `${module}/UPDATE_SETTINGS_SUCCESS`;
export const UPDATE_SETTINGS_ERROR = `${module}/UPDATE_SETTINGS_ERROR`;

export const SET_SETTINGS_FIELD = `${module}/SET_SETTINGS_FIELD`;