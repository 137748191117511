import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GridBotsStatistics from "../components/GridBotsStatistics";
import BotsState from "../../../redux/Bots";
import MarketsState from "../../../redux/Markets";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getGridBotsStatistics: BotsState.actions.getGridBotsStatistics,
      downloadGridBotsStatistics: BotsState.actions.downloadGridBotsStatistics,
      getTotalActiveBots: BotsState.actions.getTotalActiveBots,
      setField: BotsState.actions.setField,
      getMarkets: MarketsState.actions.getMarkets,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
    status: BotsState.selectors.status(state),
    totalActiveBots: BotsState.selectors.totalActiveBots(state),
    gridBotsStatistics: BotsState.selectors.gridBotsStatistics(state),
    markets: MarketsState.selectors.markets (state),
    gridBotsStatisticsMeta: BotsState.selectors.gridBotsStatisticsMeta(state),
});

export default connect(mapStateToProps, mapActionsToProps)(GridBotsStatistics);
