import React from "react";
import { Link } from 'react-router-dom';
import classnames from "classnames";
import Geetest from 'react-geetest';
// reactstrap components
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon,  InputGroupText, InputGroup, Container, Row, Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import SuccessModal from "../../../components/Modal/success.jsx";
import DangerModal from "../../../components/Modal/danger.jsx";
import { isValidToken } from '../../../utils/auth';
import history from '../../../history';

class forgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false,
      errModal: false,
      instance: false,
    };
  }

  componentDidMount() {
    this.props.geetestEmpty();
    this.props.isRecaptchaEnabled();
  }

  onChange = (path, e) => {
    return this.props.setField(path, e.target.value);
  };

  onSubmit = async event => {
    event.preventDefault();
    await this.doSubmit();
  };

  doSubmit = async () => {
    await this.props.onSubmit({
      ...this.props,
      geetest: this.props.geetest,
      recaptchaEnabled: this.props.recaptchaEnabled,
      instance: this.state.instance
    });
    if (this.props.status.success) {
      this.props.setField('email', "");
      this.toggleModal();
    }
  }

  onReset = async event => {
    event.preventDefault();
    await this.doReset();
  };

  doReset = async () => {
    await this.props.onReset({
        ...this.props,
        geetest: this.props.geetest,
        recaptchaEnabled: this.props.recaptchaEnabled,
        instance: this.state.instance,
      }, this.props.match.params.reset_token);
    if (this.props.status.success) {
      this.props.setField('password', "");
      this.props.setField('password_confirm', "");
      this.toggleModal();
    }
    if (this.props.status.error && this.props.status.error.data && this.props.status.error.data.error) {
      this.toggleErrModal();
    }  
  }

  // submit the form when geetest popup validation success
  componentDidUpdate(prevProps) {
    if ((!prevProps.geetest.validate || !prevProps.geetest.validate.geetest_challenge) &&
      this.props.geetest.validate && this.props.geetest.validate.geetest_challenge
    ) {
      if (this.props.match.params.reset_token != null) {
        this.doReset();
      } else {
        this.doSubmit();
      }
    }
  };
      
  onRequestNewToken = () => {
    history.push('/auth/forgot');
  }

  toggleModal = () => {
    this.setState({
        blockModal: !this.state.blockModal,
    });
  };

  toggleErrModal = () => {
    this.setState({
        errModal: !this.state.errModal,
    });
  };

  // validate geetest
  onGeetestSuccess = (result) => this.props.geetestValidate(result);

  // save the instance when geetest captcha is ready
  onReady = (arg, instance) => this.setState({instance: instance});

  renderConfirmationModal = () => {
    const { response } = this.props;
    return (
        <SuccessModal
            isOpen={this.state.blockModal}
            toggleFunction={() => this.toggleModal()}
            closeFunction={() => this.toggleModal()}
            body={response}
        />
    )
  };

  renderErrorModal = () => {
    const { status } = this.props;
    const response = (status.error && status.error.data) ? status.error.data.error : "Undefined error"
    return (
        <DangerModal
            isOpen={this.state.errModal}
            toggleFunction={() => this.toggleErrModal()}
            closeFunction={() => this.toggleErrModal()}
            nextFunction={() => this.onRequestNewToken()}
            confirmTitle="Request new token"
            body={response}
        />
    )
  };

  getResetPasswordForm = (isTokenValid) => { 
    const { status, recaptchaEnabled, geetest } = this.props;
    return <CardBody className="px-lg-5 py-lg-5">
        <div className="text-center text-muted mb-4">
          <small>Reset password</small>
        </div>
        { isTokenValid ? 
        <Form role="form" onSubmit={this.onReset}>
          <FormGroup
            className={classnames("mb-3", {
              focused: this.state.focusedPassword
            })}
          >
            <InputGroup className="input-group-merge input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Fill in your new password"
                type="password"
                value={this.props.password || ""}
                onChange={(e) => this.onChange('password', e)}
                onFocus={() => this.setState({ focusedPassword: true })}
                onBlur={() => this.setState({ focusedPassword: false })}
              />
            </InputGroup>
            <span className="help-block error">
            {status &&
                status.error &&
                status.error.data.field_errors &&
                status.error.data.field_errors.password}
            </span>
          </FormGroup>

          <FormGroup
            className={classnames("mb-3", {
              focused: this.state.focusedRetypePassword
            })}
          >
            <InputGroup className="input-group-merge input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-ungroup" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Confirm your password"
                type="password"
                value={this.props.password_confirm || ""}
                onChange={(e) => this.onChange('password_confirm', e)}
                onFocus={() => this.setState({ focusedRetypePassword: true })}
                onBlur={() => this.setState({ focusedRetypePassword: false })}
              />
            </InputGroup>
            <span className="help-block error">
            {status &&
                status.error &&
                status.error.data.field_errors &&
                status.error.data.field_errors.password_confirm}
            </span>
          </FormGroup>
          {recaptchaEnabled && geetest.register ? 
            <div className="custom-control custom-control-alternative"><br/>
              <Geetest
                lang="en"
                bgColor="black"
                product="bind"
                offline={!geetest.register.success}
                gt={geetest.register.gt}
                challenge={geetest.register.challenge}
                onSuccess={this.onGeetestSuccess}
                onReady={this.onReady}
              />
            </div>
            : null }

          <div className="text-center">
            <Button className="my-4" color="info" type="button" onClick={this.onReset}>
              Reset
            </Button>
          </div>
        </Form>
        : 
        <div className="text-center text-muted mb-2">
          <span className="help-block error">
            Expired or invalid token
          </span>
          <div className="text-center">
            <Button className="my-4" color="info" type="button" onClick={this.onRequestNewToken}>
              Request new token
            </Button>
          </div>

          <span className="error">
            {status.error && status.error.data && status.error.data.error}
          </span>
        </div>
        }
      </CardBody>
  }

  getForgotPasswordForm = () => {
      const { status, recaptchaEnabled, geetest } = this.props;
      return <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <small>Provide details below</small>
          </div>
          <Form role="form" onSubmit={this.onSubmit}>
            <FormGroup
              className={classnames("mb-3", {
                focused: this.state.focusedEmail
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  value={this.props.email}
                  onChange={(e) => this.onChange('email', e)}
                  onFocus={() => this.setState({ focusedEmail: true })}
                  onBlur={() => this.setState({ focusedEmail: false })}
                />
              </InputGroup>
              <span className="help-block error">
              {status &&
                  status.error &&
                  status.error.data.field_errors &&
                  status.error.data.field_errors.email}
              </span>
            </FormGroup>
            {recaptchaEnabled && geetest.register ? 
            <div className="custom-control custom-control-alternative"><br/>
              <Geetest
                lang="en"
                bgColor="black"
                product="bind"
                offline={!geetest.register.success}
                gt={geetest.register.gt}
                challenge={geetest.register.challenge}
                onSuccess={this.onGeetestSuccess}
                onReady={this.onReady}
              />
            </div>
            : null }
            <div className="text-center">
              <Button className="my-4" color="info" type="button" onClick={this.onSubmit}>
                Send
              </Button>
            </div>

            <span className="error">
              {status.error && status.error.data && status.error.data.error}
            </span>
          </Form>
        </CardBody>
  }

  render() { 
    const { match } = this.props; 
    const isReset = match.params.reset_token != null;
    const isTokenValid = isReset ? isValidToken(match.params.reset_token) : false;
    return (
      <>
        <AuthHeader
          title="Recover password!"
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                {isReset ? this.getResetPasswordForm(isTokenValid) : this.getForgotPasswordForm()}
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link
                    className="text-light"
                    to="/auth/login"
                    onClick={this.props.geetestEmpty}
                  >
                    <small>Login</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.renderErrorModal()}
          {this.renderConfirmationModal()}
        </Container>
      </>
    );
  }
}

export default forgotPassword;