import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Funds from '../components/Funds';
import State from '../../../redux/ManualDistributions';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getManualDistributionFunds: State.actions.getManualDistributionFunds,
      getManualDistribution: State.actions.getManualDistribution,
      updateManualDistributionFunds: State.actions.updateManualDistributionFunds,
      completeManualDistribution: State.actions.completeManualDistribution,
    },
    dispatch
  );

const mapStateToProps = state => ({
  approximatePrdxDistributedAmount: State.selectors.approximatePrdxDistributedAmount(state),
  funds: State.selectors.funds(state),
  currentDistribution: State.selectors.curentDistribution(state),
  meta: State.selectors.meta(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Funds);

