import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import WithdrawalsView from '../components/withdrawals';
import State from '../../../redux/Users';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getUserWithdrawals: State.actions.getUserWithdrawals,
      setField: State.actions.setField,
      cancelUserWithdraw: State.actions.cancelUserWithdraw,
    },
    dispatch
  );

const mapStateToProps = state => ({
  status: State.selectors.status(state),
  currentUser: State.selectors.currentUser(state),
  withdrawals: State.selectors.withdrawals(state),
  withdrawalsMeta: State.selectors.withdrawalsMeta(state),
  withdrawal_query: State.selectors.withdrawal_query(state),
  withdrawals_filter: State.selectors.withdrawals_filter(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(WithdrawalsView);