import React from "react";
import UserTabs from "components/Tabs/user.jsx";

import {Card, Container} from "reactstrap";
import DarkTable from "../../../components/Table";

export default class UsersView extends React.Component {

    componentDidMount = () => {
        const user_id = this.props.match.params.user_id;
        this.props.getUserDistributions({ user_id: user_id, page: 1 });
    };

    onDistributionsPagingClick = (ev, page) => {
        const user_id = this.props.match.params.user_id;
        this.props.getUserDistributions({ user_id: user_id, page: page })
    }

    renderViewDistributionSection = () => {
        return <DarkTable
            light={true}
            tableTitle="PRDX Distribution"
            columns={{
                timestamp: 'Date & Time',
                ref_id: 'Distribution ID',
                credit: 'Amount'
            }}
            rows={this.props.distributions}
            meta={this.props.distributionsmeta}
            onPagingClick={this.onDistributionsPagingClick}
        />;
    };

    render() {
        const { match } = this.props;
        return (
            <>
                <UserTabs match={match}  />
                <Container className="mt--6" fluid>
                    <Card className="user_content">
                        {this.renderViewDistributionSection()}
                    </Card>
                </Container>
            </>
        );
    }
}

