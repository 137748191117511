import React from "react";
import { Container, } from "reactstrap";
import EmptyHeader from '../../../components/Headers/EmptyHeader'
import DarkTable from "../../../components/Table";
import {formatNumber} from "../../../utils"

class Balances extends React.Component {

  componentDidMount() {
    const distributionId = this.props.match.params.distribution_id;

    this.props.getManualDistributionBalances(distributionId || '');
    if(distributionId) this.props.getDistributionTotalPrdxForAllLines(distributionId);
  }

  onPagingClick = (ev, page) => {
    this.props.getManualDistributionBalances(this.props.match.params.distribution_id || '', { ...this.props, page });
  }

  searchBalances = async (e) => {
    await this.props.setField('balancesQuery', e.target.value);
    this.props.getManualDistributionBalances(this.props.match.params.distribution_id || '', {...this.props, page: 1});
  };

  getUsersLevelList = () => {
    return [
      {label: 'All', value: ''},
      {label: 'No', value: 'none'},
      {label: 'Silver', value: 'silver'},
      {label: 'Gold', value: 'gold'},
      {label: 'Platinum', value: 'platinum'},
      {label: 'Black', value: 'black'},
    ]
  }

  setUsersLevelFilter = async (value) => {
    await this.props.setField('balancesLevel', value);
    this.props.getManualDistributionBalances(this.props.match.params.distribution_id || '', {...this.props, page: 1});
  }

  getDistributionTotalPrdxAllLines = () => {
    const {distributionTotalPrdxForAllLines} = this.props;
    let total = 0;

    Object.keys(distributionTotalPrdxForAllLines).forEach((key) => {
      total += parseFloat(distributionTotalPrdxForAllLines[key]);
    });
    return total;
  }

  render() {
    const {totalRedeemedPrdx, distributionTotalPrdxForAllLines, balancesLevel} = this.props;
    const distributionId = this.props.match.params.distribution_id;
    
    return (
      <>
        <EmptyHeader />
        <Container className="mt--6 manual-distr-container" fluid>
          <DarkTable
            headerLeftColumnSize={7}
            headerTitle={
            <>
              <div className='d-flex align-items-center mb-2'>
                <div className='mr-3'>TOTAL REDEEMED PRDX:</div>
                <div>{totalRedeemedPrdx}</div>
              </div>
              <div className='d-flex align-items-center'>
                <div className='mr-3'>{`TOTAL PRDX FOR DISTRIBUTION ${distributionId}`}</div>
                <div className='mr-2'>all lines: {formatNumber(this.getDistributionTotalPrdxAllLines(),2)}</div>
                <div className='mr-2'>silver line: {formatNumber(distributionTotalPrdxForAllLines.silver,2)}</div>
                <div className='mr-2'>gold line: {formatNumber(distributionTotalPrdxForAllLines.gold,2)}</div>
                <div className='mr-2'>platinum line: {formatNumber(distributionTotalPrdxForAllLines.platinum,2)}</div>
                <div>black line: {formatNumber(distributionTotalPrdxForAllLines.black,2)}</div>
              </div>
            </>
            }
            tableTitle="Distribution Balances"
            inlineSearch={this.searchBalances}
            inlineFilter={(value) => this.setUsersLevelFilter(value)}
            inlineFilterData={this.getUsersLevelList()}
            inlineFilterSelected={balancesLevel}
            columns={{
              user_email: "User",
              level: "Level",
              balance: "Balance",
              coin_symbol: "Coin Symbol",
              allocated_balance: "Allocated Balance",
              redeemed: "Redeemed",
              created_at: "Date",
            }}
            actionsColumn={false}
            rows={this.props.balances}
            meta={this.props.meta}
            onPagingClick={this.onPagingClick}
          />
        </Container>
      </>
    );
  }
}

export default Balances;
