import React, {memo, useEffect, useMemo, useRef} from 'react';
import {Form, Card, CardBody, CardHeader, Col, Container, Row, Button, Label} from "reactstrap";
import NotificationAlert from "react-notification-alert";


import SimpleHeader from "../../../components/Headers/SimpleHeader";
import {renderDocumentUploader} from "../../../components/Inputs";

import {downloadFile} from "../../../utils";

const OtcOrdersDetails = (props) => {
    const {
        getOtcOrderDocumentUrl,
        attachDocumentToOtcOrder,
        setOtcDocumentField,
        resetOtcDocument,
        otcOrders,
        orderDocument,
        status
    } = props;

    const notificationAlertRef = useRef();

    const params = new URLSearchParams(window.location.search);
    const orderId = params.get('id');

    const currentOtcOrder = useMemo(() => {
        return otcOrders.find(order => order.id === orderId )
    }, [otcOrders, orderId])

    useEffect(() => {
        if (!orderId || !currentOtcOrder || !currentOtcOrder.document) return;

        getOtcOrderDocumentUrl(orderId);

        return () => resetOtcDocument()
    }, []);

    const documentUploaderProp = 'doc';

    const handleAttachDocument = () => {
        attachDocumentToOtcOrder(orderId, orderDocument[documentUploaderProp]);
    };

    const handleDownloadDocument = () => {
        const fileName = 'order_document';

        downloadFile(orderDocument.url, fileName)
    };

    return (
      <>
          <SimpleHeader name="OTC order details" parentName="OTC Orders" />
          <Container className="mt--6" fluid>
            <Row>&nbsp;</Row>

            <Card>
                <CardHeader>
                    <h3 className="mb-0">Upload document</h3>
                </CardHeader>

                <CardBody>
                    {
                      orderDocument && orderDocument.url && (
                            <Row>
                                <Col md="4">
                                    <Label className="form-control-label pl-0">
                                        Download document
                                    </Label>
                                </Col>


                                <Col md="8">
                                    <Button
                                        disabled={!orderDocument}
                                        color="primary"
                                        type="button"
                                        onClick={handleDownloadDocument}
                                    >
                                        Download
                                    </Button>
                                </Col>
                            </Row>
                        )
                     }

                    <Form className="m-t-3">
                        <Row>
                            <Col md="12">
                                {
                                    renderDocumentUploader(
                                      {
                                          object: orderDocument,
                                          status,
                                          onChange: setOtcDocumentField
                                      },
                                      "Document",
                                      documentUploaderProp
                                    )
                                }
                            </Col>
                        </Row>
                    </Form>

                    <Button
                        disabled={!orderDocument || !orderDocument[documentUploaderProp] || !orderDocument.isValidDoc}
                        color="success"
                        type="button"
                        className="float-right ml-3"
                        onClick={handleAttachDocument}
                    >
                        Publish
                    </Button>
                </CardBody>
            </Card>

            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
        </Container>
    </>
    );
};

export default memo(OtcOrdersDetails);
