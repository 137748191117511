import React, {memo, useEffect, useMemo, useState} from 'react';
import {Card, Container} from "reactstrap";

import SimpleHeader from "../../../components/Headers/SimpleHeader";
import CreateManualWithdrawalModal from "../../../components/Modal/CreateManualWithdrawalModal";
import SuccessModal from "../../../components/Modal/success";
import DangerModal from "../../../components/Modal/danger";
import TabHeader from "../../../components/Tabs/wallets";
import Table from "../../../components/Table";

import {hasPermission} from "../../../utils";
import * as storage from "../../../utils/storage";
import api from "../../../api";

const manualWithdrawals = ({match, ...props}) => {
  const {getManualWithdrawals, manualWithdrawals, manualWithdrawalsMeta, currentUser} = props

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDangerModal, setShowDangerModal] = useState(false);
  const [dangerModalMessage, setDangerModalMessage] = useState('Error!');
  const [showCreateWithdrawalModal, setShowCreateWithdrawalModal] = useState(false)

  const [confirmUserEmails, setConfirmUserEmails] = useState([])
  const [inlineActions, setInlineActions] = useState([])
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({status: ''})

  const withdrawalsParams = useMemo(() => (
    { page, ...filters }
  ), [filters, page])

  const getWithdrawals = () => {
    getManualWithdrawals({ page, ...filters });
  }

  const getConfirmUsersEmails = async () => {
    try {
      const {emails} = await api.wallets.getManualDepositsConfirmUserEmails(storage.get('admin_auth_token', ''));

      setConfirmUserEmails(emails);
    }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    getConfirmUsersEmails().then();
    getWithdrawals();

    const interval = setInterval(getWithdrawals, 5000)

    return () => clearInterval(interval)
  }, [withdrawalsParams])


  useEffect(() => {
    if(hasPermission("admin.view")){
      setInlineActions(prevState => [...prevState, {
        text: "Create Manual Withdrawal",
        func: () => setShowCreateWithdrawalModal(true)
      }])
    }
  }, []);


  const emailIsConfirmed = useMemo(() => (
    confirmUserEmails.includes(currentUser.email)
  ), [confirmUserEmails])


  const confirmManualWithdrawal = async (data) => {
    const withdrawIsConfirmed = data.status === 'confirmed'

    if (withdrawIsConfirmed) {
      setShowDangerModal(true)
      setDangerModalMessage('Error! Already Confirmed')

      return;
    }

    if(!emailIsConfirmed) {
      setShowDangerModal(true)
      setDangerModalMessage('Error! You have no permission')

      return;
    }

    try {
      await api.wallets.confirmManualWithdrawal(data.ID, storage.get('admin_auth_token', ''));

      setShowSuccessModal(true)
    } catch (error){
      setShowDangerModal(true)
      setDangerModalMessage(`Server error: ${error.message}`)
    }
  }

  const actionsArray = useMemo(() => {
    return [
      {name: 'Confirm', onClick: (e, data) => confirmManualWithdrawal(data), permission: 'admin.view'},
    ]
  }, [emailIsConfirmed]);

  const renderConfirmationModal = () => {
    const toggleSuccessModal = () => setShowSuccessModal(prevState => !prevState)

    return (
      <SuccessModal
        isOpen={showSuccessModal}
        toggleFunction={toggleSuccessModal}
        closeFunction={toggleSuccessModal}
        body={'Confirmed!'}
      />
    )
  };

  const renderDangerModal = () => {
    const toggleDangerModal = () => setShowDangerModal(prevState => !prevState)

    return (
      <DangerModal
        isOpen={showDangerModal}
        toggleFunction={toggleDangerModal}
        closeFunction={toggleDangerModal}
        body={dangerModalMessage}
        withoutConfirm
      />
    )
  };

  const statusList = useMemo(() => [
    {label: 'all', value: ''},
    {label: 'confirmed', value: 'confirmed'},
    {label: 'unconfirmed', value: 'unconfirmed'}
  ],[])

  const handleCloseCreateWithdrawalModal = () => {
    setShowCreateWithdrawalModal(prevState => !prevState)
  }

  const handleClickPage = (ev, page) => {
    setPage(page)
  }

  const handleSetFilter = async (filterName, value) => {
    setPage(1);

    setFilters(prevState => ({...prevState, [filterName]: value}))
  }

  return (
    <>
      <SimpleHeader name="Manual Withdrawals" parentName="Wallets" className="pb-1"/>
      <TabHeader match={match}  />
      <Container className="mt--6" fluid>
        <Card className="user_content">
          <Table
            light
            tableTitle="Manual Withdrawals"
            inlineActions={inlineActions}
            inlineFilter={(value) => handleSetFilter('status', value)}
            inlineFilterSelected={filters.status}
            inlineFilterData={statusList}
            columns={{
              email: "User",
              status: "Status",
              amount: "Amount",
              coin_symbol: "Coin",
              txid: "TX ID",
              created_at: "Date",
            }}
            rows={manualWithdrawals}
            meta={manualWithdrawalsMeta}
            actionsColumn
            actions={actionsArray}
            onPagingClick={handleClickPage}
          />
          <CreateManualWithdrawalModal isOpen={showCreateWithdrawalModal} onClose={handleCloseCreateWithdrawalModal}/>

          {renderConfirmationModal()}
          {renderDangerModal()}
        </Card>
      </Container>
  </>);
};

export default memo(manualWithdrawals);