import React from "react";
import history from '../../../history';
import { Container, } from "reactstrap";
import EmptyHeader from '../../../components/Headers/EmptyHeader';
import DarkTable from "../../../components/Table";
import { hasPermission } from '../../../utils';

class ManualDistributions extends React.Component {

  componentDidMount() {
    if (!hasPermission('distribution.get.all')) {
      history.push('/error');
    } else {
      this.props.getManualDistributions();
    }
  }

  onPagingClick = (ev, page) => {
    this.props.getManualDistributions({ page: page });
  }

  showBalances = (event, row) => {
    event.preventDefault();
    this.props.history.push(`/prdx-manual-distribution/${row.id}/balances`);
  }

  showFunds = (event, row) => {
    event.preventDefault();
    this.props.history.push(`/prdx-manual-distribution/${row.id}/funds`);
  }

  getActionsAsArray = () => {
    return [
      { name: 'View User Balances', onClick: this.showBalances },
      { name: 'Manage Distribution', onClick: this.showFunds },
    ]
  }

  render() {
    return (
      <>
        <EmptyHeader />
        <Container className="mt--6" fluid>
          <DarkTable
            tableTitle="Distributions"
            columns={{
              day: "Date",
              ref_id: "Distribution ID",
              last_revenue_id: "Last Revenue ID",
              status: "Status",
              created_at: "Created At",
            }}
            actionsColumn={true}
            actions={this.getActionsAsArray()}
            rows={this.props.distributions}
            meta={this.props.meta}
            onPagingClick={this.onPagingClick}
          />
        </Container>
      </>
    );
  }
}

export default ManualDistributions;
