import React from "react";
import history from "../../../history";
import MaintenanceForm from "components/MaintenanceForm/index.jsx";
import SuccessModal from "../../../components/Modal/success.jsx";

import { Card, CardBody, Container, CardHeader, Button } from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";

export default class maintenanceDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blockModal: false,
    };
  }

  async componentDidMount() {
    const maintenance_id = this.props.match.params.maintenance_id;

    if (maintenance_id) {
      this.props.getMaintenance(maintenance_id);
    }
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  toggleModal = () => {
    this.setState({
      blockModal: !this.state.blockModal,
    });
  };

  renderConfirmationModal = () => {
    const response = "Saved!";

    return (
      <SuccessModal
        isOpen={this.state.blockModal}
        toggleFunction={() => {
          const { match: {params: {maintenance_id}} } = this.props; 
          if(!maintenance_id) history.push('/maintenances');
          else this.toggleModal();
        }}
        closeFunction={() => {
          const { match: {params: {maintenance_id}} } = this.props; 
          if(!maintenance_id) history.push('/maintenances');
          else this.toggleModal();
        }}
        body={response}
      />
    );
  };

  cancel = () => {
    history.push("/maintenances");
  };

  addMaintenance = async (e, status = "active") => {
    await this.props.addMaintenance(this.props, status);

    if (this.props.status.success) {
      this.toggleModal();
    }
  };

  editMaintenance = async () => {
    await this.props.editMaintenance(this.props.maintenance);

    if (this.props.status.success) {
      this.toggleModal();
    }
  };

  render() {
    const { maintenance, status, setMaintenanceField } = this.props;
    const maintenance_id = this.props.match.params.maintenance_id;

    const action_text = maintenance_id
      ? "Edit maintenance"
      : "Add maintenance";

    return (
      <>
        <SimpleHeader name={action_text} parentName="Manage Maintenances" />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader>
              <h3 className="mb-0">{action_text}</h3>
            </CardHeader>

            <CardBody>
              <MaintenanceForm
                status={status}
                object={maintenance}
                onChange={setMaintenanceField}
              />

              <br />

              <Button
                color="info"
                type="button"
                className="float-right ml-3"
                onClick={this.cancel}
              >
                Cancel
              </Button>

              {maintenance_id && maintenance && (
                <Button
                  color="success"
                  type="button"
                  className="float-right ml-3"
                  onClick={this.editMaintenance}
                >
                {"Edit"}
                </Button> 
              )}
              {!maintenance_id && (
                <Button
                  color="success"
                  type="button"
                  className="float-right ml-3"
                  onClick={this.addMaintenance}
                >
                  {"Add"}
                </Button>
              )}

              <br />

              <span className="error">
                {status.error && status.error.data && status.error.data.error}
              </span>
            </CardBody>
          </Card>
          {this.renderConfirmationModal()}
        </Container>
      </>
    );
  }
}
