const module = 'MANUAL_DISTRIBUTION';

export const GET_MANUAL_DISTRIBUTIONS_PENDING = `${module}/GET_MANUAL_DISTRIBUTIONS_PENDING`;
export const GET_MANUAL_DISTRIBUTIONS_SUCCESS = `${module}/GET_MANUAL_DISTRIBUTIONS_SUCCESS`;
export const GET_MANUAL_DISTRIBUTIONS_ERROR = `${module}/GET_MANUAL_DISTRIBUTIONS_ERROR`;

export const GET_MANUAL_DISTRIBUTION_PENDING = `${module}/GET_MANUAL_DISTRIBUTION_PENDING`;
export const GET_MANUAL_DISTRIBUTION_SUCCESS = `${module}/GET_MANUAL_DISTRIBUTION_SUCCESS`;
export const GET_MANUAL_DISTRIBUTION_ERROR = `${module}/GET_MANUAL_DISTRIBUTION_ERROR`;

export const GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_PENDING = `${module}/GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_PENDING`;
export const GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_SUCCESS = `${module}/GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_SUCCESS`;
export const GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_ERROR = `${module}/GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_ERROR`;

export const GET_MANUAL_DISTRIBUTION_FUNDS_PENDING = `${module}/GET_MANUAL_DISTRIBUTION_FUNDS_PENDING`;
export const GET_MANUAL_DISTRIBUTION_FUNDS_SUCCESS = `${module}/GET_MANUAL_DISTRIBUTION_FUNDS_SUCCESS`;
export const GET_MANUAL_DISTRIBUTION_FUNDS_ERROR = `${module}/GET_MANUAL_DISTRIBUTION_FUNDS_ERROR`;

export const UPDATE_MANUAL_DISTRIBUTION_FUNDS_PENDING = `${module}/UPDATE_MANUAL_DISTRIBUTION_FUNDS_PENDING`;
export const UPDATE_MANUAL_DISTRIBUTION_FUNDS_SUCCESS = `${module}/UPDATE_MANUAL_DISTRIBUTION_FUNDS_SUCCESS`;
export const UPDATE_MANUAL_DISTRIBUTION_FUNDS_ERROR = `${module}/UPDATE_MANUAL_DISTRIBUTION_FUNDS_ERROR`;

export const COMPLETE_MANUAL_DISTRIBUTION_PENDING = `${module}/COMPLETE_MANUAL_DISTRIBUTION_PENDING`;
export const COMPLETE_MANUAL_DISTRIBUTION_SUCCESS = `${module}/COMPLETE_MANUAL_DISTRIBUTION_SUCCESS`;
export const COMPLETE_MANUAL_DISTRIBUTION_ERROR = `${module}/COMPLETE_MANUAL_DISTRIBUTION_ERROR`;

export const GET_MANUAL_DISTRIBUTION_BALANCES_PENDING = `${module}/GET_MANUAL_DISTRIBUTION_BALANCES_PENDING`;
export const GET_MANUAL_DISTRIBUTION_BALANCES_SUCCESS = `${module}/GET_MANUAL_DISTRIBUTION_BALANCES_SUCCESS`;
export const GET_MANUAL_DISTRIBUTION_BALANCES_ERROR = `${module}/GET_MANUAL_DISTRIBUTION_BALANCES_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;