import React from "react";
import { Container, Card, CardBody, CardHeader, Button} from "reactstrap";
import EmptyHeader from '../../../components/Headers/EmptyHeader'
import DarkTable from "../../../components/Table"

class Funds extends React.Component {
  convertedBalances = {};

  componentDidMount() {
    this.props.getManualDistribution(this.props.match.params.distribution_id || '');
    this.props.getManualDistributionFunds(this.props.match.params.distribution_id || '');
  }

  /**
   * Submit form to update distribution funds
   * @param {Event} ev 
   */
  submit = (ev) => {
    ev.preventDefault();
    if (this.props.currentDistribution.status != 'pending') {
      return false;
    }
    const funds = Object.keys(this.convertedBalances).map(id => ({id: 1*id, converted_balance: this.convertedBalances[id].value}));
    const distID = this.props.match.params.distribution_id || '';
    this.props.updateManualDistributionFunds(distID, {funds: funds});
  }

  complete = (ev) => {
    ev.preventDefault();
    if (this.props.currentDistribution.status != 'pending') {
      return false;
    }
    const distID = this.props.match.params.distribution_id || '';
    this.props.completeManualDistribution(distID);
  }

  render() {
    const {currentDistribution: dist, funds, meta, approximatePrdxDistributedAmount} = this.props;
    return (
      <>
        <EmptyHeader />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader>
              <h1>Manage Distribution Funds (RefID: {dist.ref_id})</h1>
              <p className="mt-4">If the distribution is still in pending status the funds are available 
                for the set distribution user and can be converted into PRDX manually from the exchange interface.</p>
              <p className="mt-2">For each distribution fund set the converted value in PRDX which should be used for distribution. 
                Then the distribution can be completed and users will be able to clain their funds.</p>
            </CardHeader>
            <CardBody>
              <form onSubmit={this.submit}>
                <DarkTable
                  headerTitle={`Approximative amount to distribute = ${approximatePrdxDistributedAmount} PRDX`}
                  RowComponent={({data})=>{
                    return (
                      <tr>
                        <td>{`# ${data.id}`}</td>
                        <td>{data.last_revenue_id}</td>
                        <td>{`${data.total_balance} ${data.coin_symbol}`}</td>
                        <td className="text-uppercase">
                          {dist.status == 'pending' ?  <>
                            <input name={`converted_balance[${data.id}]`} defaultValue={data.converted_balance} ref={el => this.convertedBalances[data.id] = el} /> {data.converted_coin_symbol}
                          </>: <>{data.converted_balance} {data.converted_coin_symbol}</>}
                        </td>
                        <td>{data.status}</td>
                        <td>{data.created_at}</td>
                        <td></td>
                      </tr>
                    );
                  }}
                  columns={{
                    id: "",
                    last_revenue_id: "Last Revenue ID",
                    total_balance: "Total Balance",
                    converted_balance: "Converted Balance",
                    status: "Status",
                    created_at: "Date",
                  }}
                  actionsColumn={false}
                  rows={funds}
                  meta={meta}
                />
                { dist.status == 'pending' ? <Button type="submit" color="success">Update Funds</Button> : null }
              </form>
              { 
                dist.status == 'pending' ?
                <>
                  <p className="mt-4">Finalize the distribution of funds to users by clicking the button below. This operation is final and can't be reverted.</p>
                  <Button onClick={this.complete} color="primary">Finalize Distribution</Button>
                </> : <strong>Distribution is already completed and has status: "{dist.status}"</strong> }
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default Funds;
