import React from "react";
import history from '../../../history';
import CoinForm from "components/CoinForm/index.jsx";
import SuccessModal from "../../../components/Modal/success.jsx";
import DangerModal from "../../../components/Modal/danger.jsx";

import {
  Card,
  CardBody,
  Container,
  CardHeader,
  Button
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class CoinDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false,
      warningModal: false,
      warningModalType: "",
      warningBody: ""
    }; 
  }

  async componentDidMount() {
    this.props.getChains();
    this.props.getCoins();
    const coin_id = this.props.match.params.coin_id;
    if(coin_id) {
      await this.props.getCoin(coin_id); 
      if(this.props.status.error && this.props.coin.symbol === "") {
        //invalid coin symbol, redirect to list of coins
        this.cancel();
      }
    } else {
      this.props.resetForm();
    }
  }

  cancel = () => {
    history.push('/manage-coins-chains');
  }

  addCoin = async () => {
    await this.props.addCoin(this.props.coin);
    if (this.props.status.success) {
      this.toggleModal();
    }
  }

  editCoin = async () => {
    await this.props.updateCoin(this.props.coin);
    if (this.props.status.success) {
      this.toggleModal();
    }
  }

  toggleModal = () => {
    this.setState({
        blockModal: !this.state.blockModal,
    });
  }

  renderConfirmationModal = () => {
    const response = "Saved!"
    return (
        <SuccessModal
            isOpen={this.state.blockModal}
            toggleFunction={() => this.toggleModal()}
            closeFunction={() => this.toggleModal()}
            body={response}
        />
    )
  };

  toggleWarningModal = (body, type) => {
    this.setState({
      warningModal: !this.state.warningModal,
      warningBody: body,
      warningModalType: type,
    });
  };

  renderWarningModal = () => {
    return (
      <DangerModal
        isOpen={this.state.warningModal}
        toggleFunction={this.closeWarningModal}
        nextFunction={this.toggleFunctionAfterWarningModal}
        closeFunction={this.closeWarningModal}
        body={this.state.warningBody}
      />
    );
  };

  closeWarningModal = () => {
    this.toggleWarningModal();
    this.props.setCoinField(
      this.state.warningModalType,
      !this.props.coin[this.state.warningModalType]
    );
  };

  toggleFunctionAfterWarningModal = async () => {
    await this.submitFunctionByType();
    this.setState({ warningModal: false });
    if (this.props.status.success) {
      this.toggleModal();
    } else {
      this.toggleErrorModal(this.props.status.error.data.error);
    }
  };

  submitFunctionByType = async () => {
    const coin_id = this.props.match.params.coin_id;
    switch (this.state.warningModalType) {
      case 'block_deposit':
        return await this.props.changeBlockDeposit(coin_id, this.props.coin.block_deposit);
      case 'block_withdraw':
        return await this.props.changeBlockWithdraw(coin_id, this.props.coin.block_withdraw);
      default:
        return '';
    }
  };

  render() { 
    const { chains, coins, coin, status, setCoinField } = this.props; 
    const coin_id = this.props.match.params.coin_id;
    const action_text = coin_id ? "Edit coin" : "Add a coin";
    
    return (
        <>
            <SimpleHeader name={action_text} parentName="Manage Coins"/>
            <Container className="mt--6" fluid>
                <Card>
                    <CardHeader>
                        <h3 className="mb-0">{action_text}</h3>
                    </CardHeader>
                    <CardBody>
                        <CoinForm object={coin} coins={coins} chains={chains} status={status} onChange={setCoinField} toggleWarningModal={this.toggleWarningModal}/>
                        <Button color="info" type="button" className="float-right ml-3" onClick={this.cancel}>Cancel</Button>
                        <Button color="success" type="button" className="float-right" onClick={coin_id ? this.editCoin : this.addCoin}>{action_text}</Button>
                    </CardBody>
                </Card>
                {this.renderConfirmationModal()}    
                {this.renderWarningModal()}
            </Container>
        </>
    );
  }
}

export default CoinDetails;
