/*
 * Authentication Helper Functions
 */
import jwtDecode from 'jwt-decode';
import store from '../../redux/store';
import { UnauthorizedError } from '../errors';
import { logoutWithRefresh as logout } from '../../redux/Login/actions';

let timer = null;

export function tokenIsPartial(decodedToken) {
  return !decodedToken.sub && decodedToken.key && decodedToken.key !== '';
}

export function isValidToken(token) {
  if (!token) return false;
  let decoded = null;
  try {
    decoded = jwtDecode(token); 
    const now = Date.now().valueOf() / 1000;
    if (tokenIsPartial(decoded)) return false;
    if (decoded.exp && now >= decoded.exp) return false;
    return true;
  } catch (err) {
    return false;
  }
}

// returns true if decoded doesn't have the 'sub' key
export function partialLogin(token) {
  if (!token) return false;
  let decoded = null;
  try {
    decoded = jwtDecode(token);
    return tokenIsPartial(decoded);
  } catch (err) {
    return false;
  }
}

export function isLoggedIn() {
  const token = store.getState().Login.token;
  return isValidToken(token);
}

export function getLoggedInUserData(){
  return store.getState().Login.currentUser || {};
}

export function getLoggedInUserID(){
  return getLoggedInUserData().user_id || false;
}

export function assetValidToken(token) {
  return new Promise((resolve, reject) => {
    if (isValidToken(token)) return resolve()
    return reject(new UnauthorizedError('unauthorized', {token: token, error: "Token invalid or expired"}))
  }) 
}

export function logoutOnUnauthorizedError(err) {
  if (err instanceof UnauthorizedError && err.data.token !== "") {
    _logout(err.message);
    return true
  }
  return false
}

function _logout() {
  logout()(store.dispatch)
}

export function logoutOnTokenExpiration(token) {
  if (timer!= null) clearTimeout(timer)
  let seconds = 0;
  try {
    const decoded = jwtDecode(token);
    const now = Date.now().valueOf() / 1000

    if (decoded.exp && now >= decoded.exp) return false;
    seconds = 1 * decoded.exp - now;
    if (seconds <= 0) return false;
    //min because setTimeout is limited to int32, 30 days in microseconds exceeds that
    timer = setTimeout(_logout, Math.min(seconds, 2147483) * 1000);
  } catch (err) { 
    return false;
  }
}