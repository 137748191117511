import React from 'react';
import { Container, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';

import history from '../../../history';
import { hasPermission } from '../../../utils';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import AddTopicForm from '../../../components/AddTopicForm';
import TopicsList from './TopicsList';
import { doesTopicExist } from '../../../utils/topic';

export default class topics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topics: undefined,
      isEditable: false,
    };
  }

  async componentDidMount() {
    if (!hasPermission('admin.view')) {
      history.push('/error');
    } else {
      this.props.getAnnouncementSettings();
    }
  }

  handleGetTopics = () => {
    const { announcementsSettings } = this.props;

    const topics =
      announcementsSettings &&
      announcementsSettings.topics &&
      announcementsSettings.topics.map((announcement) => announcement);

    return topics || [];
  };

  handleAddTopic = async () => {
    const { topic: { topic_title: newTopicTitle }, resetForm, addTopic } = this.props;

    const { topics: defaultTopics } = this.state;

    const topics = defaultTopics || this.handleGetTopics();

    const topicsList = [...topics, newTopicTitle];

    const isSameTopic = doesTopicExist(topics, newTopicTitle);

    await addTopic(
      topicsList,
      newTopicTitle,
      isSameTopic,
      this.refs.notificationAlert,
    );

    if (!!newTopicTitle && !isSameTopic) {
      this.setState({
        topics: topicsList,
      });

      resetForm();
    }
  };

  handleEditTopic = async (topicId, topicValue) => {
    const { topics: defaultTopics } = this.state;

    const topicsList = defaultTopics || this.handleGetTopics();

    const topics = topicsList.map((topic, index) =>
      index === topicId ? topicValue : topic,
    );

    await this.props.editTopic(topics, this.refs.notificationAlert);

    this.setState({
      topics,
    });
  };

  handleDeleteTopic = async (topicId) => {
    const { topics: defaultTopics } = this.state;

    const topicsList = defaultTopics || this.handleGetTopics();

    const topics = topicsList.filter((_, index) => index !== topicId);

    await this.props.deleteTopic(topics, this.refs.notificationAlert);

    this.setState({
      topics,
    });
  };

  render() {
    const { topic, status, setAddTopicField } = this.props;

    const topics = this.handleGetTopics();

    const topicsList = this.state.topics || topics;

    return (
      <>
        <SimpleHeader name="Announcements Topics" parentName="Dashboard" />

        <Container className="mt--6" fluid>
          <Card className="user_content">
            <CardHeader>
              <h3 className="mb-0">Announcements Topics</h3>
            </CardHeader>

            <CardBody>
              <AddTopicForm
                object={topic}
                status={status}
                onChange={setAddTopicField}
                onAddTopic={this.handleAddTopic}
              />

              <Row>
                <Col md="12">
                  <TopicsList
                    topicsList={topicsList}
                    onEditTopic={this.handleEditTopic}
                    onDeleteTopic={this.handleDeleteTopic}
                    notificationRef={this.refs.notificationAlert}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>

        <div className="rna-wrapper" ref="notifH">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </>
    );
  }
}
