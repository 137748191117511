import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import forgotPassword from '../components/index';
import Login from '../../../redux/Login';

const mapActionsToProps = dispatch =>
  bindActionCreators (
    {
      setField: Login.actions.setField,
      onSubmit: Login.actions.forgotPassword,
      onReset: Login.actions.resetPassword,
      isRecaptchaEnabled: Login.actions.isRecaptchaEnabled,
      geetestValidate: Login.actions.geetestValidate,
      geetestEmpty: Login.actions.geetestEmpty
    },
    dispatch
  );

const mapStateToProps = state => ({
  status: Login.selectors.status (state),
  response: Login.selectors.response (state),
  email: Login.selectors.email (state),
  password: Login.selectors.password (state),
  password_confirm: Login.selectors.password_confirm (state),
  recaptchaEnabled: Login.selectors.recaptchaEnabled (state),
  recaptcha: Login.selectors.recaptcha (state),
  geetest: Login.selectors.geetest(state),
});

export default connect (mapStateToProps, mapActionsToProps) (forgotPassword);
