export const status = state => state.Markets.status;
export const coins = state => state.Markets.coins;
export const markets = state => state.Markets.markets;
export const filter = state => state.Markets.filter;
export const orders = state => state.Markets.orders;
export const ordersMeta = state => state.Markets.ordersMeta;
export const market = state => state.Markets.market;
export const marketsMeta = state => state.Markets.marketsMeta;
export const markets_query = state => state.Markets.markets_query;
export const orders_query = state => state.Markets.orders_query;
export const settings = state => state.Markets.settings;