import React from "react";
import UserTabs from "components/Tabs/user.jsx";

import {Card, Container} from "reactstrap";
import DarkTable from "../../../components/Table";
import {hasPermission} from "../../../utils";
import history from "../../../history";

export default class ReferralsView extends React.Component {

    componentDidMount = () => {
        const user_id = this.props.match.params.user_id;
        this.props.getReferrals({user_id: user_id, page: 1});
    };

    getActionsArray = () => {
        return [
            {name: 'View User', onClick: this.viewUser, permission: 'user.view'},
            {name: 'View Referrals', onClick: this.viewReferrals, permission: 'referral.view'},
        ].filter(item => hasPermission(item.permission))
    };

    viewUser = (e, user) => { 
        history.push('/user/' + user.id);
    }

    viewReferrals = (e, user) => {
        history.push('/user/' + user.id + "/referrals");
        this.props.getReferrals({user_id: user.id, page: 1});

    }

    onReferralsPagingClick = (e, page) => {
        const user_id = this.props.match.params.user_id;
        this.props.getReferrals({...this.props, user_id: user_id, page: page});
    }

    searchReferrals = async (e) => {
        const user_id = this.props.match.params.user_id;
        await this.props.setField('referral_query', e.target.value);
        this.props.getReferrals({...this.props, user_id: user_id, page: 1});
    }

    renderReferralsSection = () => {
        return <DarkTable
            light={true}
            tableTitle="Referrals"    
            actionsColumn={hasPermission('referral.view')}
            actions={this.getActionsArray()}
            inlineSearch={this.searchReferrals}
            columns={{
                index: "No",
                name: "Name",
                created_at: "Date",
                level_1_invited: "Invited",
                earned: "Earned",
            }}
            rows={this.props.referrals}
            meta={this.props.referrals_meta}
            onPagingClick={this.onReferralsPagingClick}
        />;
    };

    render() {
        const { match } = this.props;
        return (
            <>
                <UserTabs match={match} />
                <Container className="mt--6" fluid>
                    <Card className="user_content">
                        {this.renderReferralsSection()}
                    </Card>
                </Container>
            </>
        );
    }
}

