import * as c from "./constants";
import { asyncActionCreator, actionCreator } from "../../utils/redux";

export const getBots = asyncActionCreator(
  c.GET_BOTS_PENDING,
  c.GET_BOTS_SUCCESS,
  c.GET_BOTS_ERROR
);

export const getGridBotsStatistics = asyncActionCreator(
  c.GET_GRID_BOTS_STATISTICS_PENDING,
  c.GET_GRID_BOTS_STATISTICS_SUCCESS,
  c.GET_GRID_BOTS_STATISTICS_ERROR
);

export const downloadGridBotsStatistics = asyncActionCreator(
  c.DOWNLOAD_GRID_BOTS_STATISTICS_PENDING,
  c.DOWNLOAD_GRID_BOTS_STATISTICS_SUCCESS,
  c.DOWNLOAD_GRID_BOTS_STATISTICS_ERROR
);

export const getTrendBotsStatistics = asyncActionCreator(
  c.GET_TREND_BOTS_STATISTICS_PENDING,
  c.GET_TREND_BOTS_STATISTICS_SUCCESS,
  c.GET_TREND_BOTS_STATISTICS_ERROR
);

export const downloadTrendBotsStatistics = asyncActionCreator(
  c.DOWNLOAD_TREND_BOTS_STATISTICS_PENDING,
  c.DOWNLOAD_TREND_BOTS_STATISTICS_SUCCESS,
  c.DOWNLOAD_TREND_BOTS_STATISTICS_ERROR
);



export const getTotalActiveBots = asyncActionCreator(
  c.GET_TOTAL_ACTIVE_BOTS_PENDING,
  c.GET_TOTAL_ACTIVE_BOTS_SUCCESS,
  c.GET_TOTAL_ACTIVE_BOTS_ERROR
);


export const setField = actionCreator(c.SET_FIELD);
