import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Markets from '../components/index';
import MarketsState from '../../../redux/Markets';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getMarkets: MarketsState.actions.getMarkets,
            disableMarket: MarketsState.actions.disableMarket,
            editMarket: MarketsState.actions.editMarket,
            cancelMarketOrders: MarketsState.actions.cancelMarketOrders,
            setField: MarketsState.actions.setField,
            updateMarketSettings: MarketsState.actions.updateMarketSettings,
            resetForm: MarketsState.actions.resetForm,
            getVolumeDistributedPercent: MarketsState.actions.getVolumeDistributedPercent,
            setSettingsField: MarketsState.actions.setSettingsField
        },
        dispatch
    );

const mapStateToProps = state => ({
    markets: MarketsState.selectors.markets (state),
    marketsMeta: MarketsState.selectors.marketsMeta (state),
    markets_query: MarketsState.selectors.markets_query (state),
    settings: MarketsState.selectors.settings (state),
    status: MarketsState.selectors.status (state)
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Markets);

