import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import ManualDeposits from '../components/manualDeposits';
import WalletState from '../../../redux/Wallets';
import LoginState from '../../../redux/Login';

const mapActionsToProps = dispatch =>
  bindActionCreators (
    {
      getManualDeposits: WalletState.actions.getManualDeposits,
      setField: WalletState.actions.setField,
    },
    dispatch
  );

const mapStateToProps = state => ({
  currentUser: LoginState.selectors.currentUser (state),
  manualDepositsMeta: WalletState.selectors.manualDepositsMeta (state),
  manualDeposits: WalletState.selectors.manualDeposits (state),
  manual_deposits_status: WalletState.selectors.manual_deposits_status (state),
});

export default connect (mapStateToProps, mapActionsToProps) (ManualDeposits);
