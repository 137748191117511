import React from "react";
import {Col, Form, Row, Button} from "reactstrap";
import {Input, Checkbox} from "../Inputs"

export default class FeesForm extends React.Component {
  discountable = null;
  defaultTakerFee = null;
  defaultMakerFee = null;

  submit = (event) => {
    event.preventDefault();
    const {onSubmit, fees} = this.props;
    const {user_id} = fees || {};
    onSubmit(user_id, {
      discountable: this.discountable.checked,
      default_maker_fee: this.defaultMakerFee.value,
      default_taker_fee: this.defaultTakerFee.value,
    });
  }

  render() {
    const {fees} = this.props;
    const {discountable, default_taker_fee, default_maker_fee, taker_fee, maker_fee, level, discount_level, current_discount, status} = fees || {};

    return (
      <Form>
        <Row>
          <Col md="12">
            <Checkbox name="discountable" label="Apply discounts based on balance" ref={el=>this.discountable = el} checked={discountable} />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Input label="Default maker fee" value={default_maker_fee} name="default_maker_fee" ref={el=>this.defaultMakerFee = el} />
          </Col>
          <Col md="6">
            <Input label="Default taker fee" value={default_taker_fee} name="default_taker_fee" ref={el=>this.defaultTakerFee = el} />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Input label="Maker fee" value={maker_fee} name="maker_fee" disabled />
          </Col>
          <Col md="6">
            <Input label="Taker fee" value={taker_fee} name="taker_fee" disabled />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Input label="Distribution level" value={level} name="level" disabled />
          </Col>
          <Col md="6">
            <Input label="Discount level" value={discount_level} name="discount_level" disabled />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Input label="Discount Applied" value={current_discount} name="current_discount" disabled />
          </Col>
          <Col md="6">
            <Input label="Status" value={status} name="status" disabled />
          </Col>
        </Row>

        <Row className="flex-end-align col-md-12 no-padding-no-margin">
          <Button color="success" type="button" className="float-right" onClick={this.submit}>Update fees</Button>
        </Row>
      </Form>
    );
  }
}

