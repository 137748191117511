import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import OtcOrdersDetails from "../components/index";
import OtcOrdersState from "../../../redux/OtcOrders";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getOtcOrderDocumentUrl: OtcOrdersState.actions.getOtcOrderDocumentUrl,
      attachDocumentToOtcOrder: OtcOrdersState.actions.attachDocumentToOtcOrder,
      setOtcDocumentField: OtcOrdersState.actions.setOtcDocumentField,
      resetOtcDocument: OtcOrdersState.actions.resetOtcDocument
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: OtcOrdersState.selectors.status(state),
  orderDocument: OtcOrdersState.selectors.orderDocument(state),
  otcOrders: OtcOrdersState.selectors.otcOrders(state),
});

export default connect(mapStateToProps, mapActionsToProps)(OtcOrdersDetails);
