import React from "react";
import history from '../../../history';
import { isEmpty } from "lodash";
import {
    Container,
    Card,
    CardBody,
    CardHeader,
    Col, 
    Form, 
    Row
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader";
import { hasPermission, formatNumber } from '../../../utils';


class PrdxCirculation extends React.Component {
 
  componentDidMount() {
    if (!hasPermission('admin.view')) {
      history.push('/error');
    } else {
      this.props.getInitialData();
    }
  }

  getTotalPrdxAllLines = () => {
    const {totalPrdxForAllLines} = this.props;
    let total = 0;

    Object.keys(totalPrdxForAllLines).forEach((key) => {
      total += parseFloat(totalPrdxForAllLines[key]);
    });
    return total;
  }


  renderMarketsSection = () => {
    const {totalPrdxForAllLines, prdxCirculation} = this.props;
    let accountsMm = [];

    if(!isEmpty(prdxCirculation.accounts_mm)){
      prdxCirculation.accounts_mm.forEach((item) => {
        accountsMm.push(`${item.email}:${item.value}`);
      });
    }

    return (
    <Card className="user_content">
        <CardHeader>
            <div className='d-flex'>
                <h3 className="mb-0">PRDX Circulation</h3>
                <div className='ml-auto d-flex align-items-center'>
                    <div className='mr-3'>{`TOTAL PRDX`}</div>
                    <div className='mr-2'>all lines: {formatNumber(this.getTotalPrdxAllLines(),2)}</div>
                    <div className='mr-2'>silver line: {formatNumber(totalPrdxForAllLines.silver,2)}</div>
                    <div className='mr-2'>gold line: {formatNumber(totalPrdxForAllLines.gold,2)}</div>
                    <div className='mr-2'>platinum line: {formatNumber(totalPrdxForAllLines.platinum,2)}</div>
                    <div>black line: {formatNumber(totalPrdxForAllLines.black,2)}</div>
                </div>
            </div>
        </CardHeader>
        <CardBody>
            <Form>
                <Row>
                    <Col md="3" className='d-flex'><h4 className='mr-2'>TOTAL BURNED:</h4><h4>{prdxCirculation.total_burned ? formatNumber(prdxCirculation.total_burned, 2) : 0}</h4></Col>
                    <Col md="3" className='d-flex'><h4 className='mr-2'>TOTAL CIRCULATION:</h4><h4>{prdxCirculation.total_circulation ? formatNumber(prdxCirculation.total_circulation, 2) : 0}</h4></Col>
                    <Col md="3" className='d-flex'><h4 className='mr-2'>TOTAL DISTRIBUTION:</h4><h4>{prdxCirculation.total_distributed ? formatNumber(prdxCirculation.total_distributed, 2) : 0}</h4></Col>
                    <Col md="3" className='d-flex'><h4 className='mr-2'>TOTAL ON CHAIN:</h4><h4>{prdxCirculation.total_on_chain ? formatNumber(prdxCirculation.total_on_chain, 2) : 0}</h4></Col>
                </Row>
                <Row>
                    <Col md="3" className='d-flex'><h4 className='mr-2'>TOTAL ON EXCHANGE:</h4><h4>{prdxCirculation.total_on_exchange ? formatNumber(prdxCirculation.total_on_exchange, 2) : 0}</h4></Col>
                    <Col md="3" className='d-flex'><h4 className='mr-2'>ACCOUNT DISTRIBUTION:</h4><h4>{prdxCirculation.account_distribution ? formatNumber(prdxCirculation.account_distribution,2) : 0}</h4></Col>
                    <Col md="3" className='d-flex'><h4 className='mr-2'>ACCOUNT RECOVERY:</h4><h4>{prdxCirculation.account_recover_not_claimed ? formatNumber(prdxCirculation.account_recover_not_claimed,2) : 0}</h4></Col>
                    <Col md="3" className='d-flex'><h4 className='mr-2'>TOTAL PRDX OF USERS:</h4><h4>{prdxCirculation.total_user_on_exchange ? formatNumber(prdxCirculation.total_user_on_exchange,2) : 0}</h4></Col>
                </Row>
                <Row>
                  <Col md="12" className='d-flex flex-column'>
                    <h4 className='mr-2'>ACCOUNTS MM:</h4>
                    <div className='d-flex flex-column'>
                      {!isEmpty(accountsMm) && accountsMm.map((item) => <h4>{item}</h4>)}
                    </div>
                  </Col>
                </Row>
            </Form>
        </CardBody>
    </Card>)
  };

  render() { 
    return (
      <>
      <SimpleHeader name="PRDX Statistics" parentName="PRDX Circulation"/>
      <Container className="mt--6" fluid>
        {this.renderMarketsSection()}
      </Container>
      </>
    );
  }
}

export default PrdxCirculation;