const module = 'DISTRIBUTION';

export const SELECT_DISTRIBUTION_EVENT = `${module}/SELECT_DISTRIBUTION_EVENT`;
export const GET_DISTRIBUTION_EVENTS_PENDING = `${module}/GET_DISTRIBUTION_EVENTS_PENDING`;
export const GET_DISTRIBUTION_EVENTS_SUCCESS = `${module}/GET_DISTRIBUTION_EVENTS_SUCCESS`;
export const GET_DISTRIBUTION_EVENTS_ERROR = `${module}/GET_DISTRIBUTION_EVENTS_ERROR`;


export const GET_DISTRIBUTION_ORDERS_PENDING = `${module}/GET_DISTRIBUTION_ORDERS_PENDING`;
export const GET_DISTRIBUTION_ORDERS_SUCCESS = `${module}/GET_DISTRIBUTION_ORDERS_SUCCESS`;
export const GET_DISTRIBUTION_ORDERS_ERROR = `${module}/GET_DISTRIBUTION_ORDERS_ERROR`;

export const GET_DISTRIBUTION_ENTRIES_PENDING = `${module}/GET_DISTRIBUTION_ENTRIES_PENDING`;
export const GET_DISTRIBUTION_ENTRIES_SUCCESS = `${module}/GET_DISTRIBUTION_ENTRIES_SUCCESS`;
export const GET_DISTRIBUTION_ENTRIES_ERROR = `${module}/GET_DISTRIBUTION_ENTRIES_ERROR`;