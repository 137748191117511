import React, {memo, useEffect, useState} from 'react';
import {Button, Col, FormGroup, Input, Label, Modal} from "reactstrap";
import EmailSelect from "../EmailSelect/EmailSelect";
import api from "../../api";
import * as storage from "../../utils/storage";
import {sortBy} from "lodash";
import {forceNumeric, isValidNumber} from "../../utils";


const CreateManualWithdrawalModal = ({ isOpen, onClose }) => {

  const [userId, setUserId] = useState(null);
  const [userError, setUserError] = useState('');
  const [symbol, setSymbol] = useState('');
  const [symbolError, setSymbolError] = useState('');
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');
  const [requestError, setRequestError] = useState('');
  const [coins, setCoins] = useState([]);


  useEffect(() => {
    getCoins().then();
  }, []);

  useEffect(() => {
    if(!isOpen) resetForm()
  }, [isOpen]);

  const resetForm = () => {
    setUserId(null);
    setSymbol('');
    setAmount('');
    setUserError('');
    setAmountError('');
    setSymbolError('');
  }

  const getCoins = async() => {
    const coins = await api.assets.getCoins({}, storage.get('admin_auth_token', ''));
    const coinsSorted = sortBy(coins, 'symbol');

    setCoins(coinsSorted);
  }

  const getCoinsOptions = () => {
    const options = coins.map((item) => {
      return {label: item.symbol, value: item.symbol}
    });

    options.unshift({label: '', value: ''})
    return options;
  }

  const createWithdrawal = async () => {
    setUserError('');
    setAmountError('');
    setSymbolError('');
    setRequestError('');

    let error = false;

    if(!userId) {
      error = true;
      setUserError('Field is required');
    }

    if(!symbol) {
      error = true;
      setSymbolError('Field is required');
    }

    if(!amount) {
      error = true;
      setAmountError('Field is required');
    }

    if(!error){
      try{
        const data = {
          user_id: userId,
          coin_symbol: symbol,
          amount
        };

        await api.wallets.createManualWithdrawal(data, storage.get('admin_auth_token', ''));

        onClose();
      }catch(error){
        setRequestError(`Server error: ${error.message}`);
      }
    }
  }

  const handleUserEmailChange = (selectedOption) => {
    setUserId(selectedOption.value);
  }

  const handleAmountChange = ({target}) => {
    const value = forceNumeric(target.value);

    if (isValidNumber(value)) {
      setAmount(value);
    }
  }

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={onClose}>
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Create Manual Withdrawal
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body py-3 mb-3">
        <FormGroup className="row">
          <Label className="form-control-label" md="4">
            User Email
          </Label>
          <Col md="8">
            <EmailSelect onChange={handleUserEmailChange}/>
            <span className="help-block error">
              {userError}
            </span>
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="form-control-label" md="4">
            Coin
          </Label>
          <Col md="8">
            <Input
              type="select"
              value={symbol}
              onChange={(e) => setSymbol(e.target.value)}
            >
              {getCoinsOptions().map((option) => (
                <option key={option.value || option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
            <span className="help-block error">
                  {symbolError}
                </span>
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="form-control-label" md="4">
            Amount
          </Label>
          <Col md="8">
            <Input
              value={amount}
              type={'number'}
              onChange={(e) => handleAmountChange(e)}
            />
            <span className="help-block error">
                {amountError}
                </span>
          </Col>
        </FormGroup>
        <span className="help-block error">
          {requestError}
        </span>
      </div>
      <div className="modal-footer">
        <div className = 'ml-auto'>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={createWithdrawal}
          >
            Create Withdrawal
          </Button>
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(CreateManualWithdrawalModal);