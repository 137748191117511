import * as c from './constants';
import {asyncActionCreator, actionCreator} from '../../utils/redux';

export const getWithdrawals = asyncActionCreator(c.GET_WITHDRAWALS_PENDING, c.GET_WITHDRAWALS_SUCCESS, c.GET_WITHDRAWALS_ERROR);
export const getDeposits = asyncActionCreator(c.GET_DEPOSITS_PENDING, c.GET_DEPOSITS_SUCCESS, c.GET_DEPOSITS_ERROR);
export const getManualDeposits = asyncActionCreator(c.GET_MANUAL_DEPOSITS_PENDING, c.GET_MANUAL_DEPOSITS_SUCCESS, c.GET_MANUAL_DEPOSITS_ERROR);
export const getManualWithdrawals = asyncActionCreator(c.GET_MANUAL_WITHDRAWALS_PENDING, c.GET_MANUAL_WITHDRAWALS_SUCCESS, c.GET_MANUAL_WITHDRAWALS_ERROR);
export const getOperations = asyncActionCreator(c.GET_OPERATIONS_PENDING, c.GET_OPERATIONS_SUCCESS, c.GET_OPERATIONS_ERROR);
export const setField = actionCreator(c.SET_FIELD);
export const updateWithdraw = actionCreator(c.UPDATE_WITHDRAW);
export const changeBlockDeposit = asyncActionCreator(c.CHANGE_BLOCK_DEPOSIT_PENDING, c.CHANGE_BLOCK_DEPOSIT_SUCCESS, c.CHANGE_BLOCK_DEPOSIT_ERROR);
export const changeBlockWithdraw = asyncActionCreator(c.CHANGE_BLOCK_WITHDRAW_PENDING, c.CHANGE_BLOCK_WITHDRAW_SUCCESS, c.CHANGE_BLOCK_WITHDRAW_ERROR);
export const getBlockDeposit = asyncActionCreator(c.GET_BLOCK_DEPOSIT_PENDING, c.GET_BLOCK_DEPOSIT_SUCCESS, c.GET_BLOCK_DEPOSIT_ERROR);
export const getBlockWithdraw = asyncActionCreator(c.GET_BLOCK_WITHDRAW_PENDING, c.GET_BLOCK_WITHDRAW_SUCCESS, c.GET_BLOCK_WITHDRAW_ERROR);