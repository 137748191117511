import * as c from './constants';
import {socialNetworks, prdxLines} from '../../utils';
import moment from 'moment';

const lineLevelFields = prdxLines.reduce((result, item) => {
  return {
    ...result, 
    [`${item}_line_level`]: ''
  };
},{});

const socialNetworkFields = socialNetworks.reduce((result, item) => {
  return {
    ...result, 
    [`${item}_url`]: ''
  };
},{});

const parseLineLevelsObject = (lineLevels) => {
  return Object.keys(lineLevels).reduce((result, key) => {
    return {
      ...result, 
      [`${key}_line_level`]: lineLevels[key]
    };
  },{});
}

const parseSocialMediaLinksObject = (socialMediaLinks) => {
  return Object.keys(socialMediaLinks).reduce((result, key) => {
    return {
      ...result, 
      [`${key}_url`]: socialMediaLinks[key]
    };
  },{});
}

let initialState = {
  projects: [],
  project: {
    id: null,
    logo: null,
    title: "",
    coin_symbol: "",
    contributions_cap: "",
    blockchain: "",
    short_info: "",
    details: "",
    token_details: "",
    project_info: "",
    start_date: null,
    end_date: null,
    timezone: "",
    presale_price: "",
    status: ''
  },
  meta: {},
  status: {pending: false, success: false, error: false},
};
initialState = {
  ...initialState, 
  project:{
    ...initialState.project,
    ...lineLevelFields,
    ...socialNetworkFields
  }
};


export default (state = initialState, action = {}) => {
  switch (action.type) {

    case c.GET_PROJECT_PENDING:
    case c.GET_PROJECTS_PENDING:
    case c.ADD_PROJECT_PENDING:
    case c.UPDATE_PROJECT_PENDING:
      return {
        ...state,
        status: { pending: true, error: false, success: false },
      };

    case c.GET_PROJECT_ERROR:
    case c.GET_PROJECTS_ERROR:
    case c.ADD_PROJECT_ERROR:
    case c.UPDATE_PROJECT_ERROR:
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false },
      };

    case c.GET_PROJECTS_SUCCESS: {
      const projects = action.payload.Launchpads.map((item) => {

        const startDate = new Date(item.start_date * 1000);
        startDate.setTime(startDate.getTime() + startDate.getTimezoneOffset()*60*1000);
  
        const endDate = new Date(item.end_date * 1000);
        endDate.setTime( endDate.getTime() + endDate.getTimezoneOffset()*60*1000);

        return {
          ...item,
          coin_symbol: item.coin_symbol.toUpperCase(),
          start_date: moment(startDate).format("LLL"),
          end_date: moment(endDate).format("LLL"),
        };
      });
      return {
        ...state,
        projects,
        meta: action.payload.Meta,
      };
    }

    case c.GET_PROJECT_SUCCESS: {
      const {Launchpad} = action.payload;
      const lineLevels = parseLineLevelsObject(Launchpad.line_levels);
      const socialMediaLinks = parseSocialMediaLinksObject(Launchpad.social_media_links);

      const startDate = new Date(Launchpad.start_date * 1000);
      startDate.setTime(startDate.getTime() + startDate.getTimezoneOffset()*60*1000);

      const endDate = new Date(Launchpad.end_date * 1000);
      endDate.setTime( endDate.getTime() + endDate.getTimezoneOffset()*60*1000);

      return {
        ...state,
        project: {
          ...Launchpad,
          start_date: startDate,
          end_date: endDate,
          ...lineLevels,
          ...socialMediaLinks
        },
      };
    }

    case c.UPDATE_PROJECT_SUCCESS:
    case c.ADD_PROJECT_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
      };
    }

    case c.RESET_PROJECT_STATE: {
      return {
        ...state,
        project: {
          ...initialState.project,
        },
      };
    }
    case c.SET_PROJECT_FIELD: { 
      return {
        ...state,
        project: {
          ...state.project,
          [action.payload.field]: action.payload.value,
        }
      };
    }
    case c.SET_FIELD: { 
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};
