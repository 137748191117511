export const distributions = state => state.ManualDistributions.distributions;
export const distributionTotalPrdxForAllLines = state => state.ManualDistributions.distributionTotalPrdxForAllLines
export const curentDistribution = state => state.ManualDistributions.current_distribution;
export const approximatePrdxDistributedAmount = state => state.ManualDistributions.approximatePrdxDistributedAmount;
export const funds = state => state.ManualDistributions.funds;
export const balances = state => state.ManualDistributions.balances;
export const totalRedeemedPrdx = state => state.ManualDistributions.totalRedeemedPrdx;
export const balancesQuery = state => state.ManualDistributions.balancesQuery;
export const balancesLevel = state => state.ManualDistributions.balancesLevel;
export const meta = state => state.ManualDistributions.meta;
