import React from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";

import UserTabs from "../../../components/Tabs/user";
import KYBUploadDocsToggle from "../../../components/KYBUploadDocsToggle";
import {KYBStatusDot} from "../../../components/KYBStatusDot";
import {KYBListOfBusinessMembers} from "../../../components/KYBListOfBusinessMembers";
import {KYB_DOWNLOAD_FILES, KYB_GENERAL_STATUS} from "../../../constants/kyb";
import {KYBDownloadFile} from "../../../components/KYBDownloadFile";
import ErrorModal from "../../../components/Modal/danger";
import ShuftiPro from "../../../components/ShuftiPro";


export default class KYB extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false
    }
  }

  componentDidUpdate = (_, prevState) => {
    const { status } = this.props
    const isError = !!status.error

    if (prevState.isError !== isError) {
      this.setState({ isError })
    }
  }

  componentDidMount = () => {
    const { match, getUser, getKybStatus, getListOfBusinessMembers } = this.props
    const userId = match.params.user_id

    getUser(userId)
    getKybStatus(userId)
    getListOfBusinessMembers()
  };

  toggleErrorModal = () => {
    this.setState(({ isError }) => ({ isError: !isError }))
  }

  renderErrorModal = () => {
    const { status } = this.props;
    const { isError } = this.state;

    console.log('status',status)

    return (
      <ErrorModal
        withoutConfirm={true}
        isOpen={isError}
        toggleFunction={this.toggleErrorModal}
        closeFunction={this.toggleErrorModal}
        body={status.error.message}
      />
    );
  };

  renderKYBStatusByField = (fieldName, status) => {
    return (
      <Col md="12">
        <Row>
          <Col className="font-weight-bold" md="5">
            {fieldName}
          </Col>
          <Col className="d-flex align-items-center" md="2">
            <KYBStatusDot status={status} />
          </Col>
        </Row>
      </Col>
    );
  };

  render() {
    const {
      currentUser,
      match,
      status,
      kybStatus,
      updateKYBStepTwo,
      businessMembersList,
      downloadKYBDocument,
      KYBDocumentUrl,
      shuftiProResult
    } = this.props;

    const {
      status: commonKYBStatus,
      stepOneStatus,
      stepTwoStatus,
      isAllStepNone,
    } = kybStatus;

    const isContentExists = !isAllStepNone;
    const isKYCPassed = currentUser.user_level >= 2;

    return (
      <>
        <UserTabs match={match} />

        <Card>
          <Container className="mt--6 kyb-tab" fluid>
            <CardHeader>
              <Row>
                <Col xs={5}>
                  <div className="d-flex align-items-center">
                    <h3 className="mr-4 mb-0">KYB Status</h3>
                    <div className="d-flex ">
                      <KYBStatusDot status={commonKYBStatus} />
                    </div>
                  </div>
                </Col>
                <Col xs={7}><h3>Business members</h3></Col>
              </Row>
            </CardHeader>

            <CardBody>
              <Row>
                {status.pending && (
                  <Col xs={12}>
                    <div className="px-3 justify-content-md-center">
                      <i className="fas fa-spinner fa-spin fa-lg"></i>
                    </div>
                  </Col>
                )}

                {status.error && status.error.type === 'getUser' && (
                  <Col xs={12}>
                    <h1 className="mb-0" style={{ color: '#F00' }}>Error: {status.error.message}</h1>
                  </Col>
                )}

                {!isContentExists && (
                  <Col xs={12}>
                    <h4>User has not submitted data</h4>
                  </Col>
                )}

                {isContentExists && (
                  <>
                    <Col xs={5}>
                      <Row className="form-group">
                        {this.renderKYBStatusByField(
                          'Basic info (ShuftiPro)',
                          stepOneStatus,
                        )}
                      </Row>
                      <KYBUploadDocsToggle
                        updateKYBStepTwo={updateKYBStepTwo}
                        stepStatus={stepTwoStatus}
                        token={this.props.token}
                        userId={match.params.user_id}
                        status={status}
                      />
                      <Row className="form-group">
                        {this.renderKYBStatusByField(
                          'Related Parties (KYC Passed)',
                          isKYCPassed ? KYB_GENERAL_STATUS.SUCCESS : KYB_GENERAL_STATUS.FAIL,
                        )}
                      </Row>
                    </Col>
                    <Col xs={7}>
                      <KYBListOfBusinessMembers businessMembersList={businessMembersList} />
                    </Col>
                  </>
                )}
              </Row>
            </CardBody>

            <div className="mt-10">
              <CardHeader>
                <h3 className="mb-0">Documents</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={6}>
                    {KYB_DOWNLOAD_FILES.slice(0, KYB_DOWNLOAD_FILES.length / 2).map(
                      (file, index) => (
                        <div
                          className={index > 0 ? "mt-4" : ""}
                          key={`docs_part_1_${index}`}
                        >
                          <KYBDownloadFile
                            fieldName={file.fieldName}
                            fieldAlias={file.fieldAlias}
                            downloadKYBDocument={downloadKYBDocument}
                            KYBDocumentUrl={KYBDocumentUrl}
                            userId={match.params.user_id}
                            status={status}
                          />
                        </div>
                      ),
                    )}
                  </Col>
                  <Col xs={6}>
                    {KYB_DOWNLOAD_FILES.slice(KYB_DOWNLOAD_FILES.length / 2).map(
                      (file, index) => (
                        <div
                          className={index > 0 ? "mt-4" : ""}
                          key={`docs_part_1_${index}`}
                        >
                          <KYBDownloadFile
                            fieldName={file.fieldName}
                            fieldAlias={file.fieldAlias}
                            downloadKYBDocument={downloadKYBDocument}
                            KYBDocumentUrl={KYBDocumentUrl}
                            userId={match.params.user_id}
                            status={status}
                          />
                        </div>
                      ),
                    )}
                  </Col>
                </Row>
              </CardBody>
            </div>


            <div className="mt-10">
              <CardHeader>
                <h3 className="mb-0">ShuftiPro details</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <ShuftiPro data={shuftiProResult} />
                  </Col>
                </Row>
              </CardBody>
            </div>
          </Container>
        </Card>


        {this.renderErrorModal()}
      </>
    )
  }
}
