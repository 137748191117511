import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Markets from '../components/index';
import MarketsState from '../../../redux/Markets';
import CoinsState from '../../../redux/CoinsAndChains';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getCoins: CoinsState.actions.getCoins,
      getOrdersFromMarket: MarketsState.actions.getOrdersFromMarket,
      setField: MarketsState.actions.setField,
      cancelOrder: MarketsState.actions.cancelOrder,
      setFilterField: MarketsState.actions.setFilterField,
      getMarkets: MarketsState.actions.getMarkets,
    },
    dispatch
  );

const mapStateToProps = state => ({
  status: MarketsState.selectors.status(state),
  orders: MarketsState.selectors.orders(state),
  filter: MarketsState.selectors.filter(state),
  market: MarketsState.selectors.market(state),
  ordersMeta: MarketsState.selectors.ordersMeta(state),
  orders_query: MarketsState.selectors.orders_query(state),
  markets: MarketsState.selectors.markets (state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Markets);

