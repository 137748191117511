import React from 'react';
import * as storage from '../../utils/storage';
import AsyncSelect from 'react-select/async';
import api from '../../api';

const EmailSelect = ({onChange}) => {

  const autocompleteOptions = async (inputValue) => {
    try {
      const { Users: users } = await api.users.getAll(
        { query: inputValue },
        storage.get('admin_auth_token', '')
      );

      return users.map((item) => ({ label: item.email, value: item.id }));
    } catch (error) {
      return [];
    }
  };

  return (
    <AsyncSelect
        cacheOptions
        classNamePrefix='react-select'
        loadOptions={autocompleteOptions}
        onChange={onChange}
    />
  );
};

export default EmailSelect;
