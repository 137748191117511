import React from 'react';

import { CardWrapper } from './CardWrapper';
import { Field } from './Field';
import {
  isArrayField,
  isObjectField,
  isPrimitiveValue,
} from '../../utils/shuftiPro';

export const CardInfo = ({ cardHeader, data, fullWidth, showEmptyField }) => {
  const objectList = Object.keys(data).map((key, index) => {
    if (isObjectField(data[key])) {
      const isEmpty = Object.keys(data[key]).findIndex(
        (nestedKey) => !!data[key][nestedKey],
      );

      if (!showEmptyField && isEmpty === -1) {
        return null;
      }

      return (
        <CardWrapper cardHeader={key} fullWidth={fullWidth} key={index}>
          {Object.keys(data[key]).map((nestedKey, index) => (
            <Field
              key={`${nestedKey}-${index}`}
              title={nestedKey}
              value={data[key][nestedKey]}
              showEmptyField={showEmptyField}
            />
          ))}
        </CardWrapper>
      );
    }

    if (isArrayField(data[key])) {
      if (!showEmptyField && !data[key].length) return null;

      return (
        <CardWrapper cardHeader={key} fullWidth={fullWidth} key={index}>
          {data[key].map((item, index, array) => (
            <>
              {Object.keys(item).map(
                (key, index) =>
                  !!item[key] && (
                    <Field
                      key={`${key}-${index}`}
                      title={key}
                      value={item[key]}
                      showEmptyField={showEmptyField}
                    />
                  ),
              )}

              {index < array.length - 1 && <div className="splitter" />}
            </>
          ))}
        </CardWrapper>
      );
    }

    return null;
  });

  return (
    <>
      {cardHeader && (
        <CardWrapper cardHeader={cardHeader} fullWidth={fullWidth}>
          {Object.keys(data).map(
            (key, index) =>
              isPrimitiveValue(data[key]) && (
                <Field
                  key={`${key}-${index}`}
                  title={key}
                  value={data[key]}
                  showEmptyField={showEmptyField}
                />
              ),
          )}
        </CardWrapper>
      )}

      {objectList}
    </>
  );
};
