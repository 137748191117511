import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import KYB from "../components/kyb";
import State from "../../../redux/Users";
import LoginState from "../../../redux/Login";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: State.actions.getUser,
      getKybStatus: State.actions.getKybStatus,
      updateKYBStepTwo: State.actions.updateKYBStepTwo,
      getListOfBusinessMembers: State.actions.getListOfBusinessMembers,
      downloadKYBDocument: State.actions.downloadKYBDocument,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  currentUser: State.selectors.currentUser(state),
  status: State.selectors.status(state),
  kybStatus: State.selectors.kybStatus(state),
  shuftiProResult: State.selectors.shuftiProResult(state),
  businessMembersList: State.selectors.businessMembersList(state),
  KYBDocumentUrl: State.selectors.KYBDocumentUrl(state),
  token: LoginState.selectors.token(state),
});

export default connect(mapStateToProps, mapActionsToProps)(KYB);
