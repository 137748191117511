import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Component from '../components/withdrawals';
import WalletState from '../../../redux/Wallets';
import DashboardState from '../../../redux/Dashboard';

const mapActionsToProps = dispatch =>
  bindActionCreators (
    {
      getWithdrawals: WalletState.actions.getWithdrawals,
      setField: WalletState.actions.setField,
      setCoinField: DashboardState.actions.setField,
      getBlockWithdraw: WalletState.actions.getBlockWithdraw,
      changeBlockWithdraw: WalletState.actions.changeBlockWithdraw,
    },
    dispatch
  );

const mapStateToProps = state => ({
  data: DashboardState.selectors.data (state),
  block_withdraw: WalletState.selectors.block_withdraw(state),
  pending_withdrawals: DashboardState.selectors.pending_withdrawals (state),
  withdrawals: WalletState.selectors.withdrawals (state),
  meta: WalletState.selectors.withdrawalsMeta (state),
  withdrawals_filter: WalletState.selectors.withdrawals_filter (state),
  withdrawals_query: WalletState.selectors.withdrawals_query (state),
  status: WalletState.selectors.status (state),
});

export default connect (mapStateToProps, mapActionsToProps) (Component);
