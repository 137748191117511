import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Events from '../components/Events';
import State from '../../../redux/Distributions';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getDistributionEvents: State.actions.getDistributionEvents,
    },
    dispatch
  );

const mapStateToProps = state => ({
  events: State.selectors.events(state),
  meta: State.selectors.meta(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Events);

