import React, { Component } from 'react';

export default class CodeInput extends Component {

  input = React.createRef();

  state = {
    value: '',
    focused: false,
    code_length: []
  };
  componentDidMount = () => {
    const code_length = this.props.code_length * 1 || 6;
    this.setState({ code_length: new Array(code_length).fill(0) });
  };

  componentDidUpdate(prevProps,prevState){
    if(!prevState.code_length.length && this.state.code_length.length)  this.input.current.focus();
  }

  handleClick = () => {
    this.input.current.focus();
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    this.setState({
      focused: false
    });
  };

  handleKeyUp = (e) => {
    if (e.key === 'Backspace') {
      this.setState((state) => {
        return {
          value: state.value.slice(0, state.value.length - 1)
        };
      });
    } else if (e.key === 'Enter') {
      this.props.enterKeyPress(e);
    }
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState((state) => {
      const newValue = (state.value + value).slice(
        0,
        this.state.code_length.length
      );
      if (state.value.length >= this.state.code_length.length) return null;
      this.props.onChange(newValue);
      return {
        value: newValue
      };
    });
  };

  render() {
    const { value, focused } = this.state;
    const values = value.split('');
    return (
      <div className='code-input'>
        <div className='wrap' onClick={this.handleClick}>
          <input
            value=''
            ref={this.input}
            onChange={this.handleChange}
            onKeyUp={this.handleKeyUp}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            className='input'
          />
          {this.state.code_length.map((v, index) => {
            const selected = values.length === index;
            const filled =
              values.length === this.state.code_length.length &&
              index === this.state.code_length.length - 1;
            return (
              <div key={index} className='code-box'>
                {values[index]}
                {(selected || filled) && focused && <div className='shadows' />}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
