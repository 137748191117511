export const allUsers = (state) => state.Users.all;
export const totalUsersLineLevels = (state) => state.Users.totalUsersLineLevels;
export const meta = (state) => state.Users.meta;
export const logsmeta = (state) => state.Users.logsmeta;
export const ordersmeta = (state) => state.Users.ordersmeta;
export const tradesmeta = (state) => state.Users.tradesmeta;
export const distributionsmeta = (state) => state.Users.distributionsmeta;
export const withdrawalsMeta = (state) => state.Users.withdrawalsMeta;
export const depositmeta = (state) => state.Users.depositmeta;
export const logs = (state) => state.Users.logs;
export const orders = (state) => state.Users.orders;
export const trades = (state) => state.Users.trades;
export const wallet = (state) => state.Users.wallet;
export const distributions = (state) => state.Users.distributions;
export const withdrawals = (state) => state.Users.withdrawals;
export const bonusContracts = (state) => state.Users.bonusContracts;
export const deposits = (state) => state.Users.deposits;
export const cards = (state) => state.Users.cards;
export const bots = (state) => state.Users.bots;
export const totalActiveBots = (state) => state.Users.totalActiveBots;
export const totalOfLockedFunds = (state) => state.Users.totalOfLockedFunds;
export const currentUser = (state) => state.Users.currentUser;
export const currentUserFees = (state) => state.Users.currentUserFees;
export const users_query = (state) => state.Users.users_query;
export const activity_query = (state) => state.Users.activity_query;
export const orders_query = (state) => state.Users.orders_query;
export const orders_filter = (state) => state.Users.orders_filter;
export const trades_query = (state) => state.Users.trades_query;
export const wallet_query_bonus = (state) => state.Users.wallet_query_bonus;
export const wallet_query_main = (state) => state.Users.wallet_query_main;
export const trades_filter = (state) => state.Users.trades_filter;
export const contracts_filter = (state) => state.Users.contracts_filter;
export const withdrawal_query = (state) => state.Users.withdrawal_query;
export const withdrawals_filter = (state) => state.Users.withdrawals_filter;
export const deposit_query = (state) => state.Users.deposit_query;
export const deposits_filter = (state) => state.Users.deposits_filter;
export const status = (state) => state.Users.status;
export const kybStatus = (state) => state.Users.kybStatus;
export const shuftiProResult = (state) => state.Users.shuftiProResult;
export const businessMembersList = (state) => state.Users.businessMembersList;
export const KYBDocumentUrl = (state) => state.Users.KYBDocumentUrl;
export const account = (state) => state.Users.account;
export const addresses = (state) => state.Users.addresses;
export const withdrawSettings = (state) => state.Users.withdrawSettings;
