import * as c from './constants';
import moment from 'moment';

const initialState = {
  roles: [],
  role: {},
  permissions: [],
  status: {pending: false, success: false, error: false},
};


export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_PERMISSIONS_PENDING:
    case c.GET_ROLE_PENDING:
    case c.ADD_ROLE_PENDING:
    case c.SET_ROLE_PENDING:
    case c.GET_ROLES_PENDING: {
      return {
        ...state,
        status: {pending: true, error: false, success: false},
      };
    }
    case c.GET_PERMISSIONS_ERROR:
    case c.GET_ROLE_ERROR:
    case c.ADD_ROLE_ERROR:
    case c.SET_ROLE_ERROR:
    case c.GET_ROLES_ERROR:{
      return {
        ...state,
        status: {pending: false, error: action.payload, success: false},
      };
    }
    case c.GET_PERMISSIONS_SUCCESS: { 
      const permissionsGrouped = groupPermissions(action.payload);
      const permissions = getActivePermissions(action.payload, false);
      
      return {
        ...state,
        permissions: permissionsGrouped,
        role: {
          name: "",
          permissions: {...permissions}
        }
      };
    }
    case c.GET_ROLES_SUCCESS: { 
      const roles = action.payload.Roles.map(item => {
        return {
          ...item,
          created_at: moment(item.created_at).format('LLL')
        }
      })
      
      return {
        ...state,
        roles: roles,
        rolesMeta: action.payload.Meta
      };
    }
    case c.ADD_ROLE_SUCCESS: 
    case c.SET_ROLE_SUCCESS: { 
      return {
        ...state,
        status: {pending: false, error: false, success: true},
      };
    }
    case c.GET_ROLE_SUCCESS: {
      const permissions = getActivePermissions(action.payload.permissions, true);
      return {
        ...state,
        role: {
          ...action.payload,
          permissions: permissions
        }
      };
    }
    case c.RESET_ROLES_STATE: {
      return {
        ...state,
        role: {
          ...state.role,
          name: ""
        },
      };
    }
    case c.SET_FIELD: { 
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case c.SET_ROLE_FIELD: { 
      if(action.payload.field === Object(action.payload.field)) {
        return {
          ...state,
          role: {
            ...state.role,
            permissions: {
              ...state.role.permissions,
              [action.payload.field.alias]: action.payload.value
            }
          }
        };
      }

      return {
        ...state,
        role: {
          ...state.role,
          [action.payload.field]: action.payload.value,
        }
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};


function groupPermissions(permissions) {
  return permissions.map(item => {
      let key = item.alias.split('.')[0];
      return {
        [key]: {
          ...item,
          active: false
        }
      }
  }).reduce(
    function (r, a) { 
      let newKey = Object.keys(a)[0];
      r[newKey] = r[newKey] || [];
      r[newKey].push(a[newKey]);
      return r;
  }, Object.create(null))
}

function getActivePermissions(permissionsList, value) { 
    return permissionsList.reduce(
      function (r, a) { 
        r[a.alias] = value;
        return r;
    }, Object.create(null))
  
}