import React from "react";
import TabHeader from "components/Tabs/wallets.jsx";
import {Card, Container} from "reactstrap";
import Table from "../../../components/Table";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import SuccessModal from "../../../components/Modal/success.jsx";
import { renderToggleCheckBox } from "../../../components/Inputs"
import DangerModal from "../../../components/Modal/danger.jsx";

export default class Screen extends React.Component {
  ticker = null;
  state={
    page: 1,
    blockModal: false,
    warningModal: false,
    warningModalType: "",
    warningBody: ""
  };

  tick = () => {
    this.props.getDeposits({...this.props, page: this.state.page});
  }

  componentDidMount() {
    this.props.getCoins();
    this.props.getBlockDeposit();
    this.tick();
    this.ticker = setInterval(this.tick, 5000);
  }

  componentWillUnmount(){
    if(this.ticker){
      clearInterval(this.ticker);
    }
  }

  onClickPage = (ev, page) => {
    this.setState({page});
    this.props.getDeposits({...this.props, page});
  }

  search = async(e) => {
    this.setState({page: 1});
    await this.props.setField('deposits_query', e.target.value);
    this.props.getDeposits({...this.props, page: 1});
  }

  setFilter = async (filterName, value) => {
    this.setState({page: 1});
    await this.props.setField(filterName, value);
    this.props.getDeposits({...this.props, page: 1});
  }

  getStatusList = () => {
    return [
      {label: 'all', value: ''},
      {label: 'pending', value: 'pending'},
      {label: 'failed', value: 'failed'},
      {label: 'confirmed', value: 'confirmed'},
      {label: 'unconfirmed', value: 'unconfirmed'}
    ]
  }

  getCoinList = () => {
    const coins = [{
      label: 'all',
      value: ''
    }];

    this.props.coins.forEach((item) =>  {
      coins.push({label: item.symbol, value: item.symbol});
    });

    return coins;
  }

  toggleModal = () => {
    this.setState({
        blockModal: !this.state.blockModal,
    });
  }

  toggleWarningModal = (body, type) => {
    this.setState({
      warningModal: !this.state.warningModal,
      warningBody: body,
      warningModalType: type,
    });
  };

  renderConfirmationModal = () => {
    const response = "Saved!"
    return (
        <SuccessModal
            isOpen={this.state.blockModal}
            toggleFunction={() => this.toggleModal()}
            closeFunction={() => this.toggleModal()}
            body={response}
        />
    )
  };

  renderWarningModal = () => {
    return (
      <DangerModal
        isOpen={this.state.warningModal}
        toggleFunction={this.closeWarningModal}
        nextFunction={this.toggleFunctionAfterWarningModal}
        closeFunction={this.closeWarningModal}
        body={this.state.warningBody}
      />
    );
  };

  closeWarningModal = () => {
    this.toggleWarningModal();
    this.props.setField(
      this.state.warningModalType,
      !this.props.block_deposit
    );
  };

  toggleFunctionAfterWarningModal = async () => {
    await this.submitFunctionByType();
    this.setState({ warningModal: false });
    if (this.props.status.success) {
      this.toggleModal();
    } else {
      this.toggleErrorModal(this.props.status.error.data.error);
    }
  };

  submitFunctionByType = async () => {
    switch (this.state.warningModalType) {
      case 'block_deposit':
        return await this.props.changeBlockDeposit(this.props.block_deposit);
      default:
        return '';
    }
  };

  textForModal = (prop) => {
    switch (prop) {
      case 'block_deposit':
        return "Are you sure you want to change BLOCK STATE for DEPOSIT feature?";
     default:
        return '';
    }
  };

  onChangeCheckBox = (prop) => {
    this.toggleWarningModal(this.textForModal(prop), prop);
    this.props.setField(prop, !this.props[prop]);
  };


  render() {
    const { match, deposits, meta, deposits_filter, deposits_coin_filter } = this.props;
    return (
      <>
        <SimpleHeader name="Deposits" parentName="Wallets" className="pb-1"/>
        <TabHeader match={match}  />
        <Container className="mt--6" fluid>
          <Card className="user_content">
          <Table
            light={true}
            tableTitle={
              <div className='d-flex align-items-center'>
                <span>Deposits</span>
                <div className='ml-3 mt-2'>{renderToggleCheckBox("Block deposit", "block_deposit", {block_deposit: this.props.block_deposit}, this.onChangeCheckBox)}</div>
              </div>
            }
            actionsColumn={false}
            inlineSearch={this.search}
            inlineFilter={(value) => this.setFilter('deposits_filter', value)}
            inlineFilterSelected={deposits_filter}
            inlineFilterData={this.getStatusList()}
            inlineFilterCoin={(value) => this.setFilter('deposits_coin_filter', value)}
            inlineFilterCoinSelected={deposits_coin_filter}
            inlineFilterCoinData={this.getCoinList()}
            columns={{
              email: "User",
              status: "Status",
              amount: "Amount",
              external_system: "Payment Method",
              coin_symbol: "Coin",
              address: "Address",
              fee_amount: "Fee",
              trx_id: "TX ID",
              created_at: "Date",
            }}
            rows={deposits}
            meta={meta}
            onPagingClick={this.onClickPage}
          />
          </Card>
          {this.renderConfirmationModal()}    
          {this.renderWarningModal()}
        </Container>
      </>
    );
  }
}

