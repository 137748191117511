import React from "react";
import { Modal, Button } from "reactstrap";

const BotSettingsModal = ({ bot, onClose }) => {
  if (!bot || bot.modal !== "settings") {
    return null;
  }

  const priceRange =
    bot.selectedVersion.settings.maxPrice -
    bot.selectedVersion.settings.minPrice;
  const gridInterval = priceRange / bot.selectedVersion.settings.noOfGrids;
  const approximatedProfit =
    (gridInterval / bot.selectedVersion.settings.maxPrice) * 100;

  return (
    <Modal className="modal-dialog-centered" isOpen toggle={onClose}>
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Bot Version: {bot.selectedVersion.id}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3">
          <div className="mb-3">
            Pair:{" "}
            <b>{bot.selectedVersion.settings.currencyPair.toUpperCase()}</b>
          </div>
          <div className="mb-3">
            Price Range:{" "}
            <b>
              {bot.selectedVersion.settings.minPrice} -{" "}
              {bot.selectedVersion.settings.maxPrice}
            </b>
          </div>
          <div className="mb-3">
            Constant Open Orders:{" "}
            <b>{bot.selectedVersion.settings.noOfGrids}</b>
          </div>
          <div className="mb-3">
            Interval: <b>{gridInterval}</b>
          </div>
          <div className="mb-3">
            Approximate Min Profit: <b>{approximatedProfit}%</b>
          </div>
          <div className="mb-3">
            Profit: <b>{bot.selectedVersion.settings.profitPercent}%</b>
          </div>
          <div>
            Total Investment:{" "}
            <b>
              {bot.selectedVersion.settings.amount}{" "}
              {bot.selectedVersion.settings.coin.toUpperCase()}
            </b>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default BotSettingsModal;
