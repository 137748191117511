import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

import { hasPermission, hasUserRole } from "../../utils";

import State from "../../redux/Users";

class UserTabsView extends React.Component {
  componentDidMount() {
    const { match, getUser } = this.props;
    const user_id = match.params.user_id;

    getUser(user_id);
  }

  getTabsAndLinks = () => {
    const tabs = [
      { name: "View user", link: "/user/:user_id", permission: "user.view" },
      {
        name: "Edit user",
        link: "/user/:user_id/edit",
        permission: "user.edit",
      },
      { name: "KYB", 
        link: "/user/:user_id/kyb", 
        permission: "user.edit",
        role: "business"
      },
      {
        name: "Control Panel",
        link: "/user/:user_id/control-panel",
        permission: "user.edit",
      },
      {
        name: "View activity",
        link: "/user/:user_id/activity",
        permission: "user.view",
      },
      {
        name: "View orders",
        link: "/user/:user_id/orders",
        permission: "order.view.all",
      },
      {
        name: "View trades",
        link: "/user/:user_id/trades",
        permission: "order.view.all",
      },
      {
        name: "View withdrawals",
        link: "/user/:user_id/withdrawals",
        permission: "withdraw.view.all",
      },
      {
        name: "View deposits",
        link: "/user/:user_id/deposits",
        permission: "transaction.view.all",
      },
      {
        name: "View wallet",
        link: "/user/:user_id/wallet",
        permission: "transaction.view.all",
      },
      {
        name: "View referrals",
        link: "/user/:user_id/referrals",
        permission: "referral.view",
      },
      {
        name: "View bonus contracts",
        link: "/user/:user_id/bonus-contracts",
        permission: "user.view",
      },
      {
        name: "View bots",
        link: "/user/:user_id/bots",
        permission: "user.view",
      },
      {
        name: "PRDX distribution",
        link: "/user/:user_id/distribution",
        permission: "distribution.get",
      },
    ];

    const isAdminHasPermissionToSeeThisLink = ({link, permission}) => (
      (link !== '/user/:user_id' && hasPermission(permission)) ||
      (link === "/user/:user_id" && !hasPermission("user.edit"))
    );
    const isCurrentUserHasNeededForTabRole = ({role}) => role ? hasUserRole(role, this.props.currentUser) : true

    return tabs
      .filter(isAdminHasPermissionToSeeThisLink)
      .filter(isCurrentUserHasNeededForTabRole)
  };

  render() {
    const { match } = this.props;
    const user_id = match.params.user_id;
    const tabs = this.getTabsAndLinks();

    return (
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <Nav tabs>
            {tabs.map((item) => (
              <NavItem key={item.link}>
                <NavLink
                  className={classnames("nav-item", {
                    active: match.path === item.link,
                  })}
                  href={item.link.replace(":user_id", user_id)}
                >
                  {item.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Container>
      </div>
    );
  }
}

const mapActionsToProps = (dispatch) => bindActionCreators({
  getUser: State.actions.getUser,
}, dispatch);

const mapStateToProps = (state) => ({
  currentUser: State.selectors.currentUser(state),
});

export default connect(mapStateToProps, mapActionsToProps)(UserTabsView);