import React from 'react';
import classnames from 'classnames';

export default ({ pages, current, onClick, className, trim }) => {
  pages = 1 * pages;
  current = 1 * current;
  trim = trim ? 1 * trim : 10;
  if (pages <= 0) {
    return null;
  }

  const first = Math.max(1, current - trim);
  const last = Math.min(pages, current + trim);
  const addFirst = first > 2;
  const addLast = last < pages - 1 ;
  const mappedPages = [];

  if (first > 1) {
    mappedPages.push(getPage(1, current, ev => onClick(ev, 1)));
  }
  if (addFirst) {
    mappedPages.push(
      <li key={'hidden_start'} className="page-item disabled">
        <span className="page-link">...</span>
      </li>
    );
  }
  
  for (let i = first; i <= last; i++) {
    mappedPages.push(getPage(i, current, ev => onClick(ev, i)));
  }
  if (addLast) {
    mappedPages.push(
      <li key={'hidden_end'} className="page-item disabled">
        <span className="page-link">...</span>
      </li>
    );
  } 
  if (last < pages) {
    mappedPages.push(getPage(pages, current, ev => onClick(ev, pages)));
  }

  return (
    <nav area-label="Pagination">
      <ul className={classnames('pagination', className)}>
        {current === 1 ? (
          <li className={classnames('page-item', { disabled: current === 1 })}>
            <a className="page-link">
              Previous
            </a>
          </li>
        ) : (
          <li className={classnames('page-item', { disabled: current === 1 })}>
            <a className="page-link" onClick={ev => onClick(ev, current - 1)}>Previous</a>
          </li>
        )}
        {mappedPages}
        {current < pages ? (
          <li className={classnames('page-item', { disabled: current === pages })}>
            <a className="page-link" onClick={ev => onClick(ev, current + 1)}>
              Next
            </a>
          </li>
        ) : (
          <li className={classnames('page-item', { disabled: current === pages })}>
            <a className="page-link">Next</a>
          </li>
        )}
      </ul>
    </nav>
  );
};

function getPage(page, current, onClick) {
  return (
    <li key={page} className={classnames('page-item', { disabled: current === page })}>
      <a className="page-link" onClick={onClick}>
        {page}
      </a>
    </li>
  );
}
