import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Distributions from '../components/Distributions';
import State from '../../../redux/ManualDistributions';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getManualDistributions: State.actions.getManualDistributions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  distributions: State.selectors.distributions(state),
  meta: State.selectors.meta(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Distributions);
