import moment from "moment";

import {formatNumber} from "../../utils";

import * as c from "./constants";

const initialState = {
  orders: [],
  ordersMeta: {
    count: 0,
    limit: 10,
    page: 1,
    pages: 1,
  },
  orderDocument: null,
  status: { pending: false, success: false, error: false },
}

function transformOrderForTable ({order, user}) {
  return {
    ...order,
    user: user ? user : '',
    amount: formatNumber(order.amount, 8),
    price: formatNumber(order.price, 8),
    side: order.side.toUpperCase(),
    status: order.status,
    created_at: moment(order.created_at).format("YYYY-MM-DD H:mm:ss")
  }
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_MY_OWN_OTC_ORDERS_SUCCESS:
      const {meta: ordersMeta, orders} = action.payload;

      return {
        ...state,
        orders: orders.map(order => transformOrderForTable({order})),
        ordersMeta: {...ordersMeta, pages: Math.ceil(ordersMeta.count / ordersMeta.limit)},
        status: {...state.status, success: true}
      };

    case c.GET_ALL_OTC_ORDERS_SUCCESS:
      const {meta: allOrdersMeta, orders_with_user} = action.payload;

      return {
        ...state,
        orders: orders_with_user.map(({Order, user_email}) =>
          transformOrderForTable({order: Order, user: user_email})),
        ordersMeta: {...allOrdersMeta, pages: Math.ceil(allOrdersMeta.count / allOrdersMeta.limit)},
        status: {...state.status, success: true}
      };

    case c.GET_OTC_ORDER_DOCUMENT_SUCCESS:
      const { documentUrl } = action.payload;

      return {
        ...state,
        orderDocument: {
          ...state.orderDocument,
          url: documentUrl
        },
        status: {...state.status, success: true}
      };

    case c.ATTACH_DOCUMENT_TO_OTC_ORDER_SUCCESS:
      return {
        ...state,
        status: {...state.status, success: true},
        orderDocument: {url: state.orderDocument.url} // for reset input file
      };

    case c.GET_MY_OWN_OTC_ORDERS_PENDING:
    case c.GET_ALL_OTC_ORDERS_PENDING:
    case c.GET_OTC_ORDER_DOCUMENT_PENDING:
    case c.ATTACH_DOCUMENT_TO_OTC_ORDER_PENDING:
      return {...state, status: {...state.status, pending: true}}

    case c.GET_MY_OWN_OTC_ORDERS_ERROR:
    case c.GET_ALL_OTC_ORDERS_ERROR:
    case c.GET_OTC_ORDER_DOCUMENT_ERROR:
    case c.ATTACH_DOCUMENT_TO_OTC_ORDER_ERROR:
      return {...state, status: {...state.status, error: action.payload}}

    case c.SET_OTC_DOCUMENT_FIELD:
      return {
        ...state,
        orderDocument: {
          ...state.orderDocument,
          [action.payload.field]: action.payload.value,
        }
      }

    case c.RESET_OTC_DOCUMENT:
      return { ...state, orderDocument: null }

    default: {
      return {
        ...state,
      };
    }
  }
}