import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import RoleDetails from '../components/index';
import RolesState from '../../../redux/Roles';
import LoginState from '../../../redux/Login';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getPermissions: RolesState.actions.getPermissions,
            getUserPermissions: LoginState.actions.getUserPermissions,
            getRole: RolesState.actions.getRole,
            addRole: RolesState.actions.addRole,
            updateRole: RolesState.actions.updateRole,
            resetForm: RolesState.actions.resetForm,
            setRoleField: RolesState.actions.setRoleField,
        },
        dispatch
    );

const mapStateToProps = state => ({
    status: RolesState.selectors.status (state),
    role: RolesState.selectors.role (state),
    permissions: RolesState.selectors.permissions (state)
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(RoleDetails);

