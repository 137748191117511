import * as ac from './actionCreators';
import api from '../../api';
import { validateNotEmpty } from '../../utils/validator';
import { InvalidFields } from '../../utils/errors';
import { logoutOnUnauthorizedError } from "../../utils/auth";

export function getChains() {
  return async (dispatch, getState) => {
    dispatch(ac.getChains.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.getChains(token);
      dispatch(ac.getChains.success(data));
    } catch (err) {
      dispatch(ac.getChains.error(err));
    }
  };
}

export function getChain(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getChain.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.getChain(payload, token);
      dispatch(ac.getChain.success(data));
    } catch (err) {
      dispatch(ac.getChain.error(err));
    }
  };
}

export function addChain(payload) {
  return async (dispatch, getState) => { 
    dispatch(ac.addChain.pending());
    try {
      let field_errors = {};
      if (!validateNotEmpty(payload.name)) field_errors.name = 'Field is required';
      if (!validateNotEmpty(payload.symbol)) field_errors.symbol = 'Field is required';
    
      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });

      const token = getState().Login.token;
      const data = await api.assets.addChain(payload, token);
      dispatch(ac.addChain.success(data));
    } catch (err) {
      dispatch(ac.addChain.error(err));
    }
  };
}

export function editChain(payload) {
  return async (dispatch, getState) => { 
    dispatch(ac.editChain.pending());
    try {
      let field_errors = {};
      if (!validateNotEmpty(payload.name)) field_errors.name = 'Field is required';
      if (!validateNotEmpty(payload.symbol)) field_errors.symbol = 'Field is required';
    
      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });

      const token = getState().Login.token;
      const data = await api.assets.editChain(payload, token);
      dispatch(ac.editChain.success(data));
    } catch (err) {
      dispatch(ac.editChain.error(err));
    }
  };
}

export function disableChain(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.disableChain.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.disableChain(payload, token);
      dispatch(ac.disableChain.success(data));
    } catch (err) {
      dispatch(ac.disableChain.error(err));
    }
  };
}

export function getCoins(payload = {}) {
  return async (dispatch, getState) => {
    dispatch(ac.getCoins.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.getCoins(payload,token);
      dispatch(ac.getCoins.success(data));
    } catch (err) {
      dispatch(ac.getCoins.error(err));
    }
  };
}

export function getPresaleCoins() {
  return async (dispatch, getState) => {
    dispatch(ac.getPresaleCoins.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.getCoins({status: 'presale'},token);
      dispatch(ac.getPresaleCoins.success(data));
    } catch (err) {
      dispatch(ac.getPresaleCoins.error(err));
    }
  };
}

export function getCoin(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getCoin.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.getCoin(payload, token);
      dispatch(ac.getCoin.success(data));
    } catch (err) {
      dispatch(ac.getCoin.error(err));
    }
  };
}

export function disableCoin(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.disableCoin.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.disableCoin(payload, token);
      dispatch(ac.disableCoin.success(data));
    } catch (err) {
      dispatch(ac.disableCoin.error(err));
    }
  };
}

export function changeBlockDeposit(coinID, value) {
  return async (dispatch, getState) => {
    dispatch(ac.changeBlockDeposit.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.changeBlockDepositCoin(coinID, value, token);
      dispatch(ac.changeBlockDeposit.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.changeBlockDeposit.error(err));
    }
  };
}

export function changeBlockWithdraw(coinID, value) {
  return async (dispatch, getState) => {
    dispatch(ac.changeBlockWithdraw.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.changeBlockWithdrawCoin(coinID, value, token);
      dispatch(ac.changeBlockWithdraw.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.changeBlockWithdraw.error(err));
    }
  };
}

export function addCoin(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.addCoin.pending());
    try {

      let field_errors = {};
      if (!validateNotEmpty(payload.name)) field_errors.name = 'Field is required';
      if (!validateNotEmpty(payload.digits)) field_errors.digits = 'Field is required';
      if (!validateNotEmpty(payload.token_precision)) field_errors.token_precision = 'Field is required';
      if (!validateNotEmpty(payload.symbol)) field_errors.symbol = 'Field is required';
      if (!validateNotEmpty(payload.min_withdraw)) field_errors.min_withdraw = 'Field is required';
      if (!validateNotEmpty(payload.withdraw_fee)) field_errors.withdraw_fee = 'Field is required';
      if (!validateNotEmpty(payload.deposit_fee)) field_errors.deposit_fee = 'Field is required';
      if (!validateNotEmpty(payload.chain)) field_errors.chain = 'Field is required';
      if (!validateNotEmpty(payload.chain_symbol)) field_errors.chain_symbol = 'Field is required';
    
      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });

      const token = getState().Login.token;
      const coin = {
        name: payload.name,
        digits: payload.digits,
        precision: payload.token_precision,
        symbol: payload.symbol,
        minWithdraw: payload.min_withdraw,
        withdrawFee: payload.withdraw_fee,
        depositFee: payload.deposit_fee,
        costSymbol: payload.cost_symbol === 'self' ? payload.symbol : payload.cost_symbol,
        chainSymbol: payload.chain_symbol,
	      coinType: payload.type,
	      blockchainExplorer: payload.blockchain_explorer,
	      status: payload.status,
        should_get_value: payload.status === 'active' ? true : false,
        buyLimit: payload.buy_limit,
        sellLimit: payload.sell_limit,
        buyLimit24h: payload.buy_limit_24h,
        sellLimit24h: payload.sell_limit_24h,
      }
      const data = await api.assets.addCoin(coin, token);
      dispatch(ac.addCoin.success(data));
    } catch (err) {
      dispatch(ac.addCoin.error(err));
    }
  };
}

export function editCoin(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.editCoin.pending());
    try {

      let field_errors = {};
      if (!validateNotEmpty(payload.name)) field_errors.name = 'Field is required';
      if (!validateNotEmpty(payload.digits)) field_errors.digits = 'Field is required';
      if (!validateNotEmpty(payload.token_precision)) field_errors.token_precision = 'Field is required';
      if (!validateNotEmpty(payload.symbol)) field_errors.symbol = 'Field is required';
      if (!validateNotEmpty(payload.min_withdraw)) field_errors.min_withdraw = 'Field is required';
      if (!validateNotEmpty(payload.withdraw_fee)) field_errors.withdraw_fee = 'Field is required';
      if (!validateNotEmpty(payload.deposit_fee)) field_errors.deposit_fee = 'Field is required';
      if (!validateNotEmpty(payload.chain_symbol)) field_errors.chain_symbol = 'Field is required';

      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });

      const token = getState().Login.token;
      const coin = {
        id: payload.id,
        name: payload.name,
        digits: payload.digits,
        precision: payload.token_precision,
        symbol: payload.symbol,
        minWithdraw: payload.min_withdraw,
        withdrawFee: payload.withdraw_fee,
        depositFee: payload.deposit_fee,
        costSymbol: payload.cost_symbol === 'self' ? payload.symbol : payload.cost_symbol,
        chainSymbol: payload.chain_symbol,
	      coinType: payload.type,
	      blockchainExplorer: payload.blockchain_explorer,
	      status: payload.status,
        should_get_value: payload.status === 'active' ? true : false,
        buyLimit: payload.buy_limit,
        sellLimit: payload.sell_limit,
        buyLimit24h: payload.buy_limit_24h,
        sellLimit24h: payload.sell_limit_24h,
      }
      const data = await api.assets.editCoin(coin, token);
      dispatch(ac.editCoin.success(data));
    } catch (err) {
      dispatch(ac.editCoin.error(err));
    }
  };
}

export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}

export function setCoinField(field, value) {
  return dispatch => dispatch(ac.setCoinField({field, value}));
}

export function setChainField(field, value) {
  return dispatch => dispatch(ac.setChainField({field, value}));
}

export function resetForm() {
  return dispatch => dispatch(ac.resetForm());
}