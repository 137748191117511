import moment from 'moment'

export function validatePassword(password) {
  //const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W])/;
  const pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~§±])(?=.{8,})/);
  return pattern.test(password);
}
export function validateLength(text, length, exact) {
  return (typeof text !== 'undefined' && ((exact === true && text.length === length) || (exact !== true && text.length >= length)));
}
export function validateLengthLimit(text, length) {
  return (typeof text !== 'undefined' && text.length <= length);
}
export function validateEmail(email) {
  const trimmedEmail = email.trim();
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(trimmedEmail);
}

export function validateUsername(username) {
  const trimmedUsername = username.trim();
  const pattern = /^[a-zA-Z0-9_.]+$/;
  return pattern.test(trimmedUsername);
}

export function validateName(name) {
  const trimmedName = name.trim();
  const pattern = /^[a-zA-z_.]+$/;
  return pattern.test(trimmedName);
}

export function validateConfirmPassword(password1, password2) {
  return password1 === password2;
}

export function validateNotEmpty(field) {
  return (typeof field !== 'undefined' && field.toString().trim() !== "");
}

export function validateEighteenYearsOrOlder(field){
  var bday = moment(field);
  var now = moment();
  return now.diff(bday, 'years') >= 18
}