import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { renderABasicInput } from '../Inputs';

export default class AddTopicForm extends React.Component {
  render() {
    const { onAddTopic } = this.props;

    return (
      <Form>
        <Row>
          <Col md="4">
            {renderABasicInput(
              this.props,
              'Add topic',
              'topic_title',
              'text',
              false,
              0,
              { md: '4' },
              { md: '8' },
            )}
          </Col>

          <Col md="8">
            <Button color="success" type="button" onClick={onAddTopic}>
              Add Topic
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
