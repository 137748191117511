import React from 'react';
import UserTabs from "components/Tabs/user.jsx";

import {Card, Container} from "reactstrap";
import DarkTable from "../../../components/Table";
import {hasPermission} from '../../../utils';
import SuccessModal from "../../../components/Modal/success.jsx";

export default class WithdrawalsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false,
      body: ''
    };
  };

  componentDidMount = () => {
    const user_id = this.props.match.params.user_id;
    this.props.getUserWithdrawals({user_id: user_id, page: 1});
  };

  onWithdrawalsPagingClick = (ev, page) => {
    const user_id = this.props.match.params.user_id;
    this.props.getUserWithdrawals({...this.props, page: page, user_id: user_id,});
  };

  searchWithdrawals = async(e) => {
    const user_id = this.props.match.params.user_id;
    await this.props.setField('withdrawal_query', e.target.value);
    this.props.getUserWithdrawals({...this.props, page: 1, user_id: user_id,});
  };

  setWithdrawalsFilter = async(value) => {
    const user_id = this.props.match.params.user_id;
    await this.props.setField('withdrawals_filter', value);
    this.props.getUserWithdrawals({...this.props, page: 1, user_id: user_id,});
  };

  toggleModal = (body) => {
    this.setState({
      blockModal: !this.state.blockModal,
      body
    });
  };

  renderConfirmationModal = () => {
    return (
      <SuccessModal
        isOpen={this.state.blockModal}
        toggleFunction={() => this.toggleModal()}
        closeFunction={() => this.toggleModal()}
        body={this.state.body}
      />
    )
  };

  deleteWithdrawal = async(event, row) => {
    const user_id = this.props.match.params.user_id;
    await this.props.cancelUserWithdraw(row.id, user_id);
    if (this.props.status.success) {
      this.toggleModal('Deleted');
    }
  };

  getWithdrawalsActionsArray = () => {
    return [
      {
        name: 'Cancel',
        onClick: this.deleteWithdrawal,
        permission: 'withdraw.manage',
        disabled: {by: "status", values: [
          "in progress",
          "completed",
          "failed",
          // "user_approved",
          "admin_approved"
        ]},
      }
    ].filter(item => hasPermission(item.permission));
  };

  getStatusList = () => {
    return [
      {label: 'all', value: ''},
      {label: 'in progress', value: 'in_progress'},
      {label: 'completed', value: 'completed'},
      {label: 'pending', value: 'pending'},
      {label: 'failed', value: 'failed'},
      {label: 'user approved', value: 'user_approved'},
      {label: 'admin approved', value: 'admin_approved'}
    ];
  };

  constructWithdrawals = () => {
    if (this.props.withdrawals && this.props.currentUser) {
      return this.props.withdrawals.map(item => {
        return ({
          ...item,
          email: this.props.currentUser.email
        });
      });
    }
    return [];
  }

  renderViewWithdrawalsSection = () => {
    return <DarkTable
      light={true}
      tableTitle="View Withdrawals"
      actionsColumn={true}
      actions={this.getWithdrawalsActionsArray()}
      inlineSearch={this.searchWithdrawals}
      inlineFilter={this.setWithdrawalsFilter}
      inlineFilterData={this.getStatusList()}
      inlineFilterSelected={this.props.withdrawals_filter}
      columns={{
        user_email: "User",
        status: "Status",
        amount: "Amount",
        external_system: "Payment Method",
        coin_symbol: "Coin",
        to: "Address",
        trx_id: "TX ID",
        created_at: "Date",
      }}
      rows={this.constructWithdrawals()}
      meta={this.props.withdrawalsMeta}
      onPagingClick={this.onWithdrawalsPagingClick}
    />
  };

  render() {
    const {match} = this.props;
    return (
      <>
      <UserTabs match={match}/>
      <Container className="mt--6" fluid>
        <Card className="user_content">
          {this.renderViewWithdrawalsSection()}
        </Card>
        {this.renderConfirmationModal()}
      </Container>
      </>
    );
  }
}