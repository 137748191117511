import * as c from "./constants";
import { asyncActionCreator, actionCreator } from "../../utils/redux";

export const getMaintenance = asyncActionCreator(
  c.GET_MAINTENANCE_PENDING,
  c.GET_MAINTENANCE_SUCCESS,
  c.GET_MAINTENANCE_ERROR
);
export const getMaintenances = asyncActionCreator(
  c.GET_MAINTENANCES_PENDING,
  c.GET_MAINTENANCES_SUCCESS,
  c.GET_MAINTENANCES_ERROR
);
export const addMaintenance = asyncActionCreator(
  c.ADD_MAINTENANCE_PENDING,
  c.ADD_MAINTENANCE_SUCCESS,
  c.ADD_MAINTENANCE_ERROR
);
export const editMaintenance = asyncActionCreator(
  c.EDIT_MAINTENANCE_PENDING,
  c.EDIT_MAINTENANCE_SUCCESS,
  c.EDIT_MAINTENANCE_ERROR
);
export const changeMaintenanceStatus = asyncActionCreator(
  c.CHANGE_MAINTENANCE_STATUS_PENDING,
  c.CHANGE_MAINTENANCE_STATUS_SUCCESS,
  c.CHANGE_MAINTENANCE_STATUS_ERROR
);

export const setField = actionCreator(c.SET_FIELD);
export const setMaintenanceField = actionCreator(c.SET_MAINTENANCE_FIELD);
export const setMaintenance = actionCreator(c.SET_MAINTENANCE);
export const resetForm = actionCreator(c.RESET_MAINTENANCE_STATE);
