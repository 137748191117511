import { api } from "../config";
import { postFiles, putFiles, get } from "../utils/superagent";
import { assetValidToken } from "../utils/auth";

export const addProject = (data, token) =>
  assetValidToken(token).then(() =>
    postFiles(`${api}/admin/launchpad`, data, token)
  );

export const updateProject = (project_id, data, token) =>
  assetValidToken(token).then(() =>
  putFiles(`${api}/admin/launchpad/${project_id}`, data, token)
);

export const getProjects = async (data, token) => {
  const launchpads = await assetValidToken(token).then(() =>
    get(`${api}/admin/launchpad`, data, token)
  );

  launchpads.Meta.pages = Math.ceil(
    launchpads.Meta.count / launchpads.Meta.limit
  );

  return launchpads;
};

export const getProject = (project_id, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/launchpad/${project_id}`, {}, token)
  );