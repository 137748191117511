import React, {memo} from 'react';

import FileUploader from "devextreme-react/file-uploader";

const allowedFileExtensions = [".pdf", ".xlsx"];
const maxFileSize = 5000000; // 5mb

const DocumentsUploaderContainer = (props) => {
    const { disabled, value } = props;

  const onSelectedFilesChanged = async (e) => {
      const { onChange, setValid } = props

      onChange(e.value[0]);

      if (e.component._files[0]) {
          setValid(e.component._files[0].isValid());
      } else {
          setValid(true);
      }
  };


  return (
      <div className="image-uploader-container">
          <FileUploader
              disabled={disabled}
              selectButtonText="Select document"
              labelText=""
              allowedFileExtensions={allowedFileExtensions}
              maxFileSize={maxFileSize}
              uploadMode="useButtons"
              onValueChanged={onSelectedFilesChanged}
              value={value ? [value] : []}
          />
      </div>
  );
};

export default memo(DocumentsUploaderContainer);