import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Bots from "../components/index";
import BotsState from "../../../redux/Bots";
import LoginState from "../../../redux/Login";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getBots: BotsState.actions.getBots,
      getTotalActiveBots: BotsState.actions.getTotalActiveBots,
      setField: BotsState.actions.setField,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
    status: BotsState.selectors.status(state),
    totalActiveBots: BotsState.selectors.totalActiveBots(state),
    bots: BotsState.selectors.bots(state),
    botsStatus: BotsState.selectors.botsStatus(state),
    token: LoginState.selectors.token(state),
    botsMeta: BotsState.selectors.botsMeta(state),
});

export default connect(mapStateToProps, mapActionsToProps)(Bots);
