const module = "BOTS";

export const GET_BOTS_PENDING = `${module}/GET_BOTS_PENDING`;
export const GET_BOTS_SUCCESS = `${module}/GET_BOTS_SUCCESS`;
export const GET_BOTS_ERROR = `${module}/GET_BOTS_ERROR`;

export const GET_GRID_BOTS_STATISTICS_PENDING = `${module}/GET_GRID_BOTS_STATISTICS_PENDING`;
export const GET_GRID_BOTS_STATISTICS_SUCCESS = `${module}/GET_GRID_BOTS_STATISTICS_SUCCESS`;
export const GET_GRID_BOTS_STATISTICS_ERROR = `${module}/GET_GRID_BOTS_STATISTICS_ERROR`;

export const DOWNLOAD_GRID_BOTS_STATISTICS_PENDING = `${module}/DOWNLOAD_GRID_BOTS_STATISTICS_PENDING`;
export const DOWNLOAD_GRID_BOTS_STATISTICS_SUCCESS = `${module}/DOWNLOAD_GRID_BOTS_STATISTICS_SUCCESS`;
export const DOWNLOAD_GRID_BOTS_STATISTICS_ERROR = `${module}/DOWNLOAD_GRID_BOTS_STATISTICS_ERROR`;

export const DOWNLOAD_TREND_BOTS_STATISTICS_PENDING = `${module}/DOWNLOAD_TREND_BOTS_STATISTICS_PENDING`;
export const DOWNLOAD_TREND_BOTS_STATISTICS_SUCCESS = `${module}/DOWNLOAD_TREND_BOTS_STATISTICS_SUCCESS`;
export const DOWNLOAD_TREND_BOTS_STATISTICS_ERROR = `${module}/DOWNLOAD_TREND_BOTS_STATISTICS_ERROR`;

export const GET_TREND_BOTS_STATISTICS_PENDING = `${module}/GET_TREND_BOTS_STATISTICS_PENDING`;
export const GET_TREND_BOTS_STATISTICS_SUCCESS = `${module}/GET_TREND_BOTS_STATISTICS_SUCCESS`;
export const GET_TREND_BOTS_STATISTICS_ERROR = `${module}/GET_TREND_BOTS_STATISTICS_ERROR`;

export const GET_TOTAL_ACTIVE_BOTS_PENDING = `${module}/GET_TOTAL_ACTIVE_BOTS_PENDING`;
export const GET_TOTAL_ACTIVE_BOTS_SUCCESS = `${module}/GET_TOTAL_ACTIVE_BOTS_SUCCESS`;
export const GET_TOTAL_ACTIVE_BOTS_ERROR = `${module}/GET_TOTAL_ACTIVE_BOTS_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;
