import React from "react";
import HtmlEditor, {
  Toolbar,
  // MediaResizing,
  Item,
} from "devextreme-react/html-editor";

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const headerValues = [false, 1, 2, 3, 4, 5];

export default class HtmlEditorContainer extends React.Component {
  render() {
    const { value, onChange, disabled } = this.props;
    return (
      <HtmlEditor value={value} onValueChange={onChange} disabled={disabled}>
        {/* <MediaResizing enabled={true} /> */}
        <Toolbar multiline={false}>
          <Item formatName="undo" />
          <Item formatName="redo" />
          <Item formatName="separator" />
          <Item formatName="size" formatValues={sizeValues} />
          <Item formatName="separator" />
          <Item formatName="bold" />
          <Item formatName="italic" />
          <Item formatName="strike" />
          <Item formatName="underline" />
          <Item formatName="separator" />
          <Item formatName="alignLeft" />
          <Item formatName="alignCenter" />
          <Item formatName="alignRight" />
          <Item formatName="alignJustify" />
          <Item formatName="separator" />
          <Item formatName="orderedList" />
          <Item formatName="bulletList" />
          <Item formatName="separator" />
          <Item formatName="header" formatValues={headerValues} />
          <Item formatName="separator" />
          <Item formatName="color" />
          <Item formatName="background" />
          <Item formatName="separator" />
          <Item formatName="link" />
          <Item formatName="separator" />
          <Item formatName="codeBlock" />
          <Item formatName="blockquote" />
          <Item formatName="subscript" />
          <Item formatName="superscript" />
          <Item formatName="increaseIndent" />
          <Item formatName="decreaseIndent" />
          {/* <Item formatName="image" /> */}
          {/* <Item formatName="variable" /> */}
        </Toolbar>
      </HtmlEditor>
    );
  }
}
