import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MaintenanceDetails from "../components/index";
import MaintenancesState from "../../../redux/Maintenances";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      addMaintenance: MaintenancesState.actions.addMaintenance,
      editMaintenance: MaintenancesState.actions.editMaintenance,
      setMaintenanceField: MaintenancesState.actions.setMaintenanceField,
      getMaintenance: MaintenancesState.actions.getMaintenance,
      resetForm: MaintenancesState.actions.resetForm,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: MaintenancesState.selectors.status(state),
  maintenance: MaintenancesState.selectors.maintenance(state),
});

export default connect(mapStateToProps, mapActionsToProps)(MaintenanceDetails);
