import * as c from './constants';
import jwtDecode from 'jwt-decode';
import history from '../../history';
import * as storage from '../../utils/storage';
import {logoutOnTokenExpiration} from '../../utils/auth';
import {tokenIsPartial} from '../../utils/auth/index';

const initialToken = storage.get('admin_auth_token') || '';
logoutOnTokenExpiration(initialToken);

const initialState = {
  token: initialToken,
  currentUser: decodeToken(initialToken),
  email: '',
  response: '',
  otp_code: '',
  recaptcha: '',
  status: {pending: false, success: false, error: false},
  twoFAModal: {
    type: '',
    status: false
  },
  geetest: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.FORGOT_PASS_PENDING:
    case c.RESET_PASS_PENDING:
    case c.ISRECAPTCHAENABLED_PENDING:
    case c.USER_PERMISSIONS_PENDING:
    case c.GEETEST_REGISTER_PENDING:
    case c.LOGIN_PENDING: {
      return {
        ...state,
        status: {pending: true, error: false, success: false},
      };
    }
    case c.FORGOT_PASS_ERROR:
    case c.RESET_PASS_ERROR:
    case c.LOGIN_ERROR:
    case c.ISRECAPTCHAENABLED_ERROR:
    case c.GEETEST_REGISTER_ERROR:
    case c.USER_PERMISSIONS_ERROR: {
      return {
        ...state,
        status: {pending: false, error: action.payload, success: false},
      };
    }
    case c.FORGOT_PASS_SUCCESS: {
      return {
        ...state,
        response: action.payload.flash_message,
        status: {pending: false, error: false, success: true},
      };
    }
    case c.RESET_PASS_SUCCESS: {
      return {
        ...state,
        response: action.payload.flash_message,
        status: {pending: false, error: false, success: true},
      };
    }
    case c.LOGIN_SUCCESS: {
      // redirect to the dashboard
      const decodedToken = decodeToken(action.payload.token);
      const  partialToken = tokenIsPartial(decodedToken);
      setTimeout(() => {
        if (!partialToken) {
          history.push('/dashboard');
          logoutOnTokenExpiration(action.payload.token);
        }
      }, 0);
      return {
        ...state,
        token: action.payload.token,
        twoFAModal: {status: partialToken, type: decodedToken.twoFAType},
        currentUser: decodedToken,
        recaptcha: '',
        status: {pending: false, success: true, error: false},
      };
    }
    case c.ISRECAPTCHAENABLED_SUCCESS:
      return {
        ...state,
        recaptchaEnabled: (action.payload === 'true')
      };
    case c.USER_PERMISSIONS_SUCCESS:
      setPermissions(action.payload)
      return {
        ...state
      };
    case c.LOGOUT:
      storage.set('perm', '');
      return {
        ...state,
        token: '',
        currentUser: {},
        status: initialState.status,
      };
    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case c.GEETEST_REGISTER_SUCCESS:
      return {
        ...state,
        geetest: {
          ...state.geetest,
          register: action.payload,
        }
      };
    case c.GEETEST_VALIDATE:
      return {
        ...state,
        geetest: {
          ...state.geetest,
          validate: action.payload
        }
      };
    case c.GEETEST_RESET:
      return {
        ...state,
        geetest: {...state.geetest, validate: {}}
      };
    case c.GEETEST_EMPTY:
      return {
        ...state,
        geetest: {}
      };

    case c.REMOVE_TOKEN:
      return {
        ...state,
        token: '',
        twoFAModal: {status : false, type: ""},
      };

    default: {
      return {
        ...state,
      };
    }
  }
};

function decodeToken(token) {
  if (!token) {
    return {};
  }
  const jwt = jwtDecode(token);
  if (!tokenIsPartial(jwt)) {
    storage.set('admin_auth_token', token);
  }

  return jwt;
}

function takeTheRedirectPath(decodedToken) {
  if (tokenIsPartial(decodedToken)) {
    return `/auth/login?${decodedToken.twoFAType}2FA=true`;
  }
  return '/dashboard';
}

function setPermissions(data) {
  const perm = data.map(item => item.alias);
  storage.set('perm', perm);
}