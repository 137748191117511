import { api } from '../config';
import { get, post, put, del } from '../utils/superagent';
import { assetValidToken } from '../utils/auth';

// get all users
export const getAll = async (data, token) => {
  let users = await assetValidToken(token).then(() =>
    get(`${api}/admin/users`, data, token),
  );
  users.Meta.pages = Math.ceil(users.Meta.count / users.Meta.limit);
  return users;
};
// get users statistic
export const getTotalUsersLineLevels = async (token) => {
  let data = await assetValidToken(token).then(() =>
    get(`${api}/admin/total-users-line-levels`, {}, token),
  );
  return data;
};
// get user logs
export const getUserLogs = async (user_id, data, token) => {
  let logs = await assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}/loginlog`, data, token),
  );
  logs.meta.pages = Math.ceil(logs.meta.count / logs.meta.limit);
  return logs;
};
// get user orders
export const getUserOrders = async (user_id, data, token) => {
  let orders = await assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}/orders`, data, token),
  );
  orders.Meta.pages = Math.ceil(orders.Meta.count / orders.Meta.limit);
  return orders;
};
// get user trades
export const getUserTrades = async (user_id, data, token) => {
  let trades = await assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}/trades`, data, token),
  );
  trades.meta.pages = Math.ceil(trades.meta.count / trades.meta.limit);
  return trades;
};

export const downloadUserTrades = async (user_id, data, token) => {
  return await assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}/trades/export`, data, token),
  );
};

export const getUserDistributions = async (user_id, data, token) => {
  let distributions = await assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}/distributions`, data, token),
  );
  distributions.Meta.pages = Math.ceil(
    distributions.Meta.count / distributions.Meta.limit,
  );
  return distributions;
};

// get user deposits;
export const getUserDeposits = async (user_id, data, token) => {
  let deposits = await assetValidToken(token).then(() =>
    get(
      `${api}/admin/users/${user_id}/deposits`,
      { ...data, type: 'deposit' },
      token,
    ),
  );
  deposits.meta.pages = Math.ceil(deposits.meta.count / deposits.meta.limit);
  return deposits;
};

// get user withdrawals;
export const getUserWithdrawals = async (user_id, data, token) => {
  let withdrawals = await assetValidToken(token).then(() =>
    get(
      `${api}/admin/users/${user_id}/withdrawals`,
      { ...data, type: 'withdraw' },
      token,
    ),
  );
  withdrawals.Meta.pages = Math.ceil(
    withdrawals.Meta.count / withdrawals.Meta.limit,
  );
  return withdrawals;
};

// get user emails;
export const getUserEmails = (token) => {
  return assetValidToken(token).then(() =>
    get(`${api}/admin/downloads-emails`, {}, token),
  );
};

export const getUserBonusContracts = (user_id, data, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}/bonus-contracts`, data, token),
  );

export const getUserBots = (user_id, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}/bots`, {}, token),
  );

export const getBotAnalytics = (user_id, bot_id, version, token) =>
  assetValidToken(token).then(() =>
    get(
      `${api}/admin/users/${user_id}/bots/analytics/${bot_id}/version/${version}`,
      {},
      token,
    ),
  );

export const getBotNumbers = (user_id, bot_id, token) =>
  assetValidToken(token).then(() =>
    get(
      `${api}/admin/users/${user_id}/bots/analytics/${bot_id}/numbers`,
      {},
      token,
    ),
  );

export const cancelWithdraw = (withdrawID, userID, token) =>
  assetValidToken(token).then(() =>
    del(`${api}/admin/users/${userID}/withdraw/${withdrawID}`, {}, token),
  );

export const getUserWallet = (user_id, data, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}/wallet`, data, token),
  );

export const cancelUserOrders = (user_id, token) =>
  assetValidToken(token).then(() =>
    del(`${api}/admin/users/${user_id}/orders`, {}, token),
  );

export const revertOrder = (market_id, order_id, token) =>
  assetValidToken(token).then(() =>
    del(
      `${api}/admin/tools/market/${market_id}/order/${order_id}/revert`,
      {},
      token,
    ),
  );

export const block = (user_id, data, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/users/${user_id}/status`, data, token),
  );
export const getInfo = (user_id, data, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/statistics/users`, data, token),
  );
export const getUser = (user_id, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}`, {}, token),
  );

export const getBlockUser = (user_id, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/block/user/${user_id}`, {}, token),
  );

export const updateUser = (user_id, data, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/users/${user_id}`, data, token),
  );
export const disableTradePass = (user_id, token) =>
  assetValidToken(token).then(() =>
    del(`${api}/admin/users/${user_id}/settings/trade-password/off`, {}, token),
  );
export const disableAntiphishingKey = (user_id, token) =>
  assetValidToken(token).then(() =>
    del(`${api}/admin/users/${user_id}/settings/anti-phishing/off`, {}, token),
  );
export const enableDetectIP = (user_id, token) =>
  assetValidToken(token).then(() =>
    post(`${api}/admin/users/${user_id}/settings/detect-ip/on`, {}, token),
  );
export const disableDetectIP = (user_id, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/users/${user_id}/settings/detect-ip/off`, {}, token),
  );
export const disableGoogleAuth = (user_id, token) =>
  assetValidToken(token).then(() =>
    del(`${api}/admin/users/${user_id}/settings/2fa/google/off`, {}, token),
  );
export const disableSMSAuth = (user_id, token) =>
  assetValidToken(token).then(() =>
    del(`${api}/admin/users/${user_id}/settings/2fa/sms/off`, {}, token),
  );

export const changeBlockDepositCrypto = (user_id, value, token) =>
  assetValidToken(token).then(() =>
    put(
      `${api}/admin/block/user/${user_id}/deposit/${value ? 'on' : 'off'}`,
      { type: 'crypto' },
      token,
    ),
  );

export const changeBlockWithdrawCrypto = (user_id, value, token) =>
  assetValidToken(token).then(() =>
    put(
      `${api}/admin/block/user/${user_id}/withdraw/${value ? 'on' : 'off'}`,
      { type: 'crypto' },
      token,
    ),
  );

export const changeBlockDepositFiat = (user_id, value, token) =>
  assetValidToken(token).then(() =>
    put(
      `${api}/admin/block/user/${user_id}/deposit/${value ? 'on' : 'off'}`,
      { type: 'fiat' },
      token,
    ),
  );

export const changeBlockWithdrawFiat = (user_id, value, token) =>
  assetValidToken(token).then(() =>
    put(
      `${api}/admin/block/user/${user_id}/withdraw/${value ? 'on' : 'off'}`,
      { type: 'fiat' },
      token,
    ),
  );

export const updateUserPassword = (user_id, data, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/users/${user_id}/password`, data, token),
  );
export const updateSettings = (user_id, data, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/users/${user_id}/settings`, data, token),
  );

export const getWithdrawSettings = (user_id, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/withdraw-limits/user/${user_id}`, {}, token),
  );

export const updateWithdrawSettings = (user_id, data, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/withdraw-limits/user/${user_id}`, data, token),
  );
export const getFees = (user_id, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/users/${user_id}/fees`, {}, token),
  );
export const updateFees = (user_id, data, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/users/${user_id}/fees`, data, token),
  );

export const getPermissions = async (data, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/permissions`, data, token),
  );
export const getRoles = async (data, token) => {
  let roles = await assetValidToken(token).then(() =>
    get(`${api}/admin/roles`, data, token),
  );
  roles.Meta.pages = Math.ceil(roles.Meta.count / roles.Meta.limit);
  return roles;
};

export const getRole = (data, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/roles/` + data, {}, token),
  );
export const addRole = (data, token) =>
  assetValidToken(token).then(() => post(`${api}/admin/roles`, data, token));
export const updateRole = (data, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/roles/` + data.alias, data, token),
  );
export const removeRole = (data, token) =>
  assetValidToken(token).then(() =>
    del(`${api}/admin/roles/` + data, {}, token),
  );

export const getReferrals = async (data, token) => {
  let referrals = await assetValidToken(token).then(() =>
    get(`${api}/admin/referrals`, data, token),
  );
  referrals.Meta.pages = Math.ceil(referrals.Meta.count / referrals.Meta.limit);
  return referrals;
};
