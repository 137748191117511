import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import LaunchpadDetails from '../components';
import LaunchpadState from '../../../redux/Launchpad';
import CoinsAndChainsState from '../../../redux/CoinsAndChains';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            setProjectField: LaunchpadState.actions.setProjectField,
            getProject: LaunchpadState.actions.getProject,
            addProject: LaunchpadState.actions.addProject,
            updateProject: LaunchpadState.actions.updateProject,
            getCoins: CoinsAndChainsState.actions.getCoins,
            resetForm: LaunchpadState.actions.resetForm
        },
        dispatch
    );

const mapStateToProps = state => ({
    coins: CoinsAndChainsState.selectors.coins(state),
    status: LaunchpadState.selectors.status (state),
    project: LaunchpadState.selectors.project (state),
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(LaunchpadDetails);