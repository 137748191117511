import { trimmedLowerCaseString } from './index';

export const doesTopicExist = (topics, newTopic) => {
  const foundTopic = topics.find(
    (topic) =>
      trimmedLowerCaseString(topic) === trimmedLowerCaseString(newTopic),
  );

  return !!foundTopic;
};
