import React from 'react';
import { Col, Form, Row } from 'reactstrap';
import {
  renderABasicInput,
  renderHtmlEditor,
  renderImageUploader,
  renderSelectInput,
} from '../Inputs';

export default class AnnouncementForm extends React.Component {
  getTopics = () => {
    const { announcementsSettings } = this.props;

    const topics =
      announcementsSettings &&
      announcementsSettings.topics &&
      announcementsSettings.topics.map((announcement) => {
        return {
          value: announcement,
          label: announcement,
        };
      });

    return topics || [];
  };

  render() {
    const { object } = this.props;

    return (
      <>
        {object ? (
          <Form>
            <Row>
              <Col md="12">
                {renderImageUploader(this.props, 'Image', 'image')}
              </Col>
            </Row>

            <Row>
              <Col md="12">
                {renderABasicInput(
                  this.props,
                  'Title',
                  'title',
                  'text',
                  false,
                  0,
                  { md: '2' },
                  { md: '10' },
                )}
              </Col>
            </Row>

            <Row>
              <Col md="12">
                {renderSelectInput(
                  this.props,
                  'Topic',
                  this.getTopics(),
                  'topic',
                  false,
                  { md: '2' },
                  { md: '10' },
                )}
              </Col>
            </Row>

            <Row>
              <Col md="12">
                {renderHtmlEditor(
                  this.props,
                  'Message',
                  'message',
                  false,
                  { md: '2' },
                  { md: '10' },
                )}
              </Col>
            </Row>
          </Form>
        ) : null}
      </>
    );
  }
}
