import React from "react";
import {Col, Form, Row} from "reactstrap";
import { renderABasicInput, renderSelectInput, renderToggleCheckBox } from "../Inputs"

export default class AddCoinView extends React.Component {

  getCoinTypes = () => {
    return [
      {label: 'crypto', value: 'crypto'},
      {label: 'asset', value: 'asset'},
      {label: 'fiat', value: 'fiat'}
    ]
  }

  getCostSymbols = (sameOp = true) => {
    const { coins } = this.props;
    const costSymbols = coins.map(item => ({label: item.symbol, value: item.symbol}));
    if (sameOp){
      costSymbols.unshift({label: "-", value: ""}, {label: "Same as symbol", value: "self"});
    }
    return costSymbols;
  };

  getChainSymbols = () => {
    const { chains } = this.props;

    const chainsOptions = [{label: '', value: ''}];
    chains.forEach(item => chainsOptions.push({label: item.name, value: item.symbol}));
    return chainsOptions;
  };

  getCoinStatus = () => {
    let result = [
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'}
    ];

    if(!this.props.object.id) {
      result = [...result,{label: 'Presale', value: 'presale'}]
    }else if(this.props.object.status === 'presale'){
      result = [...result,{label: 'Presale', value: 'presale'}]
    }

    return result;
  };

  onChangeCheckBox = (prop) => {
    const {object} = this.props;
    this.props.toggleWarningModal(this.textForModal(prop), prop);
    this.props.onChange(prop, !object[prop]);
  };

  textForModal = (prop) => {
    switch (prop) {
      case 'block_deposit':
        return "Are you sure you want to change BLOCK STATE for DEPOSIT feature?";
      case 'block_withdraw':
        return "Are you sure you want to change BLOCK STATE for WITHDRAW feature?";
      default:
        return '';
    }
  };


  render() { 
    const { chains, object } = this.props;
    return (
      <>
      {chains ? 
      <Form>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Coin Name", "name")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Symbol", "symbol")}</Col>
          <Col md="4">{renderSelectInput(this.props, "Type", this.getCoinTypes(), "type")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Digits", "digits", "number")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Precision", "token_precision", "number", (object.id ? true : false))}</Col>
          <Col md="4">{renderSelectInput(this.props, "Cost Sym", this.getCostSymbols(), "cost_symbol")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Withdraw Fee", "withdraw_fee", "number")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Deposit Fee", "deposit_fee", "number")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Minimum Amount", "min_withdraw", "number")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderSelectInput(this.props, "Status", this.getCoinStatus(), "status")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Blockchain explorer", "blockchain_explorer")}</Col>
          <Col md="4">{renderSelectInput(this.props, 'Chain', this.getChainSymbols(), "chain_symbol")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Buy limit",  "buy_limit", "number")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Sell limit", "sell_limit", "number")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Buy limit 24H",  "buy_limit_24h", "number")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Sell limit 24H", "sell_limit_24h", "number")}</Col>
        </Row>
        <Row>
          <Col md="4">
              <div>{renderToggleCheckBox("Block withdrawals", "block_withdraw", object, this.onChangeCheckBox, 'justify-content-between')}</div>
              <div>{renderToggleCheckBox("Block deposits", "block_deposit", object, this.onChangeCheckBox, 'justify-content-between')}</div>
          </Col>
        </Row>
      </Form>
      : null }
      </>
    );
  }
}

