import React from 'react';
import classNames from 'classnames';
import { Card, CardTitle } from 'reactstrap';

export const CardWrapper = ({ cardHeader, children, fullWidth }) => {
  return (
    <Card
      className={classNames('card-info', {
        'card-info--full-width m-3': fullWidth,
      })}
    >
      <CardTitle className="text-capitalize p-3 card-header">
        <h5 className="m-0">{cardHeader && cardHeader.replace(/_/g, ' ')}</h5>
      </CardTitle>

      <div className="d-flex flex-wrap">{children}</div>
    </Card>
  );
};
