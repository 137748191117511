import { api } from '../config';
import { get, post, postFiles } from '../utils/superagent';
import { assetValidToken } from '../utils/auth';

export const addAnnouncement = (data, token) =>
  assetValidToken(token).then(() =>
    postFiles(`${api}/admin/announcements`, data, token),
  );

export const getAnnouncement = async (announcement_id, token) =>
  await assetValidToken(token).then(() =>
    get(`${api}/admin/announcement/${announcement_id}`, {}, token),
  );

export const getAnnouncements = async (data, token) => {
  const announcements = await assetValidToken(token).then(() =>
    get(`${api}/admin/announcements`, data, token),
  );

  announcements.meta.pages = Math.ceil(
    announcements.meta.count / announcements.meta.limit,
  );

  return announcements;
};

export const changeAnnouncementStatus = (data, token) =>
  assetValidToken(token).then(() =>
    post(`${api}/admin/announcements/status`, data, token),
  );

export const getAnnouncementsSettings = async (token) =>
  await assetValidToken(token).then(() =>
    get(`${api}/admin/announcements/settings`, {}, token),
  );

export const updateAnnouncementsSettings = (data, token) =>
  assetValidToken(token).then(() =>
    postFiles(`${api}/admin/announcements/update-settings`, data, token),
  );
