import * as c from "./constants";
import moment from "moment";
import { formatNumber } from "../../utils";

const initialState = {
  bots: [],
  gridBotsStatistics: [],
  trendBotsStatistics: [],
  totalActiveBots: 0,
  botsStatus: "active",
  botsMeta: null,
  gridBotsStatisticsMeta: null,
  trendBotsStatisticsMeta: null,
  status: { pending: false, success: false, error: false },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {

    case c.GET_BOTS_PENDING: {
      return {
        ...state,
        bots: [],
        botsMeta: null,
        status: { pending: true, error: false, success: false },
      };
    }

    case c.GET_GRID_BOTS_STATISTICS_PENDING: {
      return {
        ...state,
        gridBotsStatistics: [],
        gridBotsStatisticsMeta: null,
        status: { pending: true, error: false, success: false },
      };
    }

    case c.DOWNLOAD_GRID_BOTS_STATISTICS_PENDING:
    case c.DOWNLOAD_TREND_BOTS_STATISTICS_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false },
      };
    }

    case c.DOWNLOAD_GRID_BOTS_STATISTICS_SUCCESS:
    case c.DOWNLOAD_TREND_BOTS_STATISTICS_SUCCESS: {
        return {
          ...state,
          status: { pending: false, error: false, success: true },
        };
      }


    case c.GET_TREND_BOTS_STATISTICS_PENDING: {
      return {
        ...state,
        trendBotsStatistics: [],
        trendBotsStatisticsMeta: null,
        status: { pending: true, error: false, success: false },
      };
    }

    case c.GET_BOTS_ERROR:
    case c.GET_GRID_BOTS_STATISTICS_ERROR:
    case c.DOWNLOAD_GRID_BOTS_STATISTICS_ERROR:
    case c.DOWNLOAD_TREND_BOTS_STATISTICS_ERROR:
    case c.GET_TREND_BOTS_STATISTICS_ERROR:{
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false },
      };
    }

    case c.GET_BOTS_SUCCESS: {
      const {coins} =  action.payload;

      const bots = action.payload.botsData.bots.map((item) => {
        const botPnl = action.payload.botsPnl.find((botPnlItem) => botPnlItem.bot_id === item.id);

        let generalPNL = '--';

        if (botPnl && botPnl.bot_profit) {
          if (item.contract_id) {
            generalPNL = `${formatNumber(botPnl.bot_profit, 2)}%`;
          } else {
            // const PNLCoin = PNL.profit_coin || PNL.coin_symbol_deposit || '';

            let coinPrecision = coins.find((coinItem) => coinItem.symbol === botPnl.profit_coin);

            const PNLCoinPrecision = coinPrecision
              ? coinPrecision.digits
              : 8;

            generalPNL = `${formatNumber(
              botPnl.profit,
              PNLCoinPrecision
            )} ${botPnl.profit_coin.toUpperCase()}`;
          }
        }

        return {
          ...item,
          pnl: generalPNL,
          created_at: moment(item.created_at).utc().format("LLL"),
          expired_at: moment(item.expired_at).utc().format("LLL")
        };
      });

      return {
        ...state,
        bots,
        botsMeta: action.payload.botsData.Meta
      };
    }

    case c.GET_GRID_BOTS_STATISTICS_SUCCESS: {
      let {data, pair} = action.payload;

      const gridBotsStatistics =  data.BotWithStatistics ? data.BotWithStatistics.map((item, index) => {
        return {
          ...item,
          id: index+1,
          pair
        };
      }) : [];

      return {
        ...state,
        gridBotsStatistics,
        gridBotsStatisticsMeta: data.Meta,
      };
    }

    case c.GET_TREND_BOTS_STATISTICS_SUCCESS: {
      let {data, pair} = action.payload;

      const trendBotsStatistics =  data.BotWithStatistics ? data.BotWithStatistics.map((item, index) => {
        return {
          ...item,
          expired_at: moment(item.expired_at).format("LLL"),
          id: index+1,
          pair
        };
      }) : [];

      return {
        ...state,
        trendBotsStatistics,
        trendBotsStatisticsMeta: data.Meta,
      };
    }

    case c.GET_TOTAL_ACTIVE_BOTS_SUCCESS: {
      let totalActiveBots = action.payload;

      return {
        ...state,
        totalActiveBots
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
