export const data = state => state.Wallets.data;
export const depositsMeta = state => state.Wallets.depositsMeta;
export const manualDepositsMeta = state => state.Wallets.manualDepositsMeta;
export const manualWithdrawalsMeta = state => state.Wallets.manualWithdrawalsMeta;
export const withdrawalsMeta = state => state.Wallets.withdrawalsMeta;
export const operationsMeta = state => state.Wallets.operationsMeta;

export const deposits = state => state.Wallets.deposits;
export const manualDeposits = state => state.Wallets.manualDeposits;
export const withdrawals = state => state.Wallets.withdrawals;
export const manualWithdrawals = state => state.Wallets.manualWithdrawals;
export const operations = state => state.Wallets.operations;

export const withdrawals_query = state => state.Wallets.withdrawals_query;
export const withdrawals_filter = state => state.Wallets.withdrawals_filter;
export const deposits_query = state => state.Wallets.deposits_query;
export const deposits_filter = state => state.Wallets.deposits_filter;
export const deposits_coin_filter = state => state.Wallets.deposits_coin_filter;
export const manual_deposits_status = state => state.Wallets.manual_deposits_status;
export const operations_query = state => state.Wallets.operations_query;
export const operations_filter = state => state.Wallets.operations_filter;
export const coin_query = state => state.Wallets.coin_query;
export const block_deposit = state => state.Wallets.block_deposit;
export const block_withdraw = state => state.Wallets.block_withdraw;
export const status = state => state.Wallets.status;