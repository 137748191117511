import React from "react";
import history from '../../../history';

import {
  Container,
  Row,
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import DarkTable from "../../../components/Table";
import Can from "../../../components/Permissions";
import { hasPermission } from '../../../utils';
import DangerModal from "../../../components/Modal/danger.jsx";
import SuccessModal from "../../../components/Modal/success.jsx";

const TABLE_LIMIT = 10;

class CoinsAndChains extends React.Component {
  ticker = null;

  tick = () => {
    this.props.getCoins();
    this.props.getPresaleCoins();
  };

  state = {
    blockModal: false,
    warningModal: false,
    warningModalType: "",
    warningBody: "",
    coinToModify: null,
    chainToModify: '',
    chainsTableMeta: {
      count: this.props.chains.length,
      limit: TABLE_LIMIT,
      page: 1,
      pages: this.props.chains.length ? Math.ceil(this.props.chains.length / TABLE_LIMIT) : 1
    },
    coinsTableMeta: {
      count: this.props.coins.length + this.props.presaleCoins.length,
      limit: TABLE_LIMIT,
      page: 1,
      pages: this.props.coins.length + this.props.presaleCoins.length ? Math.ceil(this.props.coins.length + this.props.presaleCoins.length/ TABLE_LIMIT) : 1
    },
  }; 

  componentDidMount() {
      this.props.getChains();
      this.tick();

      this.ticker = setInterval(this.tick, 5000);
  }

  onChainsPagingClick  = (event, page) => {
    this.setState({
      chainsTableMeta: {
        ...this.state.chainsTableMeta,
        page
      }
    });
  }

  onCoinsPagingClick  = (event, page) => {
    this.setState({
      coinsTableMeta: {
        ...this.state.coinsTableMeta,
        page
      }
    });
  }

  componentDidUpdate(prevProps){
    const chainsLength = this.props.chains.length;
    const coinsLength = this.props.coins.length + this.props.presaleCoins.length;
    const prevCoinsLength = prevProps.coins.length + prevProps.presaleCoins.length;

    if(chainsLength !== prevProps.chains.length)
      this.setState({
        chainsTableMeta: {
          ...this.state.chainsTableMeta,
          count: chainsLength,
          pages: chainsLength ? Math.ceil(chainsLength / TABLE_LIMIT) : 1
        }
      });

    if(coinsLength !== prevCoinsLength)
      this.setState({
        coinsTableMeta: {
          ...this.state.coinsTableMeta,
          count: coinsLength,
          pages: coinsLength ? Math.ceil(coinsLength / TABLE_LIMIT) : 1
        }
      });
  }

  componentWillUnmount() {
    if (this.ticker) {
      clearInterval(this.ticker);
    }
  }

  addChain = async () => {
    await this.props.resetForm();
    history.push('/chain');
  }

  editChain = (e, chain) => {
    history.push('/chain/'+chain.symbol);
  }


  getChainsActionsArray = () => {
    return [
      {name: 'Edit', onClick: this.editChain, permission: 'chain.edit'},
      {name: 'Enable', onClick: (e, chain) => this.openChainWarningModal(chain, 'enable'), disabled: {by: "status", values: ["active", "pending"]}, permission: 'chain.edit'},
      {name: 'Disable', onClick: (e, chain) => this.openChainWarningModal(chain, 'disable'), disabled: {by: "status", values: ["inactive"]}, permission: 'chain.remove'},
    ].filter(item => hasPermission( item.permission ))
  };

  getCoinsActionsArray = () => {
    return [
      {name: 'Edit', onClick: this.editCoin, permission: 'coin.edit'},
      {name: 'Enable', onClick: (e, coin) => this.openCoinWarningModal(coin,'enable'), disabled: {by: "status", values: ["active"]}, permission: 'coin.edit'},
      {name: 'Disable', onClick: (e, coin) => this.openCoinWarningModal(coin,'disable'), disabled: {by: "status", values: ["inactive"]}, permission: 'coin.remove'},
    ].filter(item => hasPermission(item.permission))
  };

  addCoin = () => {
    history.push('/coin');
  }

  editCoin = (e, coin) => { 
    history.push('/coin/'+coin.symbol);
  }

  toggleModal = () => {
    this.setState({
        blockModal: !this.state.blockModal,
    });
  }

  renderConfirmationModal = () => {
    const response = "Saved!"
    return (
        <SuccessModal
          isOpen={this.state.blockModal}
          toggleFunction={() => this.toggleModal()}
          closeFunction={() => this.toggleModal()}
          body={response}
        />
    )
  };

  openCoinWarningModal = (coin, action) => {
    this.setState({
      warningModal: true,
      warningBody: `Are you sure you want to ${action} coin?`,
      warningModalType: `${action}_coin`,
      coinToModify: coin
    });
  };

  openChainWarningModal = (chain, action) => {
    this.setState({
      warningModal: true,
      warningBody: `Are you sure you want to ${action} chain?`,
      warningModalType: `${action}_chain`,
      chainToModify: chain
    });
  };

  renderWarningModal = () => {
    return (
      <DangerModal
        isOpen={this.state.warningModal}
        toggleFunction={this.closeWarningModal}
        nextFunction={this.toggleFunctionAfterWarningModal}
        closeFunction={this.closeWarningModal}
        body={this.state.warningBody}
      />
    );
  };

  closeWarningModal = () => {
    this.setState({
      warningModal: false
    });
  };

  toggleFunctionAfterWarningModal = async () => {
    await this.submitFunctionByType();
    this.setState({ warningModal: false });
    if (this.props.status.success) {
      this.toggleModal();

      if(this.state.warningModalType === 'disable_coin')
        this.tick();
      else if(this.state.warningModalType === 'disable_chain')
        this.props.getChains();
      else if(this.state.warningModalType === 'enable_coin')
        this.tick();
      else if(this.state.warningModalType === 'enable_chain')
        this.props.getChains();
    } else {
      console.log(this.props.status.error.data.error);
    }
  };

  submitFunctionByType = async () => {
    switch (this.state.warningModalType) {
      case 'disable_coin':
        await this.props.disableCoin(this.state.coinToModify.symbol);
        break
      case 'disable_chain':
        await this.props.disableChain(this.state.chainToModify.symbol);
        break
      case 'enable_coin':
        const coinToEdit = {...this.state.coinToModify};
        coinToEdit.id = coinToEdit.symbol;
        coinToEdit.status = 'active';
        await this.props.editCoin(coinToEdit);
        break
      case 'enable_chain':
        const chainToEdit = {...this.state.chainToModify};
        chainToEdit.id = chainToEdit.symbol;
        chainToEdit.status = 'active';
        await this.props.editChain(chainToEdit);
        break
      default:
        return '';
    }
  };


  renderChains = () => { 
    let inlineActions = [];

    if(hasPermission("chain.add")){
      inlineActions.push({
        text: "+ Add chain",
        func: this.addChain
      });
    }

    const {chainsTableMeta} = this.state;
    const pageChains = this.props.chains.slice((chainsTableMeta.page-1)*chainsTableMeta.limit, chainsTableMeta.page*chainsTableMeta.limit);

    return <Can run="chain.view">
      <DarkTable
      tableTitle="Manage Chains"
      inlineActions={inlineActions}
      actionsColumn={true}
      actions={this.getChainsActionsArray()}
      columns={{
        name: "Name",
        symbol: "Symbol",
        status: "Status",
      }}
      rows={pageChains}
      meta={chainsTableMeta}
      onPagingClick={this.onChainsPagingClick}
    />
    </Can>;
  }

  renderCoins = () => { 
    const {coinsTableMeta} = this.state;
    const coins = [...this.props.coins,...this.props.presaleCoins];
    const pageCoins = coins.slice((coinsTableMeta.page-1)*coinsTableMeta.limit, coinsTableMeta.page*coinsTableMeta.limit);

    let inlineActions = [];

    if(hasPermission("coin.add")){
      inlineActions.push({
        text: "+ Add coin",
        func: this.addCoin
      });
    }

    return <Can run="coin.view">
      <DarkTable
        tableTitle="Manage Coins"
        inlineActions={inlineActions}
        actionsColumn={true}
        actions={this.getCoinsActionsArray()}
        columns={{
          name_symbol: "Name",
          status: "Status",
          digits: "Digits",
          token_precision: "Prec.",
          cost_symbol: "Cost sym",
          withdraw_fee: "WDraw. Fee",
          deposit_fee: "Dep. Fee",
          min_withdraw: "Min. WDraw",
        }}
        rows={pageCoins}
        meta={coinsTableMeta}
        onPagingClick={this.onCoinsPagingClick}
      />
    </Can>;
  }

  render() { 
    return (
      <>
      <SimpleHeader name="Coins " parentName="Chains"  />
      <Container className="mt--6" fluid>
        <Row>&nbsp;</Row>
        {this.renderChains()}
        <Row>&nbsp;</Row>
        {this.renderCoins()}
        {this.renderWarningModal()}
        {this.renderConfirmationModal()}
      </Container>
      </>
    );
  }
}

export default CoinsAndChains;
