import moment from 'moment';
import FileSaver from 'file-saver';
import { base64StringToBlob } from 'blob-util';

import * as ac from './actionCreators';
import api from '../../api';
import { notify } from '../../utils';
import { logoutOnUnauthorizedError } from '../../utils/auth';
import { InvalidFields } from '../../utils/errors';
import {getKybStepStatus, isKybEveryStepNone} from "../../utils/kyb";
import {
  validatePassword,
  validateConfirmPassword,
} from '../../utils/validator';


export function getAllUsers(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getAll.pending());
    try {
      let payloadData = {
        query: payload.users_query,
        page: payload.page,
        filter: payload.filter
          ? payload.filter[0].value.replace('-', '_')
          : null,
      };

      const token = getState().Login.token;
      const data = await api.users.getAll(payloadData, token);
      dispatch(ac.getAll.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getAll.error(err));
    }
  };
}

export function getTotalUsersLineLevels() {
  return async (dispatch, getState) => {
    dispatch(ac.getTotalUsersLineLevels.pending());
    try {
      const token = getState().Login.token;
      const { line_levels } = await api.users.getTotalUsersLineLevels(token);
      dispatch(ac.getTotalUsersLineLevels.success(line_levels));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getTotalUsersLineLevels.error(err));
    }
  };
}

export function blockUser(userId, payload, notificationRef) {
  return async (dispatch, getState) => {
    dispatch(ac.block.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.block(userId, payload, token);
      dispatch(ac.block.success(data));
      notify('success', notificationRef, 'The user has been blocked!');
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.block.error(err));
      notify('danger', notificationRef, 'Something went wrong!');
    }
  };
}

export function cancelUserOrders(userId, notificationRef) {
  return async (dispatch, getState) => {
    dispatch(ac.cancelUserOrders.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.cancelUserOrders(userId, token);
      dispatch(ac.cancelUserOrders.success(data));
      notify('success', notificationRef, 'The orders have been canceled!');
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.cancelUserOrders.error(err));
      notify('danger', notificationRef, 'Something went wrong!');
    }
  };
}

export function getInfo(userId, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getInfo.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.getInfo(userId, payload, token);
      dispatch(ac.getInfo.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getInfo.error(err));
    }
  };
}

export function getUser(userID) {
  return async (dispatch, getState) => {
    dispatch(ac.getUser.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.getUser(userID, token);
      const { user } = await api.users.getBlockUser(userID, token);

      const blockUserData = {
        block_deposit_crypto: user.deposit_crypto,
        block_deposit_fiat: user.deposit_fiat,
        block_withdraw_crypto: user.withdraw_crypto,
        block_withdraw_fiat: user.withdraw_fiat,
      };

      dispatch(ac.getUser.success({ ...data, ...blockUserData }));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUser.error(err));
    }
  };
}

// KYB
export function getKybStatus (userId) {
  return async (dispatch, getState) => {
    dispatch(ac.getKybStatus.pending());
    try {
      const token = getState().Login.token;
      const data = await api.kyb.getKybStatus(userId, token);

      const kybStatus = {
        status: getKybStepStatus(data),
        stepOneStatus: getKybStepStatus(data, 0),
        stepTwoStatus: getKybStepStatus(data, 1),
        stepThreeStatus: getKybStepStatus(data, 2),
        stepFourStatus: getKybStepStatus(data, 3),
        isAllStepNone: isKybEveryStepNone(data, [0, 1, 2, 3]),
      }

      const shuftiProResult = data.VerificationData && JSON.parse(Buffer.from(data.VerificationData, 'base64'))

      dispatch(ac.getKybStatus.success({kybStatus, shuftiProResult}));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getKybStatus.error(err));
    }
  };
}

export function updateKYBStepTwo (userId, KYBStepTwoStatus) {
  return async (dispatch, getState) => {
    dispatch(ac.updateKYBStepTwo.pending());
    try {
      const token = getState().Login.token;
      const data = await api.kyb.putKybUpdateStepTwo(userId, KYBStepTwoStatus, token);
      const formattedStatus = getKybStepStatus({ StepTwoStatus: KYBStepTwoStatus }, 1)

      if (data === 'OK') {
        dispatch(ac.updateKYBStepTwo.success({ KYBStepTwoStatus: formattedStatus}));
      }
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.updateKYBStepTwo.error(err));
    }
  };
}

export function getListOfBusinessMembers (userId) {
  return async (dispatch, getState) => {
    dispatch(ac.getListOfBusinessMembers.pending());
    try {
      const token = getState().Login.token;
      const data = await api.kyb.getListOfBusinessMembers(userId, token);

      if (data.members_list) {
        dispatch(ac.getListOfBusinessMembers.success({businessMembersList: data.members_list}));
      }
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getListOfBusinessMembers.error(err));
    }
  };
}

export function downloadKYBDocument (userId, fileAlias) {
  return async (dispatch, getState) => {
    dispatch(ac.downloadKYBDocument.pending());
    try {
      const token = getState().Login.token;
      const data = await api.kyb.getKybDownloadDocument(userId, fileAlias, token);
      console.log('data', data)

      if (data.download_url) {
        dispatch(ac.downloadKYBDocument.success({ downloadUrl: data.download_url }));
      }
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.downloadKYBDocument.error(err));
    }
  };
}

export function getUserFees(userID) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserFees.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.getFees(userID, token);
      dispatch(ac.getUserFees.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserFees.error(err));
    }
  };
}

export function disableTradePass(userID) {
  return async (dispatch, getState) => {
    dispatch(ac.disableTradePass.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.disableTradePass(userID, token);
      dispatch(ac.disableTradePass.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.disableTradePass.error(err));
    }
  };
}

export function disableAntiphishingKey(userID) {
  return async (dispatch, getState) => {
    dispatch(ac.disableAntiphishingKey.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.disableAntiphishingKey(userID, token);
      dispatch(ac.disableAntiphishingKey.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.disableAntiphishingKey.error(err));
    }
  };
}

export function disableGoogleAuth(userID) {
  return async (dispatch, getState) => {
    dispatch(ac.disableGoogleAuth.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.disableGoogleAuth(userID, token);
      dispatch(ac.disableGoogleAuth.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.disableGoogleAuth.error(err));
    }
  };
}

export function disableSMSAuth(userID) {
  return async (dispatch, getState) => {
    dispatch(ac.disableSMSAuth.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.disableSMSAuth(userID, token);
      dispatch(ac.disableSMSAuth.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.disableSMSAuth.error(err));
    }
  };
}

export function changeBlockDepositCrypto(userID, value) {
  return async (dispatch, getState) => {
    dispatch(ac.changeBlockDepositCrypto.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.changeBlockDepositCrypto(
        userID,
        value,
        token,
      );
      dispatch(ac.changeBlockDepositCrypto.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.changeBlockDepositCrypto.error(err));
    }
  };
}

export function changeBlockDepositFiat(userID, value) {
  return async (dispatch, getState) => {
    dispatch(ac.changeBlockDepositFiat.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.changeBlockDepositFiat(userID, value, token);
      dispatch(ac.changeBlockDepositFiat.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.changeBlockDepositFiat.error(err));
    }
  };
}

export function changeBlockWithdrawCrypto(userID, value) {
  return async (dispatch, getState) => {
    dispatch(ac.changeBlockWithdrawCrypto.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.changeBlockWithdrawCrypto(
        userID,
        value,
        token,
      );
      dispatch(ac.changeBlockWithdrawCrypto.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.changeBlockWithdrawCrypto.error(err));
    }
  };
}

export function changeBlockWithdrawFiat(userID, value) {
  return async (dispatch, getState) => {
    dispatch(ac.changeBlockWithdrawFiat.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.changeBlockWithdrawFiat(
        userID,
        value,
        token,
      );
      dispatch(ac.changeBlockWithdrawFiat.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.changeBlockWithdrawFiat.error(err));
    }
  };
}

export function enableDetectIP(userID) {
  return async (dispatch, getState) => {
    dispatch(ac.enableDetectIP.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.enableDetectIP(userID, token);
      dispatch(ac.enableDetectIP.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.enableDetectIP.error(err));
    }
  };
}
export function disableDetectIP(userID) {
  return async (dispatch, getState) => {
    dispatch(ac.disableDetectIP.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.disableDetectIP(userID, token);
      dispatch(ac.disableDetectIP.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.disableDetectIP.error(err));
    }
  };
}

export function updateUser(userID, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.updateUser.pending());
    try {
      const token = getState().Login.token;
      if (payload.gender === '') {
        payload.gender = 'male';
      }
      if (payload.dob) {
        payload.dob = moment(payload.dob).format();
      }

      const data = await api.users.updateUser(userID, payload, token);
      dispatch(ac.updateUser.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.updateUser.error(err));
    }
  };
}

export function updateSettings(userID, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.updateSettings.pending());
    try {
      const payloadData = {
        detect_ip_change: payload.detect_ip_change,
        disable_twofa: payload.tfa,
        trade_password: payload.trade_password,
        anti_phishing_key: payload.antiphishing_key,
      };

      const token = getState().Login.token;
      const data = await api.users.updateSettings(userID, payloadData, token);
      dispatch(ac.updateSettings.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.updateSettings.error(err));
    }
  };
}

export function getWithdrawSettings(userID) {
  return async (dispatch, getState) => {
    dispatch(ac.getWithdrawSettings.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.getWithdrawSettings(userID, token);
      dispatch(ac.getWithdrawSettings.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getWithdrawSettings.error(err));
    }
  };
}

export function updateWithdrawSettings(userID, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.updateWithdrawSettings.pending());
    try {
      const payloadData = {
        external_system: payload.paymentMethod,
        withdraw_limit: payload.maxLimit,
      };

      const token = getState().Login.token;
      const data = await api.users.updateWithdrawSettings(
        userID,
        payloadData,
        token,
      );
      dispatch(ac.updateWithdrawSettings.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.updateWithdrawSettings.error(err));
    }
  };
}

export function updateFees(userID, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.updateFees.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.updateFees(userID, payload, token);
      dispatch(ac.updateFees.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.updateFees.error(err));
    }
  };
}

export function updateUserPassword(userID, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.updateUserPassword.pending());
    try {
      let field_errors = {};
      if (!validatePassword(payload.password))
        field_errors.password =
          'The password must contain at least one lowercase (a-z) letter, one uppercase (A-Z) letter, one digit (0-9) and one special character.';
      if (!validateConfirmPassword(payload.password, payload.confirm_password))
        field_errors.confirm_password = 'Invalid password confirmation';

      if (Object.keys(field_errors).length)
        throw new InvalidFields('Invalid form fields', { field_errors });

      const payloadData = {
        new: payload.confirm_password,
      };

      const token = getState().Login.token;
      const data = await api.users.updateUserPassword(
        userID,
        payloadData,
        token,
      );
      dispatch(ac.updateUserPassword.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.updateUserPassword.error(err));
    }
  };
}

export function getUserLogs(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserLogs.pending());
    try {
      const payloadData = {
        query: payload.activity_query,
        page: payload.page,
      };
      const token = getState().Login.token;
      const data = await api.users.getUserLogs(
        payload.user_id,
        payloadData,
        token,
      );
      dispatch(ac.getUserLogs.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserLogs.error(err));
    }
  };
}

export function getUserDeposits(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserDeposits.pending());
    try {
      const payloadData = {
        query: payload.deposit_query,
        status: payload.deposits_filter,
        page: payload.page,
      };
      const token = getState().Login.token;
      const data = await api.users.getUserDeposits(
        payload.user_id,
        payloadData,
        token,
      );
      dispatch(ac.getUserDeposits.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserDeposits.error(err));
    }
  };
}

export function getUserWithdrawals(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserWithdrawals.pending());
    try {
      const payloadData = {
        query: payload.withdrawal_query,
        status: payload.withdrawals_filter,
        page: payload.page,
      };
      const token = getState().Login.token;
      const data = await api.users.getUserWithdrawals(
        payload.user_id,
        payloadData,
        token,
      );
      dispatch(ac.getUserWithdrawals.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserWithdrawals.error(err));
    }
  };
}

export function getUserBonusContracts(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserBonusContracts.pending());
    try {
      const token = getState().Login.token;

      const payloadData = {};

      if (payload.contracts_filter) {
        payloadData.coin = payload.contracts_filter;
      }

      if (payload.startDateFilterSelected) {
        payloadData.fromDate = moment(payload.startDateFilterSelected)
          .utc(true)
          .startOf('day')
          .unix();
      }

      if (payload.endDateFilterSelected) {
        payloadData.toDate = moment(payload.endDateFilterSelected)
          .utc(true)
          .endOf('day')
          .unix();
      }

      const { data } = await api.users.getUserBonusContracts(
        payload.user_id,
        payloadData,
        token,
      );

      dispatch(
        ac.getUserBonusContracts.success({ data, coins: payload.coins }),
      );
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserBonusContracts.error(err));
    }
  };
}

export function getUserBots(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserBots.pending());
    try {
      const token = getState().Login.token;
      const {
        data: { bots: botsData },
      } = await api.users.getUserBots(payload.user_id, token);
      const { data: botsPnl } = await api.bots.getBotsPnl(token);
      const coins = await api.assets.getCoins({}, token);

      dispatch(ac.getUserBots.success({ botsData, botsPnl, coins }));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserBots.error(err));
    }
  };
}

export function getUserOrders(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserOrders.pending());
    try {
      const payloadData = {
        query: payload.orders_query,
        status: payload.orders_filter,
        account: payload.account,
        page: payload.page,
      };
      const token = getState().Login.token;
      const markets = getState().Markets.markets;
      const data = await api.users.getUserOrders(
        payload.user_id,
        payloadData,
        token,
      );
      dispatch(
        ac.getUserOrders.success({
          data,
          markets,
          status: payload.orders_filter,
        }),
      );
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserOrders.error(err));
    }
  };
}

export function getUserTrades(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserTrades.pending());
    try {
      const payloadData = {
        query: payload.trades_query,
        account: payload.account,
        sideParam: payload.trades_filter,
        page: payload.page,
      };

      if (payload.startDateFilterSelected) {
        payloadData.fromDate = moment(payload.startDateFilterSelected)
          .utc(true)
          .startOf('day')
          .unix();
      }

      if (payload.endDateFilterSelected) {
        payloadData.toDate = moment(payload.endDateFilterSelected)
          .utc(true)
          .endOf('day')
          .unix();
      }

      const token = getState().Login.token;
      const markets = getState().Markets.markets;
      const data = await api.users.getUserTrades(
        payload.user_id,
        payloadData,
        token,
      );
      dispatch(ac.getUserTrades.success({ data, markets }));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserTrades.error(err));
    }
  };
}

export function downloadUserTrades(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.downloadUserTrades.pending());
    try {
      const payloadData = {
        format: payload.format,
        query: payload.trades_query,
        account: payload.account,
        sideParam: payload.trades_filter,
      };

      if (payload.startDateFilterSelected) {
        payloadData.fromDate = moment(payload.startDateFilterSelected)
          .utc(true)
          .startOf('day')
          .unix();
      }

      if (payload.endDateFilterSelected) {
        payloadData.toDate = moment(payload.endDateFilterSelected)
          .utc(true)
          .endOf('day')
          .unix();
      }

      const token = getState().Login.token;
      const resp = await api.users.downloadUserTrades(
        payload.user_id,
        payloadData,
        token,
      );

      FileSaver.saveAs(
        base64StringToBlob(resp.data),
        `user_trades_${moment().format('YYYY_MM_DD_HHmmss')}.${resp.filetype}`,
      );

      dispatch(ac.downloadUserTrades.success());
    } catch (err) {
      dispatch(ac.downloadUserTrades.error(err));
    }
  };
}

export function getUserDistributions(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserDistributions.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.getUserDistributions(
        payload.user_id,
        payload,
        token,
      );
      dispatch(ac.getUserDistributions.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserDistributions.error(err));
    }
  };
}

export function getUserWallet(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getUserWallet.pending());
    try {
      const payloadData = {
        query: payload.wallet_query,
      };

      const token = getState().Login.token;
      const data = await api.users.getUserWallet(
        payload.user_id,
        payloadData,
        token,
      );
      dispatch(ac.getUserWallet.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getUserWallet.error(err));
    }
  };
}

export function getDepositAddresses(user_id) {
  return async (dispatch, getState) => {
    dispatch(ac.getDepositAddresses.pending());
    try {
      const token = getState().Login.token;
      const data = await api.wallets.getDepositAddresses(user_id, token);
      dispatch(ac.getDepositAddresses.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getDepositAddresses.error(err));
    }
  };
}

export function cancelUserWithdraw(withdrawID, userID) {
  return async (dispatch, getState) => {
    dispatch(ac.cancelWithdraw.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.cancelWithdraw(withdrawID, userID, token);
      dispatch(ac.cancelWithdraw.success(withdrawID));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.cancelWithdraw.error(err));
    }
  };
}

export function setCurrentUserField(field, value) {
  return (dispatch) => dispatch(ac.setUserField({ field, value }));
}

export function setWithdrawSettingsField(field, value) {
  return (dispatch) => dispatch(ac.setWithdrawSettingsField({ field, value }));
}

export function setField(field, value) {
  return (dispatch) => dispatch(ac.setField({ field, value }));
}
