const module = 'LAUNCHPAD';

export const GET_PROJECT_PENDING = `${module}/GET_PROJECT_PENDING`;
export const GET_PROJECT_SUCCESS = `${module}/GET_PROJECT_SUCCESS`;
export const GET_PROJECT_ERROR = `${module}/GET_PROJECT_ERROR`;

export const GET_PROJECTS_PENDING = `${module}/GET_PROJECTS_PENDING`;
export const GET_PROJECTS_SUCCESS = `${module}/GET_PROJECTS_SUCCESS`;
export const GET_PROJECTS_ERROR = `${module}/GET_PROJECTS_ERROR`;

export const ADD_PROJECT_PENDING = `${module}/ADD_PROJECT_PENDING`;
export const ADD_PROJECT_SUCCESS = `${module}/ADD_PROJECT_SUCCESS`;
export const ADD_PROJECT_ERROR = `${module}/ADD_PROJECT_ERROR`;

export const UPDATE_PROJECT_PENDING = `${module}/UPDATE_PROJECT_PENDING`;
export const UPDATE_PROJECT_SUCCESS = `${module}/UPDATE_PROJECT_SUCCESS`;
export const UPDATE_PROJECT_ERROR = `${module}/UPDATE_PROJECT_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;
export const SET_FILTER_FIELD = `${module}/SET_FILTER_FIELD`;
export const SET_PROJECT_FIELD = `${module}/SET_PROJECT_FIELD`;
export const RESET_PROJECT_STATE = `${module}/RESET_PROJECT_STATE`;

