import React from "react";
import {Col, Form, Row} from "reactstrap";

import {blockChains,socialNetworks,timeZones, upperCaseFirstLetter} from '../../utils';
import { renderABasicInput, renderImageUploader, renderADateTimeInput, renderHtmlEditor, renderCheckboxInput, renderSelectInput } from "../Inputs"

export default class LaunchpadProjectForm extends React.Component {

  getCoinOptions = () => {
    const options = [{
      label: '',
      value: ''
    }];

    this.props.coins.forEach((coin) => {
      options.push({
        label: coin.name,
        value: coin.symbol
      });
    });

    return options;
  }

  getBlockChainOptions = () => {
    const options = [{
      label: '',
      value: ''
    }];

    blockChains.forEach((blockChain) => {
      options.push({
        label: blockChain.toUpperCase(),
        value: blockChain
      });
    });

    return options;
  }

  getStatusOptions = () => {
    return [{
      label: '',
      value: ''
    },
    {
      label: 'Active',
      value: 'active'
    },
    {
      label: 'Inactive',
      value: 'inactive'
    },
    {
      label: 'Deleted',
      value: 'deleted'
    }];
  };

  getTimeZoneOptions = () => {
    const options = [{
      label: '',
      value: ''
    }];

    timeZones.forEach((timezone) => {
      options.push({
        label: timezone.label,
        value: timezone.value
      });
    });

    return options;
  }

  renderSocialsSection = () => { 
    let socialsRow = [];
    let socialRows = [];

    socialNetworks.forEach((item, index) => {
      if (index > 0 && index % 3 === 0) {
        socialRows.push(<Row>{socialsRow}</Row>);
        socialsRow = [];
      }

      socialsRow.push(<Col md="4">{renderABasicInput(this.props, upperCaseFirstLetter(item), `${item}_url`)}</Col>);
    });

    socialRows.push(<Row>{socialsRow}</Row>);

    return socialRows;
  }

  render() { 
    const { object, isDisabled } = this.props; 
    return (
      <>
      {object ? 
      <Form>
        <Row>
          <Col md="12"><h3>General</h3></Col>
        </Row>
        <Row>
          <Col md="12">{renderImageUploader(this.props, "Logo, (image should be square)", "logo")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderSelectInput(this.props, "Token Symbol", this.getCoinOptions(), "coin_symbol", isDisabled)}</Col>
          <Col md="4">{renderABasicInput(this.props, "Full Token Name", "title")}</Col>
          <Col md="4">{renderSelectInput(this.props, "Status",this.getStatusOptions(), "status")}</Col>

        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Total Token Available", "contributions_cap", "number", isDisabled)}</Col>
          <Col md="4">{renderABasicInput(this.props, "Presale Token Price", "presale_price", "number", isDisabled)}</Col>
          <Col md="4">{renderSelectInput(this.props, "Blockchain",this.getBlockChainOptions(), "blockchain")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderADateTimeInput(this.props, "Start Date Time", "start_date", isDisabled)}</Col>
          <Col md="4">{renderADateTimeInput(this.props, "End Date Time", "end_date", isDisabled)}</Col>
          <Col md="4">{renderSelectInput(this.props, "Timezone",this.getTimeZoneOptions(), "timezone", isDisabled)}</Col>
        </Row>
        <Row>
          <Col md="12"><h3>PRDX Member Lines Pool Weight</h3></Col>
        </Row>
        <Row>
          <Col md="3">{renderABasicInput(this.props, "Silver", "silver_line_level", "number", isDisabled)}</Col>
          <Col md="3">{renderABasicInput(this.props, "Gold", "gold_line_level", "number", isDisabled)}</Col>
          <Col md="3">{renderABasicInput(this.props, "Platinum", "platinum_line_level", "number", isDisabled)}</Col>
          <Col md="3">{renderABasicInput(this.props, "Black", "black_line_level", "number", isDisabled)}</Col>
        </Row>
        <Row>
          <Col md="12"><h3>Details</h3></Col>
        </Row>
        <Row>
          <Col md="12">{renderHtmlEditor(this.props, "Short project card info", "short_info", false, {md: 1})}</Col>
        </Row>
        <Row>
          <Col md="12">{renderHtmlEditor(this.props, "Pool Details", "details", false, {md: 1})}</Col>
        </Row>
        <Row>
          <Col md="12">{renderHtmlEditor(this.props, "Token Details", "token_details", false, {md: 1})}</Col>
        </Row>
        <Row>
          <Col md="12">{renderHtmlEditor(this.props, "Project Details", "project_info", false, {md: 1})}</Col>
        </Row>
        <Row>
          <Col md="12"><h3>Social Networks</h3></Col>
        </Row>
        {this.renderSocialsSection()}
      </Form>
      : null }
      </>
    );
  }
}

