import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import login from '../components/index';
import LoginState from '../../../redux/Login';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      setField: LoginState.actions.setField,
      login: LoginState.actions.login,
      logout: LoginState.actions.logout,
      removeToken: LoginState.actions.removeToken,
      isRecaptchaEnabled: LoginState.actions.isRecaptchaEnabled,
      getUserPermissions: LoginState.actions.getUserPermissions,
      geetestValidate: LoginState.actions.geetestValidate,
      geetestEmpty: LoginState.actions.geetestEmpty
    },
    dispatch
  );

const mapStateToProps = state => ({
  status: LoginState.selectors.status (state),
  twoFAModal: LoginState.selectors.twoFAModal (state),
  response: LoginState.selectors.response (state),
  email: LoginState.selectors.email (state),
  password: LoginState.selectors.password (state),
  otp_code: LoginState.selectors.otp_code (state),
  remember: LoginState.selectors.remember (state),
  recaptchaEnabled: LoginState.selectors.recaptchaEnabled (state),
  google_auth_key: LoginState.selectors.google_auth_key (state),
  recaptcha: LoginState.selectors.recaptcha (state),
  geetest: LoginState.selectors.geetest(state)
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(login);

