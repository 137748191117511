

import React from "react";
import history from '../../../history';

import {
  Container,
  Row,
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import DangerModal from "../../../components/Modal/danger.jsx";
import DarkTable from "../../../components/Table";
import NotificationAlert from "react-notification-alert";
import { hasPermission } from '../../../utils';

class RolesView extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        blockModal: false,
        currentRole: ""
    }
  }

  componentDidMount() {
      this.props.getRoles({page: 1});
  }

  getRolesActionsArray = () => {
    return [
      {name: 'Edit', onClick: this.editRole, permission: 'role.edit'},
      {name: 'Remove', onClick: this.removeRole, disabled: {by: "alias", values: ["member", "admin"]}, permission: 'role.remove'},
    ].filter(item => hasPermission(item.permission))
  };

  onRolesPagingClick = (ev, page) => { 
    this.props.getRoles({...this.props, page: page});
  }

  viewUsers = (e, role) => { 
    history.push('/manage-users/' + role.alias);
  }

  addRole = async () => {
    await this.props.resetForm();
    history.push('/role');
  }

  editRole = (e, role) => {
    history.push('/role/' + role.alias);
  }

  removeRole = (event, row) => {
    event.preventDefault();
    this.toggleModal(row.alias);
  };

  toggleModal = (roleId) => {
    this.setState({
        blockModal: !this.state.blockModal,
        currentRole: roleId,
    });
  };

  removeSelectedRole = async (roleId) => {
    await this.props.removeRole(roleId, this.refs.notificationAlert);
    this.props.getRoles({page: 1});
    this.toggleModal(roleId);
  };

  renderConfirmationModal = () => {
    const {currentRole} = this.state; 
    const body = `Are you sure you want to remove this role?`;
    return (
        <DangerModal
            isOpen={this.state.blockModal}
            toggleFunction={() => this.toggleModal(currentRole)}
            closeFunction={() => this.toggleModal(currentRole)}
            nextFunction={() => this.removeSelectedRole(currentRole)}
            body={body}
        />
    )
  };

  renderRolesSection = () => { 
    let inlineActions = [];

    if(hasPermission("role.add")){
      inlineActions.push({
        text: "+ Add addRole",
        func: this.addRole
      });
    }

    return <DarkTable
      tableTitle="Roles"
      inlineActions={inlineActions}
      actionsColumn={true}
      actions={this.getRolesActionsArray()}
      columns={{
        alias: "ID",
        name: "Name",
        permissions_count: "Perms.",
        created_at: "Created At",
      }}
      rows={this.props.roles}
      meta={this.props.rolesMeta}
      onPagingClick={this.onRolesPagingClick}
    />
  };

  render() { 
    const { roles } = this.props;
    return (
      <>
      <SimpleHeader name="Manage Roles" parentName="Roles" />
      <Container className="mt--6" fluid>
        <Row>&nbsp;</Row>
        {roles && this.renderRolesSection()}
        {this.renderConfirmationModal()}
        <div className="rna-wrapper">
            <NotificationAlert ref="notificationAlert"/>
        </div>
      </Container>
      </>
    );
  }
}

export default RolesView;