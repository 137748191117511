import React from 'react';
import { capitalizeFirstLetter, isPrimitiveValue } from '../../utils/shuftiPro';
import { CardInfo } from './CardInfo';
import { regexUrl } from './constant';

export const Field = ({ title, value, showEmptyField }) => {
  const isValuePrimitive = isPrimitiveValue(value);

  if (!isValuePrimitive) {
    return (
      <CardInfo
        cardHeader={title}
        data={value}
        fullWidth
        showEmptyField={showEmptyField}
      />
    );
  }

  if (!showEmptyField && !value && typeof value !== 'boolean') return null;

  const isUrl = typeof value === 'string' && value.match(regexUrl);

  return (
    <div className="field d-flex justify-content-between p-3">
      <span className="title font-weight-bold">
        {capitalizeFirstLetter(title.replace(/_/g, ' '))}
      </span>
      <span className="value">
        {isUrl ? (
          <a href={value}>{String(value)}</a>
        ) : value !== null ? (
          String(value)
        ) : (
          ''
        )}
      </span>
    </div>
  );
};
