const module = 'WALLETS';

export const GET_WITHDRAWALS_PENDING = `${module}/GET_WITHDRAWALS_PENDING`;
export const GET_WITHDRAWALS_SUCCESS = `${module}/GET_WITHDRAWALS_SUCCESS`;
export const GET_WITHDRAWALS_ERROR = `${module}/GET_WITHDRAWALS_ERROR`;

export const GET_DEPOSITS_PENDING = `${module}/GET_DEPOSITS_PENDING`;
export const GET_DEPOSITS_SUCCESS = `${module}/GET_DEPOSITS_SUCCESS`;
export const GET_DEPOSITS_ERROR = `${module}/GET_DEPOSITS_ERROR`;

export const GET_MANUAL_DEPOSITS_PENDING = `${module}/GET_MANUAL_DEPOSITS_PENDING`;
export const GET_MANUAL_DEPOSITS_SUCCESS = `${module}/GET_MANUAL_DEPOSITS_SUCCESS`;
export const GET_MANUAL_DEPOSITS_ERROR = `${module}/GET_MANUAL_DEPOSITS_ERROR`;

export const GET_MANUAL_WITHDRAWALS_PENDING = `${module}/GET_MANUAL_WITHDRAWALS_PENDING`;
export const GET_MANUAL_WITHDRAWALS_SUCCESS = `${module}/GET_MANUAL_WITHDRAWALS_SUCCESS`;
export const GET_MANUAL_WITHDRAWALS_ERROR = `${module}/GET_MANUAL_WITHDRAWALS_ERROR`;


export const CHANGE_BLOCK_DEPOSIT_PENDING = `${module}/CHANGE_BLOCK_DEPOSIT_PENDING`;
export const CHANGE_BLOCK_DEPOSIT_SUCCESS = `${module}/CHANGE_BLOCK_DEPOSIT_SUCCESS`;
export const CHANGE_BLOCK_DEPOSIT_ERROR = `${module}/CHANGE_BLOCK_DEPOSIT_ERROR`;

export const CHANGE_BLOCK_WITHDRAW_PENDING = `${module}/CHANGE_BLOCK_WITHDRAW_PENDING`;
export const CHANGE_BLOCK_WITHDRAW_SUCCESS = `${module}/CHANGE_BLOCK_WITHDRAW_SUCCESS`;
export const CHANGE_BLOCK_WITHDRAW_ERROR = `${module}/CHANGE_BLOCK_WITHDRAW_ERROR`;

export const GET_BLOCK_DEPOSIT_PENDING = `${module}/GET_BLOCK_DEPOSIT_PENDING`;
export const GET_BLOCK_DEPOSIT_SUCCESS = `${module}/GET_BLOCK_DEPOSIT_SUCCESS`;
export const GET_BLOCK_DEPOSIT_ERROR = `${module}/GET_BLOCK_DEPOSIT_ERROR`;

export const GET_BLOCK_WITHDRAW_PENDING = `${module}/GET_BLOCK_WITHDRAW_PENDING`;
export const GET_BLOCK_WITHDRAW_SUCCESS = `${module}/GET_BLOCK_WITHDRAW_SUCCESS`;
export const GET_BLOCK_WITHDRAW_ERROR = `${module}/GET_BLOCK_WITHDRAW_ERROR`;

export const GET_OPERATIONS_PENDING = `${module}/GET_OPERATIONS_PENDING`;
export const GET_OPERATIONS_SUCCESS = `${module}/GET_OPERATIONS_SUCCESS`;
export const GET_OPERATIONS_ERROR = `${module}/GET_OPERATIONS_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;

export const UPDATE_WITHDRAW = `${module}/UPDATE_WITHDRAW`;

