import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Logout from '../components/index';
import LoginState from '../../../redux/Login';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      logout: LoginState.actions.logout
    },
    dispatch
  );

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Logout);
