import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Balances from '../components/Balances';
import State from '../../../redux/ManualDistributions';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getManualDistributionBalances: State.actions.getManualDistributionBalances,
      getDistributionTotalPrdxForAllLines: State.actions.getDistributionTotalPrdxForAllLines,
      setField: State.actions.setField,
    },
    dispatch
  );

const mapStateToProps = state => ({
  balances: State.selectors.balances(state),
  totalRedeemedPrdx: State.selectors.totalRedeemedPrdx(state),
  distributionTotalPrdxForAllLines: State.selectors.distributionTotalPrdxForAllLines(state),
  balancesQuery: State.selectors.balancesQuery(state),
  balancesLevel: State.selectors.balancesLevel(state),
  meta: State.selectors.meta(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Balances);

