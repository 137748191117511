const module = 'USERS';

export const GET_USERS_PENDING = `${module}/GET_USERS_PENDING`;
export const GET_USERS_SUCCESS = `${module}/GET_USERS_SUCCESS`;
export const GET_USERS_ERROR = `${module}/GET_USERS_ERROR`;

export const GET_TOTAL_USERS_LINE_LEVELS_PENDING = `${module}/GET_TOTAL_USERS_LINE_LEVELS_PENDING`;
export const GET_TOTAL_USERS_LINE_LEVELS_SUCCESS = `${module}/GET_TOTAL_USERS_LINE_LEVELS_SUCCESS`;
export const GET_TOTAL_USERS_LINE_LEVELS_ERROR= `${module}/GET_TOTAL_USERS_LINE_LEVELS_ERROR`;

export const BLOCK_USER_PENDING = `${module}/BLOCK_USER_PENDING`;
export const BLOCK_USER_SUCCESS = `${module}/BLOCK_USER_SUCCESS`;
export const BLOCK_USER_ERROR = `${module}/BLOCK_USER_ERROR`;

export const CANCEL_USER_ORDERS_PENDING = `${module}/CANCEL_USER_ORDERS_PENDING`;
export const CANCEL_USER_ORDERS_SUCCESS = `${module}/CANCEL_USER_ORDERS_SUCCESS`;
export const CANCEL_USER_ORDERS_ERROR = `${module}/CANCEL_USER_ORDERS_ERROR`;

export const GET_INFO_PENDING = `${module}/GET_INFO_PENDING`;
export const GET_INFO_SUCCESS = `${module}/GET_INFO_SUCCESS`;
export const GET_INFO_ERROR = `${module}/GET_INFO_ERROR`;

export const GET_USER_PENDING = `${module}/GET_USER_PENDING`;
export const GET_USER_SUCCESS = `${module}/GET_USER_SUCCESS`;
export const GET_USER_ERROR = `${module}/GET_USER_ERROR`;

export const GET_KYB_STATUS_PENDING = `${module}/GET_KYB_STATUS_PENDING`;
export const GET_KYB_STATUS_SUCCESS = `${module}/GET_KYB_STATUS_SUCCESS`;
export const GET_KYB_STATUS_ERROR = `${module}/GET_KYB_STATUS_ERROR`;

export const UPDATE_KYB_STEP_TWO_PENDING = `${module}/UPDATE_KYB_STEP_TWO_PENDING`;
export const UPDATE_KYB_STEP_TWO_SUCCESS = `${module}/UPDATE_KYB_STEP_TWO_SUCCESS`;
export const UPDATE_KYB_STEP_TWO_ERROR = `${module}/UPDATE_KYB_STEP_TWO_ERROR`;

export const GET_BUSINESS_MEMBERS_LIST_PENDING = `${module}/GET_BUSINESS_MEMBERS_LIST_PENDING`;
export const GET_BUSINESS_MEMBERS_LIST_SUCCESS = `${module}/GET_BUSINESS_MEMBERS_LIST_SUCCESS`;
export const GET_BUSINESS_MEMBERS_LIST_ERROR = `${module}/GET_BUSINESS_MEMBERS_LIST_ERROR`;

export const DOWNLOAD_KYB_DOCUMENT_PENDING = `${module}/DOWNLOAD_KYB_DOCUMENT_PENDING`;
export const DOWNLOAD_KYB_DOCUMENT_SUCCESS = `${module}/DOWNLOAD_KYB_DOCUMENT_SUCCESS`;
export const DOWNLOAD_KYB_DOCUMENT_ERROR = `${module}/DOWNLOAD_KYB_DOCUMENT_ERROR`;

export const DOWNLOAD_USER_TRADES_PENDING = `${module}/DOWNLOAD_USER_TRADES_PENDING`;
export const DOWNLOAD_USER_TRADES_SUCCESS = `${module}/DOWNLOAD_USER_TRADES_SUCCESS`;
export const DOWNLOAD_USER_TRADES_ERROR = `${module}/DOWNLOAD_USER_TRADES_ERROR`;

export const GET_USER_FEES_PENDING = `${module}/GET_USER_FEES_PENDING`;
export const GET_USER_FEES_SUCCESS = `${module}/GET_USER_FEES_SUCCESS`;
export const GET_USER_FEES_ERROR = `${module}/GET_USER_FEES_ERROR`;

export const UPDATE_USER_SUCCESS = `${module}/UPDATE_USER_SUCCESS`;
export const UPDATE_USER_PENDING = `${module}/UPDATE_USER_PENDING`;
export const UPDATE_USER_ERROR = `${module}/UPDATE_USER_ERROR`;

export const UPDATE_PASSWORD_SUCCESS = `${module}/UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_PENDING = `${module}/UPDATE_PASSWORD_PENDING`;
export const UPDATE_PASSWORD_ERROR = `${module}/UPDATE_PASSWORD_ERROR`;

export const GET_WITHDRAW_SETTINGS_SUCCESS = `${module}/GET_WITHDRAW_SETTINGS_SUCCESS`;
export const GET_WITHDRAW_SETTINGS_PENDING = `${module}/GET_WITHDRAW_SETTINGS_PENDING`;
export const GET_WITHDRAW_SETTINGS_ERROR = `${module}/GET_WITHDRAW_SETTINGS_ERROR`;

export const UPDATE_WITHDRAW_SETTINGS_SUCCESS = `${module}/UPDATE_WITHDRAW_SETTINGS_SUCCESS`;
export const UPDATE_WITHDRAW_SETTINGS_PENDING = `${module}/UPDATE_WITHDRAW_SETTINGS_PENDING`;
export const UPDATE_WITHDRAW_SETTINGS_ERROR = `${module}/UPDATE_WITHDRAW_SETTINGS_ERROR`;

export const UPDATE_SETTINGS_SUCCESS = `${module}/UPDATE_SETTINGS_SUCCESS`;
export const UPDATE_SETTINGS_PENDING = `${module}/UPDATE_SETTINGS_PENDING`;
export const UPDATE_SETTINGS_ERROR = `${module}/UPDATE_SETTINGS_ERROR`;

export const UPDATE_FEES_SUCCESS = `${module}/UPDATE_FEES_SUCCESS`;
export const UPDATE_FEES_PENDING = `${module}/UPDATE_FEES_PENDING`;
export const UPDATE_FEES_ERROR = `${module}/UPDATE_FEES_ERROR`;

export const USER_LOGS_SUCCESS = `${module}/USER_LOGS_SUCCESS`;
export const USER_LOGS_PENDING = `${module}/USER_LOGS_PENDING`;
export const USER_LOGS_ERROR = `${module}/USER_LOGS_ERROR`;

export const USER_ORDERS_SUCCESS = `${module}/USER_ORDERS_SUCCESS`;
export const USER_ORDERS_PENDING = `${module}/USER_ORDERS_PENDING`;
export const USER_ORDERS_ERROR = `${module}/USER_ORDERS_ERROR`;

export const USER_TRADES_SUCCESS = `${module}/USER_TRADES_SUCCESS`;
export const USER_TRADES_PENDING = `${module}/USER_TRADES_PENDING`;
export const USER_TRADES_ERROR = `${module}/USER_TRADES_ERROR`;

export const USER_WALLET_SUCCESS = `${module}/USER_WALLET_SUCCESS`;
export const USER_WALLET_PENDING = `${module}/USER_WALLET_PENDING`;
export const USER_WALLET_ERROR = `${module}/USER_WALLET_ERROR`;

export const USER_WALLET_ADDRS_SUCCESS = `${module}/USER_WALLET_ADDRS_SUCCESS`;
export const USER_WALLET_ADDRS_PENDING = `${module}/USER_WALLET_ADDRS_PENDING`;
export const USER_WALLET_ADDRS_ERROR = `${module}/USER_WALLET_ADDRS_ERROR`;

export const USER_DISTRIBUTIONS_SUCCESS = `${module}/USER_DISTRIBUTIONS_SUCCESS`;
export const USER_DISTRIBUTIONS_PENDING = `${module}/USER_DISTRIBUTIONS_PENDING`;
export const USER_DISTRIBUTIONS_ERROR = `${module}/USER_DISTRIBUTIONS_ERROR`;

export const USER_WITHDRAWALS_SUCCESS = `${module}/USER_WITHDRAWALS_SUCCESS`;
export const USER_WITHDRAWALS_PENDING = `${module}/USER_WITHDRAWALS_PENDING`;
export const USER_WITHDRAWALS_ERROR = `${module}/USER_WITHDRAWALS_ERROR`;

export const USER_BONUS_CONTRACTS_SUCCESS = `${module}/USER_BONUS_CONTRACTS_SUCCESS`;
export const USER_BONUS_CONTRACTS_PENDING = `${module}/USER_BONUS_CONTRACTS_PENDING`;
export const USER_BONUS_CONTRACTS_ERROR = `${module}/USER_BONUS_CONTRACTS_ERROR`;

export const USER_BOTS_SUCCESS = `${module}/USER_BOTS_SUCCESS`;
export const USER_BOTS_PENDING = `${module}/USER_BOTS_PENDING`;
export const USER_BOTS_ERROR = `${module}/USER_BOTS_ERROR`;

export const USER_DEPOSITS_SUCCESS = `${module}/USER_DEPOSITS_SUCCESS`;
export const USER_DEPOSITS_PENDING = `${module}/USER_DEPOSITS_PENDING`;
export const USER_DEPOSITS_ERROR = `${module}/USER_DEPOSITS_ERROR`;

export const USER_CANCEL_WITHDRAW_ERROR = `${module}/USER_CANCEL_WITHDRAW_ERROR`;
export const USER_CANCEL_WITHDRAW_PENDING = `${module}/USER_CANCEL_WITHDRAW_PENDING`;
export const USER_CANCEL_WITHDRAW_SUCCESS = `${module}/USER_CANCEL_WITHDRAW_SUCCESS`;

export const DISABLE_TRADE_PASS_PENDING = `${module}/DISABLE_TRADE_PASS_PENDING`;
export const DISABLE_TRADE_PASS_SUCCESS = `${module}/DISABLE_TRADE_PASS_SUCCESS`;
export const DISABLE_TRADE_PASS_ERROR = `${module}/DISABLE_TRADE_PASS_ERROR`;


export const DISABLE_ANTIPHISHING_PENDING = `${module}/DISABLE_ANTIPHISHING_PENDING`;
export const DISABLE_ANTIPHISHING_SUCCESS = `${module}/DISABLE_ANTIPHISHING_SUCCESS`;
export const DISABLE_ANTIPHISHING_ERROR = `${module}/DISABLE_ANTIPHISHING_ERROR`;

export const DISABLE_GOOGLE_AUTH_PENDING = `${module}/DISABLE_GOOGLE_AUTH_PENDING`;
export const DISABLE_GOOGLE_AUTH_SUCCESS = `${module}/DISABLE_GOOGLE_AUTH_SUCCESS`;
export const DISABLE_GOOGLE_AUTH_ERROR = `${module}/DISABLE_GOOGLE_AUTH_ERROR`;

export const DISABLE_SMS_AUTH_PENDING = `${module}/DISABLE_SMS_AUTH_PENDING`;
export const DISABLE_SMS_AUTH_SUCCESS = `${module}/DISABLE_SMS_AUTH_SUCCESS`;
export const DISABLE_SMS_AUTH_ERROR = `${module}/DISABLE_SMS_AUTH_ERROR`;

export const CHANGE_BLOCK_DEPOSIT_CRYPTO_PENDING = `${module}/CHANGE_BLOCK_DEPOSIT_CRYPTO_PENDING`;
export const CHANGE_BLOCK_DEPOSIT_CRYPTO_SUCCESS = `${module}/CHANGE_BLOCK_DEPOSIT_CRYPTO_SUCCESS`;
export const CHANGE_BLOCK_DEPOSIT_CRYPTO_ERROR = `${module}/CHANGE_BLOCK_DEPOSIT_CRYPTO_ERROR`;

export const CHANGE_BLOCK_DEPOSIT_FIAT_PENDING = `${module}/CHANGE_BLOCK_DEPOSIT_FIAT_PENDING`;
export const CHANGE_BLOCK_DEPOSIT_FIAT_SUCCESS = `${module}/CHANGE_BLOCK_DEPOSIT_FIAT_SUCCESS`;
export const CHANGE_BLOCK_DEPOSIT_FIAT_ERROR = `${module}/CHANGE_BLOCK_DEPOSIT_FIAT_ERROR`;

export const CHANGE_BLOCK_WITHDRAW_CRYPTO_PENDING = `${module}/CHANGE_BLOCK_WITHDRAW_CRYPTO_PENDING`;
export const CHANGE_BLOCK_WITHDRAW_CRYPTO_SUCCESS = `${module}/CHANGE_BLOCK_WITHDRAW_CRYPTO_SUCCESS`;
export const CHANGE_BLOCK_WITHDRAW_CRYPTO_ERROR = `${module}/CHANGE_BLOCK_WITHDRAW_CRYPTO_ERROR`;

export const CHANGE_BLOCK_WITHDRAW_FIAT_PENDING = `${module}/CHANGE_BLOCK_WITHDRAW_FIAT_PENDING`;
export const CHANGE_BLOCK_WITHDRAW_FIAT_SUCCESS = `${module}/CHANGE_BLOCK_WITHDRAW_FIAT_SUCCESS`;
export const CHANGE_BLOCK_WITHDRAW_FIAT_ERROR = `${module}/CHANGE_BLOCK_WITHDRAW_FIAT_ERROR`;

export const ON_DETECT_IP_PENDING = `${module}/ON_DETECT_IP_PENDING`;
export const ON_DETECT_IP_SUCCESS = `${module}/ON_DETECT_IP_SUCCESS`;
export const ON_DETECT_IP_ERROR = `${module}/ON_DETECT_IP_ERROR`;

export const OFF_DETECT_IP_PENDING = `${module}/OFF_DETECT_IP_PENDING`;
export const OFF_DETECT_IP_SUCCESS = `${module}/OFF_DETECT_IP_SUCCESS`;
export const OFF_DETECT_IP_ERROR = `${module}/OFF_DETECT_IP_ERROR`;

export const SET_WITHDRAW_SETTINGS_FIELD = `${module}/SET_WITHDRAW_SETTINGS_FIELD`;
export const SET_CURRENT_USER_FIELD = `${module}/SET_CURRENT_USER_FIELD`;
export const SET_FIELD = `${module}/SET_FIELD`;
