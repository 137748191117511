import React from "react";
import TabHeader from "components/Tabs/wallets.jsx";
import {Card, Container} from "reactstrap";
import Table from "../../../components/Table";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

export default class Screen extends React.Component {
  ticker = null;
  tick = () => {
    this.props.getCoinStatistics({...this.props});
  }

  componentDidMount() {
    this.tick();
    this.ticker = setInterval(this.tick, 5000);
  }

  componentWillUnmount(){
    if(this.ticker){
      clearInterval(this.ticker);
    }
  }

  searchAmounts = async(e) => {
    await this.props.setField('coin_query', e.target.value);
    this.props.getCoinStatistics({...this.props});
  }

  render() {
    const { match } = this.props;
    const {coins, coin_query} = this.props;
    const filteredCoins = coins && coins.filter(item => item.coin.toLowerCase().includes(coin_query.toLowerCase()))
    return (
      <>
        <SimpleHeader name="Statistics" parentName="Wallets" className="pb-1"/>
        <TabHeader match={match}  />
        <Container className="mt--6" fluid>
          <Card className="user_content">
          <Table
            light={true}
            inlineSearch={this.searchAmounts}
            tableTitle="Amounts"
            columns={{
              coin: "Coin",
              assets: "Assets",
              liabilities: "liabilities",
              profit: "Profit",
              expenses: "Expenses",
            }}
            rows={filteredCoins}
          />
          </Card>
        </Container>
      </>
    );
  }
}

