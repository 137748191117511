import React from "react";
import { Button, Modal } from "reactstrap";

class SuccessModal extends React.Component {
  render() {
    return (
      <Modal
        className="modal-dialog-centered modal-success"
        contentClassName="bg-gradient-success"
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggleFunction()}
      >
        <div className="modal-header">
          <h6
            className="modal-title"
            id="modal-title-notification"
          >
            Success
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => this.props.closeFunction()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x"/>
            <h4 className="heading mt-4">
              {this.props.body}
            </h4>
          </div>
        </div>
        <div className="modal-footer">
          
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => this.props.closeFunction()}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}


export default SuccessModal;