import React, {memo, useEffect, useRef, useState} from 'react';

import {Container, Row} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import SimpleHeader from "../../../components/Headers/SimpleHeader";
import DarkTable from "../../../components/Table";

import { hasPermission } from "../../../utils";
import history from "../../../history";

const OtcOrders = (
  {
    getAllOtcOrders,
    otcOrders,
    otcOrdersMeta
  }) => {
    const [searchKeyword, setSearchKeyword] = useState();

    const notificationAlertRef = useRef();

    useEffect(() => {
      let query = otcOrdersMeta;

      if (searchKeyword) {
        query = {...otcOrdersMeta, email: searchKeyword, page: 1}
      }

      getAllOtcOrders(query)
    }, [searchKeyword]);

    const handleSearchUsers = (e) => setSearchKeyword(e.target.value)

    const handlePagingClick = (ev, page) => {
      getAllOtcOrders({...otcOrdersMeta, page})
    };

    const goToOtcOrderDetails = (event, row) => {
        event.preventDefault();
        history.push(`otc-orders/upload?id=${row.id}`);
    }

    const getActionsArray = () => {
        return [
            {name: 'Upload document', link: 'otc-orders/upload',  onClick: goToOtcOrderDetails, permission: 'admin.view'}
        ].filter(item => hasPermission(item.permission));
    };

    return (
        <>
            <SimpleHeader name="OTC Orders" parentName="OTC Orders" />
            <Container className="mt--6" fluid>
                <Row>&nbsp;</Row>

                <DarkTable
                    tableTitle="OTC Orders"
                    actionsColumn={hasPermission('admin.view')}
                    inlineSearch={handleSearchUsers}
                    columns={{
                        user: "User",
                        amount: "Amount",
                        price: "Price",
                        side: 'Side',
                        created_at: "Date",
                        status: "Status",
                    }}
                    actions={getActionsArray()}
                    rows={otcOrders}
                    meta={otcOrdersMeta}
                    onPagingClick={handlePagingClick}
                />

                <div className="rna-wrapper">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
            </Container>
        </>
    );
};

export default memo(OtcOrders);