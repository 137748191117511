import React from "react";
import history from '../../../history';

import {
  Container,
  Row,
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import DarkTable from "../../../components/Table";
import { hasPermission } from '../../../utils';

class Launchpad extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        cancelModal: false,
    }
  }
  
  componentDidMount() {
    if (!hasPermission('admin.view')) {
      history.push('/error');
    } else {
      this.props.getProjects({page: 1});
    }
  }

  getProjectActionsArray = () => {
    return [
      {name: 'Edit', onClick: this.editProject, permission: 'market.edit'},
    ].filter(item => hasPermission(item.permission))
  };

  onProjectsPagingClick = (ev, page) => { 
    this.props.getProjects({...this.props, page});
  }

  addProject = () => {
    history.push('/launchpad-project');
  };


  editProject = (e, project) => { 
    history.push(`/launchpad-project/${project.id}`);
  };

  renderProjectsSection = () => {
    let inlineActions = [];

    if(hasPermission("admin.view")){
      inlineActions.push({
        text: "+ Add project",
        func: this.addProject
      });
    }

    return <DarkTable
      tableTitle="Launchpad Projects"
      inlineActions={inlineActions}
      actionsColumn
      actions={this.getProjectActionsArray()}
      columns={{
        id: "Id",
        coin_symbol: "Token Symbol",
        title: "Full Token Name",
        status: "Status",
        presale_price: "Presale Price (USDT)",
        start_date: "Start Date",
        end_date: "End Date",
        timezone: "Timezone"
      }}
      rows={this.props.projects}
      meta={this.props.meta}
      onPagingClick={this.onProjectsPagingClick}
    />
  };

  render() { 
    return (
      <>
      <SimpleHeader name="Manage Launchpad Projects" parentName="Launchpad" />
      <Container className="mt--6" fluid>
        <Row>&nbsp;</Row>
        {this.renderProjectsSection()}
      </Container>
      </>
    );
  }
}

export default Launchpad;