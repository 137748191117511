import * as c from './constants';
import BigNumber from 'bignumber.js';
import { sortBy } from 'lodash';

const initialState = {
  chains: [],
  coins: [],
  presaleCoins: [],
  coin: {
    contract_address: '',
    cost_symbol: '',
    deposit_fee: 0,
    digits: 8,
    min_withdraw: 0,
    name: '',
    symbol: '',
    token_precision: 18,
    withdraw_fee: 0,
    type: 'crypto',
    status: 'active',
    blockchain_explorer: '',
    chain: 'btc'
  },
  chain: {
    name: '',
    symbol: '',
    status: 'active',
  },
  status: {pending: false, success: false, error: false},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_CHAINS_PENDING:
    case c.GET_CHAIN_PENDING:
    case c.GET_COINS_PENDING:
    case c.GET_PRESALE_COINS_PENDING:
    case c.GET_COIN_PENDING:
    case c.ADD_COIN_PENDING:
    case c.ADD_CHAIN_PENDING:
    case c.EDIT_COIN_PENDING:
    case c.CHANGE_BLOCK_DEPOSIT_PENDING:
    case c.CHANGE_BLOCK_WITHDRAW_PENDING:
    case c.EDIT_CHAIN_PENDING:
    case c.DELETE_COIN_PENDING:
    case c.DELETE_CHAIN_PENDING:
      return {
        ...state,
        status: {pending: true, error: false, success: false},
      };
    case c.GET_CHAINS_ERROR:
    case c.GET_CHAIN_ERROR:
    case c.GET_COINS_ERROR:
    case c.GET_PRESALE_COINS_ERROR:
    case c.GET_COIN_ERROR:
    case c.ADD_COIN_ERROR:
    case c.ADD_CHAIN_ERROR:
    case c.EDIT_COIN_ERROR:
    case c.CHANGE_BLOCK_DEPOSIT_ERROR:
    case c.CHANGE_BLOCK_WITHDRAW_ERROR:
    case c.EDIT_CHAIN_ERROR:
    case c.DELETE_COIN_ERROR:
    case c.DELETE_CHAIN_ERROR:
      return {
        ...state,
        status: {pending: false, error: action.payload, success: false},
      };
    case c.GET_CHAINS_SUCCESS: {
      let chains = action.payload
        .sort ((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      return {
        ...state,
        chains: chains,
      };
    }
    case c.GET_COINS_SUCCESS: {
      let coins = action.payload
        .map (item => {
          return {
            ...item,
            name_symbol: item.name + ' (' + item.symbol.toUpperCase() + ')', 
            withdraw_fee: BigNumber (item.withdraw_fee).toFixed (),
            deposit_fee: BigNumber (item.deposit_fee).toFixed (),
            min_withdraw: BigNumber (item.min_withdraw).toFixed (),
          };
        });
      const coinsSorted = sortBy(coins, 'symbol');
      
      return {
        ...state,
        coins: coinsSorted,
      };
    }
    case c.GET_PRESALE_COINS_SUCCESS: {
      let presaleCoins = action.payload
        .map (item => {
          return {
            ...item,
            name_symbol: item.name + ' (' + item.symbol.toUpperCase() + ')', 
            withdraw_fee: BigNumber (item.withdraw_fee).toFixed (),
            deposit_fee: BigNumber (item.deposit_fee).toFixed (),
            min_withdraw: BigNumber (item.min_withdraw).toFixed (),
          };
        })
        .sort ((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      return {
        ...state,
        presaleCoins,
      };
    }
    case c.GET_COIN_SUCCESS: {
      const item = action.payload; 
      return {
        ...state,
        coin: {
          ...item,
          id: item.symbol,
          withdraw_fee: BigNumber (item.withdraw_fee).toFixed (),
          deposit_fee: BigNumber (item.deposit_fee).toFixed (),
          min_withdraw: BigNumber (item.min_withdraw).toFixed (),
        },
      };
    }
    case c.GET_CHAIN_SUCCESS: {
      const item = action.payload;
      return {
        ...state,
        chain: {
          ...item,
          id: item.symbol,
        },
      };
    }
    case c.ADD_COIN_SUCCESS:
    case c.ADD_CHAIN_SUCCESS:
    case c.DELETE_COIN_SUCCESS:
    case c.DELETE_CHAIN_SUCCESS:
    case c.EDIT_CHAIN_SUCCESS: 
    case c.EDIT_COIN_SUCCESS:
    case c.CHANGE_BLOCK_DEPOSIT_SUCCESS:
    case c.CHANGE_BLOCK_WITHDRAW_SUCCESS: {
      return {
        ...state,
        status: {pending: false, error: false, success: true},
      };
    }
    case c.SET_COIN_FIELD: {
      return {
        ...state,
        coin: {
          ...state.coin,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case c.SET_CHAIN_FIELD: {
      return {
        ...state,
        chain: {
          ...state.chain,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case c.RESET_COIN_STATE: {
      return {
        ...state,
        coin: {
          ...initialState.coin,
        },
        chain: {
          ...initialState.chain,
        },
      };
    }
    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
