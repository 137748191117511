import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import PrdxCirculation from '../components';
import PrdxCirculationState from '../../../redux/PrdxCirculation';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getInitialData: PrdxCirculationState.actions.getInitialData
        },
        dispatch
    );

const mapStateToProps = state => ({
    status: PrdxCirculationState.selectors.status(state),
    prdxCirculation: PrdxCirculationState.selectors.prdxCirculation(state),
    totalPrdxForAllLines: PrdxCirculationState.selectors.totalPrdxForAllLines(state)
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(PrdxCirculation);

