import * as ac from "./actionCreators";
import api from "../../api";

export function getMyOwnOtcOrders(query) {
    return async (dispatch, getState) => {
        dispatch(ac.getMyOwnOtcOrders.pending());
        try {
            const token = getState().Login.token;
            const data = await api.otcOrders.getMyOwnOtcOrders(query, token);

            dispatch(ac.getMyOwnOtcOrders.success(data));
        } catch (err) {
            dispatch(ac.getMyOwnOtcOrders.error(err));
        }
    };
}

export function getAllOtcOrders(query) {
    return async (dispatch, getState) => {
        dispatch(ac.getAllOtcOrders.pending());
        try {
            const token = getState().Login.token;

            const data = await api.otcOrders.getAllOtcOrders(query, token);

            dispatch(ac.getAllOtcOrders.success(data));
        } catch (err) {
            dispatch(ac.getAllOtcOrders.error(err));
        }
    };
}

export function getOtcOrderDocumentUrl(otcOrderId, query) {
    return async (dispatch, getState) => {
        dispatch(ac.getOtcOrderDocument.pending());
        try {
            const token = getState().Login.token;
            const data = await api.otcOrders.getOtcOrderDocumentUrl(otcOrderId, query, token);

            dispatch(ac.getOtcOrderDocument.success(data));
        } catch (err) {
            dispatch(ac.getOtcOrderDocument.error(err));
        }
    };
}

export function attachDocumentToOtcOrder(otcOrderId, data) {
    return async (dispatch, getState) => {
        dispatch(ac.attachDocumentToOtcOrder.pending());
        try {
            const token = getState().Login.token;

            const payloadData = {
                document: data,
            };

            const formData = new FormData();
            for (const prop in payloadData) {
                formData.append(prop, payloadData[prop]);
            }

            const response = await api.otcOrders.attachDocumentToOtcOrder(otcOrderId, formData, token);

            dispatch(ac.attachDocumentToOtcOrder.success(response));

            dispatch(getOtcOrderDocumentUrl(otcOrderId))
        } catch (err) {
            dispatch(ac.attachDocumentToOtcOrder.error(err));
        }
    };
}

export function setOtcDocumentField(field, value) {
    return (dispatch) => dispatch(ac.setOtcDocumentField({ field, value }));
}

export function resetOtcDocument() {
    return (dispatch) => dispatch(ac.resetOtcDocument());
}