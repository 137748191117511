import { api } from "../config";
import { get } from "../utils/superagent";
import { assetValidToken } from "../utils/auth";

export const getBots = async (data, token) => {
    const result = await assetValidToken(token).then(() => get(`${api}/admin/bots`, data, token));
    result.data.Meta.pages = Math.ceil(result.data.Meta.count / result.data.Meta.limit);

    return result;
}

export const getBotsPnl = async (token) => {
    return await assetValidToken(token).then(() => get(`${api}/admin/bots/pnl`, {}, token));
}

export const getGridBotsStatistics = async (data, token) => {
    const result = await assetValidToken(token).then(() => get(`${api}/admin/bots/statistics/grid`, data, token));
    result.data.Meta.pages = Math.ceil(result.data.Meta.count / result.data.Meta.limit);

    return result;
}

export const downloadGridBotsStatistics = async (data, token) => {
    return await assetValidToken(token).then(() => get(`${api}/admin/bots/statistics/grid/export`, data, token));
}

export const getTrendBotsStatistics = async (data, token) => {
    const result = await assetValidToken(token).then(() => get(`${api}/admin/bots/statistics/trend`, data, token));
    result.data.Meta.pages = Math.ceil(result.data.Meta.count / result.data.Meta.limit);

    return result;
}

export const downloadTrendBotsStatistics = async (data, token) => {
    return await assetValidToken(token).then(() => get(`${api}/admin/bots/statistics/trend/export`, data, token));
}