import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  NavLink,
  Row,
  Col
} from "reactstrap";

class CardsUsers extends React.Component {
  render() {
    const {classNames = 'pb-4' } = this.props;

    return (
      <>
     <div className={`header bg-info ${classNames}`}>
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <NavLink href="/manage-users/pending-kyc">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-muted mb-0"
                        >
                          Users pending <br/>KYC
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {this.props.cards.users_pending_kyc}
                          </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-pin-3"/>
                        </div>
                      </Col>
                    </Row>
                    </NavLink>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <NavLink href="/manage-users/pending-deposit">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="  text-muted mb-0"
                        >
                         Users pending deposit
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                            {this.props.cards.users_pending_deposit}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-money-coins"/>
                        </div>
                      </Col>
                    </Row>
                    </NavLink>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                  <NavLink href="/manage-users/pending-withdraw">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="  text-muted mb-0"
                        >
                         Users pending withdrawal
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                            {this.props.cards.users_pending_withdrawal}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-purple text-white rounded-circle shadow">
                          <i className="ni ni-lock-circle-open"/>
                        </div>
                      </Col>
                    </Row>
                  </NavLink>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      </>
    );
  }
}

CardsUsers.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default CardsUsers;
