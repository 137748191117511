import React from "react";
import * as c from "./constants";
import * as moment from "moment";
import Bignumber, { BigNumber } from "bignumber.js";
import {
  fullName,
  getKycPassedLevelByStatus,
  getFilledPercent,
  formatNumber
} from "../../utils";
import { lineLevels } from "../../constants";
import {KYB_GENERAL_STATUS} from "../../constants/kyb";

const initialState = {
  all: [{}],
  withdrawSettings: {
    paymentMethod: 'bitgo',
    adv_cash_withdraw_limit: "",
    clear_junction_withdraw_limit: "",
    default_withdraw_limit: "",
    withdraw_limit: "",
    maxLimit: ''
  },
  totalUsersLineLevels: [],
  cards: [{}],
  currentUser: {},
  currentUserFees: {},
  wallet: [],
  wallet_query: "",
  addresses: [],
  email: "",
  response: "",
  orders_filter: "open",
  deposits_filter: "",
  account: "main",
  withdrawals: [],
  trades: [],
  bots: [],
  totalActiveBots: 0,
  totalOfLockedFunds: 0, // in USDT
  apiStatus: { pending: false, success: false, error: false },
  status: { pending: false, success: false, error: false },
  kybStatus: {
    status: KYB_GENERAL_STATUS.NONE,
    stepOneStatus: KYB_GENERAL_STATUS.NONE,
    stepTwoStatus: KYB_GENERAL_STATUS.NONE,
    stepThreeStatus: KYB_GENERAL_STATUS.NONE,
    stepFourStatus: KYB_GENERAL_STATUS.NONE,
    isAllStepNone: true,
  },
  shuftiProResult: null,
  businessMembersList: [],
  KYBDocumentUrl: null,
};

export const refactorResp = (payload) => {
  return payload.Users.map((user) => {
    console.log('user', user)
    return {
      ...user,
      created_at: moment(user.created_at).format("LLLL"),
      name: fullName(user),
      kyc: user.role_alias === 'business' ? '' :  `status: ${user.kyc_status}; level: ${getKycPassedLevelByStatus(
        user.kyc_status
      )}`,
    };
  });
};

const getBotType = (bot) => {
  switch (bot.type) {
    case "grid": {
      if (bot.contract_id) return `Trading Grid, contract ${bot.contract_id}`;

      return "Trading Grid";
    }

    default:
      return bot.type;
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_USERS_PENDING:
    case c.GET_TOTAL_USERS_LINE_LEVELS_PENDING:
    case c.USER_LOGS_PENDING:
    case c.USER_ORDERS_PENDING:
    case c.USER_TRADES_PENDING:
    case c.USER_WALLET_PENDING:
    case c.USER_BONUS_CONTRACTS_PENDING:
    case c.USER_BOTS_PENDING:
    case c.USER_DISTRIBUTIONS_PENDING:
    case c.USER_WITHDRAWALS_PENDING:
    case c.USER_CANCEL_WITHDRAW_PENDING:
    case c.UPDATE_PASSWORD_PENDING:
    case c.UPDATE_SETTINGS_PENDING:
    case c.GET_WITHDRAW_SETTINGS_PENDING:
    case c.UPDATE_WITHDRAW_SETTINGS_PENDING:
    case c.UPDATE_USER_PENDING:
    case c.CHANGE_BLOCK_DEPOSIT_CRYPTO_PENDING:
    case c.CHANGE_BLOCK_DEPOSIT_FIAT_PENDING:
    case c.CHANGE_BLOCK_WITHDRAW_CRYPTO_PENDING:
    case c.CHANGE_BLOCK_WITHDRAW_FIAT_PENDING:
    case c.DOWNLOAD_USER_TRADES_PENDING:
    case c.GET_KYB_STATUS_PENDING:
    case c.GET_BUSINESS_MEMBERS_LIST_PENDING:
      return {
        ...state,
        status: { pending: true, error: false, success: false },
      };

    case c.USER_WALLET_ADDRS_PENDING:
      return {
        ...state,
        addresses: [],
        status: { pending: true, error: false, success: false },
      };
    case c.GET_USERS_ERROR:
    case c.GET_TOTAL_USERS_LINE_LEVELS_ERROR:
    case c.USER_LOGS_ERROR:
    case c.USER_ORDERS_ERROR:
    case c.USER_TRADES_ERROR:
    case c.USER_WALLET_ERROR:
    case c.USER_WALLET_ADDRS_ERROR:
    case c.USER_DISTRIBUTIONS_ERROR:
    case c.USER_WITHDRAWALS_ERROR:
    case c.USER_BONUS_CONTRACTS_ERROR:
    case c.USER_BOTS_ERROR:
    case c.USER_CANCEL_WITHDRAW_ERROR:
    case c.UPDATE_PASSWORD_ERROR:
    case c.GET_WITHDRAW_SETTINGS_ERROR:
    case c.UPDATE_WITHDRAW_SETTINGS_ERROR:
    case c.UPDATE_SETTINGS_ERROR:
    case c.UPDATE_USER_ERROR:
    case c.DISABLE_TRADE_PASS_ERROR:
    case c.DISABLE_ANTIPHISHING_ERROR:
    case c.DISABLE_GOOGLE_AUTH_ERROR:
    case c.DISABLE_SMS_AUTH_ERROR:
    case c.CHANGE_BLOCK_DEPOSIT_CRYPTO_ERROR:
    case c.CHANGE_BLOCK_DEPOSIT_FIAT_ERROR:
    case c.CHANGE_BLOCK_WITHDRAW_CRYPTO_ERROR:
    case c.CHANGE_BLOCK_WITHDRAW_FIAT_ERROR:
    case c.ON_DETECT_IP_ERROR:
    case c.OFF_DETECT_IP_ERROR:
    case c.DOWNLOAD_USER_TRADES_ERROR:
    case c.GET_KYB_STATUS_ERROR:
    case c.UPDATE_KYB_STEP_TWO_ERROR:
    case c.GET_BUSINESS_MEMBERS_LIST_ERROR:
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false },
      };

    case c.GET_USER_ERROR: {
      return {
        ...state,
        status: {
          pending: false,
          error: { message: action.payload.message, type: "getUser" },
          success: false,
        },
      };
    }

    case c.DOWNLOAD_KYB_DOCUMENT_ERROR: {
      return {
        ...state,
        status: {
          pending: false,
          error: { message: action.payload.message, type: "downloadKYBDoc" },
          success: false,
        },
      };
    }
    case c.GET_USERS_SUCCESS: {
      return {
        ...state,
        meta: action.payload.Meta,
        all: refactorResp(action.payload),
      };
    }
    case c.GET_TOTAL_USERS_LINE_LEVELS_SUCCESS: {
      const totalUsersLineLevels = lineLevels.map((lineLevel) => {
        let foundTotalUsersLineLevel = action.payload.find((totalUserLineLevel) => totalUserLineLevel.user_level === lineLevel);

        if(!foundTotalUsersLineLevel){
          foundTotalUsersLineLevel = {count: 0, user_level: lineLevel};
        }

        return {
          ...foundTotalUsersLineLevel
        };
      },{});

      return {
        ...state,
        totalUsersLineLevels
      };
    }
    case c.USER_LOGS_SUCCESS: {
      let logs = action.payload.logs.map((item) => {
        let notes = JSON.parse(item.notes);
        return {
          ...item,
          notes: notes,
          country: notes.country,
          agent: `${notes.agent.Name}, ${notes.agent.OS} (${notes.agent.OSVersion})`,
          created_at: moment(item.created_at).format("lll"),
        };
      });
      return {
        ...state,
        logsmeta: action.payload.meta,
        logs: logs,
      };
    }
    case c.USER_ORDERS_SUCCESS: {
      const {
        data: { Orders: orders, Trades: trades, Meta },
        markets,
        status,
      } = action.payload;
      let dataExpandable = {};

      if (orders.length && Object.values(markets).length) {
        Object.values(trades).forEach((item) => {
          if (!dataExpandable[item.order_id]) {
            dataExpandable[item.order_id] = [];
          }

          dataExpandable[item.order_id].push(item);
        });

        const resultOrders = orders.reduce((allOrders, order) => {
          let newOrders = [];
          const market = markets.find((el) => el.id === order.market_id);
          const quoteCoin = market.quote_coin_symbol.toUpperCase();
          const marketCoin = market.market_coin_symbol.toUpperCase();

          const BN = Bignumber.clone({
            DECIMAL_PLACES: market.quote_precision_format || 8,
            ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
          });

          let data_expandable_id = order.id;
          let price = order.stop !== "none" ? order.stop_price : order.price;
          let amount = order.amount;
          let filled_amount = order.filled_amount;

          if (order.type === "oto") {
            data_expandable_id = order.init_order_id;
          } else if (order.type === "strangle") {
            data_expandable_id = order.tp_order_id;
            price = order.tp_price;
            amount = order.tp_amount;
            filled_amount = order.tp_filled_amount;
          } else if (order.type === "straddle") {
            data_expandable_id = order.sl_order_id;
            price = order.sl_price;
            amount = order.sl_amount;
            filled_amount = order.sl_filled_amount;
          }

          const filledPercent = getFilledPercent(filled_amount, order.amount);
          const data_expandable = dataExpandable[data_expandable_id] || [];

          // calculate total
          let total = 0;

          if (status === "open") {
            total = new BN(price || 0).times(amount || 0);
          } else {
            total = data_expandable.reduce(
              (sum, trade) => sum.plus(trade.quote_volume),
              new BN(0)
            );
          }

          if (order.type === "strangle") {
            newOrders = [
              {
                ...order,
                type: "limit",
                side: "sell",
                market_name: markets.find((el) => el.id === order.market_id)
                  .name,
                price: `${price} ${quoteCoin}`,
                filled_percent: filledPercent,
                total: `${total} ${quoteCoin}`,
                amount: `${order.amount} ${marketCoin}`,
                status: order.tp_status,
                created_at: moment(order.created_at).format("DD/MM/YYYY"),
                fee_amount: `${order.fee_amount} ${quoteCoin}`,
                original: order,
              },
            ];

            const sl_data_expandable = dataExpandable[order.sl_order_id] || [];

            // calculate total
            let sl_total = 0;

            if (status === "open") {
              sl_total = new BN(order.sl_price || 0).times(
                order.sl_amount || 0
              );
            } else {
              sl_total = sl_data_expandable.reduce(
                (sum, trade) => sum.plus(trade.quote_volume),
                new BN(0)
              );
            }

            const subOrder = {
              type: "limit",
              amount: `${order.sl_amount} ${marketCoin}`,
              price: `${order.sl_price} ${quoteCoin}`,
              filled_percent: getFilledPercent(
                order.sl_filled_amount,
                order.sl_amount
              ),
              status: order.sl_status,
              market_id: "oco",
              id: null,
              created_at: null,
              market_name: "oco",
              side: "buy",
              total: `${sl_total} ${quoteCoin}`,
            };

            newOrders.push(subOrder);
          } else if (order.type === "straddle") {
            newOrders = [
              {
                ...order,
                type: "stop",
                side: "buy",
                market_name: markets.find((el) => el.id === order.market_id)
                  .name,
                price: `@ ${price} ${quoteCoin}`,
                filled_percent: filledPercent,
                total: `~ ${total} ${quoteCoin}`,
                amount: `${order.amount} ${marketCoin}`,
                status: order.sl_status,
                created_at: moment(order.created_at).format("DD/MM/YYYY"),
                fee_amount: `${order.fee_amount} ${marketCoin}`,
                original: order,
              },
            ];

            const tp_data_expandable = dataExpandable[order.tp_order_id] || [];

            // calculate total
            let tp_total = 0;

            if (status === "open") {
              tp_total = new BN(order.tp_price || 0).times(
                order.tp_amount || 0
              );
            } else {
              tp_total = tp_data_expandable.reduce(
                (sum, trade) => sum.plus(trade.quote_volume),
                new BN(0)
              );
            }

            const subOrder = {
              type: "stop",
              amount: `${order.tp_amount} ${marketCoin}`,
              price: `@ ${order.tp_price} ${quoteCoin}`,
              filled_percent: getFilledPercent(
                order.tp_filled_amount,
                order.tp_amount
              ),
              status: order.tp_status,
              id: null,
              created_at: null,
              market_name: "oco",
              side: "sell",
              total: `~ ${tp_total} ${quoteCoin}`,
            };

            newOrders.push(subOrder);
          } else {
            newOrders = [
              {
                ...order,
                type: order.stop !== "none" ? "stop" : order.type,
                side: order.side,
                market_name: markets.find((el) => el.id === order.market_id)
                  .name,
                price: `${
                  order.stop !== "none" ? `@ ${price}` : price
                } ${quoteCoin}`,
                filled_percent: filledPercent,
                total: `${
                  order.stop !== "none" ? `~ ${total}` : total
                } ${quoteCoin}`,
                amount: `${order.amount} ${marketCoin}`,
                status: order.status,
                created_at: moment(order.created_at).format("DD/MM/YYYY"),
                fee_amount: `${order.fee_amount} ${
                  order.side === "sell" ? quoteCoin : marketCoin
                }`,
                original: order,
              },
            ];

            if (order.type === "oto") {
              if (order.stop === "none") {
                newOrders[0].type = order.oto_type;
              }

              let spSubOrder = null;
              let tpSubOrder = null;

              if (Number(order.sl_price) || Number(order.sl_rel_price)) {
                const sl_data_expandable =
                  dataExpandable[order.sl_order_id] || [];

                // calculate total
                let sl_total = 0;

                if (status === "open") {
                  sl_total = new BN(order.sl_price || 0).times(
                    order.sl_amount || 0
                  );
                } else {
                  sl_total = sl_data_expandable.reduce(
                    (sum, trade) => sum.plus(trade.quote_volume),
                    new BN(0)
                  );
                }

                spSubOrder = {
                  type: `${order.type} SL`,
                  amount: `${order.sl_amount} ${marketCoin}`,
                  price: `${order.sl_price} ${quoteCoin}`,
                  filled_percent: getFilledPercent(
                    order.sl_filled_amount,
                    order.sl_amount
                  ),
                  status: order.sl_status,
                  id: null,
                  created_at: null,
                  market_name: null,
                  side: null,
                  total: `${sl_total} ${quoteCoin}`,
                };
              }

              if (Number(order.tp_price) || Number(order.tp_rel_price)) {
                const tp_data_expandable =
                  dataExpandable[order.tp_order_id] || [];

                // calculate total
                let tp_total = 0;

                if (status === "open") {
                  tp_total = new BN(order.tp_price || 0).times(
                    order.tp_amount || 0
                  );
                } else {
                  tp_total = tp_data_expandable.reduce(
                    (sum, trade) => sum.plus(trade.quote_volume),
                    new BN(0)
                  );
                }

                tpSubOrder = {
                  type: `${order.type} TP`,
                  amount: `${order.tp_amount} ${marketCoin}`,
                  price: `${order.tp_price} ${quoteCoin}`,
                  filled_percent: getFilledPercent(
                    order.tp_filled_amount,
                    order.tp_amount
                  ),
                  status: order.tp_status,
                  id: null,
                  created_at: null,
                  market_name: null,
                  side: null,
                  total: `${tp_total} ${quoteCoin}`,
                };
              }

              if (spSubOrder) {
                newOrders.push(spSubOrder);
              }

              if (tpSubOrder) {
                newOrders.push(tpSubOrder);
              }
            }
          }
          return [...allOrders, ...newOrders];
        }, []);

        return {
          ...state,
          ordersmeta: Meta,
          orders: resultOrders,
        };
      }

      return {
        ...state,
        ordersmeta: Meta,
        orders: [],
      };
    }
    case c.USER_WITHDRAWALS_SUCCESS:
      let withdrawals = action.payload.WithdrawRequests.map((item) => {
        let trx_id = "";
        if (item.txid) {
          if (item.blockchain_explorer)
            trx_id = (
              <a href={`${item.blockchain_explorer}${item.txid}`}>
                {item.txid.length > 12
                  ? `${item.txid.slice(0, 12)}...`
                  : item.txid}
              </a>
            );
          else trx_id = item.txid;
        }

        let externalSystem = '';
        if(!item.external_system) externalSystem = 'crypto';
        else{
          externalSystem = item.external_system;
          if(item.data){
            const data = JSON.parse(item.data);
            if(data.iban) externalSystem = `${externalSystem}, IBAN:${data.iban}`;
          }
        }

        return {
          ...item,
          external_system: externalSystem,
          created_at: moment(item.created_at).format("lll"),
          trx_id,
        };
      });
      return {
        ...state,
        withdrawalsMeta: action.payload.Meta,
        withdrawals,
      };

    case c.USER_BONUS_CONTRACTS_SUCCESS:

      const bonusContracts = action.payload.data.map((item) => {
        let mode = 'Manual';
        if(Number(item.bot_id) > 0){
          mode = `Bot id: ${item.bot_id}`;
        }

        let coinPrec = 8;
        const foundCoin = action.payload.coins.find((coin) => coin.symbol === item.coin_symbol);
        if(foundCoin) coinPrec = foundCoin.digits;

        const amount = BigNumber(item.amount || 0);
        const bonusPercents = BigNumber(item.bonus_percents || 0);
        const volumeToTrade = BigNumber(item.volume_to_trade || 0);
        const volumeTraded = BigNumber(item.volume_traded || 0);
  
        const bonus = amount.multipliedBy(bonusPercents);
        const progress = volumeTraded.dividedBy(volumeToTrade);
        let progressInPercents = progress.multipliedBy(100).toNumber();
  
        if (progressInPercents > 100) {
          progressInPercents = 100;
        }
  
        let currentBonus = 0;
        if (progressInPercents >= 25 && progressInPercents < 50)
          currentBonus = bonus.multipliedBy(0.25);
        else if (progressInPercents >= 50 && progressInPercents < 75)
          currentBonus = bonus.multipliedBy(0.5);
        else if (progressInPercents >= 75 && progressInPercents < 100)
          currentBonus = bonus.multipliedBy(0.75);
        else if (progressInPercents >= 100) currentBonus = bonus;
  
        const ltv = bonus.dividedBy(amount.plus(bonus)).multipliedBy(100);

        return {
          ...item,
          created_at: moment(item.created_at).utc().format("lll"),
          expired_at: moment(item.expired_at).utc().format("lll"),
          amount: formatNumber(amount, coinPrec),
          bonus_percents: formatNumber(bonusPercents.multipliedBy(100), 2),
          progress_in_percent: formatNumber(progressInPercents, 2),
          volume_to_trade: formatNumber(volumeToTrade, coinPrec),
          volume_traded: formatNumber(volumeTraded,coinPrec),
          total_bonus: formatNumber(bonus,coinPrec),
          current_bonus: formatNumber(currentBonus,coinPrec),
          ltv: formatNumber(ltv,2),
          ProfitLoss: item.ProfitLoss ? formatNumber(item.ProfitLoss, coinPrec) : '0',
          mode
        };
      });

      return {
        ...state,
        bonusContracts,
      };

    case c.USER_BOTS_SUCCESS:
      const {botsData, botsPnl, coins} =  action.payload;

      let bots = botsData.map((item) => {

        const botPnl = botsPnl.find((botPnlItem) => botPnlItem.bot_id === item.id);

        let generalPNL = '--';

        if (botPnl && botPnl.bot_profit) {
          if (item.contract_id) {
            generalPNL = `${formatNumber(botPnl.bot_profit, 2)}%`;
          } else {
            let coinPrecision = coins.find((coinItem) => coinItem.symbol === botPnl.profit_coin);

            const PNLCoinPrecision = coinPrecision
              ? coinPrecision.digits
              : 8;

            generalPNL = `${formatNumber(
              botPnl.profit,
              PNLCoinPrecision
            )} ${botPnl.profit_coin.toUpperCase()}`;
          }
        }

        return {
          ...item,
          pnl: generalPNL,
          type: getBotType(item),
        };
      });

      return {
        ...state,
        bots,
        totalActiveBots: action.payload.total_active_bots,
        totalOfLockedFunds: action.payload.total_of_locked_funds,
      };

    case c.USER_CANCEL_WITHDRAW_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
        withdrawals: state.withdrawals.map((withdraw) =>
          withdraw.id === action.payload
            ? { ...withdraw, status: "pending cancellation" }
            : withdraw
        ),
      };
    }

    case c.DOWNLOAD_USER_TRADES_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true }
      };
    }

    case c.USER_DEPOSITS_SUCCESS:
      let deposits = action.payload.transactions.map((item) => {
        let trx_id = "";
        if (item.txid) {
          if (item.blockchain_explorer)
            trx_id = (
              <a href={`${item.blockchain_explorer}${item.txid}`}>
                {item.txid.length > 12
                  ? `${item.txid.slice(0, 12)}...`
                  : item.txid}
              </a>
            );
          else trx_id = item.txid;
        }

        return {
          ...item,
          external_system: item.external_system || 'crypto',
          created_at: moment(item.created_at).format("lll"),
          trx_id,
        };
      });
      return {
        ...state,
        depositmeta: action.payload.meta,
        deposits,
      };
    case c.USER_TRADES_SUCCESS: {
      const { markets } = action.payload;

      let trades = action.payload.data.trades.map((item) => {
        const market = markets.find((el) => el.id === item.market_id);
        const quoteCoin = market.quote_coin_symbol.toUpperCase();
        const marketCoin = market.market_coin_symbol.toUpperCase();
        const filledPercent = getFilledPercent(
          item.volume * item.price,
          item.quote_volume
        );

        return {
          ...item,
          price: `${item.price} ${quoteCoin}`,
          total: `${item.quote_volume} ${quoteCoin}`,
          filledPercent,
          fee_amount: `${item.fee_amount} ${
            item.side === "sell" ? quoteCoin : marketCoin
          }`,
          timestamp: moment(new Date(item.timestamp * 1000)).format("lll"),
        };
      });

      return {
        ...state,
        tradesmeta: action.payload.data.meta,
        trades,
      };
    }
    case c.USER_WALLET_SUCCESS: {
      const wallet = {};

      action.payload.forEach((account) => {
        const data = Object.values(account.balances).map((item) => {
          return {
            ...item,
            symbol: item.symbol.toUpperCase(),
          }
        });

        wallet[account.sub_account_id] = {
          data,
          bot_id: account.bot_id,
          contract_id: account.contract_id,
        };
      });

      return {
        ...state,
        wallet
      };
    }
    case c.USER_WALLET_ADDRS_SUCCESS: {
      return {
        ...state,
        addresses: action.payload,
      };
    }
    case c.USER_DISTRIBUTIONS_SUCCESS: {
      let distributions = action.payload.Liabilities.map((item) => {
        return {
          ...item,
          timestamp: moment(new Date(item.created_at * 1000)).format("lll"),
        };
      });
      return {
        ...state,
        distributionsmeta: action.payload.Meta,
        distributions,
      };
    }
    case c.BLOCK_USER_SUCCESS: {
      const result = [];
      state.all.map((user) =>
        user.id === action.payload.id
          ? result.push(action.payload)
          : result.push(user)
      );
      return {
        ...state,
        all: result,
      };
    }
    case c.GET_INFO_SUCCESS: {
      return {
        ...state,
        cards: action.payload,
      };
    }
    case c.GET_USER_SUCCESS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }

    case c.GET_KYB_STATUS_SUCCESS: {
      const { kybStatus, shuftiProResult } = action.payload
      return {
        ...state,
        kybStatus,
        shuftiProResult,
        status: { pending: false, error: false, success: true }
      };
    }

    case c.UPDATE_KYB_STEP_TWO_SUCCESS: {
      const { KYBStepTwoStatus } = action.payload

      const kybStatus = {
        ...state.kybStatus,
        stepTwoStatus: KYBStepTwoStatus
      }

      return {
        ...state,
        kybStatus,
        status: { pending: false, error: false, success: true }
      };
    }

    case c.GET_BUSINESS_MEMBERS_LIST_SUCCESS: {
      const { businessMembersList } = action.payload

      return {
        ...state,
        businessMembersList,
        status: { pending: false, error: false, success: true }
      };
    }

    case c.DOWNLOAD_KYB_DOCUMENT_SUCCESS: {
      const { downloadUrl } = action.payload

      return {
        ...state,
        KYBDocumentUrl: downloadUrl,
        status: { pending: false, error: false, success: true }
      };
    }

    case c.GET_WITHDRAW_SETTINGS_SUCCESS: {
      let maxLimit = '';

      if(state.withdrawSettings.paymentMethod === 'bitgo'){
        maxLimit = action.payload.withdraw_limit;
      }
      else if(state.withdrawSettings.paymentMethod === 'advcash'){
        maxLimit = action.payload.adv_cash_withdraw_limit;
      }
      else if(state.withdrawSettings.paymentMethod === 'clear_junction'){
        maxLimit = action.payload.clear_junction_withdraw_limit;
      }
      else{
        maxLimit = action.payload.default_withdraw_limit;
      }

      return {
        ...state,
        withdrawSettings: {
          ...state.withdrawSettings,
          ...action.payload,
          maxLimit
        },
      };
    }
    case c.GET_USER_FEES_SUCCESS: {
      return {
        ...state,
        currentUserFees: action.payload,
      };
    }
    case c.UPDATE_SETTINGS_SUCCESS:
    case c.UPDATE_WITHDRAW_SETTINGS_SUCCESS:
    case c.UPDATE_USER_SUCCESS:
    case c.UPDATE_FEES_SUCCESS:
    case c.DISABLE_TRADE_PASS_SUCCESS:
    case c.DISABLE_ANTIPHISHING_SUCCESS:
    case c.DISABLE_GOOGLE_AUTH_SUCCESS:
    case c.DISABLE_SMS_AUTH_SUCCESS:
    case c.CHANGE_BLOCK_DEPOSIT_CRYPTO_SUCCESS:
    case c.CHANGE_BLOCK_DEPOSIT_FIAT_SUCCESS:
    case c.CHANGE_BLOCK_WITHDRAW_CRYPTO_SUCCESS:
    case c.CHANGE_BLOCK_WITHDRAW_FIAT_SUCCESS:
    case c.ON_DETECT_IP_SUCCESS:
    case c.OFF_DETECT_IP_SUCCESS:
    case c.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
      };
    }
    case c.SET_WITHDRAW_SETTINGS_FIELD: {

      if(action.payload.field === 'paymentMethod'){
        let maxLimit = '';

        if(action.payload.value === 'bitgo'){
          maxLimit = state.withdrawSettings.withdraw_limit;
        }
        else if(action.payload.value === 'advcash'){
          maxLimit = state.withdrawSettings.adv_cash_withdraw_limit;
        }
        else if(action.payload.value === 'clear_junction'){
          maxLimit = state.withdrawSettings.clear_junction_withdraw_limit;
        }
        else{
          maxLimit = state.withdrawSettings.default_withdraw_limit;
        }

        return {
          ...state,
          withdrawSettings: {
            ...state.withdrawSettings,
            [action.payload.field]: action.payload.value,
            maxLimit
          },
        };
      }
      else{
        return {
          ...state,
          withdrawSettings: {
            ...state.withdrawSettings,
            [action.payload.field]: action.payload.value,
          },
        };
      }

    }
    case c.SET_CURRENT_USER_FIELD: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    default: {
      return {
        ...state,
        users: action.payload,
      };
    }
  }
};
