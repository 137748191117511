import * as ac from "./actionCreators";
import api from "../../api";
import { validateNotEmpty } from "../../utils/validator";
import { InvalidFields } from "../../utils/errors";
import { notify } from "../../utils";
import { isEmpty } from 'lodash';

export function getMaintenance(maintenance_id) {
  return async (dispatch, getState) => {
    dispatch(ac.getMaintenance.pending());
    try {
      const {data} = await api.maintenances.getMaintenances();
      dispatch(ac.getMaintenance.success(data.find((item) => item.id === Number(maintenance_id))));
    } catch (err) {
      dispatch(ac.getMaintenance.error(err));
    }
  };
}

export function getMaintenances() {
  return async (dispatch, getState) => {
    dispatch(ac.getMaintenances.pending());
    try {
      const {data} = await api.maintenances.getMaintenances();
      dispatch(ac.getMaintenances.success(data));
    } catch (err) {
      dispatch(ac.getMaintenances.error(err));
    }
  };
}

export function addMaintenance(payload, status) {
  return async (dispatch, getState) => {
    dispatch(ac.addMaintenance.pending());

    const maintenance = payload.maintenance;

    try {
      let field_errors = {};
      if (!validateNotEmpty(maintenance.title))
        field_errors.title = "Field is required";
      if (!validateNotEmpty(maintenance.message))
        field_errors.message = "Field is required";

      if (!isEmpty(field_errors))
        throw new InvalidFields("Invalid form fields", { field_errors });

      const payloadData = {
        status,
        title: maintenance.title,
        message: maintenance.message
      };

      const formData = new FormData();
      for (const prop in payloadData) {
        formData.append(prop, payloadData[prop]);
      }

      const token = getState().Login.token;

      const data = await api.maintenances.addMaintenance(formData, token);

      dispatch(ac.addMaintenance.success(data));
    } catch (err) {
      dispatch(ac.addMaintenance.error(err));
    }
  };
}

export function editMaintenance(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.editMaintenance.pending());
    try {
      let field_errors = {};
      if (!validateNotEmpty(payload.title))
        field_errors.title = "Field is required";
      if (!validateNotEmpty(payload.message))
        field_errors.message = "Field is required";

      if (!isEmpty(field_errors))
        throw new InvalidFields("Invalid form fields", { field_errors });

      const payloadData = {
        id: payload.id,
        status: payload.status,
        title: payload.title,
        message: payload.message
      };

      const formData = new FormData();
      for (const prop in payloadData) {
        formData.append(prop, payloadData[prop]);
      }

      const token = getState().Login.token;
      const data = await api.maintenances.editMaintenance(formData, token);
      dispatch(ac.editMaintenance.success(data));
    } catch (err) {
      dispatch(ac.editMaintenance.error(err));
    }
  };
}

export function changeMaintenanceStatus(status, id, notificationRef) {
  return async (dispatch, getState) => {
    dispatch(ac.changeMaintenanceStatus.pending());
    try {
      const payloadData = {
        status: status,
        id,
      };

      const token = getState().Login.token;

      await api.maintenances.changeMaintenanceStatus(
        payloadData,
        token
      );
      const {data} = await api.maintenances.getMaintenances();
      dispatch(ac.changeMaintenanceStatus.success(data));

      notify("success", notificationRef, "Status updated");
    } catch (err) {
      notify("danger", notificationRef, err.message || "Something went wrong!");
      dispatch(ac.changeMaintenanceStatus.error(err));
    }
  };
}

export function setField(field, value) {
  return (dispatch) => dispatch(ac.setField({ field, value }));
}

export function setMaintenanceField(field, value) {
  return (dispatch) => dispatch(ac.setMaintenanceField({ field, value }));
}

export function setMaintenance(maintenance) {
  return (dispatch) => dispatch(ac.setMaintenance(maintenance));
}

export function resetForm() {
  return (dispatch) => dispatch(ac.resetForm());
}
