import {api} from '../config';
import { get, post, put } from '../utils/superagent';
import { assetValidToken } from '../utils/auth';

export const generateMissingWalletAddresses = (user_id, token) => assetValidToken(token).then(() => post(`${api}/admin/users/${user_id}/wallet`, {}, token));
export const getDepositAddresses = (user_id, token) => assetValidToken(token).then(() => get(`${api}/admin/users/${user_id}/wallet/addresses`, {}, token));
export const getWithdrawFiatFees = (token) => assetValidToken(token).then(() => get(`${api}/wallets/withdraw/fiat/fees`, {}, token));

export const getBlockWallet = (user_id, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/block/user/${user_id}`, {}, token)
  );

export const changeBlockDeposit = (value, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/block/all/deposit/${value ? 'on' : 'off'}`, {}, token)
  );

export const changeBlockWithdraw = (value, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/block/all/withdraw/${value ? 'on' : 'off'}`, {}, token)
  );

// force deposit confirmation by admin
export const forceConfirm = (deposit_id, token) => assetValidToken(token).then(() => put(`${api}/admin/deposits/${deposit_id}/confirm`, {confirmations: 100}, token));
export const createManualDeposit = (data,token) => assetValidToken(token).then(() => post(`${api}/admin/manual-deposits/create`, data, token));
export const createManualWithdrawal = (data,token) => assetValidToken(token).then(() => post(`${api}/admin/manual-withdrawals/create`, data, token));
export const getManualDepositsConfirmUserEmails = (token) => assetValidToken(token).then(() => get(`${api}/admin/manual-deposits/confirming-users`, {}, token));
export const confirmManualDeposit = (manualDepositId,token) => assetValidToken(token).then(() => put(`${api}/admin/manual-deposits/${manualDepositId}/confirm`, {}, token));
export const confirmManualWithdrawal = (manualWithdrawalId,token) => assetValidToken(token).then(() => put(`${api}/admin/manual-withdrawals/${manualWithdrawalId}/confirm`, {}, token));