import * as ac from './actionCreators';
import api from '../../api';
import { logoutOnUnauthorizedError } from "../../utils/auth";

export function getBlockWithdraw() {
  return async (dispatch, getState) => {
    dispatch(ac.getBlockWithdraw.pending());
    try {

      const {token, currentUser: {sub: userID}} = getState().Login;
      const {all} = await api.users.getBlockUser(userID, token);
      const block_withdraw = all.find((item) => item.feature === 'block_withdraw');
      let block_withdraw_value = false;
      if(block_withdraw){
        block_withdraw_value = block_withdraw.value === 'true' ? true: false;
      }

      dispatch(ac.getBlockWithdraw.success(block_withdraw_value));
    } catch (err) {
      dispatch(ac.getBlockWithdraw.error(err));
    }
  };
}

export function getWithdrawals(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getWithdrawals.pending());
    try {
      const payloadData = {
        status: payload.withdrawals_filter,
        query:  payload.withdrawals_query,
        page:   payload.page,
      }

      const {token} = getState().Login;
      const data = await api.assets.getAllWithdrawals(payloadData, token);
      dispatch(ac.getWithdrawals.success(data));
    } catch (err) {
      dispatch(ac.getWithdrawals.error(err));
    }
  };
}

export function getBlockDeposit() {
  return async (dispatch, getState) => {
    dispatch(ac.getBlockDeposit.pending());
    try {
      const {token, currentUser: {sub: userID}} = getState().Login;
      const {all} = await api.users.getBlockUser(userID, token);
      const block_deposit = all.find((item) => item.feature === 'block_deposit');
      let block_deposit_value = false;
      if(block_deposit){
        block_deposit_value = block_deposit.value === 'true' ? true: false;
      }

      dispatch(ac.getBlockDeposit.success(block_deposit_value));
    } catch (err) {
      dispatch(ac.getBlockDeposit.error(err));
    }
  };
}

export function getDeposits(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getDeposits.pending());
    try {
      const payloadData = {
        status: payload.deposits_filter,
        coin_symbol: payload.deposits_coin_filter,
        query:  payload.deposits_query,
        page:   payload.page,
      }

      const {token} = getState().Login;
      const data = await api.assets.getAllDeposits(payloadData, token);
      dispatch(ac.getDeposits.success(data));
    } catch (err) {
      dispatch(ac.getDeposits.error(err));
    }
  };
}

export function getManualDeposits(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getManualDeposits.pending());
    try {
      const payloadData = {
        status: payload.manual_deposits_status,
        page:   payload.page,
      }

      const token = getState().Login.token;
      const data = await api.assets.getManualDeposits(payloadData, token);

      dispatch(ac.getManualDeposits.success(data));
    } catch (err) {
      dispatch(ac.getManualDeposits.error(err));
    }
  };
}

export function getManualWithdrawals(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getManualWithdrawals.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.getManualWithdrawals(payload, token);

      dispatch(ac.getManualWithdrawals.success(data));
    } catch (err) {
      dispatch(ac.getManualWithdrawals.error(err));
    }
  };
}

export function getOperations(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getOperations.pending());
    try {
      const payloadData = {
        status: payload.operations_filter,
        query:  payload.operations_query,
        page:   payload.page,
      }

      const token = getState().Login.token;
      const data = await api.assets.getOperations(payloadData, token);
      dispatch(ac.getOperations.success(data));
    } catch (err) {
      dispatch(ac.getOperations.error(err));
    }
  };
}

export function changeBlockDeposit(value) {
  return async (dispatch, getState) => {
    dispatch(ac.changeBlockDeposit.pending());
    try {
      const token = getState().Login.token;
      const data = await api.wallets.changeBlockDeposit(value, token);
      dispatch(ac.changeBlockDeposit.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.changeBlockDeposit.error(err));
    }
  };
}

export function changeBlockWithdraw(value) {
  return async (dispatch, getState) => {
    dispatch(ac.changeBlockWithdraw.pending());
    try {
      const token = getState().Login.token;
      const data = await api.wallets.changeBlockWithdraw(value, token);
      dispatch(ac.changeBlockWithdraw.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.changeBlockWithdraw.error(err));
    }
  };
}

export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}

export function updateWithdraw(id) {
  return dispatch => dispatch(ac.updateWithdraw(id));
}
