import React from "react";
import { format } from 'date-fns';
import { filter, orderBy } from 'lodash';

import DarkTable from "../Table";

const FILTER_OPTIONS = [
  { label: 'Active users', value: true },
  { label: 'Inactive users', value: false },
];

export class KYBListOfBusinessMembers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilter: true,
      activeOrder: {
        orderBy: '',
        order: '',
      },
      filteredList: []
    }

    this.handleOrder = this.handleOrder.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
  }

  getFilteredList() {
    const { businessMembersList } = this.props;
    const { activeFilter, activeOrder } = this.state;

    const filterValue = activeFilter === 'false' ? false : true;
    const filterList = filter(businessMembersList, { IsActive: filterValue });
    const orderedList = orderBy(
      filterList,
      [activeOrder.orderBy],
      [activeOrder.order],
    );

    return orderedList.map((item) => ({ ...item, Timestamp: format(item.Timestamp, 'yyyy-MM-dd HH:mm:ss') }))
  }

  handleFilter(value) {
    this.setState({activeFilter: value});
  };

  handleOrder(orderFields) {
    this.setState(({ activeOrder, ...state }) => {
      let newActiveOrder;
      if (activeOrder.orderBy !== orderFields) {
        newActiveOrder = { orderBy: orderFields, order: 'desc' };
      } else {
        const order = activeOrder.order === '' ? 'desc' : activeOrder.order === 'desc' ? 'asc' : '';

        newActiveOrder = { ...activeOrder, order };
      }

      return ({
        ...state,
        activeOrder: newActiveOrder
      })
    })
  };

  render() {
    const { businessMembersList } = this.props;
    const { activeFilter, activeOrder } = this.state;

    const filteredList = this.getFilteredList();

    if (!businessMembersList.length) {
      return  <h4>No users yet</h4>;
    }

    return (
      <DarkTable
        inlineFilter={this.handleFilter}
        inlineFilterData={FILTER_OPTIONS}
        inlineFilterSelected={activeFilter}
        light
        orderSettings={{
          orderBy: activeOrder.orderBy,
          order: activeOrder.order,
          orderFunction: this.handleOrder,
          orderFields: ['BusinessRole', 'Timestamp'],
        }}
        columns={{
          Timestamp: 'Date',
          FirstName: 'First Name',
          LastName: 'Last Name',
          MiddleName: 'Middle Name',
          BusinessRole: 'Business Role',
        }}
        rows={filteredList}
      />
    )
  }
}