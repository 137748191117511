import * as ac from "./actionCreators";
import api from "../../api";

export function getInitialData() {
  return async (dispatch, getState) => {
    dispatch(ac.getInitialData.pending());
    try {
      const token = getState().Login.token;
      const prdxCirculation = await api.prdxCirculation.getPrdxCirculation(token);
      const totalPrdxForAllLines = await api.prdxCirculation.getTotalPrdxForAllLines(token);
      dispatch(ac.getInitialData.success({prdxCirculation,totalPrdxForAllLines}));
    } catch (err) {
      dispatch(ac.getInitialData.error(err));
    }
  };
}