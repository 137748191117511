import React from "react";
import history from '../../../history';

import {
  Container,
  Row,
  Col,
  Card, 
  CardHeader,
  CardBody,
  Form,
  Button
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import DarkTable from "../../../components/Table";
import DangerModal from "../../../components/Modal/danger.jsx";
import NotificationAlert from "react-notification-alert";
import { hasPermission } from '../../../utils';
import { renderABasicInput } from "../../../components/Inputs"
import SuccessModal from "../../../components/Modal/success.jsx";

class MarketsView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        cancelModal: false,
        currentMarket: "",
        blockModal: false,
        blockDangerModal: false
    }

  }
  
  componentDidMount() {
    if (!hasPermission('market.view')) {
      history.push('/error');
    } else {
      this.props.getVolumeDistributedPercent();
      this.props.getMarkets({page: 1});
    }
  }

  cancelMarketOrdersAlert = (event, row) => {
      event.preventDefault();
      this.toggleCancelModal(row.id);
  };

  cancelMarketOrders = (id) => { 
      this.props.cancelMarketOrders(id, this.refs.notificationAlert);
      this.toggleCancelModal(id);
  };

  toggleCancelModal = (id) => {
    this.setState({
        cancelModal: !this.state.cancelModal,
        currentMarket: id
    });
  };

  getMarketsActionsArray = () => {
    return [
      {name: 'Edit', onClick: this.editMarket, permission: 'market.edit'},
      {name: 'View orders', onClick: this.viewMarketOrders, permission: 'order.view.all'},
      {name: 'Cancel all orders', onClick: this.cancelMarketOrdersAlert, permission: 'order.cancel.all'},
      {name: 'Disable', onClick: this.disableMarket, permission: 'market.remove', changeNameIfNecessary: this.getStatusType},
    ].filter(item => hasPermission(item.permission))
  };

  onMarketsPagingClick = (ev, page) => { 
    this.props.getMarkets({...this.props, page: page});
  }

  searchMarkets = async (e) => {
    await this.props.setField('markets_query', e.target.value);
    this.props.getMarkets({...this.props, page: 1});
  }

  disableMarket = async (e, market) => {
    if (market.status === 'active'){
      await this.props.disableMarket(market.id);
    } else {
      await this.props.editMarket({...market, status: 'active'});
    }
    this.props.getMarkets({...this.props, page: 1});
  };

  getStatusType = (market)  => {
    return market.status == 'active' ? 'Disable' : 'Enable';
  };

  addMarket = () => {
    this.props.resetForm();
    history.push('/market');
  };


  editMarket = (e, market) => { 
    history.push('/market/'+market.id);
  };

  viewMarketOrders = (e, market) => {
    history.push('/orders-from-market/'+market.id);
  }

  renderCancelOrdersConfirmationModal = () => {
    const { currentMarket } = this.state;
    const body = `Are you sure you want to cancel all orders in market ` + currentMarket + ` ?`;
    return (
        <DangerModal
            isOpen={this.state.cancelModal}
            toggleFunction={() => this.toggleCancelModal(currentMarket)}
            closeFunction={() => this.toggleCancelModal(currentMarket)}
            nextFunction={() => this.cancelMarketOrders(currentMarket)}
            body={body}
        />
    )
  };

  renderMarketsSection = () => {
    let inlineActions = [];

    if(hasPermission("market.add")){
      inlineActions.push({
        text: "+ Add market",
        func: this.addMarket
      });
    }

    return <DarkTable
      tableTitle="Markets"
      inlineActions={inlineActions}
      actionsColumn={true}
      actions={this.getMarketsActionsArray()}
      inlineSearch={this.searchMarkets}
      columns={{
        name: "Name",
        status: "Status",
        market_precision_format: "Market prec. format",
        quote_precision_format: "Quote prec. format",
        min_market_volume: "Min Market vol",
        min_quote_volume: "Min Quote vol",
      }}
      rows={this.props.markets}
      meta={this.props.marketsMeta}
      onPagingClick={this.onMarketsPagingClick}
    />
  };

  toggleModal = () => {
      this.setState({
          blockModal: !this.state.blockModal,
      });
  };

  toggleDangerModal = () => {
      this.setState({
          blockDangerModal: !this.state.blockDangerModal,
      });
  };

  renderConfirmationModal = () => {
      const body = `Saved`;
      return (
          <SuccessModal
              isOpen={this.state.blockModal}
              toggleFunction={() => this.toggleModal()}
              closeFunction={() => this.toggleModal()}
              body={body}
          />
      )
  };

  renderDangerModal = () => {
      const body = `Error`;
      return (
          <DangerModal
              isOpen={this.state.blockDangerModal}
              toggleFunction={() => this.toggleDangerModal()}
              closeFunction={() => this.toggleDangerModal()}
              withoutConfirm
              body={body}
          />
      )
  };

  updateSettings = async (settings) => {
    await this.props.updateMarketSettings(settings);

    if (this.props.status.success) {
        this.toggleModal();
    }
    else if(this.props.status.error){
        this.toggleDangerModal();
    }
}

  render() { 
    const { settings, status } = this.props; 
    return (
      <>
      <SimpleHeader name="Manage Markets" parentName="Markets" />
      <Container className="mt--6" fluid>
        <Row className='mb-5'>
          <Col md="12">
            <Card className="user_content">
              <CardHeader>
                  <h3 className="mb-0">Market Settings</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="6">{renderABasicInput({object: settings, status, onChange: this.props.setSettingsField}, 'Market Maker % Volume, footer', 'volumeDistributedPercent', "number")}</Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Button color="success" type="button" className="float-right"
              disabled={this.state.submitDisabled}
              onClick={() => this.updateSettings(settings)}>
              Update settings
            </Button>
          </Col>
        </Row>
        {this.renderMarketsSection()}
        {this.renderCancelOrdersConfirmationModal()}
        {this.renderConfirmationModal()}
        {this.renderDangerModal()}
        <div className="rna-wrapper" ref="notifH">
            <NotificationAlert ref="notificationAlert" />
        </div>
      </Container>
      </>
    );
  }
}

export default MarketsView;