import React from 'react';

export default ({ className, page, count, limit }) => {
  if (count === 0) {
    return <div className={className}>No entries found</div>;
  }

  let pagesShownUpperLimit = Math.min(limit * page, count)
  return (
    <div className={className}>
      Showing {limit * (page - 1) + 1} to {pagesShownUpperLimit} of {count} entries
    </div>
  );
};
