import React from "react";
import history from '../../../history';
import LaunchpadProjectForm from "components/LaunchpadProjectForm";
import SuccessModal from "../../../components/Modal/success.jsx";
import {getLocalizedWithZeroOffsetDate} from '../../../utils';
import {InvalidFields} from "../../../utils/errors";
import DangerModal from "../../../components/Modal/danger.jsx";

import {
  Card,
  CardBody,
  Container,
  CardHeader,
  Button
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class LaunchpadDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false,
      blockDangerModal: false,
      isStarted: false
    }; 
  }

  componentDidMount() {
    const { match: {params: {project_id}} } = this.props;

    this.props.getCoins({status: 'presale'});
    if(project_id) this.props.getProject(project_id);
  }

  componentWillUnmount(){
    this.props.resetForm();
  }

  componentDidUpdate(prevProps){
    const { match: {params: {project_id}}, project } = this.props;

    if(project_id && !prevProps.project.id && project.id && getLocalizedWithZeroOffsetDate(project.start_date, project.timezone) < Date.now()){
      this.setState({
        isStarted: true
      });
    }
  }

  toggleModal = () => {
    this.setState({
        blockModal: !this.state.blockModal,
    });
  }

  toggleDangerModal = () => {
    this.setState({
        blockDangerModal: !this.state.blockDangerModal,
    });
  }

  renderConfirmationModal = () => {
    const response = "Saved!"
    return (
        <SuccessModal
            isOpen={this.state.blockModal}
            toggleFunction={() => {
              const { match: {params: {project_id}} } = this.props; 
              if(!project_id) history.push('/launchpad-projects');
              else this.toggleModal();
            }}
            closeFunction={() => {
              const { match: {params: {project_id}} } = this.props; 
              if(!project_id) history.push('/launchpad-projects');
              else this.toggleModal();
            }}
            body={response}
        />
    )
  };

  renderDangerModal = () => {
    const response = "Error!"
    return (
        <DangerModal
            isOpen={this.state.blockDangerModal}
            toggleFunction={() => this.toggleDangerModal()}
            closeFunction={() => this.toggleDangerModal()}
            body={response}
            withoutConfirm
        />
    )
  };

  cancel = () => {
    history.push('/launchpad-projects');
  }

  addProject = async () => {
    await this.props.addProject(this.props.project);
    if (this.props.status.success) {
      this.toggleModal();
    }else if(this.props.status.error){
      if(!(this.props.status.error instanceof InvalidFields)){
        this.toggleDangerModal();
      }
    }
  }

  editProject = async () => {
    await this.props.updateProject(this.props.project, this.state.isStarted);
    if (this.props.status.success) {
      this.toggleModal();
    }else if(this.props.status.error){
      if(!(this.props.status.error instanceof InvalidFields)){
        this.toggleDangerModal();
      }
    }
  }

  render() { 
    const { coins, project, status, setProjectField, match: {params: {project_id}} } = this.props; 
    const action_text = project_id ? "Edit launchpad project" : "Add a launchpad project";
    return (
        <>
        <SimpleHeader name={action_text} parentName="Manage Launchpad Projects"/>
        <Container className="mt--6 launchpad-project" fluid>
            <Card>
                <CardHeader>
                    <h3 className="mb-0">{action_text}</h3>
                </CardHeader>
                <CardBody>
                    <LaunchpadProjectForm coins={coins} object={project} status={status} onChange={setProjectField} isDisabled = {this.state.isStarted}/>
                    <Button color="info" type="button" className="float-right ml-3" onClick={this.cancel}>Cancel</Button>
                    <Button color="success" type="button" className="float-right" onClick={project_id ? this.editProject : this.addProject}>{action_text}</Button>
                    <br/>
                    <span className="error">
                      {status.error && status.error.data && status.error.data.error}
                    </span>
                </CardBody>
            </Card>
            {this.renderConfirmationModal()}
            {this.renderDangerModal()}
        </Container>
        </>
    );
  }
}

export default LaunchpadDetails;