import * as c from './constants';
import moment from 'moment';

const initialState = {
  events: [],
  orders: [],
  entries: [],
  selectedEvent: [],
  meta: {},
  status: { pending: false, success: false, error: false },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_DISTRIBUTION_EVENTS_PENDING:
    case c.GET_DISTRIBUTION_ORDERS_PENDING:
    case c.GET_DISTRIBUTION_ENTRIES_PENDING:
      return {
        ...state,
        status: {
          pending: true,
          success: false,
          error: false
        }
      };
    case c.GET_DISTRIBUTION_EVENTS_ERROR:
    case c.GET_DISTRIBUTION_ORDERS_ERROR:
    case c.GET_DISTRIBUTION_ENTRIES_ERROR:
      return {
        ...state,
        status: {
          pending: false,
          success: false,
          error: true
        }
      };
    case c.GET_DISTRIBUTION_EVENTS_SUCCESS: {
      const { Distributions, Meta } = action.payload;
      const data = Distributions.map(item => ({ ...item, day: moment(item.day * 1000).format('LLL') }));
      return {
        ...state,
        events: data,
        meta: Meta,
        status: {
          pending: false,
          success: true,
          error: false
        }
      }
    }
    case c.GET_DISTRIBUTION_ORDERS_SUCCESS: {
      const { DistributionOrders, Meta } = action.payload;
      const data = DistributionOrders.map(item => ({ ...item, created_at: moment(item.created_at * 1000).format('LLL') }));
      return {
        ...state,
        orders: data,
        meta: Meta,
        status: {
          pending: false,
          success: true,
          error: false
        }
      }
    }
    case c.GET_DISTRIBUTION_ENTRIES_SUCCESS: {
      const { Liabilities, Meta } = action.payload;
      const data = Liabilities.map(item => ({ ...item, created_at: moment(item.created_at * 1000).format('LLL'), user_id_link: '/user/' + item.user_id + '/distribution', }));
      return {
        ...state,
        entries: data,
        meta: Meta,
        status: {
          pending: false,
          success: true,
          error: false
        }
      }
    }
    case c.SELECT_DISTRIBUTION_EVENT:
      return {
        ...state,
        selectedEvent: action.payload,
      }
    default:
      return { ...state }
  }
}