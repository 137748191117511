import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import UsersDistribution from '../components/distribution.jsx';
import State from '../../../redux/Users';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getUserDistributions: State.actions.getUserDistributions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  status: State.selectors.status(state),
  distributions: State.selectors.distributions(state),
  distributionsmeta: State.selectors.distributionsmeta(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(UsersDistribution);

