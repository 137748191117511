import React from "react";
import {Container} from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import DarkTable from "../../../components/Table"
import DangerModal from "../../../components/Modal/danger.jsx"
import history from "../../../history"
import NotificationAlert from "react-notification-alert";
import { hasPermission, hasUserRole } from '../../../utils';
import { lineLevels } from '../../../constants';
import FileSaver from 'file-saver';
import moment from 'moment';
import { isEmpty } from "lodash";
import api from "../../../api";

export default class UsersView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            blockModal: false,
            cancelModal: false,
            currentUserId: -1,
            filter: null,
        };

        this.editUser = this.gotoPage.bind(this, "edit");
        this.openKyb = this.gotoPage.bind(this, "kyb");
        this.viewControlPanel = this.gotoPage.bind(this, "control-panel");
        this.viewActivity = this.gotoPage.bind(this, "activity");
        this.viewOrders = this.gotoPage.bind(this, "orders");
        this.viewTrades = this.gotoPage.bind(this, "trades");
        this.viewWallet = this.gotoPage.bind(this, "wallet");
        this.viewDistribution = this.gotoPage.bind(this, "distribution");
        this.viewReferrals = this.gotoPage.bind(this, "referrals");
        this.viewBonusContracts = this.gotoPage.bind(this, "bonus-contracts");
        this.viewBots = this.gotoPage.bind(this, "bots");
        this.viewWithdrawals = this.gotoPage.bind(this, "withdrawals");
        this.viewDeposits = this.gotoPage.bind(this, "deposits");
    }

    componentDidMount() {
        this.loadUsersList(this.props.match.params.pending_filter);
        this.props.getTotalUsersLineLevels();
    };

    setUserFilter = ( filter, event ) => {
        this.loadUsersList(filter);
        filter = filter ? "/" + filter : "";
        history.push(`/manage-users${filter}`);
    }

    loadUsersList = (filter) => {
        let actualFilter = null;
        if (filter) {
            actualFilter = this.getFilterArray().filter(item => item.value === filter); 
            this.setState(prevState => ({
                filter: actualFilter[0]
            }));
        }

        this.props.getAllUsers({page: 1, filter: actualFilter});
    };

    onPagingClick = (ev, page) => {
        this.props.getAllUsers({page: page});
    };

    searchUsers = async (e) => {
        await this.props.setField('users_query', e.target.value);
        this.props.getAllUsers({...this.props, page: 1});
    };

    cancelUserOrdersAlert = (event, row) => {
        event.preventDefault();
        this.toggleModal(row.id, "cancel");
    };

    cancelUserOrders = (id) => {
        this.props.cancelUserOrders(id, this.refs.notificationAlert);
        this.toggleModal(id, "cancel");
    };

    blockUserAlert = (event, row) => {
        event.preventDefault();
        this.toggleModal(row.id, "block");
    };

    blockUser = (userId) => { 
        this.props.block(userId, {status: 'blocked'}, this.refs.notificationAlert);
        this.toggleModal(userId, "block");
    };

    showDetails = (event, row) => {
        event.preventDefault();
        history.push(`user/${row.id}`);
    };

    gotoPage = (subpage, event, row) => {
        event.preventDefault();
        history.push(`user/${row.id}/${subpage}`);
    };

    getActionsArray = () => {
        return [
            {name: 'Show details', link: '/user/:user_id', onClick: this.showDetails, permission: 'user.view'},
            {name: 'Edit user', link: '/user/:user_id/edit', onClick: this.editUser, permission: 'user.edit'},
            {name: "KYB", link: "/user/:user_id/kyb", onClick: this.openKyb, permission: "user.edit", role: "business"},
            {name: 'Control Panel', link: '/user/:user_id/control-panel', onClick: this.viewControlPanel, permission: 'user.edit'},
            {name: 'View activity', link: '/user/:user_id/activity', onClick: this.viewActivity, permission: 'user.view'},
            {name: 'View orders', link: '/user/:user_id/orders', onClick: this.viewOrders, permission: 'order.view.all'},
            {name: 'View trades', link: '/user/:user_id/trades', onClick: this.viewTrades, permission: 'order.view.all'},
            {name: 'View withdrawals', link: '/user/:user_id/withdrawals', onClick: this.viewWithdrawals, permission: 'order.view.all'},
            {name: 'View deposits', link: '/user/:user_id/deposits', onClick: this.viewDeposits, permission: 'order.view.all'},
            {name: 'View wallet', link: '/user/:user_id/wallet', onClick: this.viewWallet,  permission: 'transaction.view.all'},
            {name: 'View referrals', link: '/user/:user_id/referrals', onClick: this.viewReferrals,  permission: 'referral.view'},
            {name: 'View bonus contracts', link: '/user/:user_id/bonus-contracts', onClick: this.viewBonusContracts,  permission: 'user.view'},
            {name: 'View bots', link: '/user/:user_id/bots', onClick: this.viewBots,  permission: 'user.view'},
            {name: 'PRDX distribution', link: '/user/:user_id/distribution', onClick: this.viewDistribution, permission: 'distribution.get'},
            {name: 'Cancel user orders', onClick: this.cancelUserOrdersAlert, permission: 'order.cancel.all'},
            {name: 'Block', onClick: this.blockUserAlert, permission: 'user.remove'},
        ].filter(item => (
            (item.link !== '/user/:user_id' && hasPermission(item.permission)) ||
            (item.link === '/user/:user_id' && !hasPermission('user.edit')))
          )
    };
 
    getFilterArray = () => {
        return [
            {label: 'All', value: ''},
            {label: 'Pending KYC', value: 'pending-kyc'},
            {label: 'Pending Deposit', value: 'pending-deposit'},
            {label: 'Pending Withdraw', value: 'pending-withdraw'},
        ]
    };

    toggleModal = (userId, type) => {
        this.setState({
            blockModal: type === "block" ? !this.state.blockModal : this.state.blockModal,
            cancelModal: type === "cancel" ? !this.state.cancelModal : this.state.cancelModal,
            currentUserId: userId,
        });
    };

    renderConfirmationModal = () => {
        const {currentUserId} = this.state;
        const body = `Are you sure you want to block this user?`;
        return (
            <DangerModal
                isOpen={this.state.blockModal}
                toggleFunction={() => this.toggleModal(currentUserId, "block")}
                closeFunction={() => this.toggleModal(currentUserId, "block")}
                nextFunction={() => this.blockUser(currentUserId)}
                body={body}
            />
        )
    };

    renderCancelOrdersConfirmationModal = () => {
        const {currentUserId} = this.state;
        const body = `Are you sure you want to cancel all orders this user has?`;
        return (
            <DangerModal
                isOpen={this.state.cancelModal}
                toggleFunction={() => this.toggleModal(currentUserId, "cancel")}
                closeFunction={() => this.toggleModal(currentUserId, "cancel")}
                nextFunction={() => this.cancelUserOrders(currentUserId)}
                body={body}
            />
        )
    };

    downloadUserEmails = async() => {
        try{
            const data = await api.users.getUserEmails(this.props.token);

            FileSaver.saveAs(
                new Blob([data], {type: "text/plain;charset=utf-8"}),
                `registered_user_emails_${moment().format('YYYY_MM_DD_HHmmss')}.txt`
            );
        }catch(err){
            console.error(err);
        }
    }

    getSumTotalUsersLineLevels = () => {
        return this.props.totalUsersLineLevels.reduce((total, totalUsersLineLevel) => total + Number(totalUsersLineLevel.count), 0)
    }

    filterActions = (item, row) => {
        return item.role ? hasUserRole(item.role, row): true
    }

    render() {
        let inlineActions = [];

        if(hasPermission("admin.view")){
          inlineActions.push({
            text: "Download user emails",
            func: this.downloadUserEmails
          });
        }

        return (
            <>
                <SimpleHeader name="Users" parentName="Manage Users"/>
                <Container className="mt--6" fluid>
                    <DarkTable
                        headerTitle={
                            <>
                            <div className='d-flex align-items-center'>
                                <div className='mr-3'>TOTAL USERS</div>
                                <div className='mr-2'>all lines: {this.getSumTotalUsersLineLevels() || 0}</div>
                                {!isEmpty(this.props.totalUsersLineLevels) && lineLevels.map((lineLevel) => (<div className='mr-2'>{`${lineLevel} line: ${this.props.totalUsersLineLevels.find((totalUserLineLevel) => totalUserLineLevel.user_level === lineLevel).count}`}</div>))}
                            </div>
                            </>
                        }
                        inlineActions={inlineActions}
                        tableTitle={this.state.filter ? "Users " + this.state.filter.label : "Users" }
                        inlineSearch={this.searchUsers}
                        inlineFilter={this.setUserFilter}
                        inlineFilterData={this.getFilterArray()}
                        inlineFilterSelected={this.state.filter && this.state.filter.value}
                        columns={{
                            name: "Name",
                            email: "Email",
                            role_alias: "Role",
                            status: "Status",
                            kyc: "KYC"
                        }}
                        actionsColumn={true}
                        actions={this.getActionsArray()}
                        rows={this.props.allUsers}
                        meta={this.props.meta}
                        onPagingClick={this.onPagingClick}
                        filterActions={this.filterActions}
                    />
                    {this.renderConfirmationModal()}
                    {this.renderCancelOrdersConfirmationModal()}
                    <div className="rna-wrapper">
                        <NotificationAlert ref="notificationAlert"/>
                    </div>
                </Container>
            </>
        );
    }
}

