import * as c from './constants';
import { asyncActionCreator, actionCreator } from '../../utils/redux';

export const getAnnouncement = asyncActionCreator(
  c.GET_ANNOUNCEMENT_PENDING,
  c.GET_ANNOUNCEMENT_SUCCESS,
  c.GET_ANNOUNCEMENT_ERROR,
);
export const getAnnouncements = asyncActionCreator(
  c.GET_ANNOUNCEMENTS_PENDING,
  c.GET_ANNOUNCEMENTS_SUCCESS,
  c.GET_ANNOUNCEMENTS_ERROR,
);
export const getAnnouncementsSettings = asyncActionCreator(
  c.GET_ANNOUNCEMENTS_SETTINGS_PENDING,
  c.GET_ANNOUNCEMENTS_SETTINGS_SUCCESS,
  c.GET_ANNOUNCEMENTS_SETTINGS_ERROR,
);
export const addAnnouncement = asyncActionCreator(
  c.ADD_ANNOUNCEMENT_PENDING,
  c.ADD_ANNOUNCEMENT_SUCCESS,
  c.ADD_ANNOUNCEMENT_ERROR,
);
export const editAnnouncement = asyncActionCreator(
  c.EDIT_ANNOUNCEMENT_PENDING,
  c.EDIT_ANNOUNCEMENT_SUCCESS,
  c.EDIT_ANNOUNCEMENT_ERROR,
);
export const addTopic = asyncActionCreator(
  c.ADD_TOPIC_PENDING,
  c.ADD_TOPIC_SUCCESS,
  c.ADD_TOPIC_ERROR,
);
export const editTopic = asyncActionCreator(
  c.EDIT_TOPIC_PENDING,
  c.EDIT_TOPIC_SUCCESS,
  c.EDIT_ANNOUNCEMENT_ERROR,
);
export const deleteTopic = asyncActionCreator(
  c.DELETE_TOPIC_PENDING,
  c.DELETE_TOPIC_SUCCESS,
  c.DELETE_TOPIC_ERROR,
);
export const changeAnnouncementStatus = asyncActionCreator(
  c.CHANGE_ANNOUNCEMENT_STATUS_PENDING,
  c.CHANGE_ANNOUNCEMENT_STATUS_SUCCESS,
  c.CHANGE_ANNOUNCEMENT_STATUS_ERROR,
);

export const setField = actionCreator(c.SET_FIELD);
export const setAnnouncementField = actionCreator(c.SET_ANNOUNCEMENT_FIELD);
export const setAnnouncement = actionCreator(c.SET_ANNOUNCEMENT);
export const resetForm = actionCreator(c.RESET_ANNOUNCEMENT_STATE);
export const setTopicField = actionCreator(c.SET_TOPIC_FIELD);
