const module = 'PROFILE';

export const GET_PROFILE_PENDING = `${module}/GET_PROFILE_PENDING`;
export const GET_PROFILE_SUCCESS = `${module}/GET_PROFILE_SUCCESS`;
export const GET_PROFILE_ERROR = `${module}/GET_PROFILE_ERROR`;

export const SET_PROFILE_PENDING = `${module}/SET_PROFILE_PENDING`;
export const SET_PROFILE_SUCCESS = `${module}/SET_PROFILE_SUCCESS`;
export const SET_PROFILE_ERROR = `${module}/SET_PROFILE_ERROR`;

export const SET_PASSWORD_PENDING = `${module}/SET_PASSWORD_PENDING`;
export const SET_PASSWORD_SUCCESS = `${module}/SET_PASSWORD_SUCCESS`;
export const SET_PASSWORD_ERROR = `${module}/SET_PASSWORD_ERROR`;

export const SET_CURRENT_USER_FIELD = `${module}/SET_CURRENT_USER_FIELD`;
export const SET_FIELD = `${module}/SET_FIELD`;
