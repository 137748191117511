import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import UsersWallet from '../components/wallet.jsx';
import State from '../../../redux/Users';
import CoinsAndChainsState from '../../../redux/CoinsAndChains';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getUserWallet: State.actions.getUserWallet,
      getDepositAddresses: State.actions.getDepositAddresses,
      getCoins: CoinsAndChainsState.actions.getCoins,
      setField: State.actions.setField,
    },
    dispatch
  );

const mapStateToProps = state => ({
  status: State.selectors.status(state),
  coins: CoinsAndChainsState.selectors.coins(state),
  wallet: State.selectors.wallet(state),
  addresses: State.selectors.addresses(state),
  wallet_query_bonus: State.selectors.wallet_query_bonus(state),
  wallet_query_main: State.selectors.wallet_query_main(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(UsersWallet);

