import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { withRouter } from "react-router";
import moment from "moment";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  Button,
} from "reactstrap";

import DarkTable from "../Table";

import api from "../../api";

const BotAnalyticsModal = ({ bot, onClose, token, match }) => {
  let userId = null;
  if(match.params.user_id) userId = match.params.user_id;
  else if(bot && bot.user_id) userId = bot.user_id;

  const [activeTab, setActiveTab] = useState(0);
  const [funds, setFunds] = useState();
  const [botAnalytics, setBotAnalytics] = useState([]);
  const [sellOpenOrders, setSellOpenOrders] = useState([]);
  const [buyOpenOrders, setBuyOpenOrders] = useState([]);
  const [botNumbers, setBotNumbers] = useState();

  const getFunds = async () => {
    const data = await api.users.getUserWallet(
      userId,
      {
        account: bot.sub_account,
      },
      token
    );
    
    const balancesSource = data.find((item) => item.sub_account_id == bot.sub_account);
    if(balancesSource && balancesSource.balances)
      setFunds(
        Object.values(balancesSource.balances).map((balance) => ({
          ...balance,
          symbol: balance.symbol.toUpperCase(),
        }))
      );
  };

  const getBotNumbers = async () => {
    try {
      const { data } = await api.users.getBotNumbers(
        userId,
        bot.id,
        token
      );

      setBotNumbers(data);
    } catch (error) {}
  };

  const getBotOpenOrders = async () => {
    try {
      const { Orders } = await api.users.getUserOrders(
        userId,
        {
          status: "open",
          account: bot.sub_account,
          since: 0,
          page: 1,
          limit: 1000,
        },
        token
      );

      const mappedData = Orders.sort(
        (d1, d2) => Number(d2.price) - Number(d1.price)
      );

      const filteredSellOpenOrders = mappedData.filter(
        (d) => d.side === "sell"
      );

      const filteredBuyOpenOrders = mappedData.filter((d) => d.side === "buy");

      setSellOpenOrders(filteredSellOpenOrders);
      setBuyOpenOrders(filteredBuyOpenOrders);
    } catch (error) {}
  };

  const getBotAnalytics = async () => {
    try {
      const { data } = await api.users.getBotAnalytics(
        userId,
        bot.id,
        bot.selectedVersion.id,
        token
      );

      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject([]);
    }
  };

  const getBotOrderHistory = async (botAnalyticsData) => {
    try {
      const { Orders } = await api.users.getUserOrders(
        userId,
        {
          status: "filled",
          account: bot.sub_account,
          since: 0,
          page: 1,
          limit: 1000,
        },
        token
      );

      const mappedBotAnalytics = botAnalyticsData
        .filter((item) => item.version === bot.selectedVersion.id)
        .map((item, index) => {
          const isCompleted = item.orders.length > 1;

          return {
            created_at: moment(item.created_at).format("LLL"),
            id: botAnalyticsData.length - index,
            version: item.version,
            profit: `${item.profit} (${
              item.profit_percent * 100
            }%) ${item.profit_coin.toUpperCase()}`,
            status: isCompleted ? "Completed" : "Uncompleted",
            details: (
              <table className="w-100">
                <thead>
                  <tr>
                    <td>Date</td>
                    <td>Side</td>
                    <td>Amount</td>
                    <td>Price</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  {item.orders.reduce((all, orderId) => {
                    const orderDetails = Orders.find((d) => d.id === orderId);

                    if (orderDetails) {
                      return [
                        ...all,
                        <tr>
                          <td>
                            {moment(orderDetails.created_at).format("LLL")}
                          </td>
                          <td>
                            <span
                              className={
                                orderDetails.side === "buy" ? "buy" : "sell"
                              }
                            >
                              {orderDetails.side}
                            </span>
                          </td>
                          <td>{orderDetails.amount}</td>
                          <td>{orderDetails.price}</td>
                          <td>{orderDetails.amount * orderDetails.price}</td>
                        </tr>,
                      ];
                    }

                    return all;
                  }, [])}
                </tbody>
              </table>
            ),
          };
        });

      setBotAnalytics(mappedBotAnalytics);
    } catch (error) {}
  };

  useEffect(() => {
    if (bot && bot.modal === "analytics") {
      getBotAnalytics().then((botAnalyticsData) => {
        getBotOrderHistory(botAnalyticsData);
      });

      getBotNumbers();
      getBotOpenOrders();
      getFunds();
    } else {
      setActiveTab(0);
      setBotAnalytics([]);
      setSellOpenOrders([]);
      setBuyOpenOrders([]);
      setBotNumbers();
    }
  }, [bot]);

  if (!bot || bot.modal !== "analytics") {
    return null;
  }

  return (
    <Modal
      className="analytics-modal modal-dialog-centered modal-lg"
      isOpen
      toggle={onClose}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          ANALYTICS
        </h6>

        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-body">
        <div className="py-3">
          <div className="row">
            <div className="col-6">
              <span className="font-weight-normal">
                Price Range:{" "}
                <b>{`${bot.selectedVersion.settings.minPrice} - ${bot.selectedVersion.settings.maxPrice}`}</b>
              </span>
            </div>
            <div className="col-6">
              <span className="font-weight-normal">
                Constant Open Orders:{" "}
                <b>{bot.selectedVersion.settings.noOfGrids}</b>
              </span>
            </div>
            <div className="col-6">
              <span className="font-weight-normal">
                Profit: <b>{bot.selectedVersion.settings.profitPercent}%</b>
              </span>
            </div>
            <div className="col-6">
              <span className="font-weight-normal">
                Total Amount:{" "}
                <b>
                  {bot.selectedVersion.settings.amount}{" "}
                  {bot.selectedVersion.settings.coin.toUpperCase()}
                </b>
              </span>
            </div>

            <div className="col-12 d-flex mt-3">
              <div className="mr-2">
                <span className="font-weight-normal">
                  Buy: <b>{buyOpenOrders.length}</b>
                </span>
              </div>

              <div className="mx-2">
                <span className="font-weight-normal">
                  Sell: <b>{sellOpenOrders.length}</b>
                </span>
              </div>
            </div>

            <div className="col-12 d-flex my-3">
              <div className="mr-2">
                <span className="font-weight-normal">Filled Orders:</span>
              </div>

              <div className="mx-2">
                <span className="font-weight-normal">
                  Day: <b>{botNumbers ? botNumbers.day : "-"}</b>
                </span>
              </div>

              <div className="mx-2">
                <span className="font-weight-normal">
                  Week: <b>{botNumbers ? botNumbers.week : "-"}</b>
                </span>
              </div>

              <div className="mx-2">
                <span className="font-weight-normal">
                  Month: <b>{botNumbers ? botNumbers.month : "-"}</b>
                </span>
              </div>

              <div className="ml-2">
                <span className="font-weight-normal">
                  Total: <b>{botNumbers ? botNumbers.total : "-"}</b>
                </span>
              </div>
            </div>
          </div>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 0 })}
                onClick={() => setActiveTab(0)}
              >
                Open Orders
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 1 })}
                onClick={() => setActiveTab(1)}
              >
                Completed
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 2 })}
                onClick={() => setActiveTab(2)}
              >
                Funds
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={0}>
              <div className="row">
                <div className="col-6">
                  <DarkTable
                    light
                    className="buy"
                    columns={{
                      id: "Order ID",
                      amount: "Amount",
                      price: "Price",
                    }}
                    rows={buyOpenOrders}
                  />
                </div>

                <div className="col-6">
                  <DarkTable
                    light
                    className="sell"
                    columns={{
                      id: "Order ID",
                      amount: "Amount",
                      price: "Price",
                    }}
                    rows={sellOpenOrders}
                  />
                </div>
              </div>
            </TabPane>

            <TabPane tabId={1}>
              <div className="row">
                <div className="col-12">
                  <DarkTable
                    light
                    detailsColumn
                    columns={{
                      id: "No.",
                      created_at: "Date",
                      version: "Version",
                      profit: "Profit",
                      status: "Status",
                    }}
                    rows={botAnalytics}
                  />
                </div>
              </div>
            </TabPane>

            <TabPane tabId={2}>
              <div className="row">
                <div className="col-12">
                  <DarkTable
                    light
                    columns={{
                      symbol: "Coin",
                      total_balance: "Total Balance",
                      available: "Available Balance",
                      in_orders: "In Orders",
                    }}
                    rows={funds}
                  />
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>

      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default withRouter(BotAnalyticsModal);
