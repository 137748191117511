import { pickBy } from 'lodash';

export const isArrayField = (value) => Array.isArray(value);

export const isObjectField = (value) =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

export const isPrimitiveValue = (value) =>
  !(typeof value == 'object' || typeof value == 'function') || value === null;

export const getBasicField = (data) => pickBy(data, isPrimitiveValue);

export const getRestFields = (data) =>
  pickBy(data, (value) => isObjectField(value) || isArrayField(value));

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
