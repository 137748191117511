import React from "react";
import {Col, Form, Row} from "reactstrap";
import { renderABasicInput } from "../Inputs"

import "react-datepicker/dist/react-datepicker.css";

export default class PasswordView extends React.Component {

  render() {
    const { hideCurrentPassword } = this.props;
    return (
      <Form autoComplete="form-values">
        <Row>
          <Col md="12"><hr /></Col>
        </Row>
        {hideCurrentPassword ? null :
          <Row>
            <Col md="6">{renderABasicInput(this.props, "Current Password", "current_password", "password")}</Col>
          </Row>
        }
        <Row>
          <Col md="6">{renderABasicInput(this.props, "New Password", "password", "password")}</Col>
          <Col md="6">{renderABasicInput(this.props, "Confirm Password", "confirm_password", "password")}</Col>
        </Row>
      </Form>
    );
  }
}

