import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Settings from '../components/index';
import SettingsState from '../../../redux/Settings';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getSettingsInitialData: SettingsState.actions.getSettingsInitialData,
            updateSettings: SettingsState.actions.updateSettings,
            setSettingsField: SettingsState.actions.setSettingsField,
        },
        dispatch
    );

const mapStateToProps = state => ({
    settings: SettingsState.selectors.settings(state),
    status: SettingsState.selectors.status(state),
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Settings);

