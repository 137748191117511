import React from "react";
import classnames from "classnames";
import Chart from "chart.js";
import {Line} from "react-chartjs-2";
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Container,
  Col,
  CardHeader,
  Table,
  Row,
  // Input
} from "reactstrap";

import CardsHeader from "components/Headers/CardsHeader.jsx";
import UserCardsHeader from "../../../components/Headers/CardUsers"
import DarkTable from "../../../components/Table";
import Can from "../../../components/Permissions";
import {
  chartOptions,
  parseOptions,
  chartData,
} from "./charts.jsx";
import { hasPermission } from '../../../utils'

class Dashboard extends React.Component {
  generalTicker = null;
  chartPerDayTicker = null;

  constructor() {
    super();
    this.state = {
      activeNav: 'monthly',
      withdrawPage: 1,
      fromUsersPerLevelDate: null,
      toUsersPerLevelDate: null,
      fromChartDate: null,
      toChartDate: null,
      chartDateFilterError: '',
      usersPerLevelDateFilterError: '',
      currentChart: 'trades',
      showChartFeesBot: true,
      showChartFeesManual: true,
      // chartFeesCoin: ''
    }
  };

  generalTick = () => {
    this.props.getBotsStatistics();
    this.props.getGeneralStatistics({});
    this.props.getUsersInfo();
    
    const {fromUsersPerLevelDate,toUsersPerLevelDate} = this.state;
    const usersPerLevelData = {};

    if(fromUsersPerLevelDate) usersPerLevelData.fromDate = moment(fromUsersPerLevelDate).unix();
    if(toUsersPerLevelDate) usersPerLevelData.toDate = moment(toUsersPerLevelDate).unix();
    this.props.getUsersPerLevel(usersPerLevelData);

    const coinStatisticsData = {};
    this.props.getCoinStatistics(coinStatisticsData);

    this.props.getMarkets({page: 1, limit: 1});
    this.props.getWithdrawals({page: this.state.withdrawPage});
  }

  chartPerDayTick = (currentChart) =>{
    const {activeNav: type, fromChartDate: fromDate, toChartDate: toDate} = this.state;
    const data = {};
    if(type) data.type = type;
    if(fromDate) {
      const tzMins = fromDate.getTimezoneOffset();
      const dateWithoutTz = new Date(fromDate.getTime() - tzMins*60000);

      data.fromDate = moment(dateWithoutTz).unix();
    }
    if(toDate) {
      const tzMins = toDate.getTimezoneOffset();
      const dateWithoutTz = new Date(toDate.getTime() - tzMins*60000);

      data.toDate = moment(dateWithoutTz).unix();
    }

    if(currentChart === 'trades'){
      this.props.getTradesPerDay(data);
    }else if(currentChart === 'fees'){
      this.props.getFeesPerDay(data);
    }
  }

  componentDidMount() {
    // this.props.getCoins();
    this.generalTick();
    this.chartPerDayTick(this.state.currentChart);

    this.generalTicker = setInterval(this.generalTick, 10000);
    this.chartPerDayTicker = setInterval(() => this.chartPerDayTick(this.state.currentChart), 30000);
  }

  componentWillMount() {
    if (window.Chart && hasPermission('transaction.view.all')) {
      parseOptions(Chart, chartOptions());
    }
  }

  componentWillUnmount(){
    if(this.generalTicker){
      clearInterval(this.generalTicker);
    }
    if(this.chartPerDayTicker){
      clearInterval(this.chartPerDayTicker);
    }
  }

  toggleNavs = async (e, type) => { 
    e.preventDefault();
    this.setState({
      fromChartDate: null,
      toChartDate: null,
      chartDateFilterError: '',
      activeNav: type
    });

    if(this.state.currentChart === 'trades')
      this.props.getTradesPerDay({type});
    else if(this.state.currentChart === 'fees')
      this.props.getFeesPerDay({type});
  };

  onFromChartDatePickerChange = (date) => {
    const {toChartDate} = this.state;
    let data = {};

    // if(!toChartDate){
    //   let diffInTime = new Date().getTime() - date.getTime();
    //   let diffInDays = diffInTime / (1000 * 3600 * 24);
      
    //   if(diffInDays > 90){
    //     this.setState({chartDateFilterError: 'The diff between dates should be less than 3 months'});
    //     return;
    //   }
    // }
    if(toChartDate){
      let diffInTime = toChartDate.getTime() - date.getTime();
      let diffInDays = diffInTime / (1000 * 3600 * 24);
      
      if(diffInDays < 0){
        this.setState({chartDateFilterError: 'Date from cant be more than date to'});
        return;
      }
      // if(diffInDays > 90){
      //   this.setState({chartDateFilterError: 'The diff between dates should be less than 3 months'});
      //   return;
      // }
    }

    this.setState({fromChartDate: date, chartDateFilterError: '', activeNav: null});
    let tzMins = date.getTimezoneOffset();
    let dateWithoutTz = new Date(date.getTime() - tzMins*60000);
    data.fromDate = moment(dateWithoutTz).unix();

    if(toChartDate){
      tzMins = toChartDate.getTimezoneOffset();
      dateWithoutTz = new Date(toChartDate.getTime() - tzMins*60000);
      data.toDate = moment(dateWithoutTz).unix();
    }

    if(this.state.currentChart === 'trades')
      this.props.getTradesPerDay(data);
    else if(this.state.currentChart === 'fees')
      this.props.getFeesPerDay(data);
  }

  onToChartDatePickerChange = (date) => {
    const {fromChartDate} = this.state;
    let data = {};

    if(!fromChartDate){
      this.setState({chartDateFilterError: 'The date from should be set'});
      return;
    }
    else{
      let diffInTime = date.getTime() - fromChartDate.getTime();
      let diffInDays = diffInTime / (1000 * 3600 * 24);
      
      if(diffInDays < 0){
        this.setState({chartDateFilterError: 'Date to cant be less than date from'});
        return;
      }
      // if(diffInDays > 90){
      //   this.setState({chartDateFilterError: 'The diff between dates should be less than 3 months'});
      //   return;
      // }
    }

    this.setState({toChartDate: date, chartDateFilterError: '', activeNav: null});
    let tzMins = date.getTimezoneOffset();
    let dateWithoutTz = new Date(date.getTime() - tzMins*60000);
    data.toDate = moment(dateWithoutTz).unix();

    if(fromChartDate){
      tzMins = fromChartDate.getTimezoneOffset();
      dateWithoutTz = new Date(fromChartDate.getTime() - tzMins*60000);
      data.fromDate = moment(dateWithoutTz).unix();
    }

    if(this.state.currentChart === 'trades')
      this.props.getTradesPerDay(data);
    else if(this.state.currentChart === 'fees')
      this.props.getFeesPerDay(data);
  }

  onFromUsersPerLevelDatePickerChange = (date) => {
    const {toUsersPerLevelDate} = this.state;

    if(toUsersPerLevelDate){
      let diffInTime = toUsersPerLevelDate.getTime() - date.getTime();
      let diffInDays = diffInTime / (1000 * 3600 * 24);
      
      if(diffInDays < 0){
        this.setState({usersPerLevelDateFilterError: 'Date from cant be more than date to'});
        return;
      }
    }

    this.setState({fromUsersPerLevelDate: date, usersPerLevelDateFilterError: ''});

    const usersPerLevelData = {fromDate: moment(date).unix()};
    if(toUsersPerLevelDate) usersPerLevelData.toDate = moment(toUsersPerLevelDate).unix();
    this.props.getUsersPerLevel(usersPerLevelData);
  }

  onToUsersPerLevelDatePickerChange = (date) => {
    const {fromUsersPerLevelDate} = this.state;

    if(fromUsersPerLevelDate){
      let diffInTime = date.getTime() - fromUsersPerLevelDate.getTime();
      let diffInDays = diffInTime / (1000 * 3600 * 24);
      
      if(diffInDays < 0){
        this.setState({usersPerLevelDateFilterError: 'Date to cant be less than date from'});
        return;
      }
    }

    this.setState({toUsersPerLevelDate: date, usersPerLevelDateFilterError: ''});

    const usersPerLevelData = {toDate: moment(date).unix()};
    if(fromUsersPerLevelDate) usersPerLevelData.fromDate = moment(fromUsersPerLevelDate).unix();
    this.props.getUsersPerLevel(usersPerLevelData);
  }

  onWithdrawalsPagingClick = async (ev, page) => {
    this.setState({
      withdrawPage: page
    });
    this.props.getWithdrawals({page});
  }

  renderUsersPerLevelSection = () => { 
    const { data } = this.props;
    const { users_count, users_total } = data;
    const sectionWidth = hasPermission('withdraw.view.all') ? 4 : 12;

    return (
      <Can run="user.view">
        <Col xl={sectionWidth}>
          <Col xl="12" className="users-per-level-section bg-default no-scrollbar">
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                  <div className='d-flex'>
                    <div className="mb-0 text-light">Users per level</div>
                    <div className='dashboard-users-per-level-date-filter-error'>
                      {this.state.usersPerLevelDateFilterError}
                    </div>
                    <div className='ml-auto d-flex'>
                      <DatePicker
                        placeholderText="Date from"
                        maxDate={moment().toDate()}
                        popperPlacement='bottom-end'
                        selected={this.state.fromUsersPerLevelDate}
                        onChange={(date) => {
                          this.onFromUsersPerLevelDatePickerChange(date);
                        }}
                      />
                      <DatePicker
                        placeholderText="Date to"
                        maxDate={moment().toDate()}
                        popperPlacement='bottom-end'
                        selected={this.state.toUsersPerLevelDate}
                        onChange={(date) => {
                          this.onToUsersPerLevelDatePickerChange(date);
                        }}
                      />
                    </div>
                  </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive dark>
                <thead className="thead-dark">
                <tr>
                  <th scope="col"> Level</th>
                  <th scope="col">Users</th>
                  <th scope="col"/>
                </tr>
                </thead>
                <tbody>
                { users_count ? <>
                {this.renderDataWithPercentage("Level 0", users_count.level0 || 0, users_total)}
                {this.renderDataWithPercentage("Level 1", users_count.level1 || 0, users_total)}
                {this.renderDataWithPercentage("Level 2", users_count.level2 || 0, users_total)}
                {this.renderDataWithPercentage("Level 3", users_count.level3 || 0, users_total)}
                </> : null }
                </tbody>
              </Table>
            </Card>
          </Col>
        </Col>
     </Can>
    )
  };

  formatChartTradesData = () => {
    const {chartValues} = this.props;

    return {
      labels: chartValues.labels,
      datasets: [
        {
          label: 'Trades per day',
          data: chartValues.values
        }
      ]
    };
  }

  formatChartFeesData = () => {
    const {chartFeesBotValues, chartFeesManualValues} = this.props;
    const {showChartFeesBot,showChartFeesManual} = this.state;
    const datasets = [];

    if(showChartFeesBot){
      datasets.push({
        label: 'Bot fees per day',
        data: chartFeesBotValues.values,
        borderColor: 'red',
        pointBorderColor: '#fff',
        pointBackgroundColor: "red",
      });
    }
    if(showChartFeesManual){
      datasets.push({
        label: 'Manual fees per day',
        data: chartFeesManualValues.values,
        borderColor: 'orange',
        pointBorderColor: '#fff',
        pointBackgroundColor: "orange",
      });
    }

    return {
      labels: chartFeesBotValues.labels,
      datasets
    };
  }

  onChangeChart =  (selectedChart) => {
    if(this.state.currentChart !== selectedChart){
      this.chartPerDayTick(selectedChart);

      this.setState({currentChart: selectedChart})
    }
  }

  // getCoinOptions = () => {
  //   const options = this.props.simpleCoins.map((item) => {
  //     return {label: item.symbol, value: item.symbol};
  //   });

  //   options.unshift({label: 'All', value: ''});

  //   return options;
  // }


  renderNrOfTradesPerDaySection = () => { 
    const { currentChart } = this.state;
    
    return (
      <Can run="transaction.view.all">
      <Col xl="12">
        <Card className="bg-default">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-light text-uppercase ls-1 mb-1">
                  Overview
                </h6>
                <div className='d-flex align-items-center justify-content-start'>
                  <input
                    className="mr-2"
                    type="radio"
                    checked={this.state.currentChart === "trades"}
                    onChange={() => this.onChangeChart('trades')}
                  />
                  <div className="h3 text-white mb-0 mr-3">Trades per day</div>
                  <input
                    className="mr-2"
                    type="radio"
                    checked={this.state.currentChart === "fees"}
                    onChange={() => this.onChangeChart('fees')}
                  />
                  <div className="h3 text-white mb-0">Fees per day</div>
                </div>
                <div className='chart-visibility-options-row d-flex align-items-center justify-content-start'>
                {this.state.currentChart === "fees" && (
                  <>
                  <input
                    className="mr-2"
                    type="checkbox"
                    checked={this.state.showChartFeesBot === true}
                    onChange={() => this.setState((prevState) => {
                      return {
                        ...prevState,
                        showChartFeesBot: !prevState.showChartFeesBot
                      }
                    })}
                  />
                  <div className="h3 text-white mb-0 mr-3">Bot fees</div>
                  <input
                    className="mr-2"
                    type="checkbox"
                    checked={this.state.showChartFeesManual === true}
                    onChange={() => this.setState((prevState) => {
                      return {
                        ...prevState,
                        showChartFeesManual: !prevState.showChartFeesManual
                      }
                    })}
                  />
                  <div className="h3 text-white mb-0 mr-3">Manual fees</div>
                  {/* <Input
                    className="mr-2"
                    type="select" style={{'height':'30px','width':'100px','padding':'5px'}}
                    value={this.state.chartFeesCoin}
                    onChange={(e) => this.setState({chartFeesCoin: e.target.value})}
                  >
                    {this.getCoinOptions().map((option) => (
                      <option key={option.value || option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                  <div className="h3 text-white mb-0 mr-3">Coin</div> */}
                  </>)
                }
                </div>
              </div>
              <div className="col">
                <Nav className="justify-content-end" pills>
                  <div className='dashboard-chart-date-filter-error'>
                    {this.state.chartDateFilterError}
                  </div>
                  <NavItem className="mr-2 mr-md-0 d-flex align-items-center">
                    <DatePicker
                      placeholderText="Date from"
                      maxDate={moment().toDate()}
                      selected={this.state.fromChartDate}
                      onChange={(date) => {
                        this.onFromChartDatePickerChange(date);
                      }}
                    />
                  </NavItem>
                  <NavItem className="mr-2 mr-md-0 d-flex align-items-center">
                    <DatePicker
                      placeholderText="Date to"
                      maxDate={moment().toDate()}
                      selected={this.state.toChartDate}
                      onChange={(date) => {
                        this.onToChartDatePickerChange(date);
                      }}
                    />
                  </NavItem>
                  <NavItem className="mr-2 mr-md-0">
                    <NavLink
                      className={classnames("py-2 px-3", {
                        active: this.state.activeNav === 'monthly'
                      })}
                      onClick={e => this.toggleNavs(e, "monthly")}
                    >
                      <span className="d-none d-md-block">Month</span>
                      <span className="d-md-none">M</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("py-2 px-3", {
                        active: this.state.activeNav === 'weekly'
                      })}
                      data-toggle="tab"
                      onClick={e => this.toggleNavs(e, "weekly")}
                    >
                      <span className="d-none d-md-block">Week</span>
                      <span className="d-md-none">W</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="chart">
              {currentChart === 'trades' && (
                <Line
                  height={500}
                  data={this.formatChartTradesData()}
                  options={chartData.options}
                  // id="chart-sales-dark"
                  // className="chart-canvas"
                />
              )}
              {currentChart === 'fees' && (
                <>
                  <Line
                    height={500}
                    data={this.formatChartFeesData()}
                    options={chartData.options}
                  />
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      </Can>
    )
  };

  getPercentColorByValue = (value) => {
    if (value < 30) {
      return "gradient-danger"
    } else if (value < 70) {
      return "gradient-warning"
    }
    return "gradient-success"
  };

  renderDataWithPercentage = (name, value, total) => { 
    const percent = total ? (100 * value / total).toFixed() : 0
    return (
      <tr key={name.replace(' ', '_')} index={name}>
        <th scope="row">{name}</th>
        <td>{value}</td>
        <td>
          <div className="d-flex align-items-center">
            <span className="mr-2">{percent}%</span>
            <div>
              <Progress
                max="100"
                value={percent}
                color={this.getPercentColorByValue(percent)}
              />
            </div>
          </div>
        </td>
      </tr>
    )
  };

  renderWithdrawRequestsSection = () => {
    const sectionWidth = hasPermission('user.view') ? 8 : 12;

    return <Can run="withdraw.view.all">
      <Col xl={sectionWidth}>
        <Col xl="12" className="pending-withdraw-requests bg-default">
          <DarkTable
            tableTitle="Pending Withdraw Requests"
            actionsColumn={false}
            columns={{
              user: "User",
              amount: "Amount",
              coin_symbol: "Coin",
              address: "Address",
              created_at: "Created At",
              status: "Status",
            }}
            rows={this.props.pending_withdrawals}
            meta={this.props.meta}
            onPagingClick={this.onWithdrawalsPagingClick}
          />
        </Col>
      </Col>
    </Can>;
  };

  renderAmountsSection = () => {
    const { coins } = this.props;
    const newCoins = coins.map((el) => {
      const total = this.props.total_profit / el.btc_value;
      const percent = total ? (100 * el.profit / total).toFixed() : 0

      return {
        ...el,
        profit_progress_bar:  (
        <div className="d-flex align-items-center">
          <span className="mr-2">{percent}%</span>
          <div>
            <Progress
              max="100"
              value={percent}
              color={this.getPercentColorByValue(percent)}
            />
          </div>
        </div>)
      };
    });

    return <Can run="coin.view">
      <DarkTable
        tableTitle="Amounts"
        columns={{
          coin: "Coin",
          assetsFormatted: "Assets",
          liabilitiesFormatted: "liabilities",
          profitFormatted: "Profit",
          profit_progress_bar: "Profit %",
          expensesFormatted: "Expenses",
        }}
        rows={newCoins}
      />
    </Can>
  };

  render() { 
    const { data, usersCards, markets } = this.props; 

    return (
      <div className='dashboard-page'>
        <CardsHeader name="Default" parentName="Dashboards" data={data} classNames='pb-4'/>
        <UserCardsHeader  name="Default" parentName="Dashboards" cards={usersCards}  classNames='pb-5'/>
        <Container className="mt--4" bg-info fluid>
          <Row>
            {this.renderNrOfTradesPerDaySection()}
          </Row>
          <Row>
            {data && (this.renderUsersPerLevelSection())}
            {this.renderWithdrawRequestsSection()}
          </Row>
          <Row>&nbsp;</Row>
          <NavLink name="withdraw"/>
          {this.renderAmountsSection()}
        </Container>
      </div>
    );
  }
}

export default Dashboard;
