import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DepositsView from '../components/deposits';
import State from '../../../redux/Users';
import LoginState from '../../../redux/Login';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getUserDeposits: State.actions.getUserDeposits,
      setField: State.actions.setField,
    },
    dispatch
  );

const mapStateToProps = state => ({
  token: LoginState.selectors.token(state),
  status: State.selectors.status(state),
  currentUser: State.selectors.currentUser(state),
  deposits: State.selectors.deposits(state),
  depositsMeta: State.selectors.depositmeta(state),
  deposit_query: State.selectors.deposit_query(state),
  deposits_filter: State.selectors.deposits_filter(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DepositsView);