import React, { Component } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CodeInput from '../../components/CodeInput';

export default class twoFAModal extends Component {
  getScreenByType = (type) => {
    switch (type) {
      case 'google':
        return this.renderScreen(
          'Input the 6 digits code from your Google Authenticator app',
          'Google',
          type
        );
      case 'sms':
        return this.renderScreen(
          'Input the 7 digit SMS verification code received on your phone',
          'SMS',
          type
        );
      default:
        return <div />;
    }
  };

  renderScreen = (info, label, type) => {
    const codeLength = type === 'sms' ? 7 : 6;
    return (
      <div className='content'>
        <div className='center-align text'>{info}</div>
        <div className='center-align text'>{`${label} Authentication Code`}</div>
        <CodeInput
          code_length={codeLength}
          onChange={this.onChange}
          enterKeyPress={this.props.submitFunction}
        />
      </div>
    );
  };

  renderModalHeader = (type) => {
    return (
      <div className='title'>
        <div className='security_back_button' onClick={this.props.toggle}>
          <i className='fa fa-chevron-left' />
        </div>
        <div className='display-flex-center-content auto-margin'>
          <div className='image'>
            <img
              className='d-flex mx-auto img-fluid ssIcons'
              src={`/images/${type}_modal.png`}
              alt=''
            />
          </div>
          <h4>2FA</h4>
        </div>
      </div>
    );
  };

  onChange = (otpCode) => {
    const codeLength = this.props.type === 'sms' ? 7 : 6;
    this.props.onChangeOTPCode(otpCode);

    if (otpCode.length === codeLength){
      this.props.submitFunction(otpCode);
    }
  };

  render() {
    const { modal, error, type, toggle } = this.props;
    return (
      <Modal isOpen={modal} fade={false} toggle={toggle} className='twoFALoginScreen'>
        <ModalHeader>{this.renderModalHeader(type)}</ModalHeader>
        <ModalBody>{this.getScreenByType(type)}</ModalBody>
        <ModalFooter>
          <span className='error'>{error}</span>
        </ModalFooter>
      </Modal>
    );
  }
}