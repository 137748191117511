import React from "react";
import history from "../../../history";

import {
  Container
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import DarkTable from "../../../components/Table";
import { hasPermission } from '../../../utils';

class ReferralView extends React.Component {

  componentDidMount() { 
    this.props.getReferrals({page: 1}); 
  }

  searchReferrals = async (e) => {
    await this.props.setField('referral_query', e.target.value);
    this.props.getReferrals({...this.props, page: 1});
  }

  onReferralsPagingClick = (e, page) => {
    this.props.getReferrals({...this.props, page: page}); 
  }

  viewUser = (e, user) => { 
    history.push('/user/' + user.id);
  }

  viewReferrals = (e, user) => { 
    history.push('/user/' + user.id + "/referrals");
  }

  getActionsArray = () => {
    return [
      {name: 'View User', onClick: this.viewUser, permission: 'user.view'},
      {name: 'View Referrals', onClick: this.viewReferrals, permission: 'referral.view'},
    ].filter(item => hasPermission(item.permission))
  };

  renderTopInviters = () => { 
    return <DarkTable
      tableTitle="Top inviters"
      actionsColumn={hasPermission('referral.get.all')}
      actions={this.getActionsArray()}
      inlineSearch={this.searchReferrals}
      columns={{
        index: "No",
        email: "User",
        created_at: "Date",
        referral_code: "Referral Code",
        level_1_invited: "Invited",
        earned: "Earned",
      }}
      rows={this.props.referrals}
      meta={this.props.referrals_meta}
      onPagingClick={this.onReferralsPagingClick}
    />;
  }

  render() {
    return (
        <>
        <SimpleHeader name="Top inviters" parentName="Referrals" parentLink="/referrals" />
        <Container className="mt--6" fluid>
            {this.renderTopInviters()}
        </Container>
        </>
    );
  }
}

export default ReferralView;