import React from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";

class EmptyHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default EmptyHeader;
