import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Login from "./Login/reducer";
import Users from "./Users/reducer";
import Profile from "./Profile/reducer";
import Settings from "./Settings/reducer";
import Dashboard from "./Dashboard/reducer";
import Distributions from "./Distributions/reducer";
import ManualDistributions from "./ManualDistributions/reducer";
import Wallets from "./Wallets/reducer";
import Markets from "./Markets/reducer";
import Roles from "./Roles/reducer";
import Referrals from "./Referrals/reducer";
import CoinsAndChains from "./CoinsAndChains/reducer";
import Announcements from "./Announcements/reducer";
import Maintenances from "./Maintenances/reducer";
import OTCOrders from "./OtcOrders/reducer";
import Launchpad from "./Launchpad/reducer";
import Bots from "./Bots/reducer";
import PrdxCirculation from "./PrdxCirculation/reducer";

export default combineReducers({
  routing: routerReducer,
  Dashboard,
  Wallets,
  Markets,
  CoinsAndChains,
  Login,
  Profile,
  Settings,
  Users,
  Roles,
  Referrals,
  Distributions,
  ManualDistributions,
  Announcements,
  Maintenances,
  OTCOrders,
  Launchpad,
  Bots,
  PrdxCirculation
});
