import React from "react";
import { Container, } from "reactstrap";
import EmptyHeader from '../../../components/Headers/EmptyHeader'
import DarkTable from "../../../components/Table"

class Orders extends React.Component {

  componentDidMount() {
    this.props.getDistributionOrders(this.props.match.params.distribution_id || '');
  }

  onPagingClick = (ev, page) => {
    this.props.getDistributionOrders(this.props.match.params.distribution_id || '', { page: page });
  }

  render() {
    return (
      <>
        <EmptyHeader />
        <Container className="mt--6" fluid>
          <DarkTable
            tableTitle="Distribution Orders"
            columns={{
              created_at: "Date",
              ref_id: "Distribution ID",
              market_id: "Market",
              debit: "Amount",
              price: "Price",
              status: "Status"
            }}
            actionsColumn={false}
            rows={this.props.orders}
            meta={this.props.meta}
            onPagingClick={this.onPagingClick}
          />
        </Container>
      </>
    );
  }
}

export default Orders;
