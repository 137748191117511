import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Orders from '../components/Orders';
import State from '../../../redux/Distributions';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getDistributionOrders: State.actions.getDistributionOrders,
    },
    dispatch
  );

const mapStateToProps = state => ({
  orders: State.selectors.orders(state),
  meta: State.selectors.meta(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Orders);

