import React from 'react';
import {
  Col,
  Container,
  Input as InputField,
  ListGroupItem,
  Row,
} from 'reactstrap';

import styles from './TopicItem.module.scss';
import { notify } from '../../../../utils';

export default class TopicsItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditable: false,
      topicValue: '',
      topicId: undefined,
    };
  }

  handleEditTopic = (topicId) => {
    this.setState({ isEditable: true, topicId });
  };

  handleSaveTopic = (topicId, onEditTopic, notificationRef) => {
    const { topicId: defaultTopicId, topicValue } = this.state;

    if (topicValue.length) {
      onEditTopic(defaultTopicId || topicId, topicValue);

      this.setState({ isEditable: false });
    } else {
      notify('warning', notificationRef, 'Topic cannot be empty');
    }
  };

  handleDeleteTopic = (topicId, onDeleteTopic) => {
    if (window.confirm('Are you sure you want to delete this topic?')) {
      onDeleteTopic(topicId);
    }
  };

  handleChangeInput = (event) => {
    this.setState({ topicValue: event.target.value });
  };

  render() {
    const { topic, topicId, onEditTopic, onDeleteTopic, notificationRef } =
      this.props;

    const { topicValue, isEditable } = this.state;

    return (
      <ListGroupItem className="d-flex align-items-center justify-content-between">
        <Container fluid>
          <Row className="d-flex align-items-center">
            <Col md="4">
              {isEditable ? (
                <InputField
                  value={topicValue}
                  type="text"
                  bsSize="sm"
                  autoComplete="off"
                  autoFocus
                  onChange={this.handleChangeInput}
                  required
                />
              ) : (
                <div className={styles.topic}>{topic}</div>
              )}
            </Col>

            <Col md="8">
              <div className={styles.icons_container}>
                {isEditable ? (
                  <i
                    className="fa fa-share cursor-pointer text-green"
                    onClick={() =>
                      this.handleSaveTopic(
                        topicId,
                        onEditTopic,
                        notificationRef,
                      )
                    }
                  />
                ) : (
                  <i
                    className="fa fa-pen cursor-pointer text-blue"
                    onClick={() => this.handleEditTopic(topicId)}
                  />
                )}

                <i
                  className="fa fa-trash cursor-pointer text-red"
                  onClick={() => this.handleDeleteTopic(topicId, onDeleteTopic)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </ListGroupItem>
    );
  }
}
