import * as c from './constants';
import { actionCreator, asyncActionCreator } from '../../utils/redux';

export const getManualDistributions = asyncActionCreator(c.GET_MANUAL_DISTRIBUTIONS_PENDING, c.GET_MANUAL_DISTRIBUTIONS_SUCCESS, c.GET_MANUAL_DISTRIBUTIONS_ERROR);
export const getManualDistribution = asyncActionCreator(c.GET_MANUAL_DISTRIBUTION_PENDING, c.GET_MANUAL_DISTRIBUTION_SUCCESS, c.GET_MANUAL_DISTRIBUTION_ERROR);
export const getDistributionTotalPrdxForAllLines = asyncActionCreator(c.GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_PENDING, c.GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_SUCCESS, c.GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_ERROR);
export const completeManualDistribution = asyncActionCreator(c.COMPLETE_MANUAL_DISTRIBUTION_PENDING, c.COMPLETE_MANUAL_DISTRIBUTION_SUCCESS, c.COMPLETE_MANUAL_DISTRIBUTION_ERROR);
export const getManualDistributionFunds = asyncActionCreator(c.GET_MANUAL_DISTRIBUTION_FUNDS_PENDING, c.GET_MANUAL_DISTRIBUTION_FUNDS_SUCCESS, c.GET_MANUAL_DISTRIBUTION_FUNDS_ERROR);
export const updateManualDistributionFunds = asyncActionCreator(c.UPDATE_MANUAL_DISTRIBUTION_FUNDS_PENDING, c.UPDATE_MANUAL_DISTRIBUTION_FUNDS_SUCCESS, c.UPDATE_MANUAL_DISTRIBUTION_FUNDS_ERROR);
export const getManualDistributionBalances = asyncActionCreator(c.GET_MANUAL_DISTRIBUTION_BALANCES_PENDING, c.GET_MANUAL_DISTRIBUTION_BALANCES_SUCCESS, c.GET_MANUAL_DISTRIBUTION_BALANCES_ERROR);
export const setField = actionCreator(c.SET_FIELD);