import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import CoinsAndChains from '../components/index';
import CoinsAndChainsState from '../../../redux/CoinsAndChains';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getChains: CoinsAndChainsState.actions.getChains,
            getCoins: CoinsAndChainsState.actions.getCoins,
            getPresaleCoins: CoinsAndChainsState.actions.getPresaleCoins,
            editCoin: CoinsAndChainsState.actions.editCoin,
            disableCoin: CoinsAndChainsState.actions.disableCoin,
            disableChain: CoinsAndChainsState.actions.disableChain,
            editChain: CoinsAndChainsState.actions.editChain,
            resetForm: CoinsAndChainsState.actions.resetForm
        },
        dispatch
    );

const mapStateToProps = state => ({ 
    chains: CoinsAndChainsState.selectors.chains(state),
    coins: CoinsAndChainsState.selectors.coins(state),
    presaleCoins: CoinsAndChainsState.selectors.presaleCoins(state),
    status: CoinsAndChainsState.selectors.status(state),
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(CoinsAndChains);

