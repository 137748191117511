import Login from "modules/login/containers";
import Logout from "modules/logout/containers";
import ForgotPassword from "modules/forgotPassword/containers";
import Dashboard from "modules/dashboard/containers";
import ManageUsers from "modules/users/containers/index.js";

import UserDetails from "modules/userDetails/containers/index.js";
import UserDetailsActivity from "modules/userDetails/containers/activityContainer.js";
import UserControlPanel from "modules/userDetails/containers/controlPanel.js";
import UserKYB from "modules/userDetails/containers/kyb.js";
import UserDetailsOrders from "modules/userDetails/containers/ordersContainer.js";
import UserDetailsTrades from "modules/userDetails/containers/tradesContainer.js";
import UserDetailsWallet from "modules/userDetails/containers/walletContainer.js";
import UserDetailsReferrals from "modules/userDetails/containers/referralsContainer.js";
import UserDetailsBonusContracts from "modules/userDetails/containers/bonusContractContainer.js";
import UserDetailsBots from "modules/userDetails/containers/botsContainer.js";
import UserDetailsDistribution from "modules/userDetails/containers/distributionContainer.js";
import UserDetailsWithdrawals from "modules/userDetails/containers/withdrawalContainer.js";
import UserDetailsDeposits from "modules/userDetails/containers/depositsContainer.js";

import ManageCoin from "modules/coinDetails/containers";
import ManageChain from "modules/chainDetails/containers";
import ManageMarkets from "modules/markets/containers";
import MarketDetails from "modules/marketDetails/containers";
import MarketOrders from "modules/marketOrders/containers";
import Bots from "modules/bots/containers";
import GridBotsStatistics from "modules/bots/containers/gridBotsStatisticsContainer";
import TrendBotsStatistics from "modules/bots/containers/trendBotsStatisticsContainer";
import PrdxCirculation from "modules/prdxCirculation/containers";

import PRDXDistribution from "modules/distribution/containers/EventsContainer.jsx";
import PRDXDistributionOrders from "modules/distribution/containers/OrdersContainer.jsx";
import PRDXDistributionEntries from "modules/distribution/containers/EntriesContainer.jsx";

import ManualDistributions from "modules/manualDistribution/containers/DistributionsContainer.jsx";
import ManualDistributionFunds from "modules/manualDistribution/containers/FundsContainer.jsx";
import ManualDistributionBalances from "modules/manualDistribution/containers/BalancesContainer.jsx";

import ManageCoinsChains from "modules/coinsChains/containers";
import WalletWithdrawals from "modules/wallets/containers/withdrawals";
import WalletDeposits from "modules/wallets/containers/deposits";
import WalletManualDeposits from "modules/wallets/containers/manualDeposits";
import ManualWithdrawals from "./modules/wallets/containers/manualWithdrawals";
import WalletStats from "modules/wallets/containers/stats";
import UserProfile from "modules/loggedUser/containers";
import Settings from "modules/settings/containers";
import Referrals from "modules/referrals/containers";
import Announcements from "modules/announcements/containers";
import Maintenances from "modules/maintenances/containers";
import Launchpad from "modules/launchpad/containers";
import LaunchpadDetails from "modules/launchpadDetails/containers";
import AnnouncementDetails from "modules/announcementDetails/containers";
import MaintenanceDetails from "modules/maintenanceDetails/containers";

import ManageRoles from "modules/roles/containers";
import RoleDetails from "modules/roleDetails/containers";
import ErrorPage from "modules/error/";
import OtcOrders from "./modules/OtcOrders/containers";
import OtcOrdersDetails from "./modules/OtcOrdersDetails/containers";
import Topics from "./modules/topics/containers";
//import UserSetting from "modules/loggedUser/components/settings.jsx";
//import UserActivity from "modules/loggedUser/components/activity.jsx";

const routes = [
  {
    path: "dashboard",
    name: "Dashboard",
    showInMenu: true,
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    permission: "admin.view",
    layout: "/",
  },

  {
    path: "manage-users/:pending_filter",
    showInMenu: false,
    name: "Manage Users",
    icon: "ni ni-circle-08 text-orange",
    component: ManageUsers,
    layout: "/",
  },

  {
    path: "manage-users",
    showInMenu: true,
    name: "Manage Users",
    icon: "ni ni-circle-08 text-orange",
    component: ManageUsers,
    permission: "user.view",
    layout: "/",
  },

  {
    path: "role/:role_alias",
    showInMenu: false,
    name: "Edit Roles",
    icon: "ni ni-lock-circle-open text-red",
    component: RoleDetails,
    permission: "role.edit",
    layout: "/",
  },

  {
    path: "role",
    showInMenu: false,
    name: "Edit Roles",
    icon: "ni ni-lock-circle-open text-red",
    component: RoleDetails,
    permission: "role.add",
    layout: "/",
  },

  {
    path: "manage-roles",
    showInMenu: true,
    name: "Manage Roles",
    icon: "ni ni-lock-circle-open text-blue",
    component: ManageRoles,
    permission: "role.view",
    layout: "/",
  },

  {
    path: "manage-coins-chains",
    showInMenu: true,
    name: "Manage Coins & Chains",
    icon: "ni ni-money-coins text-purple",
    component: ManageCoinsChains,
    permission: "admin.view",
    layout: "/",
  },

  {
    path: "chain/:chain_id",
    showInMenu: false,
    name: "Add Chain",
    icon: "ni ni-money-coins text-purple",
    component: ManageChain,
    permission: "chain.edit",
    layout: "/",
  },

  {
    path: "chain",
    showInMenu: false,
    name: "Add Chain",
    icon: "ni ni-money-coins text-purple",
    component: ManageChain,
    permission: "chain.view",
    layout: "/",
  },

  {
    path: "manage-markets",
    showInMenu: true,
    name: "Manage Markets",
    icon: "ni ni-cart text-info",
    component: ManageMarkets,
    permission: "market.view",
    layout: "/",
  },

  {
    path: "manage-wallets",
    showInMenu: true,
    name: "Manage Wallets",
    icon: "ni ni-credit-card text-orange",
    component: WalletWithdrawals,
    permission: "wallet.view.all",
    layout: "/",
  },
  {
    path: "manage-wallets/deposits",
    showInMenu: false,
    name: "Wallet Deposits",
    icon: "ni ni-credit-card text-orange",
    component: WalletDeposits,
    permission: "wallet.view.all",
    layout: "/",
  },
  {
    path: "manage-wallets/manual-withdrawals",
    showInMenu: false,
    name: "Wallet Manual Withdrawals",
    icon: "ni ni-credit-card text-orange",
    component: ManualWithdrawals,
    permission: "wallet.view.all",
    layout: "/",
  },
  {
    path: "manage-wallets/manual-deposits",
    showInMenu: false,
    name: "Wallet Manual Deposits",
    icon: "ni ni-credit-card text-orange",
    component: WalletManualDeposits,
    permission: "wallet.view.all",
    layout: "/",
  },
  {
    path: "manage-wallets/stats",
    showInMenu: false,
    name: "Wallet Statistics",
    icon: "ni ni-credit-card text-orange",
    component: WalletStats,
    permission: "wallet.view.all",
    layout: "/",
  },

  {
    path: "referrals",
    name: "Referrals",
    showInMenu: true,
    icon: "ni ni-atom text-orange",
    component: Referrals,
    permission: "referral.get.all",
    layout: "/",
  },

  {
    path: "prdx-distribution/:distribution_id/entries",
    showInMenu: false,
    name: "PRDX Distribution Entries",
    icon: "ni ni-chart-pie-35 text-green",
    component: PRDXDistributionEntries,
    permission: "distribution.get.all",
    layout: "/",
  },

  {
    path: "prdx-distribution/:distribution_id",
    showInMenu: false,
    name: "PRDX Distribution Orders",
    icon: "ni ni-chart-pie-35 text-green",
    component: PRDXDistributionOrders,
    permission: "distribution.get",
    layout: "/",
  },

  {
    path: "prdx-distribution/:distribution_id/entries",
    showInMenu: false,
    name: "PRDX Distribution Entries",
    icon: "ni ni-chart-pie-35 text-green",
    component: PRDXDistributionEntries,
    permission: "distribution.get",
    layout: "/",
  },

  {
    path: "prdx-distribution/:distribution_id",
    showInMenu: false,
    name: "PRDX Distribution Orders",
    icon: "ni ni-chart-pie-35 text-green",
    component: PRDXDistributionOrders,
    permission: "distribution.get.all",
    layout: "/",
  },

  {
    path: "prdx-distribution",
    showInMenu: true,
    name: "PRDX Distribution",
    icon: "ni ni-chart-pie-35 text-green",
    component: PRDXDistribution,
    permission: "distribution.get.all",
    layout: "/",
  },

  {
    path: "prdx-manual-distribution/:distribution_id/balances",
    showInMenu: false,
    name: "Manual Distribution Balances",
    icon: "ni ni-chart-pie-35 text-green",
    component: ManualDistributionBalances,
    permission: "distribution.get.all",
    layout: "/",
  },

  {
    path: "prdx-manual-distribution/:distribution_id/funds",
    showInMenu: false,
    name: "Manual Distribution Funds",
    icon: "ni ni-chart-pie-35 text-green",
    component: ManualDistributionFunds,
    permission: "distribution.get.all",
    layout: "/",
  },

  // {
  //   path: "prdx-distribution/:distribution_id/entries",
  //   showInMenu: false,
  //   name: "PRDX Distribution Entries",
  //   icon: "ni ni-chart-pie-35 text-green",
  //   component: PRDXDistributionEntries,
  //   permission: "distribution.get",
  //   layout: "/"
  // },

  // {
  //   path: "prdx-distribution/:distribution_id",
  //   showInMenu: false,
  //   name: "PRDX Distribution Orders",
  //   icon: "ni ni-chart-pie-35 text-green",
  //   component: PRDXDistributionOrders,
  //   permission: "distribution.get.all",
  //   layout: "/"
  // },

  {
    path: "prdx-manual-distribution",
    showInMenu: true,
    name: "Manual Distributions",
    icon: "ni ni-chart-pie-35 text-green",
    component: ManualDistributions,
    permission: "distribution.get.all",
    layout: "/",
  },

  {
    path: "user/:user_id/edit",
    name: "Edit User",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetails,
    permission: "user.edit",
    layout: "/",
  },

  {
    path: "user/:user_id/kyb",
    name: "KYB",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserKYB,
    permission: "user.edit",
    layout: "/",
  },

  {
    path: "user/:user_id/activity",
    name: "User activity",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsActivity,
    permission: "user.view",
    layout: "/",
  },

  {
    path: "user/:user_id/control-panel",
    name: "Control Panel",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserControlPanel,
    permission: "user.view",
    layout: "/",
  },

  {
    path: "user/:user_id/orders",
    name: "User orders",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsOrders,
    permission: "user.view",
    layout: "/",
  },

  {
    path: "user/:user_id/trades",
    name: "User trades",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsTrades,
    permission: "user.view",
    layout: "/",
  },

  {
    path: "user/:user_id/distribution",
    name: "User distribution",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsDistribution,
    permission: "distribution.get",
    layout: "/",
  },

  {
    path: "user/:user_id/wallet",
    name: "User wallet",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsWallet,
    permission: "distribution.get",
    layout: "/",
  },

  {
    path: "user/:user_id/referrals",
    name: "User Referrals",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsReferrals,
    permission: "referral.view",
    layout: "/",
  },

  {
    path: "user/:user_id/bonus-contracts",
    name: "User Bonus Contracts",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsBonusContracts,
    permission: "user.view", // TODO: need update
    layout: "/",
  },

  {
    path: "user/:user_id/bots",
    name: "User Bots",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsBots,
    permission: "user.view", // TODO: need update
    layout: "/",
  },

  {
    path: "user/:user_id",
    name: "User Details",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetails,
    permission: "user.view",
    layout: "/",
  },

  {
    path: "user/:user_id/withdrawals",
    name: "User Withdrawals",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsWithdrawals,
    permission: "withdraw.view.all",
    layout: "/",
  },

  {
    path: "user/:user_id/deposits",
    name: "User Deposits",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: UserDetailsDeposits,
    permission: "transaction.view.all",
    layout: "/",
  },

  {
    path: "coin/:coin_id",
    name: "Edit coins",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: ManageCoin,
    permission: "coin.view",
    layout: "/",
  },

  {
    path: "coin",
    name: "Add new coin",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: ManageCoin,
    permission: "coin.add",
    layout: "/",
  },

  {
    path: "market/:market_id",
    name: "Edit market",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: MarketDetails,
    permission: "market.edit",
    layout: "/",
  },

  {
    path: "market",
    name: "Add new market",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: MarketDetails,
    permission: "market.add",
    layout: "/",
  },

  {
    path: "orders-from-market/:market_id?",
    name: "Orders from market",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: MarketOrders,
    permission: "order.view.all",
    layout: "/",
  },

  {
    path: "settings",
    name: "Withdraw Settings",
    showInMenu: true,
    icon: "ni ni-settings-gear-65",
    component: Settings,
    permission: "feature.edit",
    layout: "/",
  },

  {
    path: "manage-announcements",
    name: "Announcements",
    showInMenu: true,
    icon: "ni ni-notification-70",
    component: Announcements,
    permission: "admin.view", // TODO: need update
    layout: "/",
  },

  {
    path: 'topics',
    name: 'Announcements Topics',
    showInMenu: true,
    icon: 'ni ni-book-bookmark',
    component: Topics,
    permission: 'admin.view',
    layout: '/',
  },

  {
    path: "launchpad-projects",
    name: "Launchpad",
    showInMenu: true,
    icon: "ni ni-money-coins",
    component: Launchpad,
    permission: "admin.view", // TODO: need update
    layout: "/",
  },

  {
    path: "launchpad-project",
    name: "Add Launchpad Project",
    showInMenu: false,
    icon: "ni ni-notification-70",
    component: LaunchpadDetails,
    permission: "admin.view", // TODO: need update
    layout: "/",
  },

  {
    path: "launchpad-project/:project_id",
    name: "Update Launchpad Project",
    showInMenu: false,
    icon: "ni ni-notification-70",
    component: LaunchpadDetails,
    permission: "admin.view", // TODO: need update
    layout: "/",
  },

  {
    path: "bots",
    name: "Bots",
    showInMenu: false,
    icon: "ni ni-notification-70",
    component: Bots,
    permission: "admin.view",
    layout: "/",
  },

  {
    path: "bots/grid-bots-statistics/:bot_id?/:pair?",
    name: "Grid Bots Statistics",
    showInMenu: false,
    icon: "ni ni-notification-70",
    component: GridBotsStatistics,
    permission: "admin.view",
    layout: "/",
  },

  {
    path: "bots/trend-bots-statistics/:bot_id?/:pair?",
    name: "Trend Bots Statistics",
    showInMenu: false,
    icon: "ni ni-notification-70",
    component: TrendBotsStatistics,
    permission: "admin.view",
    layout: "/",
  },

  {
    path: "prdx-circulation",
    name: "PRDX Circulation",
    showInMenu: true,
    icon: "ni ni-planet",
    component: PrdxCirculation,
    permission: "admin.view",
    layout: "/",
  },

  {
    path: "maintenances",
    name: "Maintenances",
    showInMenu: true,
    icon: "ni ni-bell-55",
    component: Maintenances,
    permission: "admin.view",
    layout: "/",
  },

  {
    path: "announcement",
    name: "Add announcement",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: AnnouncementDetails,
    permission: "admin.view", // TODO: need update
    layout: "/",
  },

  {
    path: "maintenance",
    name: "Add maintenance",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: MaintenanceDetails,
    permission: "admin.view", // TODO: need update
    layout: "/",
  },

  {
    path: "announcement/:announcement_id",
    name: "Edit announcement",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: AnnouncementDetails,
    permission: "admin.view", // TODO: need update
    layout: "/",
  },

  {
    path: "maintenance/:maintenance_id",
    name: "Edit maintenance",
    showInMenu: false,
    icon: "ni ni-circle-08 text-orange",
    component: MaintenanceDetails,
    permission: "admin.view", // TODO: need update
    layout: "/",
  },

  {
    path: "/profile",
    showInMenu: false,
    name: "User Profile",
    component: UserProfile,
    permission: "profile.view",
    layout: "/current-user",
  },

  {
    path: "/login",
    showInMenu: false,
    name: "Login",
    component: Login,
    permission: "admin.view",
    layout: "/auth",
  },

  {
    path: "/logout",
    showInMenu: false,
    name: "Logout",
    component: Logout,
    permission: "admin.view",
    layout: "/auth",
  },

  {
    path: "/forgot/:reset_token",
    showInMenu: false,
    name: "Login",
    component: ForgotPassword,
    permission: "admin.view",
    layout: "/auth",
  },

  {
    path: "/forgot",
    showInMenu: false,
    name: "Login",
    component: ForgotPassword,
    permission: "admin.view",
    layout: "/auth",
  },

  {
    path: "error",
    showInMenu: false,
    name: "Error",
    component: ErrorPage,
    permission: "admin.view",
    layout: "/",
  },

  {
    path: "otc-orders",
    showInMenu: true,
    icon: "ni ni-box-2 text-purple",
    name: "OTC Orders",
    component: OtcOrders,
    permission: "admin.view",
    layout: "/",
  },

  {
    path: "otc-orders/upload",
    name: "Upload Document",
    showInMenu: false,
    component: OtcOrdersDetails,
    permission: "admin.view",
    layout: "/",
  },
];

export default routes;
