import * as c from './constants';
import moment from 'moment'
import BigNumber from 'bignumber.js';
import {formatNumber} from '../../utils';
import { groupBy,chain } from 'lodash';

const initialState = {
  chartValues: {
    labels: [],
    values: []
  },
  chartFeesValues: {
    labels: [],
    values: []
  },
  chartFeesBotValues: {
    labels: [],
    values: []
  },
  chartFeesManualValues: {
    labels: [],
    values: []
  },
  coins: [],
  coin_query: "",
  total_profit: 0,
  status: {pending: false, success: false, error: false},
};


export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_TRADES_PER_DAY_PENDING:
    case c.GET_FEES_PER_DAY_PENDING:
    case c.GET_GENERAL_STATS_PENDING:
    case c.GET_BOTS_STATISTICS_PENDING:
    case c.GET_USERS_PER_LEVEL_PENDING:
    case c.GET_WITHDRAWALS_PENDING:
    case c.GET_COIN_STATS_PENDING:
      return state;
    case c.GET_TRADES_PER_DAY_ERROR:
    case c.GET_FEES_PER_DAY_ERROR:
    case c.GET_GENERAL_STATS_ERROR:
    case c.GET_BOTS_STATISTICS_ERROR:
    case c.GET_USERS_PER_LEVEL_ERROR:
    case c.GET_WITHDRAWALS_ERROR:
    case c.GET_COIN_STATS_ERROR:
      return state;
    case c.GET_TRADES_PER_DAY_SUCCESS: {
      const labels = Object.keys(action.payload).map(value => moment(value).format('MMM D'));
      const values = Object.values(action.payload);
      return {
        ...state,
        chartValues: {
          labels: labels,
          values: values
        }
      };
    }
    case c.GET_FEES_PER_DAY_SUCCESS: {
      const {data, coins} =  action.payload;
      const charts = {};

      let totalUsdtFees = data[0].filter((item) => item.coin === 'total_usdt');
      let labels = totalUsdtFees.map((item) => moment(item.day).format('MMM D'));
      let values = totalUsdtFees.map((item) => formatNumber(item.fee, 2, null, false) || 0);
      charts.chartFeesBotValues = {labels, values};

      totalUsdtFees = data[1].filter((item) => item.coin === 'total_usdt');
      labels = totalUsdtFees.map((item) => moment(item.day).format('MMM D'));
      values = totalUsdtFees.map((item) => formatNumber(item.fee, 2, null, false) || 0);
      charts.chartFeesManualValues = {labels, values};

      //TODO: to uncomment , when will be sent the statistic per coin
      // const chartManual = chain(data[1])
      //   .groupBy("day")
      //   .map((value, key) => ({ day: key, data: value }))
      //   .value();

      // labels = chartManual.map((item) => item.day);

      // const objResManual = coins.reduce((res, item) => {
      //   res[item.symbol] = {labels, values: []};

      //   return res;
      // },{});
      // objResManual['total_usdt'] = {labels, values: []};

      // chartManual.forEach((item) => {
      //   let dayEmpty = false;
      //   const {data} = item;

      //   if('' in data){
      //     dayEmpty = true;
      //   }

      //   coins.forEach((coin) => {
      //     const values = [...objResManual[coin.symbol]['values']];

      //     if(dayEmpty){
      //       values.push('0');
      //     }
      //     else{
      //       const foundCoinData = data.find((item) => item.coin === coin.symbol);

      //       if(!foundCoinData) values.push('0');
      //       else values.push(foundCoinData.fee);
      //     }

      //     objResManual[coin.symbol] = {...objResManual[coin.symbol], values};
      //   });

      //   const values = [...objResManual['total_usdt']['values']];
      //   if(dayEmpty){
      //     values.push('0');
      //   }
      //   else{
      //     const foundTotalUsdtData = data.find((item) => item.coin === 'total_usdt');
      //     values.push(foundTotalUsdtData.fee);
      //   }

      //   objResManual['total_usdt'] = {...objResManual['total_usdt'], values};
      // });
        


      return {
        ...state,
        ...charts
      };
    }
    case c.GET_GENERAL_STATS_SUCCESS: {
      const users_total = Object.values(action.payload.users_count).reduce((total, value) => total + value, 0)
      return {
        ...state,
        data: {
          ...state.data,
          orders_count: action.payload.orders_count,
          users_total: users_total
        }
      };
    }
    case c.GET_BOTS_STATISTICS_SUCCESS: {
     return {
        ...state,
        data: {
          ...state.data,
          activeBotsCount: action.payload
        }
      };
    }
    case c.GET_USERS_PER_LEVEL_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          users_count: action.payload.users_count
        }
      };
    }
    case c.GET_COIN_STATS_SUCCESS: {
      const coins_total_profit = Object.values(action.payload).reduce((total, value) => total + (value.profit || 0) * value.btc_value, 0)
      const coins_formatted = Object.values(action.payload)
      .sort((a, b) => (a.coin > b.coin) ? 1 : ((a.coin < b.coin) ? -1 : 0))
      .map(coin => {
        return {
          ...coin,
          assets: coin.assets || 0,
          liabilities: coin.liabilities || 0,
          profit: coin.profit || 0,
          expenses: coin.expenses || 0,
          assetsFormatted: formatNumber(coin.assets, 8),
          liabilitiesFormatted: formatNumber(coin.liabilities, 8),
          profitFormatted: formatNumber(coin.profit, 8),
          expensesFormatted: formatNumber(coin.expenses, 8),
        };
      });
      return {
        ...state,
        coins: coins_formatted,
        total_profit: coins_total_profit
      };
    }
    case c.GET_WITHDRAWALS_SUCCESS: {
      let { WithdrawRequests, Meta } = action.payload;
      let dataFormatted = WithdrawRequests.map(withdrawal => {
        return {
          ...withdrawal,
          user: withdrawal.email,
          user_link: 'user/' + withdrawal.user_id,
          amount: (BigNumber(withdrawal.amount)).toFixed(),
          coin_symbol: withdrawal.coin_symbol.toUpperCase(),
          address: withdrawal.to,
          address_link: withdrawal.blockchain_explorer + withdrawal.address,
          created_at: moment(withdrawal.created_at).format('LLL')
        };
      });
      return {
        ...state,
        data: {
          ...state.data,
          withdrawals_count: Meta.count
        },
        meta: Meta,
        pending_withdrawals: dataFormatted
      };
    }
    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    default: {
      return {
        ...state,
        users: action.payload,
      };
    }
  }
};
