import React from "react";
import UserTabs from "components/Tabs/user.jsx";

import { Card, Container } from "reactstrap";
import DarkTable from "../../../components/Table";
import { hasPermission } from '../../../utils';

export default class UsersView extends React.Component {
  ticker = null;
  state = {
    page: 1,
    startDateFilterSelected: null,
    endDateFilterSelected: null,
  };

  tick = () => {
    const { user_id } = this.props.match.params;
    this.props.getUserTrades({ ...this.props, ...this.state,  user_id });
  };

  componentDidMount = async () => {
    await this.props.getMarkets({limit: 1000});
    this.tick();
    this.ticker = setInterval(this.tick, 5000);
  };

  componentWillUnmount() {
    if (this.ticker) {
      clearInterval(this.ticker);
    }
  }

  onTradesPagingClick = (ev, page) => {
    const user_id = this.props.match.params.user_id;
    this.setState({ page });
    this.props.getUserTrades({ ...this.props, ...this.state, user_id, page });
  };

  searchTrades = async (e) => {
    const user_id = this.props.match.params.user_id;
    this.setState({ page: 1 });
    await this.props.setField("trades_query", e.target.value);
    this.props.getUserTrades({ ...this.props, ...this.state,  user_id, page: 1 });
  };

  setTradesFilter = async (value) => {
    const user_id = this.props.match.params.user_id;
    this.setState({ page: 1 });
    await this.props.setField("trades_filter", value);
    this.props.getUserTrades({ ...this.props, ...this.state, user_id, page: 1 });
  };

  setAccountFilter = async (value) => {
    const user_id = this.props.match.params.user_id;
    this.setState({ page: 1 });
    await this.props.setField("account", value);
    this.props.getUserTrades({ ...this.props, ...this.state, user_id, page: 1 });
  };

  getAccountsList = () => {
    return [
      { label: "Main", value: "main" },
      { label: "Bonus", value: "bonus" },
    ];
  };

  getTradesTypeList = () => {
    return [
      { label: "all", value: "" },
      { label: "sell", value: "sell" },
      { label: "buy", value: "buy" },
    ];
  };

  setStartDate = (date) => {
    this.setState({ startDateFilterSelected: date }, () => {
      const { user_id } = this.props.match.params;

      this.props.getUserTrades({
        ...this.props,
        ...this.state,
        user_id,
        page: 1,
      });
    });
  };

  setEndDate = (date) => {
    this.setState({ endDateFilterSelected: date }, () => {
      const { user_id } = this.props.match.params;

      this.props.getUserTrades({
        ...this.props,
        ...this.state,
        user_id,
        page: 1,
      });
    });
  };

  onDownloadClick = (format) => { 
    const { user_id } = this.props.match.params;

    this.props.downloadUserTrades({...this.props, ...this.state, user_id, format});
  }

  renderViewTradesSection = () => {
    let inlineActions = [];

    if(hasPermission("admin.view")){
      inlineActions.push({
        text: "PDF",
        func: () => this.onDownloadClick('pdf')
      });

      inlineActions.push({
        text: "CSV",
        func: () => this.onDownloadClick('csv')
      });
    }

    return (
      <DarkTable
        light
        inlineFilterAccount={this.setAccountFilter}
        inlineFilterAccountData={this.getAccountsList()}
        inlineFilterAccountSelected={this.props.account}
        inlineActions={inlineActions}
        inlineSearch={this.searchTrades}
        inlineFilter={this.setTradesFilter}
        inlineFilterData={this.getTradesTypeList()}
        inlineFilterSelected={this.props.trades_filter}
        headerLeftColumnSize={5}
        startDateFilter={this.setStartDate}
        endDateFilter={this.setEndDate}
        startDateFilterSelected={this.state.startDateFilterSelected}
        endDateFilterSelected={this.state.endDateFilterSelected}
        startDateFilterPlaceholder="To created date"
        endDateFilterPlaceholder="From created date"
        tableTitle="View Trades"
        columns={{
          order_id: "Order ID",
          timestamp: "Date & Time",
          market_id: "Pair",
          side: "Side",
          fee_amount: "Fees",
          price: "Price",
          filledPercent: "Filled %",
          total: "Total",
        }}
        rows={this.props.trades}
        meta={this.props.tradesmeta}
        onPagingClick={this.onTradesPagingClick}
      />
    );
  };

  render() {
    const { match } = this.props;

    return (
      <>
        <UserTabs match={match} />
        <Container className="mt--6" fluid>
          <Card className="user_content">{this.renderViewTradesSection()}</Card>
        </Container>
      </>
    );
  }
}
