import * as ac from './actionCreators';
import api from '../../api';
import { validateEmail, validatePassword, validateConfirmPassword } from '../../utils/validator';
import { BaseError, InvalidFields, OTPRequiredError } from '../../utils/errors';
import history from '../../history';
import * as storage from '../../utils/storage';
import {partialLogin} from '../../utils/auth';


export function isRecaptchaEnabled(){
  return async dispatch => {
    dispatch(ac.isRecaptchaEnabled.pending());
    try {
      const resp = await api.auth.isRecaptchaEnabled();
      dispatch(ac.isRecaptchaEnabled.success(resp));
      dispatch(geetestRegister());
    } catch (err) {
      if (err instanceof BaseError) return dispatch(ac.isRecaptchaEnabled.error(err));
      return dispatch(ac.isRecaptchaEnabled.error(new BaseError(err)));
    }
  };
}

export function geetestValidate(data) {
  return async dispatch => {
    dispatch(ac.geetestValidate(data));
  };
}

export function geetestEmpty(data) {
  return async dispatch => {
    dispatch(ac.geetestEmpty());
  };
}

export function geetestRegister() {
  return async dispatch => {
    dispatch(ac.geetestRegister.pending());
    try {
      const resp = await api.auth.geetestRegister();
      dispatch(ac.geetestRegister.success(resp));
    } catch (err) {
      if (err instanceof BaseError) return dispatch(ac.geetestRegister.error(err));
      return dispatch(ac.geetestRegister.error(new BaseError(err)));
    }
  };
}

export function forgotPassword(payload) { 
  return async dispatch => {
    dispatch(ac.forgotPassword.pending());
    try {
      let field_errors = {};
      if (!validateEmail(payload.email)) field_errors.email = 'Please enter a valid email address';      
      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });

      if (payload.recaptchaEnabled &&
        (!payload.geetest.validate || !payload.geetest.validate.geetest_challenge)
      ) {
        payload.instance.verify();
        return ;
      }

      const data = await api.auth.forgotPassword({
        email: payload.email,
        geetest_challenge: payload.geetest.validate.geetest_challenge,
        geetest_validate: payload.geetest.validate.geetest_validate,
        geetest_seccode: payload.geetest.validate.geetest_seccode
      });
      payload.instance.reset();
      dispatch(ac.geetestReset());
      dispatch(ac.forgotPassword.success(data));
    } catch (err) {
      if (payload.instance && payload.geetest.register && payload.geetest.register.success) {
        payload.instance.reset();
        dispatch(ac.geetestReset());
      }
      dispatch(ac.forgotPassword.error(err));
    }
  };
}

export function resetPassword(payload, token) {
  return async dispatch => {
    dispatch(ac.resetPassword.pending());
    try {
      let field_errors = {};
      if (!payload.password) field_errors.password = 'This field is required';
      if (!validatePassword(payload.password))
        field_errors.password =
          'The password must contain at least one lowercase (a-z) letter, one uppercase (A-Z) letter, one digit (0-9) and one special character.';
      if (!validateConfirmPassword(payload.password, payload.password_confirm))
        field_errors.password_confirm = 'Invalid password confirmation';
      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });
      
      if (payload.recaptchaEnabled &&
        (!payload.geetest.validate || !payload.geetest.validate.geetest_challenge)
      ) {
        payload.instance.verify();
        return ;
      }
      
      const data = await api.auth.resetPassword({
        password: payload.password,
        token: token,
        geetest_challenge: payload.geetest.validate.geetest_challenge,
        geetest_validate: payload.geetest.validate.geetest_validate,
        geetest_seccode: payload.geetest.validate.geetest_seccode
      });
      dispatch(ac.resetPassword.success(data));
    } catch (err) {
      if (payload.instance && payload.geetest.register && payload.geetest.register.success) {
        payload.instance.reset();
        dispatch(ac.geetestReset());
      }
      dispatch(ac.resetPassword.error(err));
    }
  };
}

export function login(payload) {
  return async(dispatch, getState) => {
    const {token} = getState().Login;
    dispatch(ac.login.pending());
    try {
      let field_errors = {};
      if (!validateEmail(payload.email)) field_errors.email = 'Please enter a valid email address';
      if (!payload.password) field_errors.password = 'This field is required';
      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', {field_errors});

      if (payload.recaptchaEnabled &&
        (!payload.geetest.validate || !payload.geetest.validate.geetest_challenge)
      ) {
        payload.instance.verify();
        return;
      }
      const resp = await api.auth.login({
        email: payload.email,
        password: payload.password,
        remember_me: payload.remember,
        otp_code: payload.otp_code,
        geetest_challenge: payload.geetest.validate.geetest_challenge,
        geetest_validate: payload.geetest.validate.geetest_validate,
        geetest_seccode: payload.geetest.validate.geetest_seccode,
        token
      });
      if (!partialLogin(resp.token)) {
        dispatch(ac.getUserPermissions.success(await api.auth.getUserPermissions(resp.token)));
      }
      dispatch(ac.login.success(resp));
    } catch (err) {
      if ((payload.instance && payload.geetest.register && payload.geetest.register.success) && !(err instanceof OTPRequiredError)) {
        payload.instance.reset();
        dispatch(ac.geetestReset());
      }
      if (err instanceof BaseError) return dispatch(ac.login.error(err));
      return dispatch(ac.login.error(new BaseError(err)));
    }
  };
}

export function getUserPermissions() { 
  return async (dispatch, getState) => { 
    dispatch(ac.getUserPermissions.pending());
    try {
      const token = getState().Login.token;
      const resp = await api.auth.getUserPermissions(token);
      dispatch(ac.getUserPermissions.success(resp));
    } catch (err) {
      if (err instanceof BaseError) return dispatch(ac.getUserPermissions.error(err));
      return dispatch(ac.getUserPermissions.error(new BaseError(err)));
    }
  };
}

export function logout() {
  return async dispatch => {
    storage.set('admin_auth_token', '');
    dispatch(ac.logout());
    history.push('/auth/login');
  };
}

export function logoutWithRefresh() {
  return async dispatch => {
    storage.set('admin_auth_token', '');
    dispatch(ac.logout());
    history.go(0);
  };
}

export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}

export function removeToken() {
  return async (dispatch) => {
    dispatch(ac.removeToken());
  };
}
