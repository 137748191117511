import * as c from './constants';
import {asyncActionCreator, actionCreator} from '../../utils/redux';

export const getBotsStatistics = asyncActionCreator(c.GET_BOTS_STATISTICS_PENDING, c.GET_BOTS_STATISTICS_SUCCESS, c.GET_BOTS_STATISTICS_ERROR);
export const getTradesPerDay = asyncActionCreator(c.GET_TRADES_PER_DAY_PENDING, c.GET_TRADES_PER_DAY_SUCCESS, c.GET_TRADES_PER_DAY_ERROR);
export const getFeesPerDay = asyncActionCreator(c.GET_FEES_PER_DAY_PENDING, c.GET_FEES_PER_DAY_SUCCESS, c.GET_FEES_PER_DAY_ERROR);
export const getGeneralStatistics = asyncActionCreator(c.GET_GENERAL_STATS_PENDING, c.GET_GENERAL_STATS_SUCCESS, c.GET_GENERAL_STATS_ERROR);
export const getUsersPerLevel = asyncActionCreator(c.GET_USERS_PER_LEVEL_PENDING, c.GET_USERS_PER_LEVEL_SUCCESS, c.GET_USERS_PER_LEVEL_ERROR);
export const getWithdrawals = asyncActionCreator(c.GET_WITHDRAWALS_PENDING, c.GET_WITHDRAWALS_SUCCESS, c.GET_WITHDRAWALS_ERROR);
export const getCoinStatistics = asyncActionCreator(c.GET_COIN_STATS_PENDING, c.GET_COIN_STATS_SUCCESS, c.GET_COIN_STATS_ERROR);

export const setField = actionCreator(c.SET_FIELD);