import * as c from './constants';
import moment from 'moment'

const initialState = {
  referrals: [],
  referral_query: "",
  status: {pending: false, success: false, error: false},
};


export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_USER_REFERRALS_PENDING: {
      return {
        ...state,
        status: {pending: true, error: false, success: false},
      };
    }
    case c.GET_USER_REFERRALS_ERROR:{
      return {
        ...state,
        status: {pending: false, error: action.payload, success: false},
      };
    }
    case c.GET_USER_REFERRALS_SUCCESS: {
      const Meta = action.payload.Meta;
      const referrals = action.payload.Users.map((item, index) => {
        return {
          ...item,
          index: ((Meta.page-1) * Meta.limit) + index+1,
          created_at: moment(item.created_at).format('LLL'),
          earned: item.earned ? Number(item.earned).toFixed(2) : '0.00',
          name: item.email,
          level_1_invited_link: '/user/' + item.id + '/referrals'
        }
      });
      return {
        ...state,
        referrals: referrals,
        referrals_meta: action.payload.Meta
      };
    }
    case c.SET_FIELD: { 
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};
