import { api } from '../config';
import { assetValidToken } from '../utils/auth';
import { get, put } from '../utils/superagent';

export const putKybUpdateStepTwo = (user_id, step_two_status, token) => {
  return assetValidToken(token).then(() =>
    put(`${api}/admin/user/kyb/${user_id}/update_step_two`, null, token, {
      step_two_status,
    }),
  );
};

export const getKybDownloadDocument = (user_id, file_type, token) => {
  return assetValidToken(token).then(() =>
    get(
      `${api}/admin/user/kyb/${user_id}/download_document`,
      { file_type },
      token,
    ),
  );
};

export const getKybStatus = (user_id, token) => {
  return assetValidToken(token).then(() =>
    get(`${api}/admin/user/kyb/${user_id}`, {}, token),
  );
};

export const getListOfBusinessMembers = (user_id, token) => {
  return assetValidToken(token).then(() =>
    get(`${api}/admin/user/kyb/${user_id}/list-business-members`, {}, token),
  );
};
