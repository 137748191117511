import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import ChainDetail from '../components/index';
import CoinsAndChainsState from '../../../redux/CoinsAndChains';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getChain: CoinsAndChainsState.actions.getChain,
            getChains: CoinsAndChainsState.actions.getChains,
            addChain: CoinsAndChainsState.actions.addChain,
            updateChain: CoinsAndChainsState.actions.editChain,
            setField: CoinsAndChainsState.actions.setField,
            setChainField: CoinsAndChainsState.actions.setChainField,
            resetForm: CoinsAndChainsState.actions.resetForm
        },
        dispatch
    );

const mapStateToProps = state => ({ 
    chain: CoinsAndChainsState.selectors.chain(state),
    status: CoinsAndChainsState.selectors.status(state),
    
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(ChainDetail);

