import React from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import { renderABasicInput, renderSelectInput } from "../Inputs"
import CountryList from "react-select-country-list";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {eighteenYearsAgo} from "../../utils";
import "react-datepicker/dist/react-datepicker.css";

export default class ProfileView extends React.Component {

  constructor(props) {
    super(props);
    this.countryList = CountryList().getData();
  }

  renderDatePicker = (label, prop) => {
    const {object} = this.props; 
    return <FormGroup className="row">
      <Label className="form-control-label" md="4">
        {label}
      </Label>
      <Col md="8">
        <div className="input-groupx">
          <label className="DatePickerWrap col-md-12 padding-none">
            <DatePicker
              maxDate={eighteenYearsAgo()}
              showYearDropdown
              showMonthDropdown
              dateFormatCalendar="MMMM"
              scrollableYearDropdown
              yearDropdownItemNumber={75}
              placeholderText="DD/MM/YYYY"
              selected={object && object[prop] ? moment(object[prop]).toDate() : null}
              onChange={(value) => this.props.onChange(prop, value)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              filterDate={date => moment() > date}
            />
            <span className="input-group-append">
              <i className="fa fa-calendar" />
            </span>
          </label>
        </div>
      </Col>
    </FormGroup>
  } 

  getUserGender = () => {
    return [
      {label: 'Male', value: 'male'},
      {label: 'Female', value: 'female'}
    ]
  };

  render() {
    return (
      <Form autoComplete="form-values">
        <Row>
          <Col md="6">{renderABasicInput(this.props, "First Name", "first_name")}</Col>
          <Col md="6">{renderABasicInput(this.props, "Last Name", "last_name")}</Col>
        </Row>
        <Row>
          <Col md="6">{this.renderDatePicker("Date of birth", "dob")}</Col>
          <Col md="6">{renderSelectInput(this.props, "Gender", this.getUserGender(), "gender")}</Col>
        </Row>
        <Row>
          <Col md="6">{renderABasicInput(this.props, "Email", "email")}</Col>
        </Row>
        <Row>
          <Col md="6">{renderABasicInput(this.props, "Address", "address")}</Col>
          <Col md="6">{renderABasicInput(this.props, "Phone Number", "phone", "tel")}</Col>
        </Row>
        <Row>
          <Col md="6">{renderSelectInput(this.props, "Country", this.countryList, "country")}</Col>
          <Col md="6">{renderABasicInput(this.props, "State", "state")}</Col>
        </Row>
        <Row>
          <Col md="6">{renderABasicInput(this.props, "City", "city", "text")}</Col>
          <Col md="6">{renderABasicInput(this.props, "Postal code", "postal_code")}</Col>
        </Row>
      </Form>
    );
  }
}

