import {api} from '../config';
import { get, post, put, del } from '../utils/superagent';
import { assetValidToken } from '../utils/auth';

export const getChains = (token) => assetValidToken(token).then(() => get(`${api}/chains`, {source: 'admin'}, token));
export const getCoins = (data = {},token) => assetValidToken(token).then(() => get(`${api}/coins`, {source: 'admin', ...data}, token));
export const getCoin = (data, token) => assetValidToken(token).then(() => get(`${api}/coins/`+data, {source: 'admin'}, token));
export const addCoin = (data, token) => assetValidToken(token).then(() => post(`${api}/coins`, {...data, source: 'admin'}, token));
export const editCoin = (data, token) => assetValidToken(token).then(() => put(`${api}/coins/`+data.id, {...data, source: 'admin'}, token));

export const changeBlockDepositCoin = (coin_id, value, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/block/coin/${coin_id}/deposit/${value ? 'on' : 'off'}`, {}, token)
  );

export const changeBlockWithdrawCoin = (coin_id, value, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/block/coin/${coin_id}/withdraw/${value ? 'on' : 'off'}`, {}, token)
  );

export const getChain = (data, token) => assetValidToken(token).then(() => get(`${api}/chains/`+data, {source: 'admin'}, token));
export const addChain = (data, token) => assetValidToken(token).then(() => post(`${api}/chains`, {...data, source: 'admin'}, token));
export const editChain = (data, token) => assetValidToken(token).then(() => put(`${api}/chains/`+data.id, {...data, source: 'admin'}, token));

export const getMarket = (data, token) => assetValidToken(token).then(() => get(`${api}/markets/`+data, {source: 'admin'}, token));
export const addMarket = (data, token) => assetValidToken(token).then(() => post(`${api}/markets`, {...data, source: 'admin'}, token));
export const editMarket = (data, token) => assetValidToken(token).then(() => put(`${api}/markets/`+data.id, {...data, source: 'admin'}, token));

export const disableCoin = (data, token) => assetValidToken(token).then(() => del(`${api}/coins/`+data, { source: 'admin' }, token));
export const disableChain = (data, token) => assetValidToken(token).then(() => del(`${api}/chains/`+data, { source: 'admin' }, token));
export const disableMarket = (data, token) => assetValidToken(token).then(() => del(`${api}/markets/`+data, { source: 'admin' }, token));

export const cancelMarketOrders = (id, token) => assetValidToken(token).then(() => del(`${api}/admin/orders/${id}`, {}, token));
export const cancelOrder = (id, market, token) => assetValidToken(token).then(() => del(`${api}/admin/orders/${market}/${id}`, {}, token));

export const getAllWithdrawals = async (data, token) => { 
    const withdrawals = await assetValidToken(token).then(() => get(`${api}/admin/withdrawals`, { ...data, source: 'admin' }, token));
    withdrawals.Meta.pages = Math.ceil(withdrawals.Meta.count / withdrawals.Meta.limit)
    return withdrawals;
}
export const getAllDeposits = async (data, token) => {
    const deposits = await assetValidToken(token).then(() => get(`${api}/admin/deposits`, { ...data, source: 'admin' }, token));
    deposits.Meta.pages = Math.ceil(deposits.Meta.count / deposits.Meta.limit)
    return deposits;
}

export const getManualDeposits = async (data, token) => {
    const manualDeposits = await assetValidToken(token).then(() => get(`${api}/admin/manual-deposits`, { ...data, source: 'admin' }, token));
    manualDeposits.meta.pages = Math.ceil(manualDeposits.meta.count / manualDeposits.meta.limit)
    return manualDeposits;
}

export const getManualWithdrawals = async (data, token) => {
    const manualWithdrawals = await assetValidToken(token).then(() => get(`${api}/admin/manual-withdrawals`, { ...data, source: 'admin' }, token));
    manualWithdrawals.meta.pages = Math.ceil(manualWithdrawals.meta.count / manualWithdrawals.meta.limit)
    return manualWithdrawals;
}

export const getOperations = async (data, token) => {
    const operations = await assetValidToken(token).then(() => get(`${api}/admin/operations`, { ...data, source: 'admin' }, token));
    operations.Meta.pages = Math.ceil(operations.Meta.count / operations.Meta.limit)
    return operations;
}

export const getMarkets = async (data, token) => {
    const markets = await assetValidToken(token).then(() => get(`${api}/admin/markets`, { ...data, source: 'admin' }, token));
    markets.Meta.pages = Math.ceil(markets.Meta.count / markets.Meta.limit)
    return markets;
}

export const getOrdersFromMarket = async (data, token) => {
    const orders = await assetValidToken(token).then(() => get(`${data.pair ? `${api}/admin/orders/${data.pair}` : `${api}/admin/orders`}`, { ...data, source: 'admin' }, token));
    orders.meta.pages = Math.ceil(orders.meta.count / orders.meta.limit);
    return orders;
}