import React from "react";
import history from "../../../history";

import { Container, Row, Input } from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import DarkTable from "../../../components/Table";
import NotificationAlert from "react-notification-alert";
import { hasPermission } from "../../../utils";

const statusList = [
  {
    value: "published",
    label: "Published",
  },
  {
    value: "draft",
    label: "Draft",
  },
  {
    value: "deleted",
    label: "Deleted",
  },
];

export default class AnnouncementsView extends React.Component {
  componentDidMount() {
    // TODO: need update
    if (!hasPermission("admin.view")) {
      history.push("/error");
    } else {
      this.props.getAnnouncements({ page: this.props.page });
    }
  }

  componentWillUnmount() {
    this.props.setField("page", 1);
  }

  getAnnouncementsActionsArray = () => {
    return [
      {
        name: "Edit",
        onClick: this.editAnnouncement,
        permission: "admin.view", // TODO: need update
      },
    ].filter((item) => hasPermission(item.permission));
  };

  onAnnouncementsPagingClick = (ev, page) => {
    this.props.setField("page", page);

    this.props.getAnnouncements({ ...this.props, page: page });
  };

  addAnnouncement = () => {
    history.push("/announcement");
  };

  editAnnouncement = (e, announcement) => {
    history.push(`/announcement/${announcement.id}`);
  };

  renderAnnouncementsSection = () => {
    let inlineActions = [];

    if(hasPermission("admin.view")){
      inlineActions.push({
        text: "+ Add announcement",
        func: this.addAnnouncement
      });
    }

    return (
      <DarkTable
        tableTitle="Announcements"
        inlineActions={inlineActions}
        actionsColumn={true}
        actions={this.getAnnouncementsActionsArray()}
        inlineSearch={this.searchMarkets}
        columns={{
          id: "Id",
          title: "Title",
          status: "Status",
        }}
        // onRowSelectChange={(e) => {
        //   console.log(e);
        // }}
        statusCustomRowComponent={({ data }) => {
          const handleChangeStatus = (e) => {
            // TODO: need update
            if (hasPermission("admin.view")) {
              this.props.changeAnnouncementStatus(
                e.target.value,
                data.id,
                this.refs.notificationAlert
              );
            }
          };

          return (
            <td key={data.id}>
              <Input
                type="select"
                className="custom-row-select"
                value={data.status}
                onChange={handleChangeStatus}
              >
                <option value="" disabled hidden>
                  Select status
                </option>
                {statusList.map((option) => (
                  <option
                    key={option.value || option.label}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Input>
            </td>
          );
        }}
        rows={this.props.announcements}
        meta={this.props.announcementsMeta}
        onPagingClick={this.onAnnouncementsPagingClick}
      />
    );
  };

  render() {
    return (
      <>
        <SimpleHeader name="Manage Announcements" parentName="Announcements" />
        <Container className="mt--6" fluid>
          <Row>&nbsp;</Row>

          {this.renderAnnouncementsSection()}

          <div className="rna-wrapper" ref="notifH">
            <NotificationAlert ref="notificationAlert" />
          </div>
        </Container>
      </>
    );
  }
}
