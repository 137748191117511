import React from "react";

import FileUploader from "devextreme-react/file-uploader";

const allowedFileExtensions = [".jpg", ".jpeg", ".png"];
const maxFileSize = 3000000; // 3mb

export default class ImageUploaderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSrc: null,
    };
  }

  isBase64 = (image) => {
    return typeof image === 'string';
  }

  componentDidMount(){
    //if originally value is base 64 image
    if(this.isBase64(this.props.value)) this.setState({selectedSrc: this.props.value});
  }

  componentDidUpdate(prevProps){
    //if originally value is base 64 image
    if(!prevProps.value && this.props.value && this.isBase64(this.props.value)){
      this.setState({selectedSrc: this.props.value});
    }
  }

  toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      if (!file) {
        reject();
      }

      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  onSelectedFilesChanged = async (e) => {
    this.props.onChange(e.value[0]);

    if (e.component._files[0]) {
      this.props.setValid(e.component._files[0].isValid());
    } else {
      this.props.setValid(true);
    }

    let selectedSrc = null;

    try {
      selectedSrc = await this.toBase64(e.value[0]);
    } catch {
      selectedSrc = null;
    }

    this.setState({ selectedSrc });
  };

  render() {
    const { value, disabled } = this.props;
    const { selectedSrc } = this.state;

    return (
      <div className="image-uploader-container">
        <FileUploader
          disabled={disabled}
          allowedFileExtensions={allowedFileExtensions}
          maxFileSize={maxFileSize}
          uploadMode="useButtons"
          onValueChanged={this.onSelectedFilesChanged}
          value={value && !this.isBase64(value) ? [value] : []}
        />
        <img
          className="preview"
          src={selectedSrc}
          hidden={!selectedSrc}
          alt="preview"
        />
      </div>
    );
  }
}
