import * as ac from './actionCreators';
import api from '../../api';
import { validateNotEmpty } from '../../utils/validator';
import { InvalidFields } from '../../utils/errors';
import { notify } from '../../utils';
import { isEmpty } from 'lodash';

export function getAnnouncement(announcement_id) {
  return async (dispatch, getState) => {
    dispatch(ac.getAnnouncement.pending());
    try {
      const token = getState().Login.token;
      const data = await api.announcements.getAnnouncement(
        announcement_id,
        token,
      );
      dispatch(ac.getAnnouncement.success(data));
    } catch (err) {
      dispatch(ac.getAnnouncement.error(err));
    }
  };
}

export function getAnnouncements(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getAnnouncements.pending());
    try {
      const payloadData = {
        limit: payload.limit,
        page: payload.page,
      };

      const token = getState().Login.token;
      const data = await api.announcements.getAnnouncements(payloadData, token);
      dispatch(ac.getAnnouncements.success(data));
    } catch (err) {
      dispatch(ac.getAnnouncements.error(err));
    }
  };
}

export function getAnnouncementsSettings() {
  return async (dispatch, getState) => {
    dispatch(ac.getAnnouncementsSettings.pending());

    try {
      const token = getState().Login.token;
      const data = await api.announcements.getAnnouncementsSettings(token);

      dispatch(ac.getAnnouncementsSettings.success(data));
    } catch (err) {
      dispatch(ac.getAnnouncementsSettings.error(err));
    }
  };
}

export function addAnnouncement(payload, status) {
  return async (dispatch, getState) => {
    dispatch(ac.addAnnouncement.pending());

    const announcement = payload.announcement;

    try {
      let field_errors = {};
      // if (!validateNotEmpty(announcement.image))
      //   field_errors.image = "Field is required";
      if (!validateNotEmpty(announcement.title))
        field_errors.title = 'Field is required';
      if (!validateNotEmpty(announcement.message))
        field_errors.message = 'Field is required';

      if (!isEmpty(field_errors))
        throw new InvalidFields('Invalid form fields', { field_errors });

      const payloadData = {
        status: status,
        title: announcement.title,
        message: announcement.message,
        image: announcement.image,
        topic: announcement.topic,
      };

      const formData = new FormData();

      for (const prop in payloadData) {
        formData.append(prop, payloadData[prop]);
      }

      const token = getState().Login.token;

      const data = await api.announcements.addAnnouncement(formData, token);

      dispatch(ac.addAnnouncement.success(data));
    } catch (err) {
      dispatch(ac.addAnnouncement.error(err));
    }
  };
}

export function editAnnouncement(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.editAnnouncement.pending());
    try {
      let field_errors = {};
      // if (!validateNotEmpty(payload.image))
      //   field_errors.image = "Field is required";
      if (!validateNotEmpty(payload.title))
        field_errors.title = 'Field is required';
      if (!validateNotEmpty(payload.message))
        field_errors.message = 'Field is required';

      if (!isEmpty(field_errors))
        throw new InvalidFields('Invalid form fields', { field_errors });

      const payloadData = {
        id: payload.id,
        status: payload.status,
        title: payload.title,
        message: payload.message,
        image: payload.image,
        topic: payload.topic,
      };

      const formData = new FormData();
      for (const prop in payloadData) {
        formData.append(prop, payloadData[prop]);
      }

      const token = getState().Login.token;


      const data = await api.announcements.addAnnouncement(formData, token);
      dispatch(ac.editAnnouncement.success(data));
    } catch (err) {
      dispatch(ac.editAnnouncement.error(err));
    }
  };
}

export function addTopic(topics, newTopic, isSameTopic, notificationRef) {
  return async (dispatch, getState) => {
    dispatch(ac.addTopic.pending());

    try {
      let field_errors = {};

      if (!validateNotEmpty(newTopic)) {
        field_errors.topic_title = 'Field is required';
      }

      if (isSameTopic) {
        field_errors.topic_title = 'The topic already exists. Please enter a new topic';
      }

      if (!isEmpty(field_errors)) {
        throw new InvalidFields('Invalid form fields', { field_errors });
      }

      const formData = new FormData();

      for (const element of topics) {
        formData.append('topics', element);
      }

      const token = getState().Login.token;

      const data = await api.announcements.updateAnnouncementsSettings(
        formData,
        token,
      );

      dispatch(ac.addTopic.success(data));

      notify(
        'success',
        notificationRef,
        'You have successfully created a new topic',
      );
    } catch (err) {
      notify(
        'danger',
        notificationRef,
        err.message || 'Something went wrong. Please try again!',
      );

      dispatch(ac.addTopic.error(err));
    }
  };
}

export function editTopic(topics, notificationRef) {
  return async (dispatch, getState) => {
    dispatch(ac.editTopic.pending());

    try {
      const formData = new FormData();

      for (const element of topics) {
        formData.append('topics', element);
      }

      const token = getState().Login.token;

      const data = await api.announcements.updateAnnouncementsSettings(
        formData,
        token,
      );

      dispatch(ac.editTopic.success(data));

      notify(
        'success',
        notificationRef,
        'You have successfully edited the topic',
      );
    } catch (err) {
      notify(
        'danger',
        notificationRef,
        err.message || 'Something went wrong. Please try again!',
      );

      dispatch(ac.editTopic.error(err));
    }
  };
}

export function deleteTopic(topics, notificationRef) {
  return async (dispatch, getState) => {
    dispatch(ac.deleteTopic.pending());

    try {
      const formData = new FormData();

      for (const element of topics) {
        formData.append('topics', element);
      }

      const token = getState().Login.token;

      const data = await api.announcements.updateAnnouncementsSettings(
        formData,
        token,
      );

      notify(
        'success',
        notificationRef,
        'You have successfully deleted the topic',
      );

      dispatch(ac.deleteTopic.success(data));
    } catch (err) {
      notify(
        'danger',
        notificationRef,
        err.message || 'Something went wrong. Please try again!',
      );

      dispatch(ac.deleteTopic.error(err));
    }
  };
}

export function changeAnnouncementStatus(status, ids = [], notificationRef) {
  return async (dispatch, getState) => {
    dispatch(ac.changeAnnouncementStatus.pending());
    try {
      const payloadData = {
        status: status,
        id: ids,
      };

      const token = getState().Login.token;
      const page = getState().Announcements.page;

      const data = await api.announcements.changeAnnouncementStatus(
        payloadData,
        token,
      );
      dispatch(ac.changeAnnouncementStatus.success(data));

      dispatch(getAnnouncements({ page }));

      notify('success', notificationRef, 'Status updated');
    } catch (err) {
      notify('danger', notificationRef, err.message || 'Something went wrong!');
      dispatch(ac.changeAnnouncementStatus.error(err));
    }
  };
}

export function setField(field, value) {
  return (dispatch) => dispatch(ac.setField({ field, value }));
}

export function setAnnouncementField(field, value) {
  return (dispatch) => dispatch(ac.setAnnouncementField({ field, value }));
}

export function setAnnouncement(announcement) {
  return (dispatch) => dispatch(ac.setAnnouncement(announcement));
}

export function resetForm() {
  return (dispatch) => dispatch(ac.resetForm());
}

export function setTopicField(field, value) {
  return (dispatch) => dispatch(ac.setTopicField({ field, value }));
}
