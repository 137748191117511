import * as ac from './actionCreators';
import api from '../../api';
import { logoutOnUnauthorizedError } from '../../utils/auth';

export function getReferrals(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.getReferrals.pending());
    try {
      const payloadData = {
        page: payload.page,
        query: payload.referral_query,
        user_id: payload.user_id
      }
      const token = getState().Login.token;
      const data = await api.users.getReferrals(payloadData, token);
      dispatch(ac.getReferrals.success(data));
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.getReferrals.error(err));
    }
  };
}

export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}