import React from 'react';
import DateTimePicker from 'react-datetime-picker';

// reactstrap components
import { Col, FormGroup, Input as InputField, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import HtmlEditorContainer from './HtmlEditorContainer';
import ImageUploaderContainer from './ImageUploaderContainer';
import DocumentsUploaderContainer from './DocumentsUploaderContainer';

export function renderHtmlEditor(
  props,
  label,
  prop,
  disabled,
  labelProps = {},
  colProps,
) {
  const { object, status } = props;
  const { md: labelMd = 4, ...labelRestProps } = labelProps;

  return (
    <FormGroup className="row">
      {label && (
        <Label className="form-control-label" md={labelMd} {...labelRestProps}>
          {label}
        </Label>
      )}
      <Col md={label ? 12 - labelMd : 12} {...colProps}>
        <HtmlEditorContainer
          value={object && object[prop] ? object[prop] : ''}
          onChange={(value) => props.onChange(prop, value)}
          disabled={disabled}
        />
        <span className="help-block error">
          {status &&
            status.error &&
            status.error.data &&
            status.error.data.field_errors &&
            status.error.data.field_errors[prop]}
        </span>
      </Col>
    </FormGroup>
  );
}

export function renderImageUploader(
  props,
  label,
  prop,
  disabled,
  labelProps,
  colProps,
) {
  const { object, status } = props;
  return (
    <FormGroup className="row">
      <Label className="form-control-label" md="4" {...labelProps}>
        {label}
      </Label>
      <Col md="8" {...colProps}>
        <ImageUploaderContainer
          value={object && object[prop] ? object[prop] : undefined}
          onChange={(value) => props.onChange(prop, value)}
          setValid={(value) => props.onChange('isValidImage', value)}
          disabled={disabled}
        />
        <span className="help-block error">
          {status &&
            status.error &&
            status.error.data &&
            status.error.data.field_errors &&
            status.error.data.field_errors[prop]}
        </span>
      </Col>
    </FormGroup>
  );
}

export function renderDocumentUploader(
  props,
  label,
  prop,
  disabled,
  labelProps,
  colProps,
) {
  const { object, status, onChange } = props;

  return (
    <FormGroup className="row">
      <Label className="form-control-label" md="4" {...labelProps}>
        {label}
      </Label>
      <Col md="8" {...colProps}>
        <DocumentsUploaderContainer
          value={object && object[prop] ? object[prop] : undefined}
          onChange={(value) => onChange(prop, value)}
          setValid={(value) => onChange('isValidDoc', value)}
          disabled={disabled}
        />
        <span className="help-block error">
          {status &&
            status.error &&
            status.error.data &&
            status.error.data.field_errors &&
            status.error.data.field_errors[prop]}
        </span>
      </Col>
    </FormGroup>
  );
}

export function renderABasicInput(
  props,
  label,
  prop,
  type = 'text',
  disabled = false,
  maxLength = 0,
  labelProps,
  colProps,
) {
  const { object, status } = props;
  return (
    <FormGroup className="row">
      <Label className="form-control-label" md="4" {...labelProps}>
        {label}
      </Label>
      <Col md="8" {...colProps}>
        <InputField
          value={prop in object ? object[prop] : ''}
          type={type}
          disabled={disabled}
          autoComplete="off"
          maxLength={maxLength ? maxLength : ''}
          onChange={(e) => props.onChange(prop, e.target.value)}
        />

        <span className="help-block error">
          {status &&
            status.error &&
            status.error.data &&
            status.error.data.field_errors &&
            status.error.data.field_errors[prop]}
        </span>
      </Col>
    </FormGroup>
  );
}

export function renderCheckboxInput(
  props,
  label,
  prop,
  disabled = false,
  labelProps,
  colProps,
) {
  const { object } = props;
  return (
    <FormGroup className="row">
      <Label className="form-control-label" md="4" {...labelProps}>
        {label}
      </Label>
      <Col md="2" className="col-form-label" {...colProps}>
        <label className="mr-1">
          <input
            type="checkbox"
            className="feature-checkbox"
            onChange={(e) => props.onChange(prop, e.target.checked)}
            checked={prop in object ? object[prop] : true}
          />
        </label>
      </Col>
    </FormGroup>
  );
}

export function renderToggleCheckBox(
  label,
  prop,
  object,
  onChangeCheckBox,
  className,
) {
  return (
    <div className={`d-flex align-items-center ${className ? className : ''}`}>
      <Label className="form-control-label mr-3">{label}</Label>
      <label className="custom-toggle custom-toggle-info mr-1 mb-1">
        <input
          type="checkbox"
          onClick={(e) => onChangeCheckBox(prop, e)}
          checked={object[prop]}
        />
        <span
          className="custom-toggle-slider rounded-circle"
          data-label-off="OFF"
          data-label-on="ON"
        />
      </label>
    </div>
  );
}

export function renderSelectInput(
  props,
  label,
  options,
  prop,
  disabled,
  labelProps = {},
  colProps = {},
) {
  const { object, status } = props;
  const defaultValue = object && object[prop] ? object[prop] : '';
  return (
    <FormGroup className="row">
      <Label className="form-control-label" md="4" {...labelProps}>
        {label}
      </Label>
      <Col md="8" {...colProps}>
        <InputField
          type="select"
          value={defaultValue}
          disabled={disabled}
          onChange={(e) => props.onChange(prop, e.target.value)}
        >
          {options.map((option) => (
            <option key={option.value || option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </InputField>

        <span className="help-block error">
          {status &&
            status.error &&
            status.error.data &&
            status.error.data.field_errors &&
            status.error.data.field_errors[prop]}
        </span>
      </Col>
    </FormGroup>
  );
}

export function renderADateInput(
  props,
  label,
  prop,
  type = 'text',
  disabled = false,
  maxLength = 0,
) {
  const { object, status } = props;
  return (
    <FormGroup className="row">
      <Label className="form-control-label" md="4">
        {label}
      </Label>
      <Col md="8">
        <div className="input-groupx">
          <label className="DatePickerWrap col-md-12 padding-none">
            <DatePicker
              showYearDropdown
              showMonthDropdown
              dateFormatCalendar="MMMM"
              scrollableYearDropdown
              yearDropdownItemNumber={75}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              className="form-control"
              filterDate={(date) => moment() > date}
              selected={object[prop] ? moment(object[prop]).toDate() : null}
              onChange={(e) => props.onChange(prop, e)}
            />
            <span className="input-group-append">
              <i className="fa fa-calendar" />
            </span>
          </label>
        </div>

        <span className="help-block error">
          {status &&
            status.error &&
            status.error.data &&
            status.error.data.field_errors &&
            status.error.data.field_errors[prop]}
        </span>
      </Col>
    </FormGroup>
  );
}

export function renderADateTimeInput(props, label, prop, disabled = false) {
  const { object, status } = props;

  return (
    <FormGroup className="row">
      <Label className="form-control-label" md="4">
        {label}
      </Label>
      <Col md="8">
        <div className="input-groupx">
          <label className="DatePickerWrap col-md-12 padding-none">
            <DateTimePicker
              disabled={disabled}
              onChange={(e) => props.onChange(prop, e)}
              value={object[prop] || null}
            />
          </label>
        </div>

        <span className="help-block error">
          {status &&
            status.error &&
            status.error.data &&
            status.error.data.field_errors &&
            status.error.data.field_errors[prop]}
        </span>
      </Col>
    </FormGroup>
  );
}

export const Checkbox = React.forwardRef((props, ref) => {
  const { label, name, checked, disabled, labelProps, colProps, ...restProps } =
    props;

  return (
    <FormGroup className="row">
      <Label className="form-control-label" md="5" {...labelProps}>
        {label}
      </Label>
      <Col className="col-form-label" md="2" {...colProps}>
        <label className="custom-toggle custom-toggle-info mr-1">
          <input
            type="checkbox"
            name={name}
            ref={ref}
            checked={checked}
            disabled={disabled}
            {...restProps}
          />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="No"
            data-label-on="Yes"
          />
        </label>
      </Col>
    </FormGroup>
  );
});

export const Input = React.forwardRef((props, ref) => {
  const {
    value,
    error,
    label,
    type = 'text',
    disabled = false,
    readOnly,
    maxLength = 0,
  } = props;
  return (
    <FormGroup className="row">
      <Label className="form-control-label" md="4">
        {label}
      </Label>
      <Col md="8">
        <InputField
          value={value}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          innerRef={ref}
          autoComplete="off"
          maxLength={maxLength ? maxLength : ''}
        />

        <span className="help-block error">{error}</span>
      </Col>
    </FormGroup>
  );
});
