import React from "react";
import {Col, FormGroup, Label, Row } from "reactstrap";
import {renderToggleCheckBox} from "../Inputs"

export default class SecurityForm extends React.Component {

  textForModal = (prop) => {
    switch (prop) {
      case 'trade_password_exists':
        return "Are you sure you want to disable the trade password?";
      case 'detect_ip_change':
        return "Are you sure you want to change the detect IP status?";
      case 'anti_phishing_exists':
        return "Are you sure you want to disable the antiphishing key?";
      case 'google_2fa_exists':
        return "Are you sure you want to disable the google authentication?";
      case 'sms_2fa_exists':
        return "Are you sure you want to disable the SMS authentication?";
      case 'block_deposit_crypto':
        return "Are you sure you want to change BLOCK STATE for DEPOSIT CRYPTO feature?";
      case 'block_deposit_fiat':
        return "Are you sure you want to change BLOCK STATE for DEPOSIT FIAT feature?";
      case 'block_withdraw_crypto':
        return "Are you sure you want to change BLOCK STATE for WITHDRAW CRYPTO feature?";
      case 'block_withdraw_fiat':
        return "Are you sure you want to change BLOCK STATE for WITHDRAW FIAT feature?";
      default:
        return '';
    }
  };

  onChangeCheckBox = (prop) => {
    const {object} = this.props;
    this.props.toggleWarningModal(this.textForModal(prop), prop);
    this.props.onChange(prop, !object[prop]);
  };

  renderCheckBox = (label, prop) => {
    const {object} = this.props;
    return (
      <FormGroup className="row">
        <Label className="form-control-label" md="5">{label}</Label>
        <Col md="2" className="col-form-label">
          <label className="custom-toggle custom-toggle-info mr-1">
            <input type="checkbox"
              onClick={(e) => this.onChangeCheckBox(prop, e)}
              checked={object[prop]}
              disabled={prop !== 'detect_ip_change' ? object[prop] === false : false}
            />
            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"/>
          </label>
        </Col>
      </FormGroup>
    )
  };


  render() {
    const {object} = this.props;

    return (
      <div>
        {/* <Form>
          <Row>
            <Col md="6">{renderABasicInput(this.props, "Default maker fee", "default_maker_fee")}</Col>
            <Col md="6">{renderABasicInput(this.props, "Default taker fee", "default_taker_fee")}</Col>
          </Row>

          <Row>
            <Col md="6">{renderABasicInput(this.props, "Maker fee", "maker_fee", "text", true)}</Col>
            <Col md="6">{renderABasicInput(this.props, "Taker fee", "taker_fee", "text", true)}</Col>
          </Row>
          <Row className="flex-end-align col-md-12 no-padding-no-margin">
            <Button color="success" type="button" className="float-right"
                    onClick={() => this.props.updateSettings(this.props.user_id, this.props.object)}>
              Update settings
            </Button>
          </Row>
        </Form> */}
          <div className="margin-top-20">
            <Row>
              <Col md="6">{this.renderCheckBox("Detect IP change", "detect_ip_change")}</Col>
              <Col md="6">{this.renderCheckBox("Google authentication", "google_2fa_exists")}</Col>
            </Row>
            <Row>
              <Col md="6">{this.renderCheckBox("Trade password", "trade_password_exists")}</Col>
              <Col md="6">{this.renderCheckBox("SMS authentication", "sms_2fa_exists")}</Col>
            </Row>
            <Row>
              <Col md="6">{this.renderCheckBox("Antiphishing key", "anti_phishing_exists")}</Col>
              <Col md="4">
                <div className='d-flex align-items-center justify-content-between mb-2'>
                  <Label className="form-control-label">Block withdrawals</Label> <div className='d-flex'>{renderToggleCheckBox("Crypto", "block_withdraw_crypto", object,this.onChangeCheckBox)} <div className='ml-4'>{renderToggleCheckBox("Fiat", "block_withdraw_fiat", object,this.onChangeCheckBox)}</div></div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <Label className="form-control-label">Block deposits</Label> <div className='d-flex'>{renderToggleCheckBox("Crypto", "block_deposit_crypto", object,this.onChangeCheckBox)} <div className='ml-4'>{renderToggleCheckBox("Fiat", "block_deposit_fiat", object,this.onChangeCheckBox)}</div></div>
                </div>
              </Col>
            </Row>
          </div>
      </div>
    );
  }
}

