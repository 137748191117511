const module = "OTC";

export const GET_MY_OWN_OTC_ORDERS_PENDING = `${module}/GET_MY_OWN_OTC_ORDERS_PENDING`
export const GET_MY_OWN_OTC_ORDERS_SUCCESS = `${module}/GET_MY_OWN_OTC_ORDERS_SUCCESS`
export const GET_MY_OWN_OTC_ORDERS_ERROR = `${module}/GET_MY_OWN_OTC_ORDERS_ERROR`

export const GET_ALL_OTC_ORDERS_PENDING = `${module}/GET_ALL_OTC_ORDERS_PENDING`
export const GET_ALL_OTC_ORDERS_SUCCESS = `${module}/GET_ALL_OTC_ORDERS_SUCCESS`
export const GET_ALL_OTC_ORDERS_ERROR = `${module}/GET_ALL_OTC_ORDERS_ERROR`

export const GET_OTC_ORDER_DOCUMENT_PENDING = `${module}/GET_OTC_ORDER_DOCUMENT_PENDING`
export const GET_OTC_ORDER_DOCUMENT_SUCCESS = `${module}/GET_OTC_ORDER_DOCUMENT_SUCCESS`
export const GET_OTC_ORDER_DOCUMENT_ERROR = `${module}/GET_OTC_ORDER_DOCUMENT_ERROR`

export const ATTACH_DOCUMENT_TO_OTC_ORDER_PENDING = `${module}/ATTACH_DOCUMENT_TO_OTC_ORDER_PENDING`
export const ATTACH_DOCUMENT_TO_OTC_ORDER_SUCCESS = `${module}/ATTACH_DOCUMENT_TO_OTC_ORDER_SUCCESS`
export const ATTACH_DOCUMENT_TO_OTC_ORDER_ERROR = `${module}/ATTACH_DOCUMENT_TO_OTC_ORDER_ERROR`

export const SET_OTC_DOCUMENT_FIELD = `${module}/SET_OTC_DOCUMENT_FIELD`

export const RESET_OTC_DOCUMENT = `${module}/RESET_OTC_DOCUMENT`