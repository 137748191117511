import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Announcements from "../components/index";
import AnnouncementsState from "../../../redux/Announcements";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getAnnouncements: AnnouncementsState.actions.getAnnouncements,
      setField: AnnouncementsState.actions.setField,
      resetForm: AnnouncementsState.actions.resetForm,
      changeAnnouncementStatus:
        AnnouncementsState.actions.changeAnnouncementStatus,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  announcements: AnnouncementsState.selectors.announcements(state),
  announcementsMeta: AnnouncementsState.selectors.announcementsMeta(state),
});

export default connect(mapStateToProps, mapActionsToProps)(Announcements);
