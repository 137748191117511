import React from "react";
import UserTabs from "components/Tabs/user.jsx";
import { isEmpty } from "lodash";

import { Card, Container } from "reactstrap";
import DarkTable from "../../../components/Table";

export default class BonusContracts extends React.Component {
  ticker = null;
  state = {
    page: 1,
    startDateFilterSelected: null,
    endDateFilterSelected: null,
  };

  tick = () => {
    const { user_id } = this.props.match.params;
    this.props.getUserBonusContracts({ ...this.props, ...this.state, user_id });
  };

  componentDidMount = () => {
    const initializeData = async() => {
      await this.props.getCoins();

      this.tick();
      this.ticker = setInterval(this.tick, 5000);
    }

    initializeData();
  };

  componentWillUnmount() {
    if (this.ticker) {
      clearInterval(this.ticker);
    }
  }

  getCoinsList = () => {
    return [
      { label: "All", value: "" },
      ...this.props.coins.map((coin) => ({
        label: coin.symbol.toUpperCase(),
        value: coin.symbol,
      })),
    ];
  };

  setContractsFilter = async (value) => {
    const { user_id } = this.props.match.params;

    await this.props.setField("contracts_filter", value);

    this.props.getUserBonusContracts({
      ...this.props,
      ...this.state,
      user_id,
      page: 1,
    });
  };

  setStartDate = (date) => {
    this.setState({ startDateFilterSelected: date }, () => {
      const { user_id } = this.props.match.params;

      this.props.getUserBonusContracts({
        ...this.props,
        ...this.state,
        user_id,
        page: 1,
      });
    });
  };

  setEndDate = (date) => {
    this.setState({ endDateFilterSelected: date }, () => {
      const { user_id } = this.props.match.params;

      this.props.getUserBonusContracts({
        ...this.props,
        ...this.state,
        user_id,
        page: 1,
      });
    });
  };

  renderDetails = (bonusContract) => {

    return <DarkTable
      light
      tableTitle='Details'
      columns={{
        term: "Days",
        ltv: "LTV %",
        progress_in_percent: "Progress %",
        volume_to_trade: "Volume To Trade",
        volume_traded: "Volume Traded",
        total_bonus: "Total Bonus",
        ProfitLoss: "Deposited + Earned"
      }}
      rows={[bonusContract]}
    />;
  };

  renderViewBonusAccountsSection = () => {
    const bonusContracts = !isEmpty(this.props.bonusContracts) ?  this.props.bonusContracts.map((item) => {
        return {
          ...item,
          details: this.renderDetails(item),
        };
      })
      : [];


    return (
      <DarkTable
        light
        detailsColumn
        inlineFilter={this.setContractsFilter}
        inlineFilterData={this.getCoinsList()}
        inlineFilterSelected={this.props.contracts_filter}
        startDateFilter={this.setStartDate}
        endDateFilter={this.setEndDate}
        startDateFilterSelected={this.state.startDateFilterSelected}
        endDateFilterSelected={this.state.endDateFilterSelected}
        startDateFilterPlaceholder="To created date"
        endDateFilterPlaceholder="From created date"
        tableTitle="Bonus Contracts"
        columns={{
          id: "ID",
          mode: "Mode",
          created_at: "Date & Time",
          expired_at: "Expired At",
          coin_symbol: "Coin",
          amount: "Deposit",
          bonus_percents: "Total Bonus %",
          status: "Status",
          current_bonus: "Current Bonus",
        }}
        rows={bonusContracts}
      />
    );
  };

  render() {
    const { match } = this.props;

    return (
      <>
        <UserTabs match={match} />
        <Container className="mt--6" fluid>
          <Card className="user_content">
            {this.renderViewBonusAccountsSection()}
          </Card>
        </Container>
      </>
    );
  }
}
