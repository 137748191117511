import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import UsersOrders from '../components/orders.jsx';
import State from '../../../redux/Users';
import LoginState from '../../../redux/Login';
import MarketsState from '../../../redux/Markets';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getUserOrders: State.actions.getUserOrders,
      getMarkets: MarketsState.actions.getMarkets,
      cancelOrder: MarketsState.actions.cancelOrder,
      setField: State.actions.setField,
    },
    dispatch
  );

const mapStateToProps = state => ({
  token: LoginState.selectors.token(state),
  status: State.selectors.status(state),
  account: State.selectors.account(state),
  orders: State.selectors.orders(state),
  ordersmeta: State.selectors.ordersmeta(state),
  orders_query: State.selectors.orders_query(state),
  orders_filter: State.selectors.orders_filter(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(UsersOrders);

