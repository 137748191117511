import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BonusContracts from "../components/bonusContracts";
import State from "../../../redux/Users";
import CoinsAndChainsState from "../../../redux/CoinsAndChains";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserBonusContracts: State.actions.getUserBonusContracts,
      setField: State.actions.setField,
      getCoins: CoinsAndChainsState.actions.getCoins,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: State.selectors.status(state),
  bonusContracts: State.selectors.bonusContracts(state),
  account: State.selectors.account(state),
  contracts_filter: State.selectors.contracts_filter(state),
  coins: CoinsAndChainsState.selectors.coins(state),
});

export default connect(mapStateToProps, mapActionsToProps)(BonusContracts);
