import React from "react";
import {Col, Form, Row} from "reactstrap";
import { renderABasicInput, renderSelectInput } from "../Inputs"

export default class AddMarketView extends React.Component {
  

  getCoinTypes = () => {
    return [
      {label: 'crypto', value: 'crypto'},
      {label: 'asset', value: 'asset'},
      {label: 'fiat', value: 'fiat'}
    ]
  }

  getCoinSymbols = () => {
    const { coins } = this.props;
    const symbols = coins.map(item => ({label: item.symbol, value: item.symbol}));
    symbols.unshift({label: "", value: ""});
    return symbols;
  }

  getMarketStatus = () => {
    return [
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'},
      {label: 'Disabled', value: 'disabled'}
    ]
  };

  render() { 
    const { object } = this.props; 
    return (
      <>
      {object ? 
      <Form>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Market Name", "name")}</Col>
          <Col md="4">{renderSelectInput(this.props, "Status", this.getMarketStatus(), "status")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderSelectInput(this.props, "Market Symbol", this.getCoinSymbols(), "market_coin_symbol")}</Col>
          <Col md="4">{renderSelectInput(this.props, "Quote Symbol", this.getCoinSymbols(), "quote_coin_symbol")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Market precision", "market_precision", "number", object['id'])}</Col>
          <Col md="4">{renderABasicInput(this.props, "Quote precision", "quote_precision", "number", object['id'])}</Col>
        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Market precision Format", "market_precision_format", "number")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Quote precision Format", "quote_precision_format", "number")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Min market volume", "min_market_volume", "number")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Min quote volume", "min_quote_volume", "number")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Max market price", "max_market_price", "number")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Max quote price", "max_quote_price", "number")}</Col>
        </Row>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Max USDT spend limit  ", "max_usdt_spend_limit", "number")}</Col>
          <Col md="4"></Col>
        </Row>
      </Form>
      : null }
      </>
    );
  }
}

