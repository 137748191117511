import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ControlPanel from "../components/control_panel.jsx";
import State from "../../../redux/Users";
import LoginState from "../../../redux/Login";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: State.actions.getUser,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  currentUser: State.selectors.currentUser(state),
  status: State.selectors.status(state),
  token: LoginState.selectors.token(state),
});

export default connect(mapStateToProps, mapActionsToProps)(ControlPanel);
