import { KYB_GENERAL_STATUS, KYB_STEP_STATUS } from '../constants/kyb';

export const getKybStepStatus = (status, step) => {
  if (!status) return;

  switch (step) {
    case 0:
      if (status.StepOneStatus === KYB_STEP_STATUS.STEP_ONE_FAIL)
        return KYB_GENERAL_STATUS.FAIL;
      if (status.StepOneStatus === KYB_STEP_STATUS.STEP_ONE_PENDING)
        return KYB_GENERAL_STATUS.PENDING;
      if (status.StepOneStatus === KYB_STEP_STATUS.STEP_ONE_SUCCESS)
        return KYB_GENERAL_STATUS.SUCCESS;

      return KYB_GENERAL_STATUS.NONE;

    case 1:
      if (status.StepTwoStatus === KYB_STEP_STATUS.STEP_TWO_FAIL)
        return KYB_GENERAL_STATUS.FAIL;
      if (status.StepTwoStatus === KYB_STEP_STATUS.STEP_TWO_PENDING)
        return KYB_GENERAL_STATUS.PENDING;
      if (status.StepTwoStatus === KYB_STEP_STATUS.STEP_TWO_SUCCESS)
        return KYB_GENERAL_STATUS.SUCCESS;

      return KYB_GENERAL_STATUS.NONE;

    case 2:
      if (status.StepThreeStatus === KYB_STEP_STATUS.STEP_THREE_FAIL)
        return KYB_GENERAL_STATUS.FAIL;
      if (status.StepThreeStatus === KYB_STEP_STATUS.STEP_THREE_PENDING)
        return KYB_GENERAL_STATUS.PENDING;
      if (status.StepThreeStatus === KYB_STEP_STATUS.STEP_THREE_SUCCESS)
        return KYB_GENERAL_STATUS.SUCCESS;

      return KYB_GENERAL_STATUS.NONE;

    case 3:
      if (status.StepFourStatus === KYB_STEP_STATUS.STEP_FOUR_FAIL)
        return KYB_GENERAL_STATUS.FAIL;
      if (status.StepFourStatus === KYB_STEP_STATUS.STEP_FOUR_PENDING)
        return KYB_GENERAL_STATUS.PENDING;
      if (status.StepFourStatus === KYB_STEP_STATUS.STEP_FOUR_SUCCESS)
        return KYB_GENERAL_STATUS.SUCCESS;

      return KYB_GENERAL_STATUS.NONE;

    default:
      return status.Status;
  }
};

export const getKybColorByStatus = (status) => {
  switch (status) {
    case KYB_GENERAL_STATUS.NONE:
      return 'secondary';
    case KYB_GENERAL_STATUS.FAIL:
      return 'danger';
    case KYB_GENERAL_STATUS.PENDING:
      return 'warning';
    case KYB_GENERAL_STATUS.SUCCESS:
      return 'success';

    default:
      return 'secondary';
  }
};

const isKybStepNone = (step, status) => {
  switch (step) {
    case 0:
      return status.StepOneStatus === KYB_STEP_STATUS.STEP_ONE_NONE;
    case 1:
      return status.StepTwoStatus === KYB_STEP_STATUS.STEP_TWO_NONE;
    case 2:
      return status.StepThreeStatus === KYB_STEP_STATUS.STEP_THREE_NONE;
    case 3:
      return status.StepFourStatus === KYB_STEP_STATUS.STEP_FOUR_NONE;
    default:
      return false;
  }
};

export const isKybEveryStepNone = (status, steps) => {
  return steps.every((step) => isKybStepNone(step, status));
};
