import React from 'react';
import history from '../../../history';
import AnnouncementForm from 'components/AnnouncementForm/index.jsx';
import SuccessModal from '../../../components/Modal/success.jsx';

import { Card, CardBody, Container, CardHeader, Button } from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.jsx';

export default class announcementDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blockModal: false,
    };
  }

  async componentDidMount() {
    const announcement_id = this.props.match.params.announcement_id;

    if (announcement_id) {
      this.props.getAnnouncement(announcement_id);
    }

    this.props.getAnnouncementSettings();
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  toggleModal = () => {
    this.setState({
      blockModal: !this.state.blockModal,
    });
  };

  renderConfirmationModal = () => {
    const response = 'Saved!';

    return (
      <SuccessModal
        isOpen={this.state.blockModal}
        toggleFunction={() => {
          const {
            match: {
              params: { announcement_id },
            },
          } = this.props;
          if (!announcement_id) history.push('/manage-announcements');
          else this.toggleModal();
        }}
        closeFunction={() => {
          const {
            match: {
              params: { announcement_id },
            },
          } = this.props;
          if (!announcement_id) history.push('/manage-announcements');
          else this.toggleModal();
        }}
        body={response}
      />
    );
  };

  cancel = () => {
    history.push('/manage-announcements');
  };

  addAnnouncement = async (e, status = 'published') => {
    await this.props.addAnnouncement(this.props, status);

    if (this.props.status.success) {
      this.toggleModal();
    }
  };

  editAnnouncement = async () => {
    await this.props.editAnnouncement(this.props.announcement);

    if (this.props.status.success) {
      this.toggleModal();
    }
  };

  render() {
    const {
      announcement,
      status,
      setAnnouncementField,
      announcementsSettings,
    } = this.props;
    const announcement_id = this.props.match.params.announcement_id;

    const action_text = announcement_id
      ? 'Edit announcement'
      : 'Add announcement';

    return (
      <>
        <SimpleHeader name={action_text} parentName="Manage Announcements" />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader>
              <h3 className="mb-0">{action_text}</h3>
            </CardHeader>

            <CardBody>
              <AnnouncementForm
                status={status}
                object={announcement}
                onChange={setAnnouncementField}
                announcementsSettings={announcementsSettings}
              />

              <br />

              <Button
                color="info"
                type="button"
                className="float-right ml-3"
                onClick={this.cancel}
              >
                Cancel
              </Button>

              <Button
                color="success"
                type="button"
                className="float-right ml-3"
                onClick={
                  announcement_id ? this.editAnnouncement : this.addAnnouncement
                }
              >
                {announcement_id ? 'Edit' : 'Publish'}
              </Button>

              {!announcement_id ? (
                <Button
                  color="primary"
                  type="button"
                  className="float-right"
                  onClick={(e) => this.addAnnouncement(e, 'draft')}
                >
                  Draft
                </Button>
              ) : null}

              <br />

              <span className="error">
                {status.error && status.error.data && status.error.data.error}
              </span>
            </CardBody>
          </Card>
          {this.renderConfirmationModal()}
        </Container>
      </>
    );
  }
}
