import React from "react";
import * as storage from './storage';
import Bignumber from 'bignumber.js';
import moment from 'moment';

let Registry = {};

/**
 * This function returns the full name of the user
 * @param user
 * @return {string}
 */
export const fullName = (user) => {

  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }
  else if(user.first_name){
    return `${user.first_name}`;
  }
  else if(user.last_name){
    return `${user.last_name}`;
  }
  
  return '';
};

export const notify = (type, ref, title, message) => {
    let options = {
        place: "tr",
        message: (
            <div className="alert-text">
                    <span className="alert-title" data-notify="title">{title}</span>
                <span data-notify="message">{message}</span>
            </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 4
    };
    ref.notificationAlert(options);
};


export const eighteenYearsAgo = () => {
    const eighteenYears = new Date();
    eighteenYears.setTime(eighteenYears.valueOf() - 18 * 365 * 24 * 60 * 60 * 1000);
    return eighteenYears;
  };

export const hasPermission = (neededPermission) => {
    const perm = storage.get('perm');
    const permissions = perm ? perm.split(",") : [];
    
    return permissions.indexOf(neededPermission) !== -1;
};

export const hasUserRole = (neededRole, userData = {}) => {
  return userData.role_alias !== undefined && neededRole === userData.role_alias;
};

export const getKycPassedLevelByStatus = (status) => {
    switch (status) {
      case 'none':
      case 'step_one_fail':
        return 0;
      case 'step_one_success':
      case 'step_two_pending':
      case 'step_two_fail':
        return 1;
      case 'step_two_success':
      case 'step_three_pending':
      case 'step_three_fail':
      case 'step_three_success':
        return 2;
      default:
        return 0;
    }
}

export function getFilledPercent(filledAmount, amount) {
  return parseFloat(amount)
    ? `${((parseFloat(filledAmount) / parseFloat(amount)) * 100).toFixed(2)}%`
    : '0.00%';
}

function getBNInstance(precision) {
  if (!Registry[precision]) {
    const BN = Bignumber.clone({
      DECIMAL_PLACES: precision,
      ROUNDING_MODE: Bignumber.ROUND_DOWN
    });

    Registry[precision] = BN;
  }
  return Registry[precision];
}

export function formatNumber(number = 0, prec = 8, mode = null, withCommas = true) {
  const BN = getBNInstance(prec);
  return withCommas
    ? BN(number || 0).toFormat(prec, mode)
    : BN(number || 0).toFixed(prec, mode);
}

export function upperCaseFirstLetter(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const trimmedLowerCaseString = (str) => str ? str.trim().toLowerCase() : '';

export const socialNetworks = ['medium', 'website','telegram','twitter', 'instagram'];
export const prdxLines = ['silver','gold','platinum','black'];
export const blockChains = ['algoland', 'avalanche', 'armony', 'bsc', 'cardano','cosmos','chromia' ,'ethereum','glitch', 'neo', 'polkadot' ,'polygon', 'solana', 'terra', 'tomochain', 'tron' ];
export const timeZones = [
  // {label: 'GMT,	Greenwich Mean Time, GMT+0:00', value: '+0:00'},
  {label: 'UTC	Universal Coordinated Time	GMT+0:00', abr: 'UTC', value: '+00:00'},
  {label: 'ECT	European Central Time	GMT+1:00',abr: 'ECT',  value: '+01:00'},
  {label: 'EET	Eastern European Time	GMT+2:00',abr: 'EET',  value: '+02:00'},
  // {label: 'ART	(Arabic) Egypt Standard Time	GMT+2:00', value: '+2:00'},
  {label: 'EAT	Eastern African Time	GMT+3:00',abr: 'EAT',  value: '+03:00'},
  // {label: 'MET	Middle East Time	GMT+3:30',abr: 'MET',  value: '+03:30'},
  {label: 'NET	Near East Time	GMT+4:00',abr: 'NET',  value: '+04:00'},
  {label: 'PLT	Pakistan Lahore Time	GMT+5:00',abr: 'PLT',  value: '+05:00'},
  {label: 'IST	India Standard Time	GMT+5:30',abr: 'IST',  value: '+05:30'},
  {label: 'BST	Bangladesh Standard Time	GMT+6:00',abr: 'BST',  value: '+06:00'},
  {label: 'VST	Vietnam Standard Time	GMT+7:00',abr: 'VST',  value: '+07:00'},
  {label: 'CTT	China Taiwan Time	GMT+8:00',abr: 'CTT',  value: '+08:00'},
  {label: 'JST	Japan Standard Time	GMT+9:00',abr: 'JST',  value: '+09:00'},
  // {label: 'ACT	Australia Central Time	GMT+9:30',abr: 'ACT',  value: '+09:30'},
  {label: 'AET	Australia Eastern Time	GMT+10:00',abr: 'AET',  value: '+010:00'},
  // {label: 'SST	Solomon Standard Time	GMT+11:00', value: '+11:00'},
  {label: 'NST	New Zealand Standard Time	GMT+12:00',abr: 'NST',  value: '+12:00'},
  {label: 'MIT	Midway Islands Time	GMT-11:00',abr: 'MIT',  value: '-11:00'},
  {label: 'HST	Hawaii Standard Time	GMT-10:00',abr: 'HST',  value: '-10:00'},
  {label: 'AST	Alaska Standard Time	GMT-9:00',abr: 'AST',  value: '-09:00'},
  {label: 'PST	Pacific Standard Time	GMT-8:00',abr: 'PST',  value: '-08:00'},
  {label: 'PNT	Phoenix Standard Time	GMT-7:00',abr: 'PNT',  value: '-07:00'},
  // {label: 'MST	Mountain Standard Time	GMT-7:00', value: '-7:00'},
  {label: 'CST	Central Standard Time	GMT-6:00',abr: 'CST',  value: '-06:00'},
  {label: 'EST	Eastern Standard Time	GMT-5:00',abr: 'EST',  value: '-05:00'},
  // {label: 'IET	Indiana Eastern Standard Time	GMT-5:00', value: '-5:00'},
  {label: 'PRT	Puerto Rico and US Virgin Islands Time	GMT-4:00',abr: 'PRT',  value: '-04:00'},
  // {label: 'CNT	Canada Newfoundland Time	GMT-3:30',abr: 'CNT',  value: '-03:30'},
  // {label: 'AGT	Argentina Standard Time	GMT-3:00', value: '-3:00'},
  {label: 'BET	Brazil Eastern Time	GMT-3:00',abr: 'BET',  value: '-03:00'},
  {label: 'CAT	Central African Time	GMT-1:00',abr: 'CAT',  value: '-01:00'}
];

export const getDateStringWithZeroOffset = (date) => {
  let result = moment(date).format();

  result = result.substring(0, result.length - 6);
  result += '+00:00';

  return result;
}

export const getLocalizedWithZeroOffsetDate = (date, timezone) => {
  let timezoneAsNumber = Number(timezone.substring(0,3));
  let result = '';

  // +2 , subtract 2 hours to get UTC
  if(timezoneAsNumber >= 0){
    result = moment(date).subtract(timezoneAsNumber, 'hours'); //set zero offset
  }else{
    result = moment(date).add(timezoneAsNumber, 'hours'); //set zero offset
  }

  result = moment(result).format(); //convert ot moment format string
  result = result.substring(0,  result.length - 6); //remove TIMEZONE
  result = new Date(result); // make the date

  result.setHours(result.getHours() - (new Date().getTimezoneOffset() / 60));
  return result;
}

export const forceNumeric = (nr) => {
  return nr
    .replace(',', '.')
    .replace(/[^0-9.]/g, '')
    .replace(/((\d+)?\.(\d+)?)\.(.*)/g, '$1');
}

export const isValidNumber = (text, precision = 8, isInteger = false) => {
  const integerNumberRegexp = new RegExp('^\\d*$', 'gi');

  if (isInteger) {
    return integerNumberRegexp.test(text);
  }

  const decimalNumberRegexp = new RegExp(
    `^\\d+\\.{0,1}\\d{0,${precision}}$`,
    'gi'
  );

  return integerNumberRegexp.test(text) || decimalNumberRegexp.test(text);
};

export const downloadFile = (url, fileName) => {
  const link = document.createElement('a');

  link.href = url;
  link.download = fileName ? fileName : new Date().getTime();
  link._target = 'blank'

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}