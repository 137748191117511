import * as ac from './actionCreators';
import api from '../../api';
import { notify } from "../../utils";
import { logoutOnUnauthorizedError } from '../../utils/auth';
import { validateNotEmpty } from '../../utils/validator';
import { InvalidFields } from '../../utils/errors';
import moment from 'moment';

export function cancelOrder(orderId, marketId, notificationRef) { 
  return async (dispatch, getState) => {
    dispatch(ac.cancelOrder.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.cancelOrder(orderId, marketId, token);
      dispatch(ac.cancelOrder.success(data));
      notify("success", notificationRef, 'The order has been canceled!')
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.cancelOrder.error(err));
      notify("danger", notificationRef, 'Something went wrong!')
    }
  };
}

export function cancelMarketOrders(market, notificationRef) { 
  return async (dispatch, getState) => {
    dispatch(ac.cancelMarketOrders.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.cancelMarketOrders(market, token);
      dispatch(ac.cancelMarketOrders.success(data));
      notify("success", notificationRef, 'The orders have been canceled!')
    } catch (err) {
      if (logoutOnUnauthorizedError(err)) return;
      dispatch(ac.cancelMarketOrders.error(err));
      notify("danger", notificationRef, 'Something went wrong!')
    }
  };
}

export function getMarkets(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getMarkets.pending());
    try {
      const payloadData = {
        query:  payload.markets_query,
        limit:   payload.limit,
        page:   payload.page,
      }

      const token = getState().Login.token;
      const data = await api.assets.getMarkets(payloadData, token);
      dispatch(ac.getMarkets.success(data));
    } catch (err) {
      dispatch(ac.getMarkets.error(err));
    }
  };
}

export function getMarket(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getMarket.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.getMarket(payload, token);
      dispatch(ac.getMarket.success(data));
    } catch (err) {
      dispatch(ac.getMarket.error(err));
    }
  };
}

export function addMarket(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.addMarket.pending());
    const market = payload.market;
    try {
      let field_errors = {};
      if (!validateNotEmpty(market.name)) field_errors.name = 'Field is required';
      if (!validateNotEmpty(market.market_precision)) field_errors.market_precision = 'Field is required';
      if (!validateNotEmpty(market.market_precision_format)) field_errors.market_precision_format = 'Field is required';
      if (!validateNotEmpty(market.market_coin_symbol)) field_errors.market_coin_symbol = 'Field is required';
      if (!validateNotEmpty(market.quote_coin_symbol)) field_errors.quote_coin_symbol = 'Field is required';
      if (!validateNotEmpty(market.quote_precision)) field_errors.quote_precision = 'Field is required';
      if (!validateNotEmpty(market.quote_precision_format)) field_errors.quote_precision_format = 'Field is required';
      if (!validateNotEmpty(market.min_quote_volume)) field_errors.min_quote_volume = 'Field is required';
      if (!validateNotEmpty(market.min_market_volume)) field_errors.min_market_volume = 'Field is required';
      if (!validateNotEmpty(market.max_quote_price)) field_errors.max_quote_price = 'Field is required';
      if (!validateNotEmpty(market.max_market_price)) field_errors.max_market_price = 'Field is required';
      if (validateNotEmpty(market.market_coin_symbol) && validateNotEmpty(market.quote_coin_symbol) && (market.market_coin_symbol === market.quote_coin_symbol)) {
        field_errors.market_coin_symbol = field_errors.quote_coin_symbol = 'Market & Quote symbol cannot be the same';
      }
      if (!validateNotEmpty(market.max_usdt_spend_limit)) field_errors.max_usdt_spend_limit = 'Field is required';
    
      let marketExists = payload.markets.filter(item => {
        return item.market_coin_symbol === market.market_coin_symbol && item.quote_coin_symbol === market.quote_coin_symbol;
      });
      
      if (validateNotEmpty(market.market_coin_symbol) && validateNotEmpty(market.quote_coin_symbol) && marketExists.length) {
        field_errors.market_coin_symbol = field_errors.quote_coin_symbol = 'Market exists';
      }

      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });

      let payloadData = {
        name: market.name,
        status: market.status,
        baseCoin: market.market_coin_symbol,
        quoteCoin: market.quote_coin_symbol,
        basePrecision: market.market_precision,
        quotePrecision: market.quote_precision,
        basePrecisionFormat: market.market_precision_format,
        quotePrecisionFormat: market.quote_precision_format,
        baseMinVolume: market.min_market_volume,
        quoteMinVolume: market.min_quote_volume,
        baseMaxPrice: market.max_market_price,
        quoteMaxPrice: market.max_quote_price,
        maxUsdtSpendLimit: market.max_usdt_spend_limit
      }

      const token = getState().Login.token;
      const data = await api.assets.addMarket(payloadData, token);
      dispatch(ac.addMarket.success(data));
    } catch (err) {
      dispatch(ac.addMarket.error(err));
    }
  };
}

export function editMarket(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.editMarket.pending());
    try {
      let field_errors = {};
      if (!validateNotEmpty(payload.name)) field_errors.name = 'Field is required';
      if (!validateNotEmpty(payload.market_precision_format)) field_errors.market_precision_format = 'Field is required';
      if (!validateNotEmpty(payload.market_coin_symbol)) field_errors.market_coin_symbol = 'Field is required';

      if (!validateNotEmpty(payload.quote_coin_symbol)) field_errors.quote_coin_symbol = 'Field is required';
      if (!validateNotEmpty(payload.quote_precision_format)) field_errors.quote_precision_format = 'Field is required';

      if (!validateNotEmpty(payload.min_quote_volume)) field_errors.min_quote_volume = 'Field is required';
      if (payload.min_quote_volume*1 === 0) field_errors.min_quote_volume = 'Volume cannot be 0';
      if (!validateNotEmpty(payload.min_market_volume)) field_errors.min_market_volume = 'Field is required';
      if (payload.min_market_volume*1 === 0) field_errors.min_market_volume = 'Volume cannot be 0';

      if (!validateNotEmpty(payload.max_quote_price)) field_errors.max_quote_price = 'Field is required';
      if (payload.max_quote_price*1 === 0) field_errors.max_quote_price = 'Volume cannot be 0';
      if (!validateNotEmpty(payload.max_market_price)) field_errors.max_market_price = 'Field is required';
      if (payload.max_market_price*1 === 0) field_errors.max_market_price = 'Volume cannot be 0';

      if (validateNotEmpty(payload.market_coin_symbol) && validateNotEmpty(payload.quote_coin_symbol) && (payload.market_coin_symbol === payload.quote_coin_symbol)) {
        field_errors.market_coin_symbol = field_errors.quote_coin_symbol = 'Market & Quote symbol cannot be the same';
      }
      if (!validateNotEmpty(payload.max_usdt_spend_limit)) field_errors.max_usdt_spend_limit = 'Field is required';

      if (Object.keys(field_errors).length) throw new InvalidFields('Invalid form fields', { field_errors });

      let payloadData = {
        id: payload.id,
        name: payload.name,
        status: payload.status,
        // basePrecision: payload.market_precision,
        // quotePrecision: payload.quote_precision,
        basePrecisionFormat: payload.market_precision_format,
        quotePrecisionFormat: payload.quote_precision_format,
        baseMinVolume: payload.min_market_volume,
        quoteMinVolume: payload.min_quote_volume,
        baseMaxPrice: payload.max_market_price,
        quoteMaxPrice: payload.max_quote_price,
        maxUsdtSpendLimit: payload.max_usdt_spend_limit
      }

      const token = getState().Login.token;
      const data = await api.assets.editMarket(payloadData, token);
      dispatch(ac.editMarket.success(data));
    } catch (err) {
      dispatch(ac.editMarket.error(err));
    }
  };
}

export function disableMarket(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.disableMarket.pending());
    try {
      const token = getState().Login.token;
      const data = await api.assets.disableMarket(payload, token);
      dispatch(ac.disableMarket.success(data));
    } catch (err) {
      dispatch(ac.disableMarket.error(err));
    }
  };
}

export function getOrdersFromMarket(payload){
  const {email, type, side, date, status, pair} = payload.filter;
  return async (dispatch, getState) => {
    dispatch(ac.getOrdersFromMarket.pending());
    try {
      let payloadData = {
        page: payload.page,
        email,
        type,
        side,
        date: date ? moment(date).format() : "",
        status,
        pair
      };
      const token = getState().Login.token;
      const data = await api.assets.getOrdersFromMarket(payloadData, token);
      dispatch(ac.getOrdersFromMarket.success(data));
    } catch (err) {
      dispatch(ac.getOrdersFromMarket.error(err));
    }
  };
}

export function getVolumeDistributedPercent() {
  return async (dispatch, getState) => {
    dispatch(ac.getVolumeDistributedPercent.pending());
    try {
      const token = getState().Login.token;
      const data = await api.settings.getVolumeDistributedPercent(token);
      dispatch(ac.getVolumeDistributedPercent.success(data));
    } catch (err) {
      dispatch(ac.getVolumeDistributedPercent.error(err));
    }
  };
}

export function updateMarketSettings(data) {
  return async (dispatch, getState) => {
    dispatch(ac.updateMarketSettings.pending());
    try {
      const token = getState().Login.token;
      await api.settings.updateVolumeDistributedPercent(data.volumeDistributedPercent, token);
      dispatch(ac.updateMarketSettings.success());
    } catch (err) {
      dispatch(ac.updateMarketSettings.error(err));
    }
  };
}

export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}

export function setMarketField(field, value) {
  return dispatch => dispatch(ac.setMarketField({field, value}));
}

export function setSettingsField(field, value) {
  return dispatch => dispatch(ac.setSettingsField({field, value}));
}

export function setFilterField(field, value) {
  return dispatch => dispatch(ac.setFilterField({field, value}));
}

export function resetForm() {
  return dispatch => dispatch(ac.resetForm());
}