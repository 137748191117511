import React from "react";
import UserTabs from "components/Tabs/user.jsx";

import {Card, Container} from "reactstrap";
import DarkTable from "../../../components/Table";
import DangerModal from "../../../components/Modal/danger.jsx";
import NotificationAlert from "react-notification-alert";
import { hasPermission } from '../../../utils';
import api from '../../../api';
import { notify } from "../../../utils";

export default class DepositsView extends React.Component {

  state = {
    showForceConfirmModal: false,
    currentRow: null,
  };

  componentDidMount = () => {
    const user_id = this.props.match.params.user_id;
    this.props.getUserDeposits({user_id: user_id, page: 1});
  };

  componentWillUnmount(){
    this.props.setField('deposits_filter', '');
  }

  searchDeposits = async(e) => {
    const user_id = this.props.match.params.user_id;
    await this.props.setField('deposit_query', e.target.value);
    this.props.getUserDeposits({...this.props, user_id: user_id, page: 1});
  };

  setDepositFilter = async(value) => {
    const user_id = this.props.match.params.user_id;
    await this.props.setField('deposits_filter', value);
    this.props.getUserDeposits({...this.props, user_id: user_id, page: 1});
  };

  getStatusList = () => {
    return [
      {label: 'all', value: ''},
      {label: 'unconfirmed', value: 'unconfirmed'},
      {label: 'confirmed', value: 'confirmed'},
      {label: 'pending', value: 'pending'},
      {label: 'failed', value: 'failed'},
    ]
  };

  onDepositsPagingClick = (ev, page) => {
    const user_id = this.props.match.params.user_id;
    this.props.getUserDeposits({user_id, page});
  }

  // constructDeposits = () => {
  //   if (this.props.deposits && this.props.currentUser) {
  //     return this.props.deposits.map(item => {
  //       return ({
  //         ...item,
  //         email: this.props.currentUser.email,
  //       });
  //     });
  //   }
  //   return [];
  // }

  showForceConfirmModal = (event, row) => {
    event.preventDefault();
    this.toggleModal(row, "force_confirm");
  };

  getActionsArray = () => {
    return [
      {name: 'Force confirmation', onClick: this.showForceConfirmModal, permission: 'transaction.view.all'},
    ].filter(item => (
      (item.link !== '/user/:user_id' && hasPermission(item.permission)))
    )
  };

  renderViewDepositsSection = () => {
    return <DarkTable
      light={true}
      tableTitle="View Deposits"
      actionsColumn={false}
      inlineFilterSelected={this.props.deposits_filter}
      inlineSearch={this.searchDeposits}
      inlineFilter={this.setDepositFilter}
      inlineFilterData={this.getStatusList()}
      columns={{
        email: "User",
        status: "Status",
        amount: "Amount",
        external_system: "Payment Method",
        coin_symbol: "Coin",
        address: "Address",
        trx_id: "TX ID",
        created_at: "Date",
      }}
      actionsColumn={true}
      actions={this.getActionsArray()}
      rows={this.props.deposits}
      meta={this.props.depositsMeta}
      onPagingClick={this.onDepositsPagingClick}
    />
  };

  forceConfirm = (row) => { 
    const {token} = this.props;
    if (row.status != 'pending' && row.status != 'unconfirmed') {
      notify("warning", this.refs.notificationAlert, `Deposit not in pending/unconfirmed status`);
      this.toggleModal(row, "block");
      return
    }
    api.wallets.forceConfirm(row.id, token).then(resp => {
      notify("success", this.refs.notificationAlert, 'Deposit confirmation successful!')
    }).catch(err => {
      notify("danger", this.refs.notificationAlert, `${err}`)
    });
    this.toggleModal(row, "block");
  };

  toggleModal = (row, type) => {
    this.setState({
      showForceConfirmModal: type === "force_confirm" && !this.state.showForceConfirmModal,
      currentRow: row,
    });
  };

  renderForceConfirmModal = () => {
    const {currentRow} = this.state;
    return (
      <DangerModal
        isOpen={this.state.showForceConfirmModal}
        toggleFunction={() => this.toggleModal(currentRow, "force_confirm")}
        closeFunction={() => this.toggleModal(currentRow, "force_confirm")}
        nextFunction={() => this.forceConfirm(currentRow)}
        body={
          <>
            <p>Are you sure you want to force the confirmation of this deposit?</p>
            <p>The locked funds from the deposit will be moved to the available balance. This action cannot be reverted.</p>
          </>
        }
      />
    )
  };

  render() {
    const {match} = this.props;
    return (
      <>
      <UserTabs match={match}/>
      <Container className="mt--6" fluid>
        <Card className="user_content">
          {this.renderViewDepositsSection()}
          {this.renderForceConfirmModal()}
          <div className="rna-wrapper">
            <NotificationAlert ref="notificationAlert"/>
          </div>
        </Card>
      </Container>
      </>
    );
  }
}