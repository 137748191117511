/* globals localStorage */

export function set(key, value, def = null) {
  return localStorage.setItem(key, value) || def;
}

export function get(key, value, def = null) {
  return localStorage.getItem(key, value) || def;
}

export function remove(key) {
  return localStorage.removeItem(key);
}

export function clear() {
  return localStorage.clear();
}
