import * as ac from './actionCreators';
import api from '../../api';

export function getTradesPerDay(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getTradesPerDay.pending());
    try {
      const token = getState().Login.token;
      const data = await api.dashboard.getTradesPerDay(payload, token);
      dispatch(ac.getTradesPerDay.success(data));
    } catch (err) {
      dispatch(ac.getTradesPerDay.error(err));
    }
  };
}

export function getFeesPerDay(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getFeesPerDay.pending());
    try {
      const token = getState().Login.token;
      const coins = getState().CoinsAndChains.coins;

      const data = await Promise.all([
        api.dashboard.getFeesPerDay({...payload, mode: 'bot'}, token),
        api.dashboard.getFeesPerDay({...payload, mode: 'manual'}, token),
        // api.dashboard.getFeesPerDay(payload, token),
      ]);

      dispatch(ac.getFeesPerDay.success({data, coins}));
    } catch (err) {
      dispatch(ac.getFeesPerDay.error(err));
    }
  };
}

export function getGeneralStatistics(payload = {}) {
  return async (dispatch, getState) => {
    dispatch(ac.getGeneralStatistics.pending());
    try {
      const token = getState().Login.token;
      const data = await api.dashboard.getGeneralStatistics(payload, token);
      dispatch(ac.getGeneralStatistics.success(data));
    } catch (err) {
      dispatch(ac.getGeneralStatistics.error(err));
    }
  };
}

export function getBotsStatistics() {
  return async (dispatch, getState) => {
    dispatch(ac.getBotsStatistics.pending()); 
    try {
      const token = getState().Login.token;
      const data = await api.dashboard.getBotsStatistics(token);
      dispatch(ac.getBotsStatistics.success(data));
    } catch (err) {
      dispatch(ac.getBotsStatistics.error(err));
    }
  };
}

export function getUsersPerLevel(payload = {}) {
  return async (dispatch, getState) => {
    dispatch(ac.getUsersPerLevel.pending());
    try {
      const token = getState().Login.token;
      const data = await api.dashboard.getGeneralStatistics(payload, token);
      dispatch(ac.getUsersPerLevel.success(data));
    } catch (err) {
      dispatch(ac.getUsersPerLevel.error(err));
    }
  };
}

export function getWithdrawals(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getWithdrawals.pending());
    try {
      const token = getState().Login.token;
      const data = await api.dashboard.getWithdrawals(payload, token);
      dispatch(ac.getWithdrawals.success(data));
    } catch (err) {
      dispatch(ac.getWithdrawals.error(err));
    }
  };
}

export function getCoinStatistics(payload = {})  {
  return async (dispatch, getState) => {
    dispatch(ac.getCoinStatistics.pending());
    try {
      const token = getState().Login.token;
      const data = await api.dashboard.getCoinStatistics(payload, token);
      dispatch(ac.getCoinStatistics.success(data));
    } catch (err) {
      dispatch(ac.getCoinStatistics.error(err));
    }
  };
}

export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}

