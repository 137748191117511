import * as c from './constants';
import moment from 'moment'
import BigNumber from 'bignumber.js';

const initialState = {
  markets: [],
  orders: [],
  ordersMeta: {},
  coins: [],
  market: {
    name: "",
    market_coin_symbol: "",
    quote_coin_symbol: "",
    market_precision: "",
    market_precision_format: "",
    quote_precision: "",
    quote_precision_format: "",
    status: "active",
    min_market_volume: "",
    min_quote_volume: "",
    max_market_price: "",
    max_quote_price: "",
    maxUsdtSpendLimit: ""
  },
  settings: {
    volumeDistributedPercent: ""
  },
  filter: {
    email: '',
    type: '',
    side: '',
    date: '',
    status: '',
    pair: ''
  },
  status: {pending: false, success: false, error: false},
};


export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_MARKETS_PENDING:
    case c.ADD_MARKET_PENDING:
    case c.EDIT_MARKET_PENDING:
    case c.GET_MARKET_PENDING:
    case c.GET_VOLUME_DISTRIBUTED_PERCENT_PENDING:
    case c.UPDATE_MARKET_SETTINGS_PENDING: {
      return {
        ...state,
        status: {pending: true, error: false, success: false},
      };
    }

    case c.GET_ORDERS_PENDING: {
      return {
        ...state,
        orders: [],
        ordersMeta: {},
        status: {pending: true, error: false, success: false},
      };
    }


    case c.GET_ORDERS_ERROR:
    case c.GET_MARKETS_ERROR:
    case c.ADD_MARKET_ERROR:
    case c.EDIT_MARKET_ERROR:
    case c.GET_MARKET_ERROR:
    case c.GET_VOLUME_DISTRIBUTED_PERCENT_ERROR:
    case c.UPDATE_MARKET_SETTINGS_ERROR: {
      return {
        ...state,
        status: {pending: false, error: action.payload, success: false},
      };
    }
    case c.GET_MARKETS_SUCCESS: {
      const markets = action.payload.Markets.map(item => {
        return {
          ...item,
          created_at: moment(item.created_at).format('LLL')
        }
      })
      return {
        ...state,
        markets: markets,
        marketsMeta: action.payload.Meta
      };
    }
    case c.GET_MARKET_SUCCESS: {
      const market = action.payload;
      return {
        ...state,
        market: {
          ...market,
          min_market_volume: BigNumber(market.min_market_volume).toFixed(),
          min_quote_volume: BigNumber(market.min_quote_volume).toFixed(),
          max_market_price: BigNumber(market.max_market_price).toFixed(),
          max_quote_price: BigNumber(market.max_quote_price).toFixed(),
          created_at: moment(market.created_at).format('LLL')
        }
      };
    }
    case c.GET_ORDERS_SUCCESS: {
      const orders = action.payload.orders_with_user.map(item => {
        return {
          ...item,
          email_link: `/user/${item.owner_id}/orders`,
          // user: item.first_name + " " + item.last_name,
          // user_link: "/user/" + item.owner_id,
          filled: parseFloat(item.amount) ? ((parseFloat(item.filled_amount)/parseFloat(item.amount)*100).toFixed(2) + '%') : '0.00%',
          created_at: moment(item.created_at).utc().format('LLL')
        }
      });
      return {
        ...state,
        orders: orders,
        ordersMeta: action.payload.meta,
        status: {pending: false, error: false, success: true},
      };
    }

    case c.GET_VOLUME_DISTRIBUTED_PERCENT_SUCCESS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          volumeDistributedPercent: action.payload
        }
      };
    }

    case c.ADD_MARKET_SUCCESS: 
    case c.EDIT_MARKET_SUCCESS:
    case c.UPDATE_MARKET_SETTINGS_SUCCESS: {
      return {
        ...state,
        status: {pending: false, error: false, success: true},
      };
    }
    case c.RESET_MARKET_STATE: {
      return {
        ...state,
        market: {
          ...initialState.market,
        },
      };
    }
    case c.SET_MARKET_FIELD: { 
      return {
        ...state,
        market: {
          ...state.market,
          [action.payload.field]: action.payload.value,
        }
      };
    }
    case c.SET_FIELD: { 
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case c.SET_SETTINGS_FIELD: {
      return {
        ...state,
        settings: {...state.settings, [action.payload.field]: action.payload.value}
      };
    }
    case c.SET_FILTER_FIELD: {
      return {
        ...state,
        filter: {...state.filter, [action.payload.field]: action.payload.value}
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};
