import React from "react";
import { hasPermission } from '../../utils';

class Can extends React.Component {
  
  render() {
    const { run } = this.props; 
    return (
      hasPermission(run) ? this.props.children : null
    );
  }
}

export default Can;