import * as ac from "./actionCreators";
import api from "../../api";
import FileSaver from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import moment from 'moment';

export function getBots(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getBots.pending());
    try {
      let order_by = 'create';

      switch(payload.orderBy){
        case 'created_at':
          order_by = 'create';
          break;
        case 'expired_at':
          order_by = 'expire';
          break;
        default:
          order_by = 'create';
      }

      const payloadData = {
        limit: payload.limit,
        page: payload.page,
        bot_id: payload.botId,
        status: payload.botsStatus,
        order_by,
        order: payload.order || "desc"
      };

      if(payload.statisticsDateData && payload.statisticsDateData.from_date) payloadData.fromDate = payload.statisticsDateData.from_date;
      if(payload.statisticsDateData && payload.statisticsDateData.to_date) payloadData.toDate = payload.statisticsDateData.to_date;

      const token = getState().Login.token;
      const {data: botsData} = await api.bots.getBots(payloadData, token);
      const {data: botsPnl} = await api.bots.getBotsPnl(token);
      const coins = await api.assets.getCoins({},token);
      dispatch(ac.getBots.success({botsData, botsPnl, coins}));
    } catch (err) {
      dispatch(ac.getBots.error(err));
    }
  };
}

export function getGridBotsStatistics(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getGridBotsStatistics.pending());
    try {
      const payloadData = {
        limit: payload.limit,
        page: payload.page,
        pair: payload.pair,
        bot_id: payload.botId,
      };

      if(payload.statisticsDateData && payload.statisticsDateData.from_date) payloadData.from_date = payload.statisticsDateData.from_date;
      if(payload.statisticsDateData && payload.statisticsDateData.to_date) payloadData.to_date = payload.statisticsDateData.to_date;

      const token = getState().Login.token;
      const {data} = await api.bots.getGridBotsStatistics(payloadData, token);
      dispatch(ac.getGridBotsStatistics.success({data, pair: payload.pair}));
    } catch (err) {
      dispatch(ac.getGridBotsStatistics.error(err));
    }
  };
}

export function downloadGridBotsStatistics(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.downloadGridBotsStatistics.pending());
    try {
      const payloadData = {
        format: payload.format,
        pair: payload.pair,
        bot_id: payload.botId,
      };

      if(payload.statisticsDateData && payload.statisticsDateData.from_date) payloadData.from_date = payload.statisticsDateData.from_date;
      if(payload.statisticsDateData && payload.statisticsDateData.to_date) payloadData.to_date = payload.statisticsDateData.to_date;

      const token = getState().Login.token;
      const resp = await api.bots.downloadGridBotsStatistics(payloadData, token);

      FileSaver.saveAs(
        base64StringToBlob(resp.data),
        `grid_bots_statistics_${moment().format('YYYY_MM_DD_HHmmss')}.${
          resp.filetype
        }`
      );

      dispatch(ac.downloadGridBotsStatistics.success());
    } catch (err) {
      dispatch(ac.downloadGridBotsStatistics.error(err));
    }
  };
}

export function getTrendBotsStatistics(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getTrendBotsStatistics.pending());
    try {
      const payloadData = {
        limit: payload.limit,
        page: payload.page,
        pair: payload.pair,
        bot_id: payload.botId,
      };

      if(payload.statisticsDateData && payload.statisticsDateData.from_date) payloadData.from_date = payload.statisticsDateData.from_date;
      if(payload.statisticsDateData && payload.statisticsDateData.to_date) payloadData.to_date = payload.statisticsDateData.to_date;

      const token = getState().Login.token;
      const {data} = await api.bots.getTrendBotsStatistics(payloadData, token);
      dispatch(ac.getTrendBotsStatistics.success({data, pair: payload.pair}));
    } catch (err) {
      dispatch(ac.getTrendBotsStatistics.error(err));
    }
  };
}

export function downloadTrendBotsStatistics(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.downloadTrendBotsStatistics.pending());
    try {
      const payloadData = {
        format: payload.format,
        pair: payload.pair,
        bot_id: payload.botId,
      };

      if(payload.statisticsDateData && payload.statisticsDateData.from_date) payloadData.from_date = payload.statisticsDateData.from_date;
      if(payload.statisticsDateData && payload.statisticsDateData.to_date) payloadData.to_date = payload.statisticsDateData.to_date;

      const token = getState().Login.token;
      const resp = await api.bots.downloadTrendBotsStatistics(payloadData, token);

      FileSaver.saveAs(
        base64StringToBlob(resp.data),
        `trend_bots_statistics_${moment().format('YYYY_MM_DD_HHmmss')}.${
          resp.filetype
        }`
      );

      dispatch(ac.downloadTrendBotsStatistics.success());
    } catch (err) {
      dispatch(ac.downloadTrendBotsStatistics.error(err));
    }
  };
}

export function getTotalActiveBots() {
  return async (dispatch, getState) => {
    dispatch(ac.getTotalActiveBots.pending());
    try {
      const token = getState().Login.token;
      const data = await api.dashboard.getBotsStatistics(token);
      dispatch(ac.getTotalActiveBots.success(data));
    } catch (err) {
      dispatch(ac.getTotalActiveBots.error(err));
    }
  };
}



export function setField(field, value) {
  return (dispatch) => dispatch(ac.setField({ field, value }));
}
