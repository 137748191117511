import React from "react";
import history from '../../../history';
import MarketForm from "components/MarketForm/index.jsx";
import SuccessModal from "../../../components/Modal/success.jsx";

import {
  Card,
  CardBody,
  Container,
  CardHeader,
  Button
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class MarketView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false
    }; 
  }

  async componentDidMount() {
    this.props.getCoins();
    this.props.getMarkets({limit: 0});
    const market_id = this.props.match.params.market_id;
    if(market_id) {
      await this.props.getMarket(market_id); 
      if(this.props.status.error && (!this.props.market || this.props.market.id === "")) {
        //invalid market id, redirect to list of markets
        this.cancel();
      }
    } else {
      this.props.resetForm();
    }
  }

  toggleModal = () => {
    this.setState({
        blockModal: !this.state.blockModal,
    });
  }

  renderConfirmationModal = () => {
    const response = "Saved!"
    return (
        <SuccessModal
            isOpen={this.state.blockModal}
            toggleFunction={() => this.toggleModal()}
            closeFunction={() => this.toggleModal()}
            body={response}
        />
    )
  };

  cancel = () => {
    history.push('/manage-markets');
  }

  addMarket = async () => {
    await this.props.addMarket(this.props);
    if (this.props.status.success) {
      this.toggleModal();
    }
  }

  editMarket = async () => { 
    await this.props.editMarket(this.props.market);
    if (this.props.status.success) {
      this.toggleModal();
    }
  }

  render() { 
    const { market, coins, status, setMarketField } = this.props; 
    const market_id = market && market.id;
    const action_text = market_id ? "Edit market" : "Add a market";
    return (
        <>
        <SimpleHeader name={action_text} parentName="Manage Markets"/>
        <Container className="mt--6" fluid>
            <Card>
                <CardHeader>
                    <h3 className="mb-0">{action_text}</h3>
                </CardHeader>
                <CardBody>
                    <MarketForm object={market} coins={coins} status={status} onChange={setMarketField} />
                    <Button color="info" type="button" className="float-right ml-3" onClick={this.cancel}>Cancel</Button>
                    <Button color="success" type="button" className="float-right" onClick={market_id ? this.editMarket : this.addMarket}>{action_text}</Button>
                    <br/>
                    <span className="error">
                      {status.error && status.error.data && status.error.data.error}
                    </span>
                </CardBody>
            </Card>
            {this.renderConfirmationModal()}
        </Container>
        </>
    );
  }
}

export default MarketView;