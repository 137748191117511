import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import ManualWithdrawals from '../components/manualWithdrawals';
import WalletState from '../../../redux/Wallets';
import LoginState from '../../../redux/Login';

const mapActionsToProps = dispatch =>
  bindActionCreators (
    {
      getManualWithdrawals: WalletState.actions.getManualWithdrawals,
    },
    dispatch
  );

const mapStateToProps = state => ({
  currentUser: LoginState.selectors.currentUser (state),
  manualWithdrawalsMeta: WalletState.selectors.manualWithdrawalsMeta(state),
  manualWithdrawals: WalletState.selectors.manualWithdrawals(state),
});

export default connect (mapStateToProps, mapActionsToProps) (ManualWithdrawals);
