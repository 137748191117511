import React from "react";
import * as c from './constants';
import moment from 'moment'
import BigNumber from 'bignumber.js';

const initialState = {
  chartValues: {
    labels: [],
    values: []
  },
  block_deposit: false,
  block_withdraw: false,
  deposits_coin_filter: '',
  manual_deposits_status: '',
  deposits_filter: '',
  deposits: [],
  manual_deposits: [],
  withdrawals:[],
  manual_withdrawals: [],
  coins: [{}],
  coin_query: "",
  total_profit: 0,
  status: {pending: false, success: false, error: false},
};


export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_WITHDRAWALS_PENDING:
    case c.GET_DEPOSITS_PENDING:
    case c.GET_OPERATIONS_PENDING:
      return state;
    case c.CHANGE_BLOCK_DEPOSIT_PENDING:
    case c.CHANGE_BLOCK_WITHDRAW_PENDING:
    case c.GET_BLOCK_DEPOSIT_PENDING:
    case c.GET_BLOCK_WITHDRAW_PENDING:
      return {
        ...state,
        status: { pending: true, error: false, success: false },
      };
    case c.GET_WITHDRAWALS_ERROR:
    case c.GET_DEPOSITS_ERROR:
    case c.GET_OPERATIONS_ERROR:
      return state;
    case c.CHANGE_BLOCK_WITHDRAW_ERROR:
    case c.CHANGE_BLOCK_DEPOSIT_ERROR:
    case c.GET_BLOCK_WITHDRAW_ERROR:
    case c.GET_BLOCK_DEPOSIT_ERROR:
        return {
          ...state,
          status: { pending: false, error: action.payload, success: false },
        };
    case c.GET_WITHDRAWALS_SUCCESS: {
      const withdrawals = action.payload.WithdrawRequests.map(item => {
        const user = item.first_name + ' ' + item.last_name;
        let trx_id = '';
        if(item.txid){
          if(item.blockchain_explorer) trx_id = <a href={`${item.blockchain_explorer}${item.txid}`}>{item.txid.length > 12 ? `${item.txid.slice(0,12)}...` : item.txid}</a>;
          else trx_id = item.txid;
        }
        
        return {
          ...item,
          user: user.trim() ? user : item.email,
          user_link: 'user/' + item.user_id,
          address: item.to,
          amount: (BigNumber(item.amount)).toFixed(),
          coin_symbol: item.coin_symbol.toUpperCase(),
          created_at: moment(item.created_at).format('LLL'),
          fee_amount: (BigNumber(item.fee_amount)).toFixed(),
          trx_id
        }
      })
      return {
        ...state,
        withdrawals,
        withdrawalsMeta: action.payload.Meta
      };
    }

    case c.UPDATE_WITHDRAW:{
      return {
        ...state,
        withdrawals: state.withdrawals.map((withdraw) =>
          withdraw.id === action.payload
            ? {...withdraw, status: 'pending cancellation'}
            : withdraw
        )
      };
    }
    case c.GET_DEPOSITS_SUCCESS: {
      const deposits = action.payload.Transactions.map(item => {
        const user = item.first_name + ' ' + item.last_name;
        let trx_id = '';
        if(item.txid){
          if(item.blockchain_explorer) trx_id = <a href={`${item.blockchain_explorer}${item.txid}`}>{item.txid.length > 12 ? `${item.txid.slice(0,12)}...` : item.txid}</a>;
          else trx_id = item.txid;
        }

        return {
          ...item,
          external_system: item.external_system || 'crypto',
          user: user.trim() ? user : item.email,
          user_link: 'user/' + item.user_id,
          address_link: item.blockchain_explorer + item.address,
          amount: (BigNumber(item.amount)).toFixed(),
          coin_symbol: item.coin_symbol.toUpperCase(),
          created_at: moment(item.created_at).format('LLL'),
          fee_amount: (BigNumber(item.fee_amount)).toFixed(),
          trx_id
        }
      })
      return {
        ...state,
        deposits,
        depositsMeta: action.payload.Meta
      };
    }
    case c.GET_MANUAL_DEPOSITS_SUCCESS: {
      const manualDeposits = action.payload.manual_transactions.map(item => {
        const user = item.first_name + ' ' + item.last_name;
        let trx_id = '';
        if(item.txid){
          if(item.blockchain_explorer) trx_id = <a href={`${item.blockchain_explorer}${item.txid}`}>{item.txid.length > 12 ? `${item.txid.slice(0,12)}...` : item.txid}</a>;
          else trx_id = item.txid;
        }

        return {
          ...item,
          external_system: item.external_system || 'crypto',
          user: user.trim() ? user : item.email,
          user_link: 'user/' + item.user_id,
          address_link: item.blockchain_explorer + item.address,
          amount: (BigNumber(item.amount)).toFixed(),
          coin_symbol: item.coin_symbol.toUpperCase(),
          created_at: moment(item.created_at).format('LLL'),
          trx_id
        }
      })
      return {
        ...state,
        manualDeposits,
        manualDepositsMeta: action.payload.meta
      };
    }

    case c.GET_MANUAL_WITHDRAWALS_SUCCESS: {
      const {manual_transactions, meta} = action.payload

      return {
        ...state,
        manualWithdrawals: manual_transactions,
        manualWithdrawalsMeta: meta
      };
    }

    case c.GET_OPERATIONS_SUCCESS: {
      const operations = action.payload.Operations.map(item => {
        return {
          ...item,
          created_at: moment(item.created_at).format('LLL')
        }
      })
      return {
        ...state,
        operations: operations,
        operationsMeta: action.payload.Meta
      };
    }
    case c.CHANGE_BLOCK_WITHDRAW_SUCCESS: 
    case c.CHANGE_BLOCK_DEPOSIT_SUCCESS:{
        return {
          ...state,
          status: { pending: false, error: false, success: true },
        };
      }

    case c.GET_BLOCK_WITHDRAW_SUCCESS: 
      return {
          ...state,
          block_withdraw: action.payload,
          status: { pending: false, error: false, success: true },
        };
      
    case c.GET_BLOCK_DEPOSIT_SUCCESS:
      return {
        ...state,
        block_deposit: action.payload,
        status: { pending: false, error: false, success: true },
      };
    
    case c.SET_FIELD: { 
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};
