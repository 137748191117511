import request from "superagent";
import { api } from "../config";
import {
  AccessDeniedError,
  ServerValidationError,
  OTPRequiredError,
  PreconditionFailedError,
} from "./errors";
const HTTP_STATUS_OTP_REQUIRED = 423;
const HTTP_STATUS_PRECONDITION_FAILED = 412;
const HTTP_STATUS_ACCESS_DENIED_FAILED = 403;
/**
 * @description Append token & other header fields to the default request header
 * @param token: String
 * @param others: Object
 * @returns Object
 * @private
 */
function getRequestHeader(token = null, others = {}) {
  let header = {
    "Content-Type": "application/x-www-form-urlencoded",
    ...others,
  };
  if (token) {
    header = Object.assign({}, header, { Authorization: `${token}` });
  }
  return header;
}

const getError = (err, res) => {
  if (res && res.status === HTTP_STATUS_ACCESS_DENIED_FAILED) {
    return new AccessDeniedError(
      `${res.body.error || (err && err.message) || "Something went wrong"}`,
      res.body
    );
  }
  if (res && res.status === HTTP_STATUS_PRECONDITION_FAILED) {
    if (res.body && res.body.error) {
      return new PreconditionFailedError(
        `${res.body.error || (err && err.message) || "Precondition failed"}`,
        res.body
      );
    }
    return new PreconditionFailedError("Precondition failed", res.body);
  }
  // check for locked status to signal OTP verification flow
  if (res && res.status === HTTP_STATUS_OTP_REQUIRED) {
    if (res.body && res.body.error) {
      return new OTPRequiredError(
        `${res.body.error || (err && err.message) || "Something went wrong"}`,
        res.body
      );
    }
    return new OTPRequiredError(
      "Two factor authentication code required",
      res.body
    );
  }
  if (res && res.body && res.body.error) {
    return new ServerValidationError(
      `${res.body.error || (err && err.message) || "Something went wrong"}`,
      res.body
    );
  }
  return err || new Error("Something went wrong");
};
/**
 * @description Handle API response
 * @param err: Object
 * @param res: Object
 * @param resolve: Function
 * @param reject: Function
 * @private
 */
function handleResponse(err, res, resolve, reject) {
  if (err) {
    return reject(getError(err, res));
  }
  if (!res.ok) {
    return reject(getError(err, res));
  } else if (
    res.type !== "application/octet-stream" && !res.body 
    // (!res.body || res.body.success === false)
  ) {
    return reject(getError(err, res));
  }

  if (res.type === "application/octet-stream") return resolve(res.text);

  return resolve(res.body);
}

/**
 * @description GET request
 * @param path: String
 * @param query: Object
 * @param token: String
 * @returns Promise
 */
export function get(path, query = {}, token = null) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    request
      .get(path)
      .query(query)
      .set(header)
      .timeout({ response: api.timeout })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description POST request
 * @param path: String
 * @param payload: Object
 * @param token: String
 * @returns Promise
 */
export function post(path, payload, token = null) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    request
      .post(path)
      .set(header)
      .send(payload)
      .timeout({ response: api.timeout })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description POST request
 * @param path: String
 * @param payload: Object
 * @param token: String
 * @returns Promise
 */
export function postJson(path, payload, token = null) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    header["Content-Type"] = "application/json";
    request
      .post(path)
      .set(header)
      .send(JSON.stringify(payload))
      .timeout({ response: api.timeout })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description POST request
 * @param path: String
 * @param payload: Object
 * @param token: String
 * @returns Promise
 */
export function postFiles(path, payload, token = null) {
  return new Promise((resolve, reject) => {
    let header = {};
    if (token) {
      header = Object.assign({}, header, { Authorization: `${token}` });
    }
    request
      .post(path)
      .set(header)
      .send(payload)
      .timeout({ response: api.timeout })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description PUT request
 * @param path: String
 * @param payload: Object
 * @param token: String
 * @returns Promise
 */
export function put(path, payload, token = null, query = {}) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    request
      .put(path)
      .send(payload)
      .query(query)
      .set(header)
      .timeout({ response: api.timeout })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description PUT request
 * @param path: String
 * @param payload: Object
 * @param token: String
 * @returns Promise
 */
 export function putFiles(path, payload, token = null) {
  return new Promise((resolve, reject) => {
    let header = {};
    if (token) {
      header = Object.assign({}, header, { Authorization: `${token}` });
    }
    request
      .put(path)
      .send(payload)
      .set(header)
      .timeout({ response: api.timeout })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}

/**
 * @description DELETE request
 * @param path: String
 * @param query: Object
 * @param token: String
 * @returns Promise
 */
export function del(path, query = {}, token = null) {
  return new Promise((resolve, reject) => {
    const header = getRequestHeader(token);
    request
      .delete(path)
      .query(query)
      .set(header)
      .timeout({ response: api.timeout })
      .end((err, res) => handleResponse(err, res, resolve, reject));
  });
}
