import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Bots from "../components/bots/bots";
import UsersState from "../../../redux/Users";
import LoginState from "../../../redux/Login";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserBots: UsersState.actions.getUserBots,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  status: UsersState.selectors.status(state),
  bots: UsersState.selectors.bots(state),
  totalActiveBots: UsersState.selectors.totalActiveBots(state),
  totalOfLockedFunds: UsersState.selectors.totalOfLockedFunds(state),
  token: LoginState.selectors.token(state)
});

export default connect(mapStateToProps, mapActionsToProps)(Bots);
