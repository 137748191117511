import React from "react";
import history from '../../../history';
import {Container, Col, Form, Row, FormGroup, Label, Button, Input} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import Panels from "components/Collapse/index.jsx";
import DarkTable from "../../../components/Table";
import DangerModal from "../../../components/Modal/danger.jsx";
import NotificationAlert from "react-notification-alert";
import { hasPermission } from '../../../utils';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { isEmpty } from "lodash";

class MarketView extends React.Component {

  state = {
    cancelModal: false,
    currentId: -1,
    cancelOrdersMarketId: null,
    page: 1
  }

  getOrdersFromMarket = (page = this.state.page, pair = this.props.filter.pair) => {
    this.props.getOrdersFromMarket({...this.props , page, filter: {...this.props.filter, pair}});
  }

  async componentDidMount() { 
    await this.props.getMarkets({limit: 1000});
    const market_id = this.props.match.params.market_id;

    if(market_id){
      if(!isEmpty(this.props.markets)){

        if(this.props.markets.find((item) => item.id === market_id)){
          this.props.setFilterField('pair', market_id);
          this.getOrdersFromMarket(1, market_id);
        }
        else{
          this.getOrdersFromMarket();
        }
      }
    }
    else{
      this.getOrdersFromMarket();
    }
  }

  componentDidUpdate(prevProps){
    const market_id = this.props.match.params.market_id;

    if(market_id && isEmpty(prevProps.markets) && !isEmpty(this.props.markets)){
      
      if(this.props.markets.find((item) => item.id === market_id)){
        this.props.setFilterField('pair', market_id);
        this.getOrdersFromMarket(1, market_id);
      }
      else{
        this.getOrdersFromMarket();
      }
    }
  }

  searchOrders = async (e) => {
    this.props.setField('orders_query', e.target.value);
  };

  onOrdersPagingClick = (e, page) => {
    this.setState({page});
    this.getOrdersFromMarket(page);
  };

  cancelOrderAlert = (event, row) => {
    event.preventDefault();
    this.toggleModal(row.id, row.market_id);
  };

  cancelOrder = (id, cancelOrdersMarketId) => {
    this.props.cancelOrder(id, cancelOrdersMarketId, this.refs.notificationAlert);
    this.toggleModal(id, cancelOrdersMarketId);
    this.setState({page: 1});
    this.getOrdersFromMarket(1);
  };

  getActionsArray = () => {
    return [
      {name: 'Cancel', onClick: this.cancelOrderAlert, permission: 'order.cancel.all'},
    ].filter(item => hasPermission(item.permission))
  };

  toggleModal = (Id, marketId) => {
    this.setState({
        cancelModal: !this.state.cancelModal,
        currentId: Id,
        cancelOrdersMarketId: marketId
    });
  };

  renderCancelOrderConfirmationModal = () => {
    const {currentId, cancelOrdersMarketId} = this.state;
    const body = `Are you sure you want to cancel this order?`;
    return (
        <DangerModal
            isOpen={this.state.cancelModal}
            toggleFunction={() => this.toggleModal(currentId, cancelOrdersMarketId)}
            closeFunction={() => this.toggleModal(currentId, cancelOrdersMarketId)}
            nextFunction={() => this.cancelOrder(currentId, cancelOrdersMarketId)}
            body={body}
        />
    )
  };

  renderOrders = (market_id) => { 
    return <DarkTable
      tableTitle={"Orders"}
      actionsColumn={hasPermission('order.cancel.all')}
      actions={this.getActionsArray()}
      formFilter={true}
      renderFormFilter={this.renderFormFilter}
      inlineSearch={this.searchOrders}
      columns={{
        id: "Order ID",
        email: "User",
        market_id: "Pair",
        type: "Type",
        side: "Side",
        price: "Price",
        amount: "Amount",
        filled: "Filled",
        status: "Status",
        created_at: "Created at" 
      }}
      rows={this.props.orders}
      meta={this.props.ordersMeta}
      onPagingClick={this.onOrdersPagingClick}
    />;
  };

  renderFormFilter = () => {
    return <Panels content={this.getPanelContent()}/>
  };

  getOrdersType = () => {
    return [
      {label: 'All', value: ''},
      {label: 'Limit', value: 'limit'},
      {label: 'Market', value: 'market'}
    ]
  };

  getOrdersSide = () => {
    return [
      {label: 'All', value: ''},
      {label: 'Sell', value: 'sell'},
      {label: 'Buy', value: 'buy'}
    ]
  };

  getPairs = () => {
    const pairs = [{label: 'All', value: ''}];
    
    if(!isEmpty(this.props.markets)){
      this.props.markets.forEach((item) => pairs.push({label: item.id.toUpperCase(), value: item.id}));
    }

    return pairs;
  };

  getOrdersStatus = () => {
    return [
      {label: 'All', value: ''},
      {label: 'Open', value: 'open'},
      {label: 'Filled', value: 'filled'},
      {label: 'Cancelled', value: 'cancelled'},
    ]
  };


  renderDatePicker = (label, prop, disabled) => {
    const {filter} = this.props;
    return <FormGroup className="row">
      <Label className="form-control-label" md="4">
        {label}
      </Label>
      <Col md="8">
        <div className="input-groupx">
          <label className="DatePickerWrap col-md-12 padding-none">
            <DatePicker
              showYearDropdown
              showMonthDropdown
              dateFormatCalendar="MMMM"
              scrollableYearDropdown
              yearDropdownItemNumber={75}
              placeholderText="DD/MM/YYYY"
              selected={filter && filter[prop] ? moment(filter[prop]).toDate() : null}
              onChange={async (e) => {
                this.props.setFilterField(prop, e);
              }}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              disabled={disabled}
              filterDate={date => moment() > date}
            />
            <span className="input-group-append">
              <i className="fa fa-calendar" />
            </span>
          </label>
        </div>
      </Col>
    </FormGroup>
  };

  getPanelContent = () => {
    const {status: {pending}} = this.props;
    
    return <Form>
      <Row>
        <Col md="4">{this.renderABasicInput("Email", "email", "text", false)}</Col>
        <Col md="4"> {this.renderSelectInput("Side", this.getOrdersSide(), "side", false)}</Col>
        <Col md="4">{this.renderSelectInput("Status", this.getOrdersStatus(), "status", false)}</Col>
      </Row>
      <Row> 
        <Col md="4">{this.renderSelectInput("Type", this.getOrdersType(), "type", false)}</Col>
        <Col md="4">{this.renderDatePicker("Date", "date", false)}</Col>
        <Col md="4">{this.renderSelectInput("Pair", this.getPairs(), "pair", false)}</Col>
      </Row>
      <Row>
        <Col md="12" className='d-flex justify-content-end'>
          <Button color="success" type="button" className="float-right"
            disabled={pending}
            onClick={() =>{
              this.setState({page: 1});
              this.getOrdersFromMarket(1);
            }}>
            Filter
          </Button>
        </Col>
      </Row>
    </Form>
  };

  renderABasicInput(label, prop, type = "text", disabled = false, maxLength = 0) {
    const {filter} = this.props;
    const value = filter && filter[prop] ? filter[prop] : "";
    return <FormGroup className="row">
      <Label className="form-control-label" md="4">
        {label}
      </Label>
      <Col md="8">
        <Input
          value={value}
          type={type} disabled={disabled}
          autoComplete="off"
          maxLength={maxLength ? maxLength : ""}
          onChange={async(e) => {
            this.props.setFilterField(prop, e.target.value);
          }
          }
        />
      </Col>
    </FormGroup>
  };

  renderSelectInput(label, options, prop, disabled) {
    const {filter} = this.props;
    const defaultValue = filter && filter[prop] ? filter[prop] : "";
    return (<FormGroup className="row">
        <Label className="form-control-label" md="4">{label}</Label>
        <Col md="8">
          <Input type="select" value={defaultValue} disabled={disabled} onChange={async (e) => {
            this.props.setFilterField(prop, e.target.value);
          }
          }>
            {options.map(option => (
              <option key={option.value || option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>
    )
  };

  render() { 
    const market_id = this.props.match.params.market_id;
    return (
        <>
        <SimpleHeader name="Market Orders" parentName="Markets" parentLink="/manage-markets" />
        <Container className="mt--6" fluid>
            {this.renderOrders(market_id)}
            {this.renderCancelOrderConfirmationModal()}
            <div className="rna-wrapper" ref="notifH">
                <NotificationAlert ref="notificationAlert" />
            </div>
        </Container>
        </>
    );
  }
}

export default MarketView;