import * as c from "./constants";
import moment from "moment";

const initialState = {
  maintenances: [],
  maintenance: {
    title: "",
    message: ""
  },
  status: { pending: false, success: false, error: false },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.EDIT_MAINTENANCE_PENDING:
    case c.CHANGE_MAINTENANCE_STATUS_PENDING:
    case c.GET_MAINTENANCE_PENDING:
    case c.GET_MAINTENANCES_PENDING:
    case c.ADD_MAINTENANCE_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false },
      };
    }

    case c.EDIT_MAINTENANCE_ERROR:
    case c.CHANGE_MAINTENANCE_STATUS_ERROR:
    case c.GET_MAINTENANCE_ERROR:
    case c.GET_MAINTENANCES_ERROR:
    case c.ADD_MAINTENANCE_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false },
      };
    }

    case c.GET_MAINTENANCE_SUCCESS: {
      const maintenance = action.payload;

      return {
        ...state,
        maintenance
      };
    }

    case c.CHANGE_MAINTENANCE_STATUS_SUCCESS:
    case c.GET_MAINTENANCES_SUCCESS: {
      const maintenances = action.payload.map((item) => {
        return {
          ...item,
          created_at: moment(item.created_at).format("LLL"),
        };
      });
      return {
        ...state,
        maintenances,
      };
    }


    case c.EDIT_MAINTENANCE_SUCCESS:
    case c.ADD_MAINTENANCE_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
      };
    }

    case c.RESET_MAINTENANCE_STATE: {
      return {
        ...state,
        maintenance: {
          ...initialState.maintenance,
        },
      };
    }

    case c.SET_MAINTENANCE_FIELD: {
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          [action.payload.field]: action.payload.value,
        },
      };
    }

    case c.SET_MAINTENANCE: {
      return {
        ...state,
        maintenance: action.payload,
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
