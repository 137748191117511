import React from "react";
import {Button} from "reactstrap";

import {downloadFile} from "../../utils";

export class KYBDownloadFile extends React.Component {
  downloadDocument(fileAlias) {
    const { userId, downloadKYBDocument, KYBDocumentUrl } = this.props

    const fileName = `kyb_user_${userId}_${fileAlias}`;

    downloadKYBDocument(userId, fileAlias);

    if (KYBDocumentUrl) {
      downloadFile(KYBDocumentUrl, fileName)
    }

  }

  render() {
    const { fieldName, fieldAlias, status } = this.props;

    return (
      <>
        <div className="font-weight-bold">
          {fieldName}
        </div>

        <Button
          className="mt-1 btn-icon btn-neutral btn-$round"
          color="default"
          onClick={() => this.downloadDocument(fieldAlias)}
          size="sm"
          disabled={status.pending}
        >
          <i className="fas fa-download"></i>
          <span className="btn-inner--text">Download</span>
        </Button>
      </>
    )
  }
}