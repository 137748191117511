export const KYB_GENERAL_STATUS = {
  NONE: 'none',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail',
};

export const KYB_STEP_STATUS = {
  STEP_ONE_NONE: 'none',
  STEP_ONE_PENDING: 'step_one_pending',
  STEP_ONE_SUCCESS: 'step_one_success',
  STEP_ONE_FAIL: 'step_one_fail',

  STEP_TWO_NONE: 'none',
  STEP_TWO_PENDING: 'step_two_pending',
  STEP_TWO_SUCCESS: 'step_two_success',
  STEP_TWO_FAIL: 'step_two_fail',

  STEP_THREE_NONE: 'none',
  STEP_THREE_PENDING: 'step_three_pending',
  STEP_THREE_SUCCESS: 'step_three_success',
  STEP_THREE_FAIL: 'step_three_fail',

  STEP_FOUR_NONE: 'none',
  STEP_FOUR_PENDING: 'step_four_pending',
  STEP_FOUR_SUCCESS: 'step_four_success',
  STEP_FOUR_FAIL: 'step_four_fail',
};

export const KYB_FILE_NAMES = {
  certificate_of_incorporation: 'certificate_of_incorporation',
  memorandum_article_association: 'memorandum_article_association',
  register_directors: 'register_directors',
  register_of_members: 'register_of_members',
  ownership_structure: 'ownership_structure',
  sanctions_questionnaire: 'sanctions_questionnaire',
};

export const KYB_DOWNLOAD_FILES = [
  {
    fieldName: '1. Certificate of Incorporation',
    fieldAlias: KYB_FILE_NAMES.certificate_of_incorporation,
  },
  {
    fieldName: '2. Memorandum A Article of Association / Construction / By-Law',
    fieldAlias: KYB_FILE_NAMES.memorandum_article_association,
  },
  {
    fieldName: '3. Register Directors / Certificate of Incumbency / Business Registry or equivalent',
    fieldAlias: KYB_FILE_NAMES.register_directors,
  },
  {
    fieldName: '4. Register of Members (Shareholders) / Certificate of Incumbency / Business Registry or equivalent',
    fieldAlias: KYB_FILE_NAMES.register_of_members,
  },
  {
    fieldName: '5. Ownership Structure',
    fieldAlias: KYB_FILE_NAMES.ownership_structure,
  },
  {
    fieldName: '6. Sanctions Questionnaire',
    fieldAlias: KYB_FILE_NAMES.sanctions_questionnaire,
  },
];