import React, { useState, useEffect } from "react";
import { Modal, Button, Col, FormGroup, Label, Input } from "reactstrap";
import EmailSelect from "../../components/EmailSelect/EmailSelect";
import api from '../../api';
import * as storage from '../../utils/storage';
import {
  forceNumeric,
  isValidNumber
} from '../../utils';
import { sortBy } from 'lodash';

const CreateManualDepositModal = ({ isOpen, onClose }) => {
  const [userId, setUserId] = useState(null);
  const [userError, setUserError] = useState('');
  const [symbol, setSymbol] = useState('');
  const [symbolError, setSymbolError] = useState('');
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');
  const [requestError, setRequestError] = useState('');
  const [coins, setCoins] = useState([]);
  

  useEffect(() => {
    getCoins();
  }, []);

  useEffect(() => {
    if(!isOpen){
      setUserId(null);
      setSymbol('');
      setAmount('');
      setUserError('');
      setAmountError('');
      setSymbolError('');
      setRequestError('');
    }
  }, [isOpen]);

  const getCoins = async() => {
    const coins = await api.assets.getCoins({}, storage.get('admin_auth_token', ''));
    const coinsSorted = sortBy(coins, 'symbol');

    setCoins(coinsSorted);
  }

  const onUserEmailChange = (selectedOption) => {
    setUserId(selectedOption.value);
  }

  const onAmountChange = (e) => {
    const value = forceNumeric(e.target.value);

    if (isValidNumber(value)) {
      setAmount(value);
    }
  };
  

  const getCoinsOptions = () => {
    const options = coins.map((item) => {
      return {label: item.symbol, value: item.symbol} 
    });

    options.unshift({label: '', value: ''})
    return options;
  }

  const createDeposit = async() => {
    setUserError('');
    setAmountError('');
    setSymbolError('');
    setRequestError('');
    let error = false;

    if(!userId) {
      error = true; 
      setUserError('Field is required');
    }

    if(!symbol) {
      error = true;
      setSymbolError('Field is required');
    }

    if(!amount) {
      error = true;
      setAmountError('Field is required');
    }

    if(!error){
      try{
        const data = {
          user_id: userId,
          coin_symbol: symbol,
          amount
        };

        await api.wallets.createManualDeposit(data, storage.get('admin_auth_token', ''));
        onClose();
      }catch(error){
        setRequestError(`Server error: ${error.message}`);
      }
    }
  }

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={onClose}>
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Create Manual Deposit
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3">
          <div className="mb-3">
            <FormGroup className="row">
              <Label className="form-control-label" md="4">
                User Email
              </Label>
              <Col md="8">
                <EmailSelect onChange={onUserEmailChange}/>
                <span className="help-block error">
                {userError}
              </span>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="form-control-label" md="4">
                Coin
              </Label>
              <Col md="8">
                <Input
                  type="select"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                >
                  {getCoinsOptions().map((option) => (
                    <option key={option.value || option.label} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                <span className="help-block error">
                  {symbolError}
                </span>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="form-control-label" md="4">
                Amount
              </Label>
              <Col md="8">
                <Input
                  value={amount}
                  type={'number'}
                  onChange={(e) => onAmountChange(e)}
                />
                <span className="help-block error">
                {amountError}
                </span>
              </Col>
            </FormGroup>
            <span className="help-block error">
              {requestError}
            </span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className = 'ml-auto'>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={createDeposit}
          >
            Create Deposit
          </Button>
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateManualDepositModal;
