import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Launchpad from '../components/index';
import LaunchpadState from '../../../redux/Launchpad';

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getProjects: LaunchpadState.actions.getProjects,
        },
        dispatch
    );

const mapStateToProps = state => ({
    projects: LaunchpadState.selectors.projects (state),
    meta: LaunchpadState.selectors.meta (state)
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Launchpad);

