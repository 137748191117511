import * as c from './constants';

const initialState = {
  settings: {
    withdraw_limit_level_0: "0",
    withdraw_limit_level_1: "0",
    withdraw_limit_level_2: "0",
    withdraw_limit_level_3: "0",

    adv_cash_withdraw_limit_level_0: "0",
    adv_cash_withdraw_limit_level_1: "0",
    adv_cash_withdraw_limit_level_2: "0",
    adv_cash_withdraw_limit_level_3: "0",

    clear_junction_withdraw_limit_level_0: "0",
    clear_junction_withdraw_limit_level_1: "0",
    clear_junction_withdraw_limit_level_2: "0",
    clear_junction_withdraw_limit_level_3: "0",

    default_withdraw_limit_level_0: "0",
    default_withdraw_limit_level_1: "0",
    default_withdraw_limit_level_2: "0",
    default_withdraw_limit_level_3: "0",

    adv_cash_fee: "0",
    clear_junction_fee: "0",
    default_withdraw_fee: "0",

    withdraw_fiat_payment_method: "advCash",
  },
  status: {pending: false, success: false, error: false},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_SETTINGS_INITIAL_DATA_PENDING:
    case c.UPDATE_SETTINGS_PENDING:
      return {
        ...state,
        status: {pending: true, error: false, success: false},
      };
    case c.GET_SETTINGS_INITIAL_DATA_ERROR:
    case c.UPDATE_SETTINGS_ERROR:
      return {
        ...state,
        status: {pending: false, error: action.payload, success: false},
      };
    case c.UPDATE_SETTINGS_SUCCESS: 
      return {
        ...state,
        status: {pending: false, error: false, success: true},
      };
    case c.GET_SETTINGS_INITIAL_DATA_SUCCESS: {
      let withdrawLimits = action.payload.withdrawLimits
        .reduce(function (res, item) {
          res[item.feature] = item.value;
          return res;
        }, {});
      return {
        ...state,
        settings: {
          ...state.settings,
          ...withdrawLimits,
          ...action.payload.withdrawFiatFees
        },
        status: {pending: false, error: false, success: true},
      };
    }
    
    case c.SET_SETTINGS_FIELD: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.payload.field]: action.payload.value,
        }
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
