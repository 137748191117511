import React from 'react';
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Button,
  Col,
  Form,
  Row,
} from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import SuccessModal from '../../../components/Modal/success.jsx';
import DangerModal from '../../../components/Modal/danger.jsx';
import {
  renderABasicInput,
  renderSelectInput,
} from '../../../components/Inputs';

const paymentMethods = [
  { value: 'advCash', label: 'Advanced Cash' },
  { value: 'clearJunction', label: 'Clear Junction' },
  { value: 'default', label: 'Default' },
];

export default class SettingsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false,
      blockDangerModal: false,
      submitDisabled: true,
    };
  }

  componentDidMount() {
    const getInitialData = async () => {
      await this.props.getSettingsInitialData();

      if (this.props.status.success) this.setState({ submitDisabled: false });
      else if (this.props.status.error) {
        this.toggleDangerModal();
      }
    };

    getInitialData();
  }

  toggleModal = () => {
    this.setState({
      blockModal: !this.state.blockModal,
    });
  };

  toggleDangerModal = () => {
    this.setState({
      blockDangerModal: !this.state.blockDangerModal,
    });
  };

  renderConfirmationModal = () => {
    const body = `Saved`;
    return (
      <SuccessModal
        isOpen={this.state.blockModal}
        toggleFunction={() => this.toggleModal()}
        closeFunction={() => this.toggleModal()}
        body={body}
      />
    );
  };

  renderDangerModal = () => {
    const body = `Error`;
    return (
      <DangerModal
        isOpen={this.state.blockDangerModal}
        toggleFunction={() => this.toggleDangerModal()}
        closeFunction={() => this.toggleDangerModal()}
        withoutConfirm
        body={body}
      />
    );
  };

  updateSettings = async (settings) => {
    await this.props.updateSettings(settings);

    if (this.props.status.success) {
      this.toggleModal();
    } else if (this.props.status.error) {
      this.toggleDangerModal();
    }
  };

  getFiatWithdrawLimitFieldName = () => {
    if (this.props.settings.withdraw_fiat_payment_method === 'advCash')
      return 'adv_cash_withdraw_limit_level_';
    else if (
      this.props.settings.withdraw_fiat_payment_method === 'clearJunction'
    )
      return 'clear_junction_withdraw_limit_level_';
    else if (this.props.settings.withdraw_fiat_payment_method === 'default')
      return 'default_withdraw_limit_level_';
  };

  getFiatWithdrawFeeFieldName = () => {
    if (this.props.settings.withdraw_fiat_payment_method === 'advCash')
      return 'adv_cash_fee';
    else if (
      this.props.settings.withdraw_fiat_payment_method === 'clearJunction'
    )
      return 'clear_junction_fee';
    else if (this.props.settings.withdraw_fiat_payment_method === 'default')
      return 'default_withdraw_fee';
  };

  handleWithdrawFiatPaymentMethodChange = (ev) => {
    this.props.setSettingsField(
      'withdraw_fiat_payment_method',
      ev.target.value,
    );
  };

  render() {
    const { settings, status } = this.props;
    return (
      <>
        <SimpleHeader name="Withdraw Settings" parentName="Dashboard" />
        <Container className="mt--6" fluid>
          <Card className="user_content">
            <CardHeader>
              <h3 className="mb-0">Withdraw Crypto Settings</h3>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6">
                    {renderABasicInput(
                      {
                        object: settings,
                        status,
                        onChange: this.props.setSettingsField,
                      },
                      'Level 0 withdraw limit (BTC)',
                      'withdraw_limit_level_0',
                      'text',
                    )}
                  </Col>
                  <Col md="6">
                    {renderABasicInput(
                      {
                        object: settings,
                        status,
                        onChange: this.props.setSettingsField,
                      },
                      'Level 1 withdraw limit (BTC)',
                      'withdraw_limit_level_1',
                      'text',
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    {renderABasicInput(
                      {
                        object: settings,
                        status,
                        onChange: this.props.setSettingsField,
                      },
                      'Level 2 withdraw limit (BTC)',
                      'withdraw_limit_level_2',
                      'text',
                    )}
                  </Col>
                  <Col md="6">
                    {renderABasicInput(
                      {
                        object: settings,
                        status,
                        onChange: this.props.setSettingsField,
                      },
                      'Level 3 withdraw limit (BTC)',
                      'withdraw_limit_level_3',
                      'text',
                    )}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card className="user_content">
            <CardHeader>
              <Row>
                <Col md="8">
                  <h3 className="mb-0">Withdraw Fiat Settings</h3>
                </Col>
                <Col md="4">
                  {renderSelectInput(
                    {
                      object: settings,
                      status,
                      onChange: this.props.setSettingsField,
                    },
                    'Payment Method',
                    paymentMethods,
                    'withdraw_fiat_payment_method',
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6">
                    {renderABasicInput(
                      {
                        object: settings,
                        status,
                        onChange: this.props.setSettingsField,
                      },
                      'Level 0 withdraw limit (USDT)',
                      `${this.getFiatWithdrawLimitFieldName()}0`,
                      'text',
                    )}
                  </Col>
                  <Col md="6">
                    {renderABasicInput(
                      {
                        object: settings,
                        status,
                        onChange: this.props.setSettingsField,
                      },
                      'Level 1 withdraw limit (USDT)',
                      `${this.getFiatWithdrawLimitFieldName()}1`,
                      'text',
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    {renderABasicInput(
                      {
                        object: settings,
                        status,
                        onChange: this.props.setSettingsField,
                      },
                      'Level 2 withdraw limit (USDT)',
                      `${this.getFiatWithdrawLimitFieldName()}2`,
                      'text',
                    )}
                  </Col>
                  <Col md="6">
                    {renderABasicInput(
                      {
                        object: settings,
                        status,
                        onChange: this.props.setSettingsField,
                      },
                      'Level 3 withdraw limit (USDT)',
                      `${this.getFiatWithdrawLimitFieldName()}3`,
                      'text',
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    {renderABasicInput(
                      {
                        object: settings,
                        status,
                        onChange: this.props.setSettingsField,
                      },
                      'Withdraw fee (EUR)',
                      this.getFiatWithdrawFeeFieldName(),
                      'text',
                    )}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Button
            color="success"
            type="button"
            className="float-right"
            disabled={this.state.submitDisabled}
            onClick={() => this.updateSettings(settings)}
          >
            Update settings
          </Button>
          {this.renderConfirmationModal()}
          {this.renderDangerModal()}
        </Container>
      </>
    );
  }
}
