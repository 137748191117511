const module = 'DASHBOARD';

export const GET_TRADES_PER_DAY_PENDING = `${module}/GET_TRADES_PER_DAY_PENDING`;
export const GET_TRADES_PER_DAY_SUCCESS = `${module}/GET_TRADES_PER_DAY_SUCCESS`;
export const GET_TRADES_PER_DAY_ERROR = `${module}/GET_TRADES_PER_DAY_ERROR`;

export const GET_FEES_PER_DAY_PENDING = `${module}/GET_FEES_PER_DAY_PENDING`;
export const GET_FEES_PER_DAY_SUCCESS = `${module}/GET_FEES_PER_DAY_SUCCESS`;
export const GET_FEES_PER_DAY_ERROR = `${module}/GET_FEES_PER_DAY_ERROR`;

export const GET_GENERAL_STATS_PENDING = `${module}/GET_GENERAL_STATS_PENDING`;
export const GET_GENERAL_STATS_SUCCESS = `${module}/GET_GENERAL_STATS_SUCCESS`;
export const GET_GENERAL_STATS_ERROR = `${module}/GET_GENERAL_STATS_ERROR`;

export const GET_BOTS_STATISTICS_PENDING = `${module}/GET_BOTS_STATISTICS_PENDING`;
export const GET_BOTS_STATISTICS_SUCCESS = `${module}/GET_BOTS_STATISTICS_SUCCESS`;
export const GET_BOTS_STATISTICS_ERROR = `${module}/GET_BOTS_STATISTICS_ERROR`;

export const GET_USERS_PER_LEVEL_PENDING = `${module}/GET_USERS_PER_LEVEL_PENDING`;
export const GET_USERS_PER_LEVEL_SUCCESS = `${module}/GET_USERS_PER_LEVEL_SUCCESS`;
export const GET_USERS_PER_LEVEL_ERROR = `${module}/GET_USERS_PER_LEVEL_ERROR`;

export const GET_WITHDRAWALS_PENDING = `${module}/GET_WITHDRAWALS_PENDING`;
export const GET_WITHDRAWALS_SUCCESS = `${module}/GET_WITHDRAWALS_SUCCESS`;
export const GET_WITHDRAWALS_ERROR = `${module}/GET_WITHDRAWALS_ERROR`;

export const GET_COIN_STATS_PENDING = `${module}/GET_COIN_STATS_PENDING`;
export const GET_COIN_STATS_SUCCESS = `${module}/GET_COIN_STATS_SUCCESS`;
export const GET_COIN_STATS_ERROR = `${module}/GET_COIN_STATS_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;

