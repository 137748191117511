import React from "react";
import UserTabs from "components/Tabs/user.jsx";
import moment from "moment";

import { Card, Container, Button } from "reactstrap";
import DarkTable from "../../../../components/Table";
import BotSettingsModal from "../../../../components/Modal/botSettingsModal";
import BotAnalyticsModal from "../../../../components/Modal/botAnalyticsModal";

export default class Bots extends React.Component {
  ticker = null;
  state = {
    page: 1,
    selectedBot: undefined,
  };

  tick = () => {
    const { user_id } = this.props.match.params;
    this.props.getUserBots({ ...this.props, user_id });
  };

  componentDidMount = () => {
    this.tick();
    this.ticker = setInterval(this.tick, 5000);
  };

  componentWillUnmount() {
    if (this.ticker) {
      clearInterval(this.ticker);
    }
  }

  renderDetails = (bot) => {
    return (
      <table className="w-100">
        <thead>
          <tr>
            <td>Version</td>
            <td>Created</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {bot.versions
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .reverse()
            .map((item) => {
              return (
                <tr>
                  <td>{item.id}</td>
                  <td>{moment(item.created_at).format("LLL")}</td>
                  <td>
                    <Button
                      color="primary"
                      size="sm"
                      type="button"
                      onClick={() => {
                        this.setState({
                          selectedBot: {
                            ...bot,
                            modal: "settings",
                            selectedVersion: item,
                          },
                        });
                      }}
                    >
                      Settings
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      type="button"
                      onClick={() => {
                        this.setState({
                          selectedBot: {
                            ...bot,
                            modal: "analytics",
                            selectedVersion: item,
                          },
                        });
                      }}
                    >
                      Analytics
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  renderViewBotsSection = () => {
    const bots = this.props.bots
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      .reverse()
      .map((item) => {
        return {
          ...item,
          details: this.renderDetails(item),
        };
      });

    return (
      <DarkTable
        light
        detailsColumn
        tableTitle="Bots"
        columns={{
          id: "ID",
          type: "Type",
          status: "Status",
          pnl: "PNL"
        }}
        rows={bots}
      />
    );
  };

  render() {
    const { match, totalActiveBots, totalOfLockedFunds, token } = this.props;

    return (
      <>
        <UserTabs match={match} />
        <Container className="mt--6" fluid>
          <Card className="user_content">
            <div className="m-4">
              <div className="mb-2">Total Active Bots: {totalActiveBots}</div>
              <div>Total of Locked Funds: {totalOfLockedFunds} USDT</div>
            </div>

            {this.renderViewBotsSection()}
          </Card>
          <BotSettingsModal
            bot={this.state.selectedBot}
            onClose={() => this.setState({ selectedBot: undefined })}
          />
          <BotAnalyticsModal
            token={token}
            bot={this.state.selectedBot}
            onClose={() => this.setState({ selectedBot: undefined })}
          />
        </Container>
      </>
    );
  }
}
