import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
} from "reactstrap";

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: ["collapseOne"]
    };
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };
  render() {
    return (
      <>
      <div className="accordion">
        <Card className="card-plain">
          <CardHeader
            role="tab"
            onClick={() => this.collapsesToggle("collapseThree")}
            aria-expanded={this.state.openedCollapses.includes(
              "collapseThree"
            )}
          >
            <h5 className="mb-0 left-ta ">Filter</h5>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={this.state.openedCollapses.includes("collapseThree")}
          >
            <CardBody>
              {this.props.content}
            </CardBody>
          </Collapse>
        </Card>
      </div>
      </>
    );
  }
}

export default Panels;