import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Component from '../components/stats';
import WalletState from '../../../redux/Wallets';
import DashboardState from '../../../redux/Dashboard';

const mapActionsToProps = dispatch =>
  bindActionCreators (
    {
      getCoinStatistics: DashboardState.actions.getCoinStatistics,
      setField: WalletState.actions.setField,
    },
    dispatch
  );

const mapStateToProps = state => ({
  coins: DashboardState.selectors.coins (state),
  coin_query: DashboardState.selectors.coin_query (state),
});

export default connect (mapStateToProps, mapActionsToProps) (Component);
