import React from "react";
import UserTabs from "components/Tabs/user.jsx";

import { Card, CardBody, Container } from "reactstrap";
import DarkTable from "../../../components/Table";
import ErrorModal from "../../../components/Modal/danger.jsx";

export default class UsersView extends React.Component {
  ticker = null;
  state={
    page: 1,
    blockModal: false
  };

  tick = () => {
    const {user_id} = this.props.match.params;
    this.props.getUserLogs({...this.props, user_id, page: this.state.page});
  }

  componentDidMount = () => {
    const user_id = this.props.match.params.user_id;
    this.props.getUser(user_id);
    this.tick();
    this.ticker = setInterval(this.tick, 5000);
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.status.error &&
      this.props.status.error.type === "getUser" &&
      prevProps.status.error !== this.props.status.error
    ) {
      this.setState({
        errorModal: true,
        bodyError: this.props.status.error.message,
      });
    }
  };


  componentWillUnmount(){
    if(this.ticker){
      clearInterval(this.ticker);
    }
  }

  toggleErrorModal = (body) => {
    this.setState({
      errorModal: !this.state.errorModal,
      bodyError: body,
    });
  };

  renderErrorModal = () => {
    return (
      <ErrorModal
        withoutConfirm={true}
        isOpen={this.state.errorModal}
        toggleFunction={() => this.toggleErrorModal()}
        closeFunction={() => this.toggleErrorModal()}
        body={this.state.bodyError}
      />
    );
  };

  onPagingClick = (ev, page) => {
    const user_id = this.props.match.params.user_id;
    this.setState({page});
    this.props.getUserLogs({...this.props, user_id, page });
  };

  searchActivity = async (e) => {
    const user_id = this.props.match.params.user_id;
    this.setState({page: 1});
    await this.props.setField("activity_query", e.target.value);
    this.props.getUserLogs({ ...this.props, user_id, page: 1 });
  };

  renderViewActivitySection = () => {
    return (
      <DarkTable
        light={true}
        inlineSearch={this.searchActivity}
        tableTitle="View Activity"
        columns={{
          created_at: "Date and Time",
          ip: "IP Address",
          agent: "Device",
          country: "Country",
        }}
        rows={this.props.logs}
        meta={this.props.logsmeta}
        onPagingClick={this.onPagingClick}
      />
    );
  };

  render() {
    const { match, status } = this.props;

    return (
      <>
        <UserTabs match={match} />
        <Container className="mt--6" fluid>
          <Card className="user_content">
            {(!status.error ||
              (status.error && status.error.type !== "getUser")) &&
              this.renderViewActivitySection()}
            {status.error && status.error.type === "getUser" && (
              <CardBody>
                <h1 className="mb-0" style={{ color: "#F00" }}>
                  Error! {status.error.message}
                </h1>
              </CardBody>
            )}
          </Card>
          {this.renderErrorModal()}
        </Container>
      </>
    );
  }
}
