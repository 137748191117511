import {api} from '../config';
import {get} from '../utils/superagent';
import {assetValidToken} from '../utils/auth';

export const getTradesPerDay = (data, token) => assetValidToken(token).then(() => get(`${api}/admin/statistics/trades`, data, token));
export const getFeesPerDay = (data, token) => assetValidToken(token).then(() => get(`${api}/admin/statistics/fees`, data, token));
export const getGeneralStatistics = (data, token) => assetValidToken(token).then(() => get(`${api}/admin/statistics/general`, data, token));
export const getCoinStatistics = (data, token) => assetValidToken(token).then(() => get(`${api}/admin/statistics/coins`, data, token));

export const getBotsStatistics = (token) => assetValidToken(token).then(() => get(`${api}/admin/statistics/bots`, {}, token));
export const getWithdrawals = async(data, token) => {
  const withdrawals = await assetValidToken(token).then(() => get(`${api}/admin/withdrawals`, {
    ...data,
    status: 'pending'
  }, token));
  withdrawals.Meta.pages = Math.ceil(withdrawals.Meta.count / withdrawals.Meta.limit);

  return withdrawals;
}