/**
 * Errors that can be thrown by validation or connection to external systems.
 * New error types should be added in this file and should extend the BaseError class.
 *
 * @author Cosmin Harangus <cosmin@around25.com>
 */
export class BaseError extends Error {
  name = 'BaseError';
  data = {};
  constructor(message, data) {
    super(message);
    this.data = data;
  }
}

export class InvalidFields extends BaseError {
  name = 'InvalidFields';
}

export class ServerValidationError extends BaseError {
  name = 'ServerValidationError';
}

export class UnauthorizedError extends BaseError {
  name = 'Unauthorized'
}

export class AccessDeniedError extends BaseError {
  name = 'AccessDeniedError'
}

export class OTPRequiredError extends BaseError {
  name = 'OTPRequiredError';
}

export class PreconditionFailedError extends BaseError {
  name = 'PreconditionFailedError';
}