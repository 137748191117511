import * as c from "./constants";

const initialState = {
  prdxCirculation: {},
  totalPrdxForAllLines: {},
  status: { pending: false, success: false, error: false },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.GET_INITIAL_DATA_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false },
      };
    }

    case c.GET_INITIAL_DATA_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false },
      };
    }

    case c.GET_INITIAL_DATA_SUCCESS: {
      const {prdxCirculation,totalPrdxForAllLines} = action.payload;

      return {
        ...state,
        prdxCirculation,
        totalPrdxForAllLines
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
