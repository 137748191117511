import React from "react";
import {Col, Row} from "reactstrap";

import {Checkbox} from "../Inputs";
import {KYBStatusDot} from "../KYBStatusDot";

import {KYB_GENERAL_STATUS, KYB_STEP_STATUS} from "../../constants/kyb";

export default class KYBUploadDocsToggle extends React.Component {
  constructor(props) {
    super(props)
    this.toggleStepTwo = this.toggleStepTwo.bind(this)
  }

  toggleStepTwo() {
    const { updateKYBStepTwo, stepStatus, userId, token} = this.props;

    if (stepStatus === KYB_GENERAL_STATUS.PENDING || stepStatus === KYB_GENERAL_STATUS.FAIL) {
      updateKYBStepTwo(userId, KYB_STEP_STATUS.STEP_TWO_SUCCESS, token)
    }

    if (stepStatus === KYB_GENERAL_STATUS.SUCCESS) {
      updateKYBStepTwo(userId, KYB_STEP_STATUS.STEP_TWO_FAIL, token)
    }
  };

  render() {
    const { status, stepStatus } = this.props;

    if (stepStatus === KYB_GENERAL_STATUS.NONE) {
      return (
        <Row className="form-group d-flex justify-content-between">
          <Col md="12" className="kyb-status">
            <Row>
              <Col className="font-weight-bold" md="5">
                Upload Documents
              </Col>
              <Col className="d-flex align-items-center" md="2">
                <KYBStatusDot status={stepStatus} />
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col md="12">
          <Checkbox
            onChange={this.toggleStepTwo}
            name="upload_documents"
            label="Uploaded Documents"
            checked={stepStatus === KYB_GENERAL_STATUS.SUCCESS}
            disabled={stepStatus === KYB_GENERAL_STATUS.NONE || status.pending}
          />
        </Col>
      </Row>
    )
  }
}