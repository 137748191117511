import React from "react";

import {
  Container,
  Col,
  Row,
} from "reactstrap";

import EmptyHeader from "components/Headers/EmptyHeader.jsx";

class ErrorView extends React.Component {
  render() { 
    
    return (
      <>
      <EmptyHeader  />
      <Container className="mt--6" fluid>
        <Row className="mt-8">
          <Col md="12" className="text-center error-code">403</Col>
        </Row>
        <Row>
          <Col md="12" className="text-center error-message">
            You do not have access to this page or resource!
          </Col>
        </Row>
      </Container>
      </>
    );
  }
}

export default ErrorView;