import * as ac from './actionCreators';
import api from '../../api';
import { validateNotEmpty, validateLengthLimit } from '../../utils/validator';
import { InvalidFields } from '../../utils/errors';


export function getRoles(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.getRoles.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.getRoles(payload, token);
      dispatch(ac.getRoles.success(data));
    } catch (err) {
      dispatch(ac.getRoles.error(err));
    }
  };
}

export function getRole(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.getRole.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.getRole(payload, token);
      dispatch(ac.getRole.success(data));
    } catch (err) {
      dispatch(ac.getRole.error(err));
    }
  };
}

export function getPermissions(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.getPermissions.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.getPermissions(payload, token);
      dispatch(ac.getPermissions.success(data));
    } catch (err) {
      dispatch(ac.getPermissions.error(err));
    }
  };
}

export function addRole(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.addRole.pending());
    try {
      const permissions = Object.keys(payload.permissions).filter(item => payload.permissions[item])
      
      let field_errors = {};
      if (!validateNotEmpty(payload.name)) field_errors.name = 'This field is required';
      if (!validateLengthLimit(payload.name, 50)) field_errors.name = 'Name cannot be longer then 50 characters';
      if (permissions.length === 0) field_errors.permissions = 'At least one permission is required';
      
      if (Object.keys(field_errors).length) {
        field_errors.action_required = 'Please correct errors in page before proceeding';
        throw new InvalidFields('Invalid form fields', { field_errors });
      }

      const payloadData = {
        alias: payload.name.toLowerCase().replace(/( |-)/ig,"."), 
        name: payload.name,
        permissions: permissions
      }
      const token = getState().Login.token;
      const data = await api.users.addRole(payloadData, token);
      dispatch(ac.addRole.success(data));
    } catch (err) {
      dispatch(ac.addRole.error(err));
    }
  };
}

export function updateRole(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.updateRole.pending());
    try {
      const permissions = Object.keys(payload.permissions).filter(item => payload.permissions[item])
      
      let field_errors = {};
      if (!validateNotEmpty(payload.name)) field_errors.name = 'This field is required';
      if (!validateLengthLimit(payload.name, 50)) field_errors.name = 'Name cannot be longer then 50 characters';
      if (permissions.length === 0) field_errors.permissions = 'At least one permission is required';
      
      if (Object.keys(field_errors).length) {
        field_errors.action_required = 'Please correct errors in page before proceeding';
        throw new InvalidFields('Invalid form fields', { field_errors });
      }

      const payloadData = {
        alias: payload.alias, 
        name: payload.name,
        permissions: permissions
      }
      const token = getState().Login.token;
      const data = await api.users.updateRole(payloadData, token);
      dispatch(ac.updateRole.success(data));
    } catch (err) {
      dispatch(ac.updateRole.error(err));
    }
  };
}

export function removeRole(payload) { 
  return async (dispatch, getState) => {
    dispatch(ac.removeRole.pending());
    try {
      const token = getState().Login.token;
      const data = await api.users.removeRole(payload, token);
      dispatch(ac.removeRole.success(data));
    } catch (err) {
      dispatch(ac.removeRole.error(err));
    }
  };
}

export function setField(field, value) {
  return dispatch => dispatch(ac.setField({field, value}));
}

export function setRoleField(field, value) {
  return dispatch => dispatch(ac.setRoleField({field, value}));
}

export function resetForm() {
  return dispatch => dispatch(ac.resetForm());
}