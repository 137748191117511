import * as ac from './actionCreators';
import api from '../../api';

export function getSettingsInitialData() {
  return async (dispatch, getState) => {
    dispatch(ac.getSettingsInitialData.pending());
    try {
      const token = getState().Login.token;
      const withdrawLimits = await api.settings.getWithdrawLimits(token);
      const withdrawFiatFees = await api.wallets.getWithdrawFiatFees(token);
      let data = {withdrawLimits, withdrawFiatFees};

      dispatch(ac.getSettingsInitialData.success(data));
    } catch (err) {
      dispatch(ac.getSettingsInitialData.error(err));
    }
  };
}

export function updateSettings(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.updateSettings.pending());
    try {
      const token = getState().Login.token;
      let withdrawLimitsData = {};
      let withdrawFiatFeeData = {};

      for(let i=0;i<4;i++){
        withdrawLimitsData[`withdraw_limit_level_${i}`] = payload[`withdraw_limit_level_${i}`];
      }

      if(payload.withdraw_fiat_payment_method === 'advCash'){
        for(let i=0;i<4;i++){
          withdrawLimitsData[`adv_cash_withdraw_limit_level_${i}`] = payload[`adv_cash_withdraw_limit_level_${i}`];
        }

        withdrawFiatFeeData = {
          withdraw_fee: payload.adv_cash_fee,
          external_system: 'advcash'
        };
      }else if(payload.withdraw_fiat_payment_method === 'clearJunction'){
        for(let i=0;i<4;i++){
          withdrawLimitsData[`clear_junction_withdraw_limit_level_${i}`] = payload[`clear_junction_withdraw_limit_level_${i}`];
        }

        withdrawFiatFeeData = {
          withdraw_fee: payload.clear_junction_fee,
          external_system: 'clear_junction'
        };
      }else if(payload.withdraw_fiat_payment_method === 'default'){
        for(let i=0;i<4;i++){
          withdrawLimitsData[`default_withdraw_limit_level_${i}`] = payload[`default_withdraw_limit_level_${i}`];
        }

        withdrawFiatFeeData = {
          withdraw_fee: payload.default_withdraw_fee,
          external_system: 'default'
        };
      }
      
      await api.settings.updateWithdrawLimitSettings(withdrawLimitsData, token);
      await api.settings.updateWithdrawFiatFee(withdrawFiatFeeData, token);
      
      dispatch(ac.updateSettings.success());
    } catch (err) {
      dispatch(ac.updateSettings.error(err));
    }
  };
}

export function setSettingsField(field, value) {
  return dispatch => dispatch(ac.setSettingsField({field, value}));
}
