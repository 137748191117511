const module = 'MARKETS';

export const GET_MARKETS_PENDING = `${module}/GET_MARKETS_PENDING`;
export const GET_MARKETS_SUCCESS = `${module}/GET_MARKETS_SUCCESS`;
export const GET_MARKETS_ERROR = `${module}/GET_MARKETS_ERROR`;

export const GET_MARKET_PENDING = `${module}/GET_MARKET_PENDING`;
export const GET_MARKET_SUCCESS = `${module}/GET_MARKET_SUCCESS`;
export const GET_MARKET_ERROR = `${module}/GET_MARKET_ERROR`;

export const GET_ORDERS_PENDING = `${module}/GET_ORDERS_PENDING`;
export const GET_ORDERS_SUCCESS = `${module}/GET_ORDERS_SUCCESS`;
export const GET_ORDERS_ERROR = `${module}/GET_ORDERS_ERROR`;

export const CANCEL_ORDERS_PENDING = `${module}/CANCEL_ORDERS_PENDING`;
export const CANCEL_ORDERS_SUCCESS = `${module}/CANCEL_ORDERS_SUCCESS`;
export const CANCEL_ORDERS_ERROR = `${module}/CANCEL_ORDERS_ERROR`;

export const CANCEL_ORDER_PENDING = `${module}/CANCEL_ORDER_PENDING`;
export const CANCEL_ORDER_SUCCESS = `${module}/CANCEL_ORDER_SUCCESS`;
export const CANCEL_ORDER_ERROR = `${module}/CANCEL_ORDER_ERROR`;

export const DELETE_MARKETS_PENDING = `${module}/DELETE_MARKETS_PENDING`;
export const DELETE_MARKETS_SUCCESS = `${module}/DELETE_MARKETS_SUCCESS`;
export const DELETE_MARKETS_ERROR = `${module}/DELETE_MARKETS_ERROR`;

export const ADD_MARKET_PENDING = `${module}/ADD_MARKET_PENDING`;
export const ADD_MARKET_SUCCESS = `${module}/ADD_MARKET_SUCCESS`;
export const ADD_MARKET_ERROR = `${module}/ADD_MARKET_ERROR`;

export const EDIT_MARKET_PENDING = `${module}/EDIT_MARKET_PENDING`;
export const EDIT_MARKET_SUCCESS = `${module}/EDIT_MARKET_SUCCESS`;
export const EDIT_MARKET_ERROR = `${module}/EDIT_MARKET_ERROR`;

export const UPDATE_MARKET_SETTINGS_PENDING = `${module}/UPDATE_MARKET_SETTINGS_PENDING`;
export const UPDATE_MARKET_SETTINGS_SUCCESS = `${module}/UPDATE_MARKET_SETTINGS_SUCCESS`;
export const UPDATE_MARKET_SETTINGS_ERROR = `${module}/UPDATE_MARKET_SETTINGS_ERROR`;

export const GET_VOLUME_DISTRIBUTED_PERCENT_PENDING = `${module}/GET_VOLUME_DISTRIBUTED_PERCENT_PENDING`;
export const GET_VOLUME_DISTRIBUTED_PERCENT_SUCCESS = `${module}/GET_VOLUME_DISTRIBUTED_PERCENT_SUCCESS`;
export const GET_VOLUME_DISTRIBUTED_PERCENT_ERROR = `${module}/GET_VOLUME_DISTRIBUTED_PERCENT_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;
export const SET_FILTER_FIELD = `${module}/SET_FILTER_FIELD`;
export const SET_MARKET_FIELD = `${module}/SET_MARKET_FIELD`;
export const SET_SETTINGS_FIELD = `${module}/SET_SETTINGS_FIELD`;
export const RESET_MARKET_STATE = `${module}/RESET_MARKET_STATE`;

