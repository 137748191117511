import React from "react";
import TabHeader from "components/Tabs/wallets.jsx";
import {Card, Container} from "reactstrap";
import Table from "../../../components/Table";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import CreateManualDepositModal from "../../../components/Modal/createManualDepositModal";
import DangerModal from "../../../components/Modal/danger"
import SuccessModal from "../../../components/Modal/success";
import api from "../../../api";
import * as storage from '../../../utils/storage';
import { hasPermission } from '../../../utils';

export default class Screen extends React.Component {
  ticker = null;
  state={
    confirmUserEmails: [],
    showSuccessModal: false,
    showDangerModal: false,
    dangerModalMessage: '',
    showCreateManualDepositModal: false,
    page: 1
  };

  tick = () => {
    this.props.getManualDeposits({...this.props, page: this.state.page});
  }

  getManualDepositsConfirmUsers = async() => {
    try{
      const {emails: confirmUserEmails} = await api.wallets.getManualDepositsConfirmUserEmails(storage.get('admin_auth_token', ''));
      this.setState({
        confirmUserEmails
      });
    }catch(error){
    }
  }

  componentDidMount() {
    this.getManualDepositsConfirmUsers();

    this.tick();
    this.ticker = setInterval(this.tick, 5000);
  }

  componentWillUnmount(){
    if(this.ticker){
      clearInterval(this.ticker);
    }
  }

  onClickPage = (ev, page) => {
    this.setState({page});
    this.props.getManualDeposits({...this.props, page});
  }

  setFilter = async (filterName, value) => {
    this.setState({page: 1});
    await this.props.setField(filterName, value);
    this.props.getManualDeposits({...this.props, page: 1});
  }

  getStatusList = () => {
    return [
      {label: 'all', value: ''},
      {label: 'confirmed', value: 'confirmed'},
      {label: 'unconfirmed', value: 'unconfirmed'}
    ]
  }

  toggleSuccessModal = () => {
    this.setState({
      showSuccessModal: !this.state.showSuccessModal,
    });
  }

  toggleDangerModal = () => {
    this.setState({
      showDangerModal: !this.state.showDangerModal,
    });
  }

  createManualDeposit = () => {
    this.setState({
      showCreateManualDepositModal: true
    });
  }

  renderDangerModal = () => {
    
    return (
        <DangerModal
            isOpen={this.state.showDangerModal}
            toggleFunction={() => this.toggleDangerModal()}
            closeFunction={() => this.toggleDangerModal()}
            body={this.state.dangerModalMessage || 'Error!'}
            withoutConfirm
        />
    )
  };

  renderConfirmationModal = () => {

    return (
        <SuccessModal
            isOpen={this.state.showSuccessModal}
            toggleFunction={() => this.toggleSuccessModal()}
            closeFunction={() => this.toggleSuccessModal()}
            body={'Confirmed!'}
        />
    )
  };

  

  confirmManualDeposit = async(data) => {

    if(this.props.manualDeposits.find((item) => item.ID === data.ID && item.status === 'confirmed')){
      this.setState({
        showDangerModal: true,
        dangerModalMessage: 'Error! Already Confirmed'
      });

      return;
    }

    if(!this.state.confirmUserEmails.includes(this.props.currentUser.email)){
      this.setState({
        showDangerModal: true,
        dangerModalMessage: 'Error! You have no permission'
      });

      return;
    }

    try{
      await api.wallets.confirmManualDeposit(data.ID, storage.get('admin_auth_token', ''));

      this.setState({
        showSuccessModal: true
      });
    }catch(error){
      this.setState({
        showDangerModal: true,
        dangerModalMessage: `Server error: ${error.message}`
      });
    }

  }

  getActionsArray = () => {
    return [
      {name: 'Confirm', onClick: (e, data) => this.confirmManualDeposit(data), permission: 'admin.view'},
    ]
};

  render() {
    let inlineActions = [];

    if(hasPermission("admin.view")){
      inlineActions.push({
        text: "Create Manual Deposit",
        func: this.createManualDeposit
      });
    }

    const { match, manualDeposits, manualDepositsMeta, manual_deposits_status } = this.props;
    return (
      <>
        <SimpleHeader name="Deposits" parentName="Wallets" className="pb-1"/>
        <TabHeader match={match}  />
        <Container className="mt--6" fluid>
          <Card className="user_content">
          <Table
            light
            tableTitle="Manual Deposits"
            inlineActions={inlineActions}
            inlineFilter={(value) => this.setFilter('manual_deposits_status', value)}
            inlineFilterSelected={manual_deposits_status}
            inlineFilterData={this.getStatusList()}
            columns={{
              email: "User",
              status: "Status",
              amount: "Amount",
              external_system: "Payment Method",
              coin_symbol: "Coin",
              address: "Address",
              trx_id: "TX ID",
              created_at: "Date",
            }}
            rows={manualDeposits}
            meta={manualDepositsMeta}
            actionsColumn
            actions={this.getActionsArray()}
            onPagingClick={this.onClickPage}
          />
          <CreateManualDepositModal isOpen={this.state.showCreateManualDepositModal} onClose={() => this.setState({showCreateManualDepositModal: false})}/>
          </Card>
          {this.renderConfirmationModal()}
          {this.renderDangerModal()}
        </Container>
      </>
    );
  }
}

