import {get, postFiles} from "../utils/superagent";
import {assetValidToken} from "../utils/auth";

import {api} from "../config";

export const getMyOwnOtcOrders = (query, token) =>
    assetValidToken(token).then(() =>
        get(`${api}/otc/get`, query, token)
    );

export const getAllOtcOrders = (query, token) =>
  assetValidToken(token).then(() =>
    get(`${api}/admin/otc/orders`, query, token)
  )

export const attachDocumentToOtcOrder = (otcOrderId, data, token) =>
  assetValidToken(token).then(() =>
    postFiles(`${api}/admin/otc/document/${otcOrderId}`, data, token)
  );

export const getOtcOrderDocumentUrl = (otcOrderId, query, token) =>
    assetValidToken(token).then(() =>
        get(`${api}/admin/otc/document/${otcOrderId}`, query, token)
    );
