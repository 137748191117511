import React from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import {Checkbox, renderABasicInput, renderSelectInput} from "../Inputs"
import CountryList from "react-select-country-list";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {eighteenYearsAgo} from "../../utils";
import "react-datepicker/dist/react-datepicker.css";

export default class AddUserView extends React.Component {

  constructor(props) {
    super(props);
    this.countryList = CountryList().getData();
    this.kyb_passed = null;
  }

  renderDatePicker = (label, prop, disabled) => {
    const {object} = this.props; 
    return <FormGroup className="row">
      <Label className="form-control-label" md="4">
        {label}
      </Label>
      <Col md="8">
        <div className="input-groupx">
          <label className="DatePickerWrap col-md-12 padding-none">
            <DatePicker
              maxDate={eighteenYearsAgo()}
              showYearDropdown
              showMonthDropdown
              dateFormatCalendar="MMMM"
              scrollableYearDropdown
              yearDropdownItemNumber={75}
              placeholderText="DD/MM/YYYY"
              selected={object && object[prop] ? moment(object[prop]).toDate() : null}
              onChange={(value) => this.props.onChange(prop, value)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              disabled={disabled}
              filterDate={date => moment() > date}
            />
            <span className="input-group-append">
              <i className="fa fa-calendar" />
            </span>
          </label>
        </div>
      </Col>
    </FormGroup>
  }

  getUserRoles = () => { 
    const { roles } = this.props;
    return roles.map(item => ({label: item.name, value: item.alias}))
  };

  getUserStatus = () => {
    return [
      {label: 'Pending', value: 'pending'},
      {label: 'Active', value: 'active'},
      {label: 'Blocked', value: 'blocked'},
      {label: 'Removed', value: 'removed'}
    ]
  };

  getUserGender = () => {
    return [
      {label: 'Male', value: 'male'},
      {label: 'Female', value: 'female'}
    ]
  };

  getLevel = () => {
    // const options =  [];
    // for (let i = currentLevel; i >= 0; i--) {
    //   options.push({label: `${i}`, value: `${i}`})
    // }
    // if(options.length === 3){ // passed KYC, enables level 3
    //   options.unshift({label: `3`, value: `3`})
    // }
    // return options;

    return [
      {label: '0', value: 0},
      {label: '1', value: 1},
      {label: '2', value: 2},
      {label: '3', value: 3}
    ]
  };

  render() {
    const {object, disabled} = this.props;
    const kyb_passed = this.kyp_passed && this.kyp_passed.checked;

    return (
      <Form>
        {/* <Row>
          <Col md="6">{renderABasicInput(this.props, "First Name", "first_name", "text", disabled)}</Col>
          <Col md="6">{renderABasicInput(this.props, "Last Name", "last_name", "text", disabled)}</Col>
        </Row> */}
        {/* <Row>
          <Col md="6">{this.renderDatePicker("Date of birth", "dob", disabled)}</Col>
          <Col md="6">{renderSelectInput(this.props, "Gender", this.getUserGender(), "gender", disabled)}</Col>
        </Row> */}
        <Row>
          <Col md="6">{renderABasicInput(this.props, "Email", "email", "text", true)}</Col>
          <Col
            md="6">{renderSelectInput(this.props, "Role", this.getUserRoles(), "role_alias", disabled)}</Col>
        </Row>
        <Row>
          <Col md="6">{renderSelectInput(this.props, "Status", this.getUserStatus(), "status", disabled)}</Col>
          {object && <Col md="6">{renderSelectInput(this.props, "Level", this.getLevel(object.user_level), "user_level", disabled)}</Col>}
        </Row>
        <Row>
          {/* <Col md="6">{renderABasicInput(this.props, "Address", "address", "text", disabled)}</Col> */}
          <Col md="6">{renderABasicInput(this.props, "Phone Number", "phone", "tel", disabled)}</Col>
        </Row>
        {/* <Row>
          <Col md="6">{renderSelectInput(this.props, "Country", this.countryList, "country", disabled)}</Col>
          <Col md="6">{renderABasicInput(this.props, "State", "state", "text", disabled)}</Col>
        </Row>
        <Row>
          <Col md="6">{renderABasicInput(this.props, "City", "city", "text", disabled)}</Col>
          <Col md="6">{renderABasicInput(this.props, "Postal code", "postal_code", "text", disabled)}</Col>
        </Row> */}
      </Form>
    );
  }
}

