import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import UsersTrades from '../components/trades.jsx';
import State from '../../../redux/Users';
import MarketState from '../../../redux/Markets';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getUserTrades: State.actions.getUserTrades,
      getMarkets: MarketState.actions.getMarkets,
      setField: State.actions.setField,
      downloadUserTrades: State.actions.downloadUserTrades,
    },
    dispatch
  );

const mapStateToProps = state => ({
  status: State.selectors.status(state),
  trades: State.selectors.trades(state),
  account: State.selectors.account(state),
  tradesmeta: State.selectors.tradesmeta(state),
  trades_query: State.selectors.trades_query(state),
  trades_filter: State.selectors.trades_filter(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(UsersTrades);

