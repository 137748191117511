import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Component from '../components/deposits';
import WalletState from '../../../redux/Wallets';
import CoinsAndChainsState from '../../../redux/CoinsAndChains';

const mapActionsToProps = dispatch =>
  bindActionCreators (
    {
      getCoins: CoinsAndChainsState.actions.getCoins,
      getDeposits: WalletState.actions.getDeposits,
      getBlockDeposit: WalletState.actions.getBlockDeposit,
      changeBlockDeposit: WalletState.actions.changeBlockDeposit,
      setField: WalletState.actions.setField,
    },
    dispatch
  );

const mapStateToProps = state => ({
  coins: CoinsAndChainsState.selectors.coins (state),
  block_deposit: WalletState.selectors.block_deposit (state),
  meta: WalletState.selectors.depositsMeta (state),
  deposits: WalletState.selectors.deposits (state),
  deposits_filter: WalletState.selectors.deposits_filter (state),
  deposits_coin_filter: WalletState.selectors.deposits_coin_filter (state),
  deposits_query: WalletState.selectors.deposits_query (state),
  status: WalletState.selectors.status (state),
});

export default connect (mapStateToProps, mapActionsToProps) (Component);
