import React from "react";
import history from '../../../history';
import moment from "moment";
import BotSettingsModal from "../../../components/Modal/botSettingsModal";
import BotAnalyticsModal from "../../../components/Modal/botAnalyticsModal";

import {
  Container,
  Row,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import DarkTable from "../../../components/Table";
import { hasPermission } from '../../../utils';

class Bots extends React.Component {
  state = {
    selectedBot: undefined,
    fromStatisticsDate: null,
    toStatisticsDate: null,
    orderBy: '',
    order: '',
    botId: ''
  };

  onFromStatisticsDatePickerChange = (date) => {
    const {toStatisticsDate} = this.state;
    this.setState({fromStatisticsDate: date});

    const statisticsDateData = {from_date: moment(date).unix()};
    if(toStatisticsDate) statisticsDateData.to_date = moment(toStatisticsDate).unix();
    this.props.getBots(this.getBotsParams([{name: 'statisticsDateData', value:  statisticsDateData}]));
  }

  onToStatisticsDatePickerChange = (date) => {
    const {fromStatisticsDate} = this.state;
    this.setState({toStatisticsDate: date});

    const statisticsDateData = {to_date: moment(date).unix()};
    if(fromStatisticsDate) statisticsDateData.from_date = moment(fromStatisticsDate).unix();
    this.props.getBots(this.getBotsParams([{name: 'statisticsDateData', value:  statisticsDateData}]));
  }

  componentDidMount() {
    if (!hasPermission('admin.view')) {
      history.push('/error');
    } else {
      this.props.getTotalActiveBots();
      this.props.getBots(this.getBotsParams());
    }
  }

  onPagingClick = (ev, page) => { 
    this.props.getBots(this.getBotsParams([{name: 'page', value: page}]));
  }

    getBotsStatusOptions = () => {
        return [
            {label: 'Active', value: 'active'},
            {label: 'Stopped', value: 'stopped'},
            {label: 'Stopped By System', value: 'stopped_by_system_trigger'},
            {label: 'Archived', value: 'archived'},
        ]
    };

    changeBotsStatus = (value) => {
        this.props.setField('botsStatus',value);
        this.props.getBots(this.getBotsParams([{name: 'botsStatus', value}]));
    }

    inlineSearch = async (e) => {
      this.setState({botId: e.target.value});
      this.props.getBots(this.getBotsParams([{name: 'botId', value: e.target.value}]));
    }

    getBotsParams = (paramsToSet) => {
      const {fromStatisticsDate, toStatisticsDate} = this.state;
      const statisticsDateData = {
        from_date: fromStatisticsDate ? moment(fromStatisticsDate).unix() : null,
        to_date: toStatisticsDate ? moment(toStatisticsDate).unix() : null
      };

      const params = {page: 1, statisticsDateData, botsStatus: this.props.botsStatus, orderBy: this.state.orderBy, order: this.state.order, botId: this.state.botId};

      if(!isEmpty(paramsToSet)){
        paramsToSet.forEach((item) =>  params[item.name] = item.value);
      }
      return params;
    }

    renderDetails = (bot) => {
      return (
        <table className="w-100">
          <thead>
            <tr>
              <td>Version</td>
              <td>Created</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {bot.versions
              .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
              .reverse()
              .map((item,index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{moment(item.created_at).format("LLL")}</td>
                    <td>
                      <Button
                        color="primary"
                        size="sm"
                        type="button"
                        onClick={() => {
                          this.setState({
                            selectedBot: {
                              ...bot,
                              modal: "settings",
                              selectedVersion: item,
                            },
                          });
                        }}
                      >
                        Settings
                      </Button>
                      <Button
                        color="primary"
                        size="sm"
                        type="button"
                        onClick={() => {
                          this.setState({
                            selectedBot: {
                              ...bot,
                              modal: "analytics",
                              selectedVersion: item,
                            },
                          });
                        }}
                      >
                        Analytics
                      </Button>
                      <Button
                        color="primary"
                        size="sm"
                        type="button"
                        onClick={() => history.push(`/bots/${bot.type}-bots-statistics/${bot.id}/${item.settings.currencyPair}`)}
                      >
                        Statistics
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      );
    };

  orderFunction = (orderField) => {
    
    let order = 'desc';
    if(this.state.orderBy === orderField && this.state.order === 'desc'){
      order = 'asc';
    }

    this.setState({orderBy: orderField, order});
    this.props.getBots(this.getBotsParams([{name: 'orderBy', value: orderField}, {name: 'order', value: order}]));
  }

  renderBotsSection = () => {
    const bots = this.props.bots
    // .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    // .reverse()
    .map((item) => {
      return {
        ...item,
        details: this.renderDetails(item),
      };
    });

    const tableTitle = <div>
      <Link to='/bots' className='mr-2'>Bots</Link>/<Link to='/bots/grid-bots-statistics' className='ml-2'>Bots Statistics</Link>
      <div style={{'font-size': '16px'}}>Total Active Bots: {this.props.totalActiveBots}</div>
    </div>;

    return <DarkTable
      light
      detailsColumn
      tableTitle={tableTitle}
      headerLeftColumnSize={5}
      inlineSearch={this.inlineSearch}
      inlineSearchPlaceholder={'Search by bot id'}
      inlineFilter={this.changeBotsStatus}
      inlineFilterData={this.getBotsStatusOptions()}
      inlineFilterSelected={this.props.botsStatus}
      endDateFilter={this.onToStatisticsDatePickerChange}
      endDateFilterSelected={this.state.toStatisticsDate}
      startDateFilter={this.onFromStatisticsDatePickerChange}
      startDateFilterSelected={this.state.fromStatisticsDate}
      orderSettings={{
        orderBy: this.state.orderBy,
        order: this.state.order,
        orderFunction: this.orderFunction,
        orderFields: ['created_at','expired_at']
      }}
      columns={{
        id: "id",
        user_email: "user",
        status: "status",
        type: "type",
        contract_id: "contract id",
        created_at: "created at",
        expired_at: "expired at",
        pnl: "PNL"
      }}
      rows={bots}
      meta={this.props.botsMeta}
      onPagingClick={this.onPagingClick}
    />
  };

  render() { 
    const {token} = this.props;

    return (
      <>
      <SimpleHeader name="Manage Bots" parentName="Bots" />
      <Container className="mt--6" fluid>
        <Row>&nbsp;</Row>
        {this.renderBotsSection()}
          <BotSettingsModal
            bot={this.state.selectedBot}
            onClose={() => this.setState({ selectedBot: undefined })}
          />
          <BotAnalyticsModal
            token={token}
            bot={this.state.selectedBot}
            onClose={() => this.setState({ selectedBot: undefined })}
          />
      </Container>
      </>
    );
  }
}

export default Bots;