import * as c from "./constants";
import {actionCreator, asyncActionCreator} from "../../utils/redux";

export const getMyOwnOtcOrders = asyncActionCreator(
    c.GET_MY_OWN_OTC_ORDERS_PENDING,
    c.GET_MY_OWN_OTC_ORDERS_SUCCESS,
    c.GET_MY_OWN_OTC_ORDERS_ERROR
);

export const getAllOtcOrders = asyncActionCreator(
    c.GET_ALL_OTC_ORDERS_PENDING,
    c.GET_ALL_OTC_ORDERS_SUCCESS,
    c.GET_ALL_OTC_ORDERS_ERROR
);

export const getOtcOrderDocument = asyncActionCreator(
    c.GET_OTC_ORDER_DOCUMENT_PENDING,
    c.GET_OTC_ORDER_DOCUMENT_SUCCESS,
    c.GET_OTC_ORDER_DOCUMENT_ERROR
);

export const attachDocumentToOtcOrder = asyncActionCreator(
    c.ATTACH_DOCUMENT_TO_OTC_ORDER_PENDING,
    c.ATTACH_DOCUMENT_TO_OTC_ORDER_SUCCESS,
    c.ATTACH_DOCUMENT_TO_OTC_ORDER_ERROR
);

export const setOtcDocumentField = actionCreator(c.SET_OTC_DOCUMENT_FIELD);

export const resetOtcDocument = actionCreator(c.RESET_OTC_DOCUMENT);
