const module = "MAINTENANCES";

export const GET_MAINTENANCE_PENDING = `${module}/GET_MAINTENANCE_PENDING`;
export const GET_MAINTENANCE_SUCCESS = `${module}/GET_MAINTENANCE_SUCCESS`;
export const GET_MAINTENANCE_ERROR = `${module}/GET_MAINTENANCE_ERROR`;

export const GET_MAINTENANCES_PENDING = `${module}/GET_MAINTENANCES_PENDING`;
export const GET_MAINTENANCES_SUCCESS = `${module}/GET_MAINTENANCES_SUCCESS`;
export const GET_MAINTENANCES_ERROR = `${module}/GET_MAINTENANCES_ERROR`;

export const ADD_MAINTENANCE_PENDING = `${module}/ADD_MAINTENANCE_PENDING`;
export const ADD_MAINTENANCE_SUCCESS = `${module}/ADD_MAINTENANCE_SUCCESS`;
export const ADD_MAINTENANCE_ERROR = `${module}/ADD_MAINTENANCE_ERROR`;

export const EDIT_MAINTENANCE_PENDING = `${module}/EDIT_MAINTENANCE_PENDING`;
export const EDIT_MAINTENANCE_SUCCESS = `${module}/EDIT_MAINTENANCE_SUCCESS`;
export const EDIT_MAINTENANCE_ERROR = `${module}/EDIT_MAINTENANCE_ERROR`;

export const CHANGE_MAINTENANCE_STATUS_PENDING = `${module}/CHANGE_MAINTENANCE_STATUS_PENDING`;
export const CHANGE_MAINTENANCE_STATUS_SUCCESS = `${module}/CHANGE_MAINTENANCE_STATUS_SUCCESS`;
export const CHANGE_MAINTENANCE_STATUS_ERROR = `${module}/CHANGE_MAINTENANCE_STATUS_ERROR`;

export const SET_FIELD = `${module}/SET_FIELD`;
export const SET_MAINTENANCE_FIELD = `${module}/SET_MAINTENANCE_FIELD`;
export const SET_MAINTENANCE = `${module}/SET_MAINTENANCE`;
export const RESET_MAINTENANCE_STATE = `${module}/RESET_MAINTENANCE_STATE`;
