import React from "react";
import UserTabs from "components/Tabs/user.jsx";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Button,
} from "reactstrap";
import api from "../../../api";
import ErrorModal from "../../../components/Modal/danger.jsx";

export default class UsersView extends React.Component {
  state = { status: null, errorModal: false, bodyError: "" };

  componentDidMount = () => {
    const { user_id } = this.props.match.params;
    this.props.getUser(user_id);
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.status.error &&
      this.props.status.error.type === "getUser" &&
      prevProps.status.error !== this.props.status.error
    ) {
      this.setState({
        errorModal: true,
        bodyError: this.props.status.error.message,
      });
    }
  };

  toggleErrorModal = (body) => {
    this.setState({
      errorModal: !this.state.errorModal,
      bodyError: body,
    });
  };

  renderErrorModal = () => {
    return (
      <ErrorModal
        withoutConfirm={true}
        isOpen={this.state.errorModal}
        toggleFunction={() => this.toggleErrorModal()}
        closeFunction={() => this.toggleErrorModal()}
        body={this.state.bodyError}
      />
    );
  };

  generateAddrs = (e) => {
    const { token } = this.props;
    const { user_id } = this.props.match.params;
    this.setState({ status: "Loading" });
    api.wallets
      .generateMissingWalletAddresses(user_id, token)
      .then((resp) => {
        this.setState({ status: "Done" });
        setTimeout(() => this && this.setState({ status: null }), 2000);
      })
      .catch((err) => {
        this.setState({ status: "Failed" });
        setTimeout(() => this && this.setState({ status: null }), 2000);
      });
  };

  render() {
    const { match, status: propsStatus } = this.props;
    const { status } = this.state;

    return (
      <>
        <UserTabs match={match} />

        <Container className="mt--6" fluid>
          <Card className="user_content">
            {(!propsStatus.error ||
              (propsStatus.error && propsStatus.error.type !== "getUser")) && (
              <Card>
                <>
                  <CardHeader>Generate Missing Deposit Addresses</CardHeader>
                  <CardBody>
                    <p>
                      If the deposit addresses for a user were not properly
                      generated use this functionality to generate the missing
                      deposit addresses.
                    </p>
                    <p>
                      Before using this functionality please make sure there are
                      enough funds in the ETH Gas tank and that at least 30
                      minutes passed since the user registered or this was last
                      used.
                    </p>
                  </CardBody>
                  <CardFooter>
                    <Button color="success" onClick={this.generateAddrs}>
                      Generate Addresses{status ? ` - ${status}` : ""}
                    </Button>
                  </CardFooter>
                </>
              </Card>
            )}
            {propsStatus.error && propsStatus.error.type === "getUser" && (
              <CardBody>
                <h1 className="mb-0" style={{ color: "#F00" }}>
                  Error! {propsStatus.error.message}
                </h1>
              </CardBody>
            )}
          </Card>
          {this.renderErrorModal()}
        </Container>
      </>
    );
  }
}
