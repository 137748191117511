import { api } from "../config";
import { get, put, postFiles } from "../utils/superagent";
import { assetValidToken } from "../utils/auth";

export const addMaintenance = (data, token) =>
  assetValidToken(token).then(() =>
    postFiles(`${api}/admin/system-info/maintenance`, data, token)
  );

export const editMaintenance = (data, token) =>
  assetValidToken(token).then(() =>
    postFiles(`${api}/admin/system-info/maintenance`, data, token)
  );
  
export const getMaintenances = () =>
  get(`${api}/system-info/maintenance`, {}, null);

export const changeMaintenanceStatus = (data, token) =>
  assetValidToken(token).then(() =>
    put(`${api}/admin/system-info/maintenance/status`, data, token)
  );
