import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TrendBotsStatistics from "../components/TrendBotsStatistics";
import BotsState from "../../../redux/Bots";
import MarketsState from "../../../redux/Markets";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getTrendBotsStatistics: BotsState.actions.getTrendBotsStatistics,
      downloadTrendBotsStatistics: BotsState.actions.downloadTrendBotsStatistics,
      getTotalActiveBots: BotsState.actions.getTotalActiveBots,
      setField: BotsState.actions.setField,
      getMarkets: MarketsState.actions.getMarkets,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
    status: BotsState.selectors.status(state),
    totalActiveBots: BotsState.selectors.totalActiveBots(state),
    trendBotsStatistics: BotsState.selectors.trendBotsStatistics(state),
    markets: MarketsState.selectors.markets (state),
    trendBotsStatisticsMeta: BotsState.selectors.trendBotsStatisticsMeta(state),
});

export default connect(mapStateToProps, mapActionsToProps)(TrendBotsStatistics);
