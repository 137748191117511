import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import UsersActivity from '../components/activity.jsx';
import State from '../../../redux/Users';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getUser: State.actions.getUser,
      getUserLogs: State.actions.getUserLogs,
      setField: State.actions.setField,
    },
    dispatch
  );

const mapStateToProps = state => ({
  currentUser: State.selectors.currentUser(state),
  status: State.selectors.status(state),
  logs: State.selectors.logs(state),
  logsmeta: State.selectors.logsmeta(state),
  activity_query: State.selectors.activity_query(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(UsersActivity);

