import * as c from './constants';
import moment from 'moment';

const initialState = {
  distributions: [],
  current_distribution: {},
  approximatePrdxDistributedAmount: '0',
  funds: [],
  balances: [],
  totalRedeemedPrdx: '0',
  distributionTotalPrdxForAllLines: {
    none: 0,
    silver: 0,
    gold: 0,
    platinum: 0,
    black: 0
  },
  balancesQuery: "",
  balancesLevel: "",
  meta: {},
  status: { pending: false, success: false, error: false },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case c.UPDATE_MANUAL_DISTRIBUTION_FUNDS_PENDING:
    case c.COMPLETE_MANUAL_DISTRIBUTION_PENDING:
    case c.GET_MANUAL_DISTRIBUTIONS_PENDING:
    case c.GET_MANUAL_DISTRIBUTION_FUNDS_PENDING:
    case c.GET_MANUAL_DISTRIBUTION_BALANCES_PENDING:
    case c.GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_PENDING:
      return {
        ...state,
        status: {
          pending: true,
          success: false,
          error: false
        }
      };
    case c.UPDATE_MANUAL_DISTRIBUTION_FUNDS_ERROR:
    case c.COMPLETE_MANUAL_DISTRIBUTION_ERROR:
    case c.GET_MANUAL_DISTRIBUTIONS_ERROR:
    case c.GET_MANUAL_DISTRIBUTION_FUNDS_ERROR:
    case c.GET_MANUAL_DISTRIBUTION_BALANCES_ERROR:
    case c.GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_ERROR:
      return {
        ...state,
        status: {
          pending: false,
          success: false,
          error: true
        }
      };

    case c.GET_MANUAL_DISTRIBUTIONS_SUCCESS: {
      const { Distributions, Meta } = action.payload;
      const data = Distributions.map(item => ({ 
        ...item, 
        created_at: moment(new Date(item.created_at * 1000)).format("lll"), 
        day: moment(item.day * 1000).format('LLL') 
      }));
      return {
        ...state,
        distributions: data,
        meta: Meta,
        status: {
          pending: false,
          success: true,
          error: false
        }
      }
    }

    case c.GET_DISTRIBUTION_TOTAL_PRDX_FOR_ALL_LINES_SUCCESS: {
      return {
        ...state,
        distributionTotalPrdxForAllLines: action.payload
      }
    }

    case c.COMPLETE_MANUAL_DISTRIBUTION_SUCCESS:
    case c.GET_MANUAL_DISTRIBUTION_SUCCESS: {
      return {
        ...state,
        current_distribution: {...action.payload, day: moment(action.payload.day * 1000).format('LLL')},
        status: {
          pending: false,
          success: true,
          error: false
        }
      }
    }
    case c.UPDATE_MANUAL_DISTRIBUTION_FUNDS_SUCCESS:
    case c.GET_MANUAL_DISTRIBUTION_FUNDS_SUCCESS: {
      const { ApproximateAmount, DistributionFunds, Meta } = action.payload;
      const data = DistributionFunds.map(item => ({ ...item, created_at: moment(item.created_at * 1000).format('LLL') }));
      return {
        ...state,
        approximatePrdxDistributedAmount: ApproximateAmount,
        funds: data,
        meta: Meta,
        status: {
          pending: false,
          success: true,
          error: false
        }
      }
    }
    case c.GET_MANUAL_DISTRIBUTION_BALANCES_SUCCESS: {
      const { DistributionBalances, Meta, TotalRedeemedPRDX } = action.payload;
      const data = DistributionBalances.map(item => ({ ...item, created_at: moment(item.created_at * 1000).format('LLL'), redeemed: item.status === 'claimed' ? 'yes' : 'no'}));
      return {
        ...state,
        balances: data,
        meta: Meta,
        totalRedeemedPrdx: TotalRedeemedPRDX,
        status: {
          pending: false,
          success: true,
          error: false
        }
      }
    }
    case c.SET_FIELD: { 
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    default:
      return { ...state }
  }
}