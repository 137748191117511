import * as c from './constants';
import {asyncActionCreator, actionCreator} from '../../utils/redux';

export const getRoles = asyncActionCreator(c.GET_ROLES_PENDING, c.GET_ROLES_SUCCESS, c.GET_ROLES_ERROR);
export const getRole = asyncActionCreator(c.GET_ROLE_PENDING, c.GET_ROLE_SUCCESS, c.GET_ROLE_ERROR);
export const addRole = asyncActionCreator(c.ADD_ROLE_PENDING, c.ADD_ROLE_SUCCESS, c.ADD_ROLE_ERROR);
export const updateRole = asyncActionCreator(c.SET_ROLE_PENDING, c.SET_ROLE_SUCCESS, c.SET_ROLE_ERROR);
export const removeRole = asyncActionCreator(c.DELETE_ROLE_PENDING, c.DELETE_ROLE_SUCCESS, c.DELETE_ROLE_ERROR);
export const getPermissions = asyncActionCreator(c.GET_PERMISSIONS_PENDING, c.GET_PERMISSIONS_SUCCESS, c.GET_PERMISSIONS_ERROR);

export const setField = actionCreator(c.SET_FIELD);
export const setRoleField = actionCreator(c.SET_ROLE_FIELD);
export const resetForm = actionCreator(c.RESET_ROLES_STATE);