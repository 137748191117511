import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Profile from '../components/profile.jsx';
import ProfileState from '../../../redux/Profile';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
        getProfileDetails: ProfileState.actions.getProfileDetails,
        setCurrentUserField: ProfileState.actions.setCurrentUserField,
        updateUser: ProfileState.actions.updateProfileDetails,
        updatePassword: ProfileState.actions.updatePassword,
    },
    dispatch
  );

const mapStateToProps = state => ({
  currentUser: ProfileState.selectors.currentUser(state),
  status: ProfileState.selectors.status(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Profile);



