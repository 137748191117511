import React from "react";
import history from '../../../history';
import ChainForm from "components/ChainForm/index.jsx";
import SuccessModal from "../../../components/Modal/success.jsx";

import {
  Card,
  CardBody,
  Container,
  CardHeader,
  Button
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class ChainDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false
    }; 
  }

  async componentDidMount() {
    this.props.getChains();
    
    const chain_id = this.props.match.params.chain_id;
    if(chain_id) {
      await this.props.getChain(chain_id); 
      if(this.props.status.error && this.props.chain.symbol === "") {
        //invalid chain symbol, redirect to list of chains
        this.cancel();
      }
    } else {
      this.props.resetForm();
    }
  }

  cancel = () => {
    history.push('/manage-coins-chains');
  }

  addChain = async () => {
    await this.props.addChain(this.props.chain);
    if (this.props.status.success) {
      this.toggleModal();
    }
  }

  editChain = async () => { 
    await this.props.updateChain(this.props.chain);
    if (this.props.status.success) {
      this.toggleModal();
    }
  }

  toggleModal = () => {
    this.setState({
        blockModal: !this.state.blockModal,
    });
  }

  renderConfirmationModal = () => {
    const response = "Saved!"
    return (
        <SuccessModal
            isOpen={this.state.blockModal}
            toggleFunction={() => this.toggleModal()}
            closeFunction={() => this.toggleModal()}
            body={response}
        />
    )
  };

  render() { 
    const { chains, chain, status, setChainField } = this.props;
    const chain_id = this.props.match.params.chain_id;
    const action_text = chain_id ? "Edit chain" : "Add a chain";
    
    return (
        <>
            <SimpleHeader name={action_text} parentName="Manage Chains"/>
            <Container className="mt--6" fluid>
                <Card>
                    <CardHeader>
                        <h3 className="mb-0">{action_text}</h3>
                    </CardHeader>
                    <CardBody>
                        <ChainForm object={chain} chains={chains} status={status} onChange={setChainField} />
                        <Button color="info" type="button" className="float-right ml-3" onClick={this.cancel}>Cancel</Button>
                        <Button color="success" type="button" className="float-right" onClick={chain_id ? this.editChain : this.addChain}>{action_text}</Button>
                    </CardBody>
                </Card>
                {this.renderConfirmationModal()}    
            </Container>
        </>
    );
  }
}

export default ChainDetail;
