import * as c from './constants';
import moment from 'moment';

const initialState = {
  announcements: [],
  announcementsSettings: [],
  announcement: {
    topic_title: '',
    message: '',
  },
  announcementsMeta: undefined,
  status: { pending: false, success: false, error: false },
  page: 1,
  limit: 10,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    // case c.CHANGE_ANNOUNCEMENT_STATUS_PENDING:
    case c.GET_ANNOUNCEMENT_PENDING:
    case c.GET_ANNOUNCEMENTS_SETTINGS_PENDING:
    case c.EDIT_ANNOUNCEMENT_PENDING:
    case c.GET_ANNOUNCEMENTS_PENDING:
    case c.ADD_ANNOUNCEMENT_PENDING:
    case c.ADD_TOPIC_PENDING:
    case c.EDIT_TOPIC_PENDING:
    case c.DELETE_TOPIC_PENDING: {
      return {
        ...state,
        status: { pending: true, error: false, success: false },
      };
    }

    // case c.CHANGE_ANNOUNCEMENT_STATUS_ERROR:
    case c.GET_ANNOUNCEMENT_ERROR:
    case c.GET_ANNOUNCEMENTS_SETTINGS_ERROR:
    case c.EDIT_ANNOUNCEMENT_ERROR:
    case c.GET_ANNOUNCEMENTS_ERROR:
    case c.ADD_ANNOUNCEMENT_ERROR:
    case c.ADD_TOPIC_ERROR:
    case c.EDIT_TOPIC_ERROR:
    case c.DELETE_TOPIC_ERROR: {
      return {
        ...state,
        status: { pending: false, error: action.payload, success: false },
      };
    }

    case c.GET_ANNOUNCEMENT_SUCCESS: {
      const announcement = action.payload;

      return {
        ...state,
        announcement,
      };
    }

    case c.GET_ANNOUNCEMENTS_SUCCESS: {
      const announcements = action.payload.announcement.map((item) => {
        return {
          ...item,
          created_at: moment(item.created_at).format('LLL'),
        };
      });
      return {
        ...state,
        announcements: announcements,
        announcementsMeta: action.payload.meta,
      };
    }

    case c.GET_ANNOUNCEMENTS_SETTINGS_SUCCESS: {
      const announcementsSettings = action.payload;

      return {
        ...state,
        announcementsSettings,
      };
    }

    // case c.CHANGE_ANNOUNCEMENT_STATUS_SUCCESS:
    case c.EDIT_ANNOUNCEMENT_SUCCESS:
    case c.ADD_ANNOUNCEMENT_SUCCESS:
    case c.ADD_TOPIC_SUCCESS:
    case c.EDIT_TOPIC_SUCCESS:
    case c.DELETE_TOPIC_SUCCESS: {
      return {
        ...state,
        status: { pending: false, error: false, success: true },
      };
    }

    case c.RESET_ANNOUNCEMENT_STATE: {
      return {
        ...state,
        announcement: {
          ...initialState.announcement,
        },
      };
    }

    case c.SET_ANNOUNCEMENT_FIELD:
    case c.SET_TOPIC_FIELD: {
      return {
        ...state,
        announcement: {
          ...state.announcement,
          [action.payload.field]: action.payload.value,
        },
      };
    }

    case c.SET_ANNOUNCEMENT: {
      return {
        ...state,
        announcement: action.payload,
      };
    }

    case c.SET_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
