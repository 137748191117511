import React from "react";
import UserTabs from "components/Tabs/user.jsx";

import {Card, Container} from "reactstrap";
import DarkTable from "../../../components/Table";
import DangerModal from "../../../components/Modal/danger.jsx";
import NotificationAlert from "react-notification-alert";
import { hasPermission } from '../../../utils';
import api from '../../../api';
import { notify } from "../../../utils";

export default class UsersView extends React.Component {
  ticker = null;
  state={
    page: 1,
    showRevertModal: false,
    showCancelModal: false,
    cancelOrderId: null,
    cancelOrdersMarketId: null
  };

  tick = () => {
    this.load(this.state.page);
  }

  componentDidMount = async () => {
    await this.props.getMarkets({limit: 1000});
    this.tick();
    this.ticker = setInterval(this.tick, 5000);
  };

  componentWillUnmount(){
    if(this.ticker){
      clearInterval(this.ticker);
    }
  }

  onOrdersPagingClick = (ev, page) => {
    this.setState({page});
    this.load(page);
  }

  searchOrders = async (e) => {
    this.setState({page: 1});
    await this.props.setField('orders_query', e.target.value);
    this.load(1);
  }

  setOrdersFilter = async (value) => {
    this.setState({page: 1});
    await this.props.setField('orders_filter', value);
    this.load(1);
  }

  setOrdersAccountFilter = async (value) => {
    this.setState({page: 1});
    await this.props.setField('account', value);
    this.load(1);
  }

  showConfirmRevertModal = (event, row) => {
    event.preventDefault();
    this.toggleModal(row, "revert");
  };

  revertOrder = (order) => { 
    const {token} = this.props;
    api.users.revertOrder(order.market_id, order.id, token).then(resp => {
      notify("success", this.refs.notificationAlert, 'Order added in revert queue!')
    }).catch(err => {
      notify("danger", this.refs.notificationAlert, `${err}`)
    });
    this.toggleModal(order, "block");
  };

  getActionsArray = () => {
    return [
      {name: 'Revert Order (only for pending)', onClick: this.showConfirmRevertModal, permission: 'order.cancel.all'},
      {name: 'Cancel', onClick: this.cancelOrderAlert, permission: 'order.cancel.all'},
    ].filter(item => (
      (item.link !== '/user/:user_id' && hasPermission(item.permission)))
    )
  };

  load(page) {
    page = page || 1;
    const {user_id} = this.props.match.params;
    this.props.getUserOrders({...this.props, user_id, page});
  }

  getOrdersStatusList = () => {
    return [
      {label: 'Open', value: 'open'},
      {label: 'Filled', value: 'filled'},
      {label: 'Cancelled', value: 'cancelled'},
    ]
  }

  getAccountsList = () => {
    return [
      {label: 'Main', value: 'main'},
      {label: 'Bonus', value: 'bonus'}
    ]
  }

  renderViewOrdersSection = () => {
    return <DarkTable
      light={true}
      inlineFilterAccount={this.setOrdersAccountFilter}
      inlineFilterAccountData={this.getAccountsList()}
      inlineFilterAccountSelected={this.props.account}
      inlineSearch={this.searchOrders}
      inlineFilter={this.setOrdersFilter}
      inlineFilterSelected={this.props.orders_filter}
      inlineFilterData={this.getOrdersStatusList()}
      tableTitle="View Orders"
      columns={{
        id: "ID",
        created_at: "Date & Time",
        market_id: "Pair",
        side: "Side",
        // stop: "Stop",
        fee_amount: "Fees",

        type: "Type",
        amount: "Amount",
        price: "Price",
        filled_percent: "Filled",
        total: "Total",
        status: "Status",
      }}
      actionsColumn={true}
      actions={this.getActionsArray()}
      rows={ this.props.orders }
      meta={ this.props.ordersmeta }
      onPagingClick={this.onOrdersPagingClick}
    />;
  };

  toggleModal = (order, type) => {
    this.setState({
      showRevertModal: type === "revert" && !this.state.showRevertModal,
      currentOrder: order,
    });
  };

  renderConfirmRevertModal = () => {
    const {currentOrder} = this.state;
    return (
      <DangerModal
        isOpen={this.state.showRevertModal}
        toggleFunction={() => this.toggleModal(currentOrder, "revert")}
        closeFunction={() => this.toggleModal(currentOrder, "revert")}
        nextFunction={() => this.revertOrder(currentOrder)}
        body="Are you sure you want to revert this order?"
      />
    )
  };

  cancelOrderAlert = (event, row) => {
    event.preventDefault();
    this.toggleCancelModal(row.id, row.market_id);
  };

  cancelOrder = (orderId, marketId) => {
    this.props.cancelOrder(orderId, marketId, this.refs.notificationAlert);
    this.toggleCancelModal(orderId, marketId);
    this.setState({page: 1});
    this.tick(1);
  };

  toggleCancelModal = (orderId, marketId) => {
    this.setState({
        showCancelModal: !this.state.showCancelModal,
        cancelOrderId: orderId,
        cancelOrdersMarketId: marketId,
    });
  };

  renderCancelOrderConfirmationModal = () => {
    const {cancelOrderId, cancelOrdersMarketId} = this.state;
    const body = `Are you sure you want to cancel this order?`;
    return (
        <DangerModal
            isOpen={this.state.showCancelModal}
            toggleFunction={() => this.toggleCancelModal(cancelOrderId, cancelOrdersMarketId)}
            closeFunction={() => this.toggleCancelModal(cancelOrderId, cancelOrdersMarketId)}
            nextFunction={() => this.cancelOrder(cancelOrderId, cancelOrdersMarketId)}
            body={body}
        />
    )
  };

  render() {
    const { match } = this.props;
    return (
      <>
        <UserTabs match={match} />
        <Container className="mt--6" fluid>
          <Card className="user_content">
            {this.renderViewOrdersSection()}
            {this.renderConfirmRevertModal()}
            {this.renderCancelOrderConfirmationModal()}
            <div className="rna-wrapper">
              <NotificationAlert ref="notificationAlert"/>
            </div>
          </Card>
        </Container>
      </>
    );
  }
}
