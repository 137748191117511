import React from "react";
import {Link} from 'react-router-dom';
import classnames from "classnames";
import Geetest from 'react-geetest';
import history from '../../../history';
import Modal from '../../../components/Modal/2fa.jsx';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import {OTPRequiredError } from '../../../utils/errors';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: false,
      twoFAModal: this.is2FActivated()
    };

    this.onChangeRememberMe = this.onChange.bind(this, 'remember');
    this.onChangeOTPCode = this.onChange.bind(this, 'otp_code');
    this.onChangeRecaptcha = this.onChange.bind(this, 'recaptcha');
  }

  componentDidMount() {
    this.props.geetestEmpty();
    this.props.isRecaptchaEnabled();
    if (this.state.twoFAModal === true) {
      this.closeTheModalInInterval();
    }
  }

  closeTheModal() {
    this.props.geetestEmpty();
    this.props.isRecaptchaEnabled();
    this.props.removeToken();
    this.setState({twoFAModal: false});
  }

  // close the modal after 5 min - when the token expires
  closeTheModalInInterval() {
    setInterval(async() => {
      this.closeTheModal();
    }, 5 * 60 * 1000);
  }

  is2FActivated = (props = this.props) => {
    return props.twoFAModal.status;
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      twoFAModal: !prevState.twoFAModal
    }));
  };

  render2FAModal = () => {
    const {status} = this.props;
    const error =
      status.error && status.error.message ? status.error.message : '';
    return (
      <Modal
        onChangeOTPCode={this.onChangeOTPCode}
        className='sms-google-modal'
        modal={this.state.twoFAModal}
        type={this.get2FAType()}
        submitFunction={this.login}
        error={error}
        toggle={() => this.closeTheModal()}
      />
    );
  };

  get2FAType = () => {
    if (!this.is2FActivated()) return null;
    return this.props.twoFAModal.type;
  };


  onChange = (path, e) => {
    switch (path) {
      case 'remember':
        return this.props.setField('remember', e.target.checked);
      case 'otp_code':
        return this.props.setField('otp_code', e);
      default:
        return this.props.setField(path, e.target.value);
    }

};

  login = async(otpCode) => {
    await this.props.login({
      ...this.props,
      otp_code: otpCode || this.props.otp_code,
      source: 'admin',
      geetest: this.props.geetest,
      recaptchaEnabled: this.props.recaptchaEnabled,
      instance: this.state.instance
    });
    const {error} = this.props.status;
    if (this.props.twoFAModal.status && (error === false || !(error instanceof OTPRequiredError))){
      this.toggleModal()
    }
  };

  // submit the form when geetest popup validation success
  componentDidUpdate(prevProps) {
    if ((!prevProps.geetest.validate || !prevProps.geetest.validate.geetest_challenge) &&
      this.props.geetest.validate && this.props.geetest.validate.geetest_challenge
    ) {
      this.login();
    }
  };

  // validate geetest
  onGeetestSuccess = (result) => this.props.geetestValidate(result);

  // save the instance when geetest captcha is ready
  onReady = (arg, instance) => this.setState({instance: instance});

  renderLoginFields = () => {
    const {status, recaptchaEnabled, geetest} = this.props;
    return <Form role="form" onSubmit={this.login}>
      <FormGroup
        className={classnames("mb-3", {
          focused: this.state.focusedEmail
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83"/>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Email"
            type="email"
            value={this.props.email}
            onChange={(e) => this.onChange('email', e)}
            onFocus={() => this.setState({focusedEmail: true})}
            onBlur={() => this.setState({focusedEmail: false})}
          />
        </InputGroup>
        <span className="help-block error">
              {status &&
              status.error &&
              status.error.data &&
              status.error.data.field_errors &&
              status.error.data.field_errors.email}
              </span>
      </FormGroup>
      <FormGroup
        className={classnames({
          focused: this.state.focusedPassword
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open"/>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Password"
            type="password"
            value={this.props.password || ""}
            onChange={(e) => this.onChange('password', e)}
            onFocus={() =>
              this.setState({focusedPassword: true})
            }
            onBlur={() =>
              this.setState({focusedPassword: false})
            }
          />
        </InputGroup>
        <span className="help-block error">
            {status &&
            status.error &&
            status.error.data &&
            status.error.data.field_errors &&
            status.error.data.field_errors.password}
            </span>
      </FormGroup>

      <div className="custom-control custom-control-alternative custom-checkbox">
        <input
          className="custom-control-input"
          id=" customCheckLogin"
          type="checkbox"
          checked={this.props.remember || false}
          onChange={this.onChangeRememberMe}
        />
        <label
          className="custom-control-label"
          htmlFor=" customCheckLogin"
        >
          <span className="text-muted">Remember me</span>
        </label>
      </div>
      {recaptchaEnabled && geetest.register ?
        <div className="custom-control custom-control-alternative"><br/>
          <Geetest
            lang="en"
            bgColor="black"
            product="bind"
            offline={!geetest.register.success}
            gt={geetest.register.gt}
            challenge={geetest.register.challenge}
            onSuccess={this.onGeetestSuccess}
            onReady={this.onReady}
          />
        </div>
        : null }
      <div className="text-center">
        <Button className="my-4" color="info" type="button" onClick={this.login}>
          Sign in
        </Button><br />
        {status.error && status.error.data && status.error.data.error ?
          <span className="error">{status.error.data.error}</span>
          : null
        }
      </div>
    </Form>
  }

  render() {
    return (
      <>
      <AuthHeader
        title="Welcome admin!"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">

              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Sign in to your account</small>
                </div>
                {this.renderLoginFields()}
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link
                  className="text-light"
                  to="/auth/forgot"
                  onClick={this.props.geetestEmpty}
                >
                  <small>Forgot password?</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {this.render2FAModal()}
      </>
    );
  }
}

export default Login;