import React from 'react';
import UserForm from 'components/UserForm/index.jsx';
import SecurityForm from 'components/UserForm/securityForm.jsx';
import FeesForm from 'components/UserForm/fees.jsx';
import PasswordForm from 'components/UserForm/password.jsx';
import UserTabs from 'components/Tabs/user.jsx';

import SuccessModal from '../../../components/Modal/success.jsx';
import ErrorModal from '../../../components/Modal/danger.jsx';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Col,
  Form,
  Row,
} from 'reactstrap';
import { hasPermission } from '../../../utils';
import {
  renderABasicInput,
  renderSelectInput,
} from '../../../components/Inputs';

const paymentMethods = [
  { value: 'bitgo', label: 'Bitgo' },
  { value: 'advcash', label: 'Advanced Cash' },
  { value: 'clear_junction', label: 'Clear Junction' },
  { value: 'default', label: 'Default' },
];

export default class UsersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockModal: false,
      errorModal: false,
      warningModal: false,
      bodyError: '',
      warningModalType: '',
    };
  }

  componentDidMount = () => {
    const user_id = this.props.match.params.user_id;
    this.props.getRoles({ limit: 0 });
    this.props.getUser(user_id);
    this.props.getUserFees(user_id);
    this.props.getWithdrawSettings(user_id);
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.status.error &&
      this.props.status.error.type === 'getUser' &&
      prevProps.status.error !== this.props.status.error
    ) {
      this.setState({
        errorModal: true,
        bodyError: this.props.status.error.message,
      });
    }
  };

  toggleModal = () => {
    this.setState({
      blockModal: !this.state.blockModal,
    });
  };

  toggleErrorModal = (body) => {
    this.setState({
      errorModal: !this.state.errorModal,
      bodyError: body,
    });
  };

  toggleWarningModal = (body, type) => {
    this.setState({
      warningModal: !this.state.warningModal,
      bodyError: body,
      warningModalType: type,
    });
  };

  updateUser = async (user_id, currentUser) => {
    await this.props.updateUser(user_id, currentUser);
    if (this.props.status.success) {
      this.toggleModal();
    }
  };
  updatePassword = async (user_id, currentUser) => {
    await this.props.updatePassword(user_id, currentUser);
    if (this.props.status.success) {
      this.toggleModal();
    }
  };

  updateSettings = async (user_id, currentUser) => {
    await this.props.updateSettings(user_id, currentUser);
    if (this.props.status.success) {
      this.toggleModal();
    } else {
      this.toggleErrorModal(this.props.status.error.data.error);
    }
  };

  updateWithdrawSettings = async (user_id, withdrawSettings) => {
    await this.props.updateWithdrawSettings(user_id, withdrawSettings);
    if (this.props.status.success) {
      await this.props.getWithdrawSettings(user_id);
      this.toggleModal();
    } else {
      this.toggleErrorModal(this.props.status.error.data.error);
    }
  };

  renderConfirmationModal = () => {
    return (
      <SuccessModal
        isOpen={this.state.blockModal}
        toggleFunction={() => this.toggleModal()}
        closeFunction={() => this.toggleModal()}
        body="Saved"
      />
    );
  };

  renderErrorModal = () => {
    return (
      <ErrorModal
        withoutConfirm={true}
        isOpen={this.state.errorModal}
        toggleFunction={() => this.toggleErrorModal()}
        closeFunction={() => this.toggleErrorModal()}
        body={this.state.bodyError}
      />
    );
  };

  renderWarningModal = () => {
    return (
      <ErrorModal
        isOpen={this.state.warningModal}
        toggleFunction={this.closeWarningModal}
        nextFunction={this.toggleFunctionAfterWarningModal}
        closeFunction={this.closeWarningModal}
        body={this.state.bodyError}
      />
    );
  };

  closeWarningModal = () => {
    this.toggleWarningModal();
    this.props.setCurrentUserField(
      this.state.warningModalType,
      !this.props.currentUser[this.state.warningModalType],
    );
  };

  submitDetectIPChange = async (user_id) => {
    if (this.props.currentUser.detect_ip_change) {
      await this.props.enableDetectIP(user_id);
    } else {
      await this.props.disableDetectIP(user_id);
    }
  };

  submitFunctionByType = async () => {
    const user_id = this.props.match.params.user_id;
    switch (this.state.warningModalType) {
      case 'trade_password_exists':
        return await this.props.disableTradePass(user_id);
      case 'detect_ip_change':
        return await this.submitDetectIPChange(user_id);
      case 'anti_phishing_exists':
        return await this.props.disableAntiphishingKey(user_id);
      case 'google_2fa_exists':
        return await this.props.disableGoogleAuth(user_id);
      case 'sms_2fa_exists':
        return await this.props.disableSMSAuth(user_id);
      case 'block_deposit_crypto':
        return await this.props.changeBlockDepositCrypto(
          user_id,
          this.props.currentUser.block_deposit_crypto,
        );
      case 'block_deposit_fiat':
        return await this.props.changeBlockDepositFiat(
          user_id,
          this.props.currentUser.block_deposit_fiat,
        );
      case 'block_withdraw_crypto':
        return await this.props.changeBlockWithdrawCrypto(
          user_id,
          this.props.currentUser.block_withdraw_crypto,
        );
      case 'block_withdraw_fiat':
        return await this.props.changeBlockWithdrawFiat(
          user_id,
          this.props.currentUser.block_withdraw_fiat,
        );
      default:
        return '';
    }
  };

  toggleFunctionAfterWarningModal = async () => {
    await this.submitFunctionByType();
    this.setState({ warningModal: false });
    if (this.props.status.success) {
      this.toggleModal();
    } else {
      this.toggleErrorModal(this.props.status.error.data.error);
    }
  };

  render() {
    const {
      currentUser,
      fees,
      updateFees,
      setCurrentUserField,
      status,
      match,
      roles,
      disableTradePass,
    } = this.props;

    const isEdit =
      match.path === '/user/:user_id/edit' && hasPermission('user.edit')
        ? true
        : false;
    const title = isEdit ? 'Edit user' : 'View user';
    const user_id = this.props.match.params.user_id;
    return (
      <>
        <UserTabs match={match} />
        <Container className="mt--6" fluid>
          <Card className="user_content">
            {(!status.error ||
              (status.error && status.error.type !== 'getUser')) && (
              <>
                <CardBody>
                  <UserForm
                    disabled={!isEdit}
                    object={currentUser}
                    status={status}
                    roles={roles}
                    onChange={setCurrentUserField}
                  />
                  {isEdit && (
                    <Button
                      color="success"
                      type="button"
                      className="float-right"
                      onClick={() => this.updateUser(user_id, currentUser)}
                    >
                      {title}
                    </Button>
                  )}
                </CardBody>
                {isEdit && (
                  <>
                    <CardBody>
                      <PasswordForm
                        object={currentUser}
                        status={status}
                        hideCurrentPassword={true}
                        onChange={setCurrentUserField}
                      />
                      <Button
                        color="success"
                        type="button"
                        className="float-right"
                        onClick={() =>
                          this.updatePassword(user_id, currentUser)
                        }
                      >
                        Change password
                      </Button>
                    </CardBody>
                    <CardHeader>
                      <h3 className="mb-0">User settings</h3>
                    </CardHeader>
                    <CardBody>
                      <SecurityForm
                        object={currentUser}
                        onChange={setCurrentUserField}
                        disableTradePass={disableTradePass}
                        user_id={user_id}
                        updateSettings={this.updateSettings}
                        toggleWarningModal={this.toggleWarningModal}
                      />
                    </CardBody>

                    <CardHeader>
                      <h3 className="mb-0">Trading Fees</h3>
                    </CardHeader>
                    <CardBody>
                      <FeesForm fees={fees} onSubmit={updateFees} />
                    </CardBody>
                    <CardHeader>
                      <Row>
                        <Col md="8">
                          <h3 className="mb-0">Withdraw Settings</h3>
                        </Col>
                        <Col md="4">
                          {renderSelectInput(
                            {
                              object: this.props.withdrawSettings,
                              status,
                              onChange: this.props.setWithdrawSettingsField,
                            },
                            'Payment Method',
                            paymentMethods,
                            'paymentMethod',
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="5">
                            {renderABasicInput(
                              {
                                object: this.props.withdrawSettings,
                                status,
                                onChange: this.props.setWithdrawSettingsField,
                              },
                              `Max Withdraw Limit ${
                                this.props.withdrawSettings.paymentMethod ===
                                'bitgo'
                                  ? '(BTC)'
                                  : '(USDT)'
                              }`,
                              'maxLimit',
                              'number',
                            )}
                          </Col>
                        </Row>
                        <Button
                          color="success"
                          type="button"
                          className="float-right"
                          onClick={() =>
                            this.updateWithdrawSettings(
                              user_id,
                              this.props.withdrawSettings,
                            )
                          }
                        >
                          Update withdraw settings
                        </Button>
                      </Form>
                    </CardBody>
                  </>
                )}
              </>
            )}
            {status.error && status.error.type === 'getUser' && (
              <CardBody>
                <h1 className="mb-0" style={{ color: '#F00' }}>
                  Error! {status.error.message}
                </h1>
              </CardBody>
            )}
          </Card>
          {this.renderConfirmationModal()}
          {this.renderWarningModal()}
          {this.renderErrorModal()}
        </Container>
      </>
    );
  }
}
