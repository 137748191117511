import * as ac from './actionCreators';
import api from '../../api';

export function getDistributionEvents(payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getDistributionEvents.pending());
    try {
      const token = getState().Login.token;
      const data = await api.distributions.getDistributionEvents(payload, token);
      dispatch(ac.getDistributionEvents.success(data));
    } catch (err) {
      dispatch(ac.getDistributionEvents.error(err));
    }
  };
}

export function getDistributionOrders(distribution_id, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getDistributionOrders.pending());
    try {
      const token = getState().Login.token;
      const data = await api.distributions.getDistributionOrders(distribution_id, payload, token);
      dispatch(ac.getDistributionOrders.success(data));
    } catch (err) {
      dispatch(ac.getDistributionOrders.error(err));
    }
  };
}

export function getDistributionEntries(distribution_id, payload) {
  return async (dispatch, getState) => {
    dispatch(ac.getDistributionEntries.pending());
    try {
      const token = getState().Login.token;
      const data = await api.distributions.getDistributionEntries(distribution_id, payload, token);
      dispatch(ac.getDistributionEntries.success(data));
    } catch (err) {
      dispatch(ac.getDistributionEntries.error(err));
    }
  };
}

export function selectDistributionEvent(distribution) {
  return (dispatch) => {
    dispatch(ac.selectDistributionEvent(distribution));
  }
}