import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Topics from '../components';
import AnnouncementsState from '../../../redux/Announcements';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      addTopic: AnnouncementsState.actions.addTopic,
      editTopic: AnnouncementsState.actions.editTopic,
      deleteTopic: AnnouncementsState.actions.deleteTopic,
      getAnnouncementSettings:
        AnnouncementsState.actions.getAnnouncementsSettings,
      setAddTopicField: AnnouncementsState.actions.setTopicField,
      resetForm: AnnouncementsState.actions.resetForm,
    },
    dispatch,
  );

const mapStateToProps = (state) => ({
  topic: AnnouncementsState.selectors.announcement(state),
  announcements: AnnouncementsState.selectors.announcements(state),
  status: AnnouncementsState.selectors.status(state),
  announcementsSettings:
    AnnouncementsState.selectors.announcementsSettings(state),
});

export default connect(mapStateToProps, mapActionsToProps)(Topics);
