import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import OtcOrders from "../components/index";
import OtcOrdersState from "../../../redux/OtcOrders";

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllOtcOrders: OtcOrdersState.actions.getAllOtcOrders,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  otcOrders: OtcOrdersState.selectors.otcOrders(state),
  otcOrdersMeta: OtcOrdersState.selectors.otcOrdersMeta(state),
  status: OtcOrdersState.selectors.status(state),
});

export default connect(mapStateToProps, mapActionsToProps)(OtcOrders);
