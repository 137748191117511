import React from "react";
import {Col, Form, Row} from "reactstrap";
import { renderABasicInput, renderSelectInput } from "../Inputs"

export default class ChainForm extends React.Component {

  getChainStatus = () => {
    return [
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'}
    ]
  };

  render() { 
    return ( 
      <Form>
        <Row>
          <Col md="4">{renderABasicInput(this.props, "Chain Name", "name")}</Col>
          <Col md="4">{renderABasicInput(this.props, "Symbol", "symbol")}</Col>
          <Col md="4">{renderSelectInput(this.props, "Status", this.getChainStatus(), "status")}</Col>
        </Row>
      </Form>
    );
  }
}

