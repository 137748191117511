import * as c from './constants';
import {asyncActionCreator, actionCreator} from '../../utils/redux';

export const forgotPassword = asyncActionCreator(c.FORGOT_PASS_PENDING, c.FORGOT_PASS_SUCCESS, c.FORGOT_PASS_ERROR);
export const resetPassword = asyncActionCreator(c.RESET_PASS_PENDING, c.RESET_PASS_SUCCESS, c.RESET_PASS_ERROR);
export const login = asyncActionCreator(c.LOGIN_PENDING, c.LOGIN_SUCCESS, c.LOGIN_ERROR);
export const isRecaptchaEnabled = asyncActionCreator(c.ISRECAPTCHAENABLED_PENDING, c.ISRECAPTCHAENABLED_SUCCESS, c.ISRECAPTCHAENABLED_ERROR);
export const getUserPermissions = asyncActionCreator(c.USER_PERMISSIONS_PENDING, c.USER_PERMISSIONS_SUCCESS, c.USER_PERMISSIONS_ERROR);
export const geetestRegister = asyncActionCreator(c.GEETEST_REGISTER_PENDING, c.GEETEST_REGISTER_SUCCESS, c.GEETEST_REGISTER_ERROR);
export const geetestValidate = actionCreator(c.GEETEST_VALIDATE);
export const geetestReset = actionCreator(c.GEETEST_RESET);
export const geetestEmpty = actionCreator(c.GEETEST_EMPTY);

export const removeToken = actionCreator(c.REMOVE_TOKEN);

export const setField = actionCreator(c.SET_FIELD);
export const logout = actionCreator(c.LOGOUT);