import React from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import { renderABasicInput } from "../Inputs"

export default class AddRoleView extends React.Component {
  
  onChangeCheckbox = (item, e) => { 
    const { object, onChange } = this.props; 
    onChange(item, !object.permissions[item.alias]);
  }

  renderCheckBox = (item, value) => { 
    return (
        <Row>
            <Label className="form-control-label font-weight-normal" md="3">{item.name}</Label>
            <Col md="2" className="col-form-label">
                <label className="mr-1">
                    <input type="checkbox" className="feature-checkbox" onChange={(e) => this.onChangeCheckbox(item, e)} checked={value || false}/>
                </label>
            </Col>
        </Row>
    )
  };

  renderGroup = (index, items, values) => { 
    return (
      <>
        <Label className="form-control-label propper-case mt-3" >{index} permissions</Label>
        {items && items.map(item => { 
          return <div key={item.alias}>{this.renderCheckBox(item, values[item.alias])}</div>
        })}
      </>
    )
  };

  render() { 
    const { object, permissions, status } = this.props;  
    return (
      <Form>
        <Row>
          <Col md="6">{renderABasicInput(this.props, "Role Name", "name", "text", false, 50)}</Col> 
        </Row>

        {permissions && Object.keys(permissions).map(item => { 
          return <Row className="bottom-border" key={item}><Col>{this.renderGroup(item, permissions[item], object.permissions)}</Col></Row>
        })}
        <span className="help-block error">
          {status &&
            status.error &&
            status.error.data &&
            status.error.data.field_errors &&
            status.error.data.field_errors.permissions}
        </span>
      </Form>
    );
  }
}

