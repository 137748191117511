import React from 'react';
import { ListGroup } from 'reactstrap';
import TopicsItem from './TopicItem/TopicItem';

export default class TopicsList extends React.Component {
  render() {
    const { topicsList, onEditTopic, onDeleteTopic, notificationRef } =
      this.props;

    return (
      <ListGroup>
        {topicsList.map((topic, index) => (
          <TopicsItem
            key={`${topic}_${index}`}
            topic={topic}
            topicId={index}
            onEditTopic={onEditTopic}
            onDeleteTopic={onDeleteTopic}
            notificationRef={notificationRef}
          />
        ))}
      </ListGroup>
    );
  }
}
