import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Users from '../components/index';
import State from '../../../redux/Users';
import RolesState from '../../../redux/Roles';

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      getUser: State.actions.getUser,
      getUserFees: State.actions.getUserFees,
      disableTradePass: State.actions.disableTradePass,
      disableAntiphishingKey: State.actions.disableAntiphishingKey,
      enableDetectIP: State.actions.enableDetectIP,
      disableDetectIP: State.actions.disableDetectIP,
      disableGoogleAuth: State.actions.disableGoogleAuth,
      disableSMSAuth: State.actions.disableSMSAuth,
      changeBlockDepositCrypto: State.actions.changeBlockDepositCrypto,
      changeBlockDepositFiat: State.actions.changeBlockDepositFiat,
      changeBlockWithdrawCrypto: State.actions.changeBlockWithdrawCrypto,
      changeBlockWithdrawFiat: State.actions.changeBlockWithdrawFiat,
      setCurrentUserField: State.actions.setCurrentUserField,
      setWithdrawSettingsField: State.actions.setWithdrawSettingsField,
      updateUser: State.actions.updateUser,
      updateSettings: State.actions.updateSettings,
      getWithdrawSettings: State.actions.getWithdrawSettings,
      updateWithdrawSettings: State.actions.updateWithdrawSettings,
      updateFees: State.actions.updateFees,
      updatePassword: State.actions.updateUserPassword,
      getRoles: RolesState.actions.getRoles,
    },
    dispatch
  );

const mapStateToProps = state => ({
  currentUser: State.selectors.currentUser(state),
  fees: State.selectors.currentUserFees(state),
  status: State.selectors.status(state),
  roles: RolesState.selectors.roles(state),
  withdrawSettings: State.selectors.withdrawSettings(state),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Users);

