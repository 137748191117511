import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AnnouncementDetails from '../components/index';
import AnnouncementsState from '../../../redux/Announcements';

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      addAnnouncement: AnnouncementsState.actions.addAnnouncement,
      editAnnouncement: AnnouncementsState.actions.editAnnouncement,
      setAnnouncementField: AnnouncementsState.actions.setAnnouncementField,
      getAnnouncement: AnnouncementsState.actions.getAnnouncement,
      getAnnouncementSettings:
        AnnouncementsState.actions.getAnnouncementsSettings,
      resetForm: AnnouncementsState.actions.resetForm,
    },
    dispatch,
  );

const mapStateToProps = (state) => ({
  status: AnnouncementsState.selectors.status(state),
  announcement: AnnouncementsState.selectors.announcement(state),
  announcements: AnnouncementsState.selectors.announcements(state),
  announcementsSettings:
    AnnouncementsState.selectors.announcementsSettings(state),
});

export default connect(mapStateToProps, mapActionsToProps)(AnnouncementDetails);
