import * as c from './constants';
import {asyncActionCreator, actionCreator} from '../../utils/redux';

export const getChains = asyncActionCreator(c.GET_CHAINS_PENDING, c.GET_CHAINS_SUCCESS, c.GET_CHAINS_ERROR);
export const getCoins = asyncActionCreator(c.GET_COINS_PENDING, c.GET_COINS_SUCCESS, c.GET_COINS_ERROR);
export const getPresaleCoins = asyncActionCreator(c.GET_PRESALE_COINS_PENDING, c.GET_PRESALE_COINS_SUCCESS, c.GET_PRESALE_COINS_ERROR);
export const getCoin = asyncActionCreator(c.GET_COIN_PENDING, c.GET_COIN_SUCCESS, c.GET_COIN_ERROR);
export const addCoin = asyncActionCreator(c.ADD_COIN_PENDING, c.ADD_COIN_SUCCESS, c.ADD_COIN_ERROR);
export const editCoin = asyncActionCreator(c.EDIT_COIN_PENDING, c.EDIT_COIN_SUCCESS, c.EDIT_COIN_ERROR);
export const changeBlockDeposit = asyncActionCreator(c.CHANGE_BLOCK_DEPOSIT_PENDING, c.CHANGE_BLOCK_DEPOSIT_SUCCESS, c.CHANGE_BLOCK_DEPOSIT_ERROR);
export const changeBlockWithdraw = asyncActionCreator(c.CHANGE_BLOCK_WITHDRAW_PENDING, c.CHANGE_BLOCK_WITHDRAW_SUCCESS, c.CHANGE_BLOCK_WITHDRAW_ERROR);

export const getChain = asyncActionCreator(c.GET_CHAIN_PENDING, c.GET_CHAIN_SUCCESS, c.GET_CHAIN_ERROR);
export const addChain = asyncActionCreator(c.ADD_CHAIN_PENDING, c.ADD_CHAIN_SUCCESS, c.ADD_CHAIN_ERROR);
export const editChain = asyncActionCreator(c.EDIT_CHAIN_PENDING, c.EDIT_CHAIN_SUCCESS, c.EDIT_CHAIN_ERROR);

export const disableCoin = asyncActionCreator(c.DELETE_COIN_PENDING, c.DELETE_COIN_SUCCESS, c.DELETE_COIN_ERROR);
export const disableChain = asyncActionCreator(c.DELETE_CHAIN_PENDING, c.DELETE_CHAIN_SUCCESS, c.DELETE_CHAIN_ERROR);

export const setField = actionCreator(c.SET_FIELD);
export const setCoinField = actionCreator(c.SET_COIN_FIELD);
export const setChainField = actionCreator(c.SET_CHAIN_FIELD);
export const resetForm = actionCreator(c.RESET_COIN_STATE);